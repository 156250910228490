<template>
  <el-select size="mini" v-model="selfDelivery">
    <el-option
      v-for="item in SelfDeliveryAllList"
      :key="item.Key"
      :label="item.Name"
      :value="item.Key"
    ></el-option>
  </el-select>
</template>
<script>
export default {
  name: "SelfDeliveryControl",
  props: ["useAllOpt", "value"],
  data: function () {
    return {
      selfDelivery: null,
    };
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal != oldVal) {
        this.selfDelivery = newVal;
      }
    },
    selfDelivery(newVal) {
      this.$emit("input", newVal);
    },
  },
  computed: {
    SelfDeliveryAllList() {
      if (this.useAllOpt) {
        return [
          { Key: "All", Name: "Все" },
          ...this.$store.getters.selfDeliveryList,
        ];
      }
      return this.$store.getters.selfDeliveryList;
    },
  },
  methods: {},
  mounted: function () {
    this.selfDelivery = this.value;
  },
};
</script>