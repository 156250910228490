<template>
  <el-select
    size="mini"
    v-model="payType"
    multiple
    collapse-tags
    @change="handleChange"
  >
    <el-option
      v-for="item in PayTypeAllList"
      :key="item.Key"
      :label="item.Name"
      :value="item.Key"
    ></el-option>
  </el-select>
</template>
<script>
export default {
  name: "PayTypeListControl",
  props: ["clearVal"],
  data: function () {
    return {
      payType: [],
      oldVal: [],
    };
  },
  watch: {
    clearVal(newV, oldV) {
      this.payType = null;
      this.$emit("input", null);
    },
  },
  computed: {
    PayTypeAllList() {
      return [{ Key: "All", Name: "Все" }, ...this.$store.getters.payTypeList];
    },
    DictValLength() {
      return this.$store.getters.payTypeList.length;
    },
  },
  methods: {
    handleChange(newVal) {
      this.handleValue(newVal, true);
    },
    handleValue(newVal, emit) {
      let allOptInNewVal = newVal.indexOf("All") > -1;
      let allOptInOldVal = this.oldVal.indexOf("All") > -1;
      let newValLength = newVal.filter((value) => value != "All").length;
      if (allOptInNewVal && !allOptInOldVal) {
        let allVals = [];
        this.$store.getters.payTypeList.forEach((element) => {
          allVals.push(element.Key);
        });
        allVals.push("All");
        this.payType = allVals;
      } else if (!allOptInNewVal && allOptInOldVal) {
        this.payType = [];
      } else if (
        !allOptInNewVal &&
        !allOptInOldVal &&
        newValLength == this.DictValLength
      ) {
        this.payType.push("All");
      } else if (
        allOptInNewVal &&
        allOptInOldVal &&
        newValLength <= this.DictValLength
      ) {
        let pos = newVal.indexOf("All");
        if (pos > -1) {
          this.payType.splice(pos, 1);
        }
      }
      this.oldVal = this._.cloneDeep(this.payType);
      if (emit) {
        this.$emit(
          "input",
          this.payType.filter((value) => value != "All")
        );
      }
    },
  },
};
</script>