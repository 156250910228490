<template>
  <div>
    <el-menu
      size="mini"
      mode="horizontal"
      background-color="#dcd9d9"
      active-text-color="#7d7d7d"
      text-color="black"
      @select="handleSelect"
    >
      <el-submenu
        v-if="$store.getters.pageSettings.IsAccessToSecure"
        index="Secure"
      >
        <template slot="title">Безопасность</template>
        <el-menu-item index="/Secure/Role">Пользователи</el-menu-item>
        <el-menu-item index="/Secure/RoleGroup">Группы</el-menu-item>
        <el-menu-item index="/Secure/Page">Страницы</el-menu-item>
      </el-submenu>
      <el-submenu index="Order">
        <template slot="title">Создать заказ</template>
        <el-menu-item index="/Order/OrderEditDelivery?orderType=0"
          >Заявка на доставку</el-menu-item
        >
        <el-menu-item index="/Order/OrderEditDelivery?orderType=4"
          >Клиентский возврат</el-menu-item
        >
        <el-menu-item index="/Order/OrderEditDelivery?orderType=5"
          >Обмен</el-menu-item
        >
        <el-menu-item index="/Order/OrderEditPickup">Самовывоз</el-menu-item>
        <el-menu-item index="/Order/OrderEditCargoPickup">Забор</el-menu-item>
        <el-menu-item index="/Order/OrderEditFreight">Фрахт</el-menu-item>
      </el-submenu>
      <el-submenu index="DeliveryCompany">
        <template slot="title">Заказы</template>
        <el-menu-item index="/DeliveryCompany/OrderSearch"
          >Поиск заказов</el-menu-item
        >
        <el-menu-item
          index="/DeliveryCompany/Shipment"
          v-if="$store.getters.pageSettings.IsAccessToShipment"
          >Сформировать отгрузку</el-menu-item
        >
        <el-menu-item
          index="/DeliveryCompany/Trip"
          v-if="$store.getters.pageSettings.IsAccessToTrips"
          >Рейс</el-menu-item
        >
        <el-menu-item
          index="/DeliveryCompany/GroupOperations"       
          v-if="$store.getters.pageSettings.IsAccessToGroupOperations"   
          >Групповые операции</el-menu-item
        >
      </el-submenu>
      <el-submenu index="Reports">
        <template slot="title">Отчеты</template>
        <el-menu-item index="/Reports/Deliv">Отчет по доставкам</el-menu-item>
        <el-menu-item
          index="/Reports/DelivCnt"
          v-if="$store.getters.pageSettings.IsAccessToReportDelivCnt"
          >Количество доставок</el-menu-item
        >
        <el-menu-item index="/Reports/DelivDetail"
          >Отчет по доставкам с детализацией по заказам</el-menu-item
        >
        <el-menu-item index="/Reports/DelivAgent">Отчет агента</el-menu-item>
      </el-submenu>
      <el-submenu index="Settings">
        <template slot="title">Настройка</template>
        <el-menu-item
          v-if="$store.getters.pageSettings.IsAccessToSettings"
          index="/Settings/Shops"
          >Магазины</el-menu-item
        >
        <el-menu-item
          v-if="$store.getters.pageSettings.IsAccessToSettings"
          index="/Settings/Logs"
          >Логи</el-menu-item
        >
        <el-menu-item
          v-if="$store.getters.pageSettings.IsAccessToSettings"
          index="/Settings/Docs"
          >Документация</el-menu-item
        >
        <el-menu-item
          v-if="$store.getters.pageSettings.IsAccessToSettings"
          index="/Settings/FileDownloadTemplates"
          >Шаблоны</el-menu-item
        >
        <el-menu-item
          v-if="$store.getters.pageSettings.IsAccessToSettings"
          index="/Settings/ServiceWorker"
          >Планировщик задач</el-menu-item
        >
        <el-menu-item
          v-if="$store.getters.pageSettings.IsAccessToSettings"
          index="/Settings/Directory"
          >Справочники</el-menu-item
        >
        <el-menu-item
          v-if="$store.getters.pageSettings.IsAccessToSettings"
          index="/Settings/Admin"
          >Администрирование</el-menu-item
        >
        <el-menu-item index="/Settings/SetPassword"
          >Сменить пароль</el-menu-item
        >
      </el-submenu>
      <el-menu-item index="1" @click="logout">Выход</el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "Navigation",
  data: function () {
    return {};
  },
  methods: {
    handleSelect(key, keyPath) {
      this.$router.push(key);
    },
    logout() {
      this.$store
        .dispatch("logout")
        .then(() => this.$router.push("/"))
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style scoped>
</style>
