<template>
  <div>
    <el-table
      :data="tableData"
      size="mini"
      :header-cell-class-name="'headerCell'"
    >
      <el-table-column prop="who" label="Кто" align="center"> </el-table-column>
      <el-table-column prop="modifyDate" label="Когда" align="center">
      </el-table-column>
      <el-table-column prop="tariffName" label="Тариф" align="center">
      </el-table-column>
      <el-table-column prop="tariffId" label="#Тариф" align="center">
      </el-table-column>
      <el-table-column prop="tariffType" label="Тип тарифа" align="center">
      </el-table-column>
      <el-table-column prop="weight" label="Вес заказа" align="center">
        <template slot-scope="scope">
          <div>{{scope.row.calcByVolumetricWeight?scope.row.chargeableVolumetricWeight:scope.row.weight}}</div>
        </template>
      </el-table-column>
      <el-table-column prop="weightCategory" label="Категория" align="center">
      </el-table-column>
      <el-table-column prop="regionName" label="Регион" align="center">
      </el-table-column>
      <el-table-column prop="serviceDC" label="Стоимость" align="center">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "OrderComponentServSDHist",
  props: ["orderid"],
  data: function () {
    return {
      tableData: [],
    };
  },
  watch: {
    orderid: function (newVal, oldVal) {
      if (newVal > 0) {
        this.getServiceSDHistory(newVal);
      }
    },
  },
  mounted: function () {
    this.getServiceSDHistory(this.orderid);
  },
  methods: {
    getServiceSDHistory: function (orderid) {
      var self = this;
      this.axios
        .post("/OrderEdit/getSdHistory", {
          orderId: orderid,
        })
        .then(function (response) {
          self.tableData = response.data.Data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    refresh() {
      this.getServiceSDHistory(this.orderid);
    },
  },
};
</script>

<style scoped>
</style>
