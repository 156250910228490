<template>
  <div>
    <div style="margin: 10px">
      <el-select
        size="mini"
        v-model="schemeId"
        placeholder="Выберите схему"
        @change="handleSchemeChange"
      >
        <el-option
          v-for="item in schemes"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>
    </div>
    <div style="margin: 10px">
      <el-button @click="saveZones" size="mini">Сохранить</el-button>
    </div>
    <el-table
      :data="zones"
      size="mini"
      :header-cell-class-name="'headerCell'"
    >
      <el-table-column prop="Id" label="Id" align="center"> </el-table-column>
      <el-table-column prop="Name" label="Название зоны" align="center">
      </el-table-column>
      <el-table-column
        prop="IntervalSubstRequired"
        label="Признак замены интервала"
        align="center"
      >
        <template slot-scope="scope">
          <el-checkbox
            size="mini"
            v-model="scope.row.IntervalSubstRequired"
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column
        prop="TimeDeliveryFrom"
        label="Интервал от"
        align="center"
      >
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.TimeDeliveryFrom"
            maxlength="100"
            style="max-width: 100px"            
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="TimeDeliveryTo" label="Интервал до" align="center">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.TimeDeliveryTo"
            maxlength="100"
            style="max-width: 100px"            
          ></el-input>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
  
          
  <script>
export default {
  name: "Zones",
  props: [],
  data: function () {
    return {
      schemeId: null,
      schemes: [],
      mapId: null,
      maps: [],
      polygonsToZones: [],
      polygons: [],
      loading: false,
      maptypes: [],
    };
  },
  computed: {
    zones() {
      let schemeId = this.schemeId;
      if (!schemeId || !this.schemes) return [];

      let scheme = this.schemes.find((item) => item.Id == schemeId);
      if (!scheme) return [];
      return scheme.Zones;
    },
  },
  methods: {
    saveZones() {
      if (!this.schemeId) {
        this.showError("Не выбрана схема");
        return;
      }

      let self = this;
      self.startLoading();
      this.axios
        .post("/Admin/saveZones", {
          zones: self.zones,
        })
        .then(function (response) {
          self.showSuccess("Сохранение завершено");
          self.stopLoading();
        })
        .catch(function (error) {
          self.showError("Произошла ошибка при сохранении");
          self.stopLoading();
          console.log(error);
        });
    },
    handleSchemeChange() {
      let schemeId = this.schemeId;
      let scheme = this.schemes.find((item) => item.Id == schemeId);
      if (scheme) {
        if (scheme.IdMap) {
          this.mapId = scheme.IdMap;
        } else {
          this.mapId = null;
        }
      }
    },
    loadData() {
      var schemesRequest = this.axios.post("/Admin/getSchemes", {});
      this.startLoading();
      var self = this;
      this.axios
        .all([schemesRequest])
        .then(
          self.axios.spread((...responses) => {
            var responseSchemesData = responses[0];
            self.schemes = responseSchemesData.data.Data;
            self.stopLoading();
          })
        )
        .catch((error) => {
          console.log(error);
          self.stopLoading();
        });
    },
    showSuccess(message) {
      this.$message({
        message: message,
        type: "success",
      });
    },
    showError(message) {
      this.$message.error(message);
    },
    startLoading() {
      this.$loading();
    },
    stopLoading() {
      this.$loading().close();
    },
  },
  mounted: function () {
    this.loadData();
  },
};
</script>
  