<template>
  <el-select
    size="mini"
    v-model="deliveryPlace"
    :disabled="disabled"
    placeholder="Выберите из списка"
  >
    <el-option
      v-for="item in DeliveryPlaceAllList"
      :key="item.Key"
      :label="item.Name"
      :value="item.Key"
    ></el-option>
  </el-select>
</template>
<script>
export default {
  name: "DeliveryTerminalControl",
  props: ["useAllOpt", "value", "disabled"],
  data: function () {
    return {
      deliveryPlace: null,
    };
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal != oldVal) {
        if (
          this.DeliveryPlaceAllList.some((placeId) => placeId.Key === newVal)
        ) {
          this.deliveryPlace = newVal;
        } else {
          this.deliveryPlace = null;
        }
      }
    },
    deliveryPlace(newVal) {
      this.$emit("input", newVal);
      this.$emit("change", newVal);
    },
  },
  computed: {
    DeliveryPlaceAllList() {
      if (this.useAllOpt) {
        return [
          { Key: "All", Name: "Все" },
          ...this.$store.getters.deliveryTerminalsList,
        ];
      }
      return this.$store.getters.deliveryTerminalsList;
    },
  },
  methods: {},
  mounted: function () {
    this.deliveryPlace = this.value;
  },
};
</script>