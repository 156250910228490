import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter);

//страницы
import Default from '../components/Default.vue'

import Role from '../components/Role.vue'
import RoleGroup from '../components/RoleGroup.vue'
import Pages from '../components/Pages.vue'

import OrderEditDelivery from '../components/OrderEditDelivery.vue'
import OrderEditPickup from '../components/OrderEditPickup.vue'
import OrderEditCargoPickup from '../components/OrderEditCargoPickup.vue'
import OrderEditFreight from '../components/OrderEditFreight.vue'

import OrderSearch from '../components/OrderSearch.vue'
import Shipment from '../components/Shipment.vue'

import ReportDelivery from '../components/ReportDelivery.vue'
import ReportDelivCnt from '../components/ReportDelivCnt.vue'
import ReportDeliveryDetail from '../components/ReportDeliveryDetail.vue'
import ReportAgent from '../components/ReportAgent.vue'

import SetPass from '../components/SetPass.vue'
import Shops from '../components/Shops.vue'
import Shop from '../components/ShopEdit.vue'
import Docs from '../components/Docs.vue'
import Logs from '../components/Logs.vue'
import FileDownloadTemplates from '../components/FileDownloadTemplates.vue'
import Admin from '../components/Admin.vue'

import Trip from '../components/Trip.vue'
import ServiceWorker from '../components/ServiceWorker.vue'
import Directory from '../components/Directory.vue'
import GroupOperations from '../components/GroupOperations.vue'

const routes = [
    { path: '/', component: Default },
    { path: '/Default', component: Default },
    { path: '/Secure/Role', component: Role },
    { path: '/Secure/RoleGroup', component: RoleGroup },
    { path: '/Secure/Page', component: Pages },
    { path: '/Order/OrderEditDelivery', component: OrderEditDelivery },
    { path: '/Order/OrderEditPickup', component: OrderEditPickup },
    { path: '/Order/OrderEditCargoPickup', component: OrderEditCargoPickup },
    { path: '/Order/OrderEditFreight', component: OrderEditFreight },
    { path: '/DeliveryCompany/OrderSearch', component: OrderSearch },
    { path: '/DeliveryCompany/Shipment', component: Shipment },
    { path: '/DeliveryCompany/GroupOperations', component: GroupOperations },
    { path: '/Reports/Deliv', component: ReportDelivery },
    { path: '/Reports/DelivCnt', component: ReportDelivCnt },
    { path: '/Reports/DelivDetail', component: ReportDeliveryDetail },
    { path: '/Reports/DelivAgent', component: ReportAgent },
    { path: '/Settings/Shops', component: Shops },
    { path: '/Settings/ShopEdit', component: Shop },
    { path: '/Settings/Logs', component: Logs },
    { path: '/Settings/Docs', component: Docs },
    { path: '/Settings/FileDownloadTemplates', component: FileDownloadTemplates },
    { path: '/Settings/SetPassword', component: SetPass },
    { path: '/Settings/ServiceWorker', component: ServiceWorker },
    { path: '/Settings/Directory', component: Directory },
    { path: '/Settings/Admin', component: Admin },

    { path: '/DeliveryCompany/Trip', component: Trip }
  ]

export const router = new VueRouter({
    mode: 'history',
    routes // сокращённая запись для `routes: routes`
  })