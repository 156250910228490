<template>
  <div>
    <div style="font-weight: bold; font-size: xx-large; color: dimgray">
      Смена пароля
    </div>
    <div style="margin: 10px; color: dimgray">
      В пароле должно быть не менее 5ти символов. В целях безопасности
      желательно использовать в пароле и цифры и буквы(в верхней и нижней
      раскладке). Перед заполнением убедитесь, что у вас стоит английская
      раскладка.
    </div>
    <el-form
      size="mini"
      style="width: 600px"
      ref="passData"
      :model="passData"
      :rules="rules"
      label-width="250px"
    >
      <el-form-item label="Старый пароль" prop="OldPassword">
        <el-input
          size="mini"
          v-model="passData.OldPassword"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item label="Новый пароль" prop="NewPassword">
        <el-input
          size="mini"
          v-model="passData.NewPassword"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item label="Новый пароль (подтверждение)" prop="NewPasswordRepl">
        <el-input
          size="mini"
          v-model="passData.NewPasswordRepl"
          show-password
        ></el-input>
      </el-form-item>
    </el-form>
    <el-button type="success" size="mini" @click="savePassword"
      >Сохранить</el-button
    >
  </div>
</template>

<script>
export default {
  name: "SetPass",
  data: function () {
    var checkNewPass = (rule, value, callback) => {
      if (!this.passData.NewPassword) {
        callback("Введите новый пароль");
      } else if (this.passData.NewPassword.length < 5) {
        callback("Длина пароля должна не меньше 5 символов");
      } else {
        callback();
      }
    };
    var checkNewPassRepl = (rule, value, callback) => {
      if (this.passData.NewPassword !== this.passData.NewPasswordRepl) {
        callback("Подтверждение пароля не совпадает с новым");
      } else {
        callback();
      }
    };
    return {
      passData: {
        OldPassword: "",
        NewPassword: "",
        NewPasswordRepl: "",
      },
      rules: {
        OldPassword: [
          { required: true, message: "Введите старый пароль", trigger: "blur" },
        ],
        NewPassword: [{ validator: checkNewPass, trigger: "blur" }],
        NewPasswordRepl: [{ validator: checkNewPassRepl, trigger: "blur" }],
      },
    };
  },
  methods: {
    savePassword() {
      this.$refs["passData"].validate((valid) => {
        if (valid) {
          var self = this;
          this.axios
            .post("/SetPassword/savePassword", {
              passData: this.passData,
            })
            .then(function (response) {
              if (response.data.Error) {
                self.showError(response.data.Error);
              } else if (!response.data.Success) {
                self.showError("Произошла ошибка при сохранении");
              } else {
                self.showSuccess("Новый пароль успешно сохранен");
                self.passData = {};
              }
            })
            .catch(function (error) {
              self.showError("Произошла ошибка при сохранении");
            });
        }
      });
    },
    showSuccess(message) {
      this.$message({
        message: message,
        type: "success",
      });
    },
    showError(message) {
      this.$message.error(message);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>