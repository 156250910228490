<template>
  <el-select
    size="mini"
    v-model="shopMainId"
    :disabled="disabled"
    filterable
    placeholder="Выберите из списка"
    @change="handleChange"
  >
    <el-option
      v-for="item in ShopMainList"
      :key="item.InternetShopId"
      :label="item.Name"
      :value="item.InternetShopId"
    ></el-option>
  </el-select>
</template>
<script>
export default {
  name: "MainShopControl",
  props: ["value", "disabled", "selectAny","isEnable"],
  data: function () {
    return {
      shopMainId: null,
    };
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal != oldVal) {
        if (
          this.ShopMainList.some((shopId) => shopId.InternetShopId === newVal)
        ) {
          this.shopMainId = newVal;
        } else {
          this.shopMainId = null;
        }
      }
    },
    shopMainId(newVal) {
      this.$emit("input", newVal);
      let item = this.ShopMainList.find(function (element) {
        if (element.InternetShopId !== newVal) {
          return false;
        }
        return true;
      });
      //this.$emit("change", item);
    },
  },
  computed: {
    ShopMainList() {
      let self = this;
      return this.$store.getters.organizationList.filter(
        (shop) => shop.ParentId == null && (!self.isEnable || shop.IsEnable)
      );
    },
  },
  methods: {
    handleChange(val){
      let shop = this.getShop(val);
      this.$emit("change", shop);
    },
    getShop(internetShopId) {
      let shop = null;
      this.$store.getters.organizationList.filter(function (item) {
        if (item.ParentId == null && item.InternetShopId == internetShopId) {
          shop = item;
        }
      });
      return shop;
    },
  },
  mounted: function () {
    if (this.value) {
      this.shopMainId = this.value;
      this.$emit("change",this.getShop(this.shopMainId));
    } else if (this.selectAny && this.ShopMainList.length === 1) {
      this.shopMainId = this.ShopMainList[0].InternetShopId;
      this.$emit("change",this.getShop(this.shopMainId));
    }
  },
};
</script>