<template>
  <div>
    <el-table
      :data="tableData"
      size="mini"
      :header-cell-class-name="'headerCell'"
      v-loading="loading"
    >
      <el-table-column prop="whoLastChange" label="Кто" align="center">
      </el-table-column>
      <el-table-column prop="modifyDate" label="Изменено" align="center">
        <template slot-scope="scope">
          <div>{{ getDateTime(scope.row.modifyDate) }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="deliveryDate"
        label="Дата доставки"
        width="120"
        align="center"
      >
        <template slot-scope="scope">
          <div>{{ getDate(scope.row.deliveryDate) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="Состояние" align="center">
      </el-table-column>
      <el-table-column prop="statusName" label="Статус" align="center">
      </el-table-column>
      <el-table-column prop="statusId" label="Код" width="70" align="center">
      </el-table-column>
      <el-table-column
        prop="reasonName"
        label="Причина отказа/переноса"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="carNumber" label="Номер а/м" align="center">
      </el-table-column>
      <el-table-column prop="pay_name" label="Форма оплаты" align="center">
      </el-table-column>
      <el-table-column
        prop="sourcePlaceId"
        label="Регион приема"
        width="70"
        align="center"
      >
        <template slot-scope="scope">
          <div>
            {{
              scope.row.sourcePlaceId == 0
                ? "Отсутвует"
                : scope.row.sourcePlaceId == 1
                ? "MSW"
                : "SPB"
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="deliveryPlaceId"
        label="Регион доставки"
        width="70"
        align="center"
      >
        <template slot-scope="scope">
          <div>
            {{
              scope.row.deliveryPlaceId == 0
                ? "Отсутвует"
                : scope.row.deliveryPlaceId == 1
                ? "MSW"
                : "SPB"
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="deliveryCost"
        label="Ст-ть доставки"
        width="70"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="PiecesOfFreight"
        label="Число мест"
        width="70"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="ProcessingTerminal"
        label="Терминал обработки"
        width="80"
        align="center"
      >
        <template slot-scope="scope">
          <div>
            {{
              scope.row.ProcessingTerminal == 0
                ? "Отсутвует"
                : scope.row.ProcessingTerminal == 1
                ? "MSW"
                : "SPB"
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="dateEvent"
        label="Время события в МП"
        align="center"
      >
        <template slot-scope="scope">
          <div>{{ getDateTime(scope.row.dateEvent) }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="chargeableWeight"
        label="Вес"
        width="70"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="DeliveryServiceLevel"
        label="Использовать СпецТариф"
        width="70"
        align="center"
      >
        <template slot-scope="scope">
          <div>
            {{
              scope.row.DeliveryServiceLevel
                ? "VIP"
                : "Обычный"
            }}
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { dates } from "../../scripts/dates";
export default {
  name: "OrderComponentHistory",
  props: ["orderid", "isLoad"],
  data: function () {
    return {
      tableData: [],
      loading: false,
    };
  },
  methods: {
    getDate(dat) {
      return dates.methods.toStringDate(dat);
    },
    getDateTime(dat) {
      return dates.methods.toStringDateTime(dat);
    },
    refresh: function () {
      this.loadData(this.orderid);
    },
    loadData: function (orderId) {
      var self = this;
      self.loading = true;
      this.axios
        .post("/OrderEdit/getOrderHistory", {
          orderId: orderId,
        })
        .then(function (response) {
          self.loading = false;
          self.tableData = response.data.Data;
        })
        .catch(function (error) {
          self.loading = false;
          console.log(error);
        });
    },
  },
  watch: {
    orderid: function (newVal, oldVal) {
      if (newVal > 0 && this.isLoad) {
        //this.loadData(newVal);
      }
    },
  },
  mounted: function () {
    this.loadData(this.orderid);
  },
};
</script>

<style scoped>
</style>
