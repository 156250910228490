<template>
  <el-input
    :disabled="disabled"
    class="inputControl"
    :min="min"
    size="mini"
    v-model="inputValueTxt"
    @change="handleChange"
    style="text-align: center"
  ></el-input>
</template>

<script>
export default {
  name: "NumberControl",
  props: ["value", "disabled", "min", "precision"],
  data: function () {
    return {
      inputValueTxt: "",
      inputValue: null,
      inputValueOldTxt: "",
    };
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal != this.inputValue) {
        this.inputValue = newVal;
        if (!isNaN(this.precision) && !isNaN(newVal)) {
          this.inputValueTxt = newVal.toFixed(this.precision);
        } else {
          this.inputValueTxt = newVal;
        }
      }
    },
  },
  mounted: function () {
    this.inputValue = this.value;
    this.inputValueTxt = this.getTxtVal(this.inputValue);
  },
  methods: {
    getTxtVal(val) {
      if (!isNaN(this.precision) && !isNaN(val)) {
        return val.toFixed(this.precision);
      } else {
        return val;
      }
    },
    handleChange(val) {
      let parseVal = this.parseValue(val);
      if (!isNaN(parseVal)) {
        this.$emit("input", parseVal);
        this.inputValue = parseVal;

        if (!isNaN(this.precision)) {
          this.inputValueTxt = parseVal.toFixed(this.precision);
        } else {
          this.inputValueTxt = parseVal;
        }

        this.inputValueOldTxt = this.inputValueTxt;
      } else {
        this.inputValueTxt = this.inputValueOldTxt;
      }
    },
    parseValue(val) {
      if (val === undefined || val === null) {
        return val;
      }
      let parseValue = Number(val.replace(",", "."));
      return parseValue;
    },
  },
};
</script>

<style scoped>
.inputControl >>> .el-input__inner {
  text-align: center;
}
</style>
