<template>
  <div v-loading="fullScreenLoading" class="vueTemplate">
    <div style="padding-left: 10px">
      <el-checkbox v-model="edit" v-if="editableBarcodes()" size="mini"
        >Включить редактирование</el-checkbox
      >
      <el-checkbox @change="showDeleted" v-model="deleted" size="mini"
        >Показывать удаленные</el-checkbox
      >
      <el-button v-if="edit" @click="saveBarcodes" size="mini"
        >Сохранить</el-button
      >
    </div>
    <el-table
      :data="tableData"
      :row-class-name="getRowClass"
      size="mini"
      :header-cell-class-name="'headerCell'"
      align="center"
    >
      <el-table-column prop="barcode" label="Штрихкод" align="center">
        <template slot-scope="scope">
          <el-input
            :disabled="
              !edit ||
              (!!scope.row.stockDate && !superEditable) ||
              scope.row.isDeleted
            "
            size="small"
            v-model="scope.row.barcode"
            controls-position="right"
            maxlength="50"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="setDate" label="Дата" align="center">
      </el-table-column>
      <el-table-column prop="stockDate" label="Дата поступления" align="center">
      </el-table-column>
      <el-table-column
        v-if="isAgregator"
        prop="type"
        label="Расширение файла этикетки"
        align="center"
      >
      </el-table-column>
      <el-table-column
        v-if="isAgregator"
        prop="printFormat"
        label="Формат печати этикетки"
        align="center"
      >
      </el-table-column>
      <el-table-column v-if="isAgregator" label="Скачать" align="center">
        <template slot-scope="scope">
          <el-button size="mini" @click="downloadMarking(scope.row)"
            >Скачать</el-button
          >
          <el-button
            v-show="scope.row.type == 'svg'"
            size="mini"
            @click="downloadMarkingPDF(scope.row)"
            >Скачать PDF</el-button
          >
        </template>
      </el-table-column>
      <el-table-column v-if="edit" label="Удалить" align="center">
        <template slot-scope="scope">
          <el-button
            v-show="
              (!scope.row.stockDate || superEditable) &&
              !scope.row.isDeleted &&
              delEnabled &&
              !scope.row.delMark
            "
            icon="el-icon-delete"
            @click="deleteBarcode(scope, true)"
          ></el-button>
          <el-button
            v-show="
              (!scope.row.stockDate || superEditable) &&
              !scope.row.isDeleted &&
              scope.row.delMark
            "
            icon="el-icon-plus"
            @click="deleteBarcode(scope, false)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { files } from "../../scripts/files";
export default {
  name: "OrderComponentBarcode",
  props: ["orderid", "editable", "supereditable", "isAgregator"],
  data: function () {
    return {
      tableData: [],
      excluded: false,
      deleted: false,
      edit: false,
      fullScreenLoading: false
    };
  },
  watch: {
    orderid: function (newVal, oldVal) {
      this.getOrderBarcodes(newVal, this.excluded, this.deleted);
    },
    tableData: function (newVal) {
      this.$emit(
        "update-barcodes",
        newVal.filter((item) => !item.isDeleted).length
      );
    },
  },
  computed: {
    superEditable() {
      return this.supereditable;
    },
    delEnabled() {
      return (
        this.tableData.filter((item) => !item.isDeleted && !item.delMark)
          .length > 1
      );
    },
  },
  methods: {
    refresh: function () {
      this.getOrderBarcodes(this.orderid, this.excluded, this.deleted);
    },
    downloadMarkingPDF(row) {
      let self = this;
      this.axios
        .post("/OrderEdit/getOrderBarcodeMarking", {
          orderId: self.orderid,
          barcode: row.barcode,
          toPDF: true,
        })
        .then(function (response) {
          files.methods.downloadBase64Doc(
            response.data.Data.FileName,
            response.data.Data.FileData
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    downloadMarking(row) {
      let self = this;
      this.axios
        .post("/OrderEdit/getOrderBarcodeMarking", {
          orderId: self.orderid,
          barcode: row.barcode,
          toPDF: false,
        })
        .then(function (response) {
          files.methods.downloadBase64Doc(
            response.data.Data.FileName,
            response.data.Data.FileData
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getRowClass(scope, rowIndex) {
      if (scope.row.stockDate) {
        return "isAccepted";
      } else if (scope.row.delMark || scope.row.isDeleted) {
        return "isDeleted";
      } else {
        return "";
      }
    },
    editableBarcodes: function () {
      if (this.orderid > 0 && (this.editable || this.supereditable))
        return true;
      return false;
    },
    deleteBarcode: function (item, delMark) {
      //this.tableData.splice(item.$index, 1);
      this.$set(this.tableData[item.$index], "delMark", delMark);
    },
    addBarcode: function () {
      var newRow = { id: null, piece: 1 };
      this.tableData = [...this.tableData, newRow];
      this.numOfBarcodes = this.tableData.length;
    },
    saveBarcodesWithConfirm(updatePieces) {
      let self = this;
      self
        .$confirm("Удалить связанные товары?", "Warning", {
          confirmButtonText: "Да",
          cancelButtonText: "Нет",
          type: "warning",
        })
        .then(() => {
          this.saveBarcodesConfirm(updatePieces, true);
        })
        .catch(() => {
          this.saveBarcodesConfirm(updatePieces, false);
        });
    },
    checkAndSaveBarcodes: function (updatePieces) {
      let self = this;
      let barcodes = this.getBarcodesToSave();
      this.axios
        .post("/OrderEdit/checkExistBindItems", {
          orderId: self.orderid,
          barcodes: barcodes,
        })
        .then(function (response) {
          if (response.data.Exists) {
            self.saveBarcodesWithConfirm(updatePieces);
          }
          else{
            self.saveBarcodesConfirm(updatePieces, false);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getBarcodesToSave() {
      let barcodes = this.tableData
        .filter((item) => !item.isDeleted)
        .map(function (item) {
          return {
            id: item.id,
            barcode: item.barcode,
            piece: item.piece,
            delMark: item.delMark,
          };
        });

      return barcodes;
    },
    saveBarcodes: function () {
      let self = this;
      this.$confirm("Изменить число мест?", "Warning", {
        confirmButtonText: "Да",
        cancelButtonText: "Нет",
        type: "warning",
      })
        .then(() => {
          self.checkAndSaveBarcodes(true);
        })
        .catch(() => {
          self.checkAndSaveBarcodes(false);
        });
    },
    saveBarcodesConfirm: function (updatePieces, delBindItems) {
      var self = this;
      var barcodes = this.getBarcodesToSave();
      this.axios
        .post("/OrderEdit/updateOrderBarcodes", {
          orderId: this.orderid,
          barcodes: barcodes,
          updatePieces,
          delBindItems,
        })
        .then(function (response) {
          self.$emit("save-barcodes");
          self.edit = false;
          self.getOrderBarcodes(self.orderid, self.excluded, self.deleted);
          self.$message({ message: "Изменения сохранены", type: "success" });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    showExcluded: function (excluded) {
      this.getOrderBarcodes(this.orderid, excluded, this.deleted);
    },
    showDeleted: function (deleted) {
      this.getOrderBarcodes(this.orderid, this.excluded, deleted);
    },
    getOrderBarcodes: function (orderId, excluded, deleted) {
      if (orderId > 0) {
        var self = this;
        self.startLoading();
        this.axios
          .post("/OrderEdit/getOrderBarcodes", {
            orderId: orderId,
            excluded: excluded,
            deleted: deleted,
          })
          .then(function (response) {
            self.tableData = response.data.Data;
            self.stopLoading();
            self.$emit("change-barcodes", self.getBarcodesToSave());
          })
          .catch(function (error) {
            self.stopLoading();
            console.log(error);
          });
      }
    },
    startLoading() {
      this.fullScreenLoading = true;
    },
    stopLoading() {
      this.fullScreenLoading = false;
    }
  },
  mounted: function () {
    this.getOrderBarcodes(this.orderid, this.excluded, this.deleted);
  },
};
</script>

<style scoped>
.vueTemplate >>> .isAccepted {
  background: #19fc9e !important;
}
.vueTemplate >>> .isDeleted {
  background: #d4d4d4 !important;
}
</style>
