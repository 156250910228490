<template>
  <div>
    <el-tabs type="border-card" :value="'first'">
      <el-tab-pane label="Причины отказа/переноса" name="first">
        <div style="min-height: 390px; padding-top: 10px">
          <el-table size="mini" :data="reasons" border>
            <el-table-column prop="Id" label="Id" align="center">
            </el-table-column>
            <el-table-column prop="Code1C" label="Код 1С" align="center">
            </el-table-column>
            <el-table-column prop="Name" label="Название" align="center">
            </el-table-column>
            <el-table-column prop="IsActive" label="Активна в админке" align="center">
                <template slot-scope="scope">
                    <el-checkbox disabled v-model="scope.row.IsActive"></el-checkbox>
                </template>
            </el-table-column>
            <el-table-column prop="Guilty" label="Виновный" align="center">
            </el-table-column>
            <el-table-column prop="Initiator" label="Инициатор" align="center">
            </el-table-column>
            <el-table-column prop="Inactive" label="Недоступна по API" align="center">
                <template slot-scope="scope">
                    <el-checkbox disabled v-model="scope.row.Inactive"></el-checkbox>
                </template>
            </el-table-column>            
          </el-table>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "Directory",
  data: function () {
    return {
      reasons: [],
    };
  },
  methods: {
    getData() {
      let self = this;
      this.startLoading();
      this.axios
        .post("/Directory/getDirectories", {})
        .then((resp) => {
          self.reasons = resp.data.TransCancelReasonList;
          self.stopLoading();
        })
        .catch((e) => {
          self.stopLoading();
          self.showError("Произошла ошибка");
          console.log(e);
        });
    },
    startLoading() {
      this.$loading();
    },
    stopLoading() {
      this.$loading().close();
    },
    showSuccess(message) {
      this.$message({
        message: message,
        type: "success",
      });
    },
    showError(message) {
      this.$message.error(message);
    },
  },
  mounted: function () {
    this.getData();
  },
};
</script>

<style scoped>
</style>
