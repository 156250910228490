<template>
  <div>
    <el-dialog
      width="400"
      :center="true"
      title="Редактирование шаблона"
      :visible.sync="visualSettings.dialogNewTemplate"
    >
      <el-form
        size="mini"
        ref="editTemplate"
        :model="template"
        label-width="250px"
        :rules="rules"
      >
        <el-form-item label="Идентификатор" prop="Id">
          <el-input disabled size="mini" v-model="template.Id"></el-input>
        </el-form-item>
        <el-form-item label="Название файла" prop="FileName">
          <el-input disabled size="mini" v-model="template.FileName"></el-input>
        </el-form-item>
        <el-form-item
          label="Название файла для пользователя"
          prop="FileNameUser"
        >
          <el-input size="mini" v-model="template.FileNameUser"></el-input>
        </el-form-item>
        <el-form-item label="Активен" prop="IsActive">
          <el-checkbox
            :disabled="!template.CanInactive"
            v-model="template.IsActive"
            size="mini"
          ></el-checkbox>
        </el-form-item>
        <el-form-item
          label="Файл"
          prop="IsActive"
          v-if="template.IsFileEditable"
        >
          <el-upload
            ref="upload"
            action=""
            :on-change="handleUploadChange"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            :limit="2"
            :file-list="fileList"
            :auto-upload="false"
          >
            <el-button slot="trigger" size="small" type="primary"
              >выберите файл</el-button
            >
          </el-upload>
        </el-form-item>
      </el-form>
      <span style="text-align: center">
        <div style="margin: 10px">
          <el-button @click="saveChanges" type="success" size="small"
            >Сохранить</el-button
          >
          <el-button @click="showDialogTemplate(false)" size="small"
            >Отмена</el-button
          >
        </div>
      </span>
    </el-dialog>
    <el-button
      :disabled="!$store.getters.ruleSettings.IsAccessToEditOrderTemplates"
      style="margin: 10px"
      type="primary"
      size="mini"
      @click="addTemplate()"
      >Добавить шаблон</el-button
    >
    <el-table
      @current-change="handleCurrentChange"
      ref="templates"
      highlight-current-row
      :data="templatesData"
      border
      size="mini"
    >
      <el-table-column
        header-align="center"
        label="Id"
        prop="Id"
        align="center"
        width="50"
      ></el-table-column>
      <el-table-column
        header-align="center"
        label="Название файла"
        prop="FileName"
        align="center"
      ></el-table-column>
      <el-table-column
        header-align="center"
        label="Название файла для пользователя"
        prop="FileNameUser"
        align="center"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.FileNameUser }}</div>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        label="Дата изменения"
        prop="ModifyDate"
        align="center"
      >
        <template slot-scope="scope">
          <div>{{ getStringDate(scope.row.ModifyDateStr) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="Загрузить" header-align="center" align="center">
        <template slot-scope="scope">
          <el-button size="small" @click="downloadTemplate(scope.row)"
            >Загрузить</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        label="Состояние"
        align="center"
        width="200"
      >
        <template slot-scope="scope">
          <el-checkbox
            disabled
            v-model="scope.row.IsActive"
            size="mini"
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        label="Сохранить"
        align="center"
        width="200"
      >
        <template slot-scope="scope">
          <el-button
            :disabled="
              !$store.getters.ruleSettings.IsAccessToEditOrderTemplates
            "
            size="small"
            @click="editTemplate(scope.row)"
            >Изменить</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "FileDownloadTemplates",
  data: function () {
    return {
      fileList: [],
      template: {
        IsFileEditable: true,
        CanInactive: true,
      },
      selectedRow: null,
      templatesData: [{}],
      shopDict: [],
      visualSettings: {
        dialogNewTemplate: false,
      },
      rules: {
        FileNameUser: [
          {
            required: true,
            message: "Введите название документа для пользователя",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {},
  methods: {
    editTemplate(template) {
      this.template = this._.cloneDeep(template);
      this.showDialogTemplate(true);
    },
    showDialogTemplate(isShow) {
      this.visualSettings.dialogNewTemplate = isShow;
    },
    handleCurrentChange(val) {
      this.selectedRow = val;
    },
    getStringDate(dat) {
      if (dat) {
        try {
          let dd = new Date(dat);
          return [
            dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate(),
            dd.getMonth() < 9 ? "0" + (dd.getMonth() + 1) : dd.getMonth(),
            dd.getFullYear(),
          ].join(".");
        } catch (e) {
          console.log(e);
          return "";
        }
      }
      return "";
    },
    downloadTemplate(row) {
      let self = this;
      this.axios
        .post("/FileDownloadTemplates/getFileTemplate", {
          templateId: row.Id,
        })
        .then((resp) => {
          this.$helpMethods.downloadBase64Doc(
            resp.data.FileName,
            resp.data.FileData
          );
        })
        .catch((e) => {
          console.log(e);
          self.showError("Произошла ошибка");
        });
    },
    handleUploadChange(file, fileList) {
      this.fileList = fileList.slice(-1);
      this.template.FileName = file.name;
    },
    readFile(raw, cb) {
      var reader = new FileReader();
      reader.readAsDataURL(raw);
      reader.onload = function () {
        if (
          reader.result &&
          reader.result.length > 0 &&
          reader.result.indexOf(",")
        ) {
          let result = reader.result.substring(reader.result.indexOf(",") + 1);
          cb(result);
        }
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },
    saveChanges() {
      let self = this;
      this.$refs["editTemplate"].validate((valid) => {
        if (valid) {
          if (self.fileList.length > 0) {
            self.readFile(self.fileList[0].raw, function (fileData) {
              self.template.FileContentBase64 = fileData;
              self.saveTemplate(self.template);
            });
          } else {
            self.saveTemplate(self.template);
          }
        }
      });
    },
    saveTemplate(template) {
      var self = this;
      this.axios
        .post("/FileDownloadTemplates/saveTemplate", {
          template: {
            Id: template.Id,
            FileName: template.FileName,
            FileNameUser: template.FileNameUser,
            IsActive: template.IsActive,
            FileContentBase64: template.FileContentBase64,
          },
        })
        .then(function (response) {
          if (response.data.Success) {
            self.loadData();
            self.showDialogTemplate(false);
          } else {
            self.showError("Произошла ошибка");
          }
        })
        .catch(function (error) {
          self.showError("Произошла ошибка");
        });
    },
    addTemplate() {
      this.template = { IsActive: true };
      this.$set(this.template, "IsFileEditable", true);
      this.$set(this.template, "CanInactive", true);
      this.showDialogTemplate(true);
    },
    setTemplateState(templateId, isActive) {
      var self = this;
      this.axios
        .post("/FileDownloadTemplates/setActiveStateTemplate", {
          templateId,
          isActive,
        })
        .then(function (response) {
          if (response.data.Success) {
            self.loadData();
          } else {
            self.showError("Произошла ошибка");
          }
        })
        .catch(function (error) {
          self.showError("Произошла ошибка");
        });
    },
    startLoading() {
      this.fullScreenLoading = true;
    },
    stopLoading() {
      this.fullScreenLoading = false;
    },
    loadData() {
      var self = this;
      this.startLoading();
      this.axios
        .post("/FileDownloadTemplates/getTemplatesData", {})
        .then((response) => {
          self.templatesData = response.data.Data;
          self.stopLoading();
        })
        .catch((error) => {
          console.log(error);
          self.stopLoading();
        });
    },
    showSuccess(message) {
      this.$message({
        message: message,
        type: "success",
      });
    },
    showError(message) {
      this.$message.error(message);
    },
  },
  mounted: function () {
    this.loadData();
  },
};
</script>

<style scoped>
</style>
