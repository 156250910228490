<template>
  <div>
    <el-button
      style="margin: 10px"
      type="primary"
      v-loading.fullscreen.lock="fullScreenLoading"
      size="mini"
      @click="showDialogNewPage(true)"
      >Добавить новую страницу</el-button
    >
    <el-dialog
      width="400"
      :center="true"
      title="Новая страница"
      :visible.sync="visualSettings.dialogNewPage"
    >
      <el-form
        size="mini"
        ref="newPage"
        :model="newPage"
        label-width="150px"
        :rules="rules"
      >
        <el-form-item label="Имя страницы" prop="PageName">
          <el-input size="mini" v-model="newPage.PageName"></el-input>
        </el-form-item>
        <el-form-item label="Адрес страницы" prop="PageUrl">
          <el-input size="mini" v-model="newPage.PageUrl"></el-input>
        </el-form-item>
      </el-form>
      <span style="text-align: center">
        <div style="margin: 10px">
          <el-button @click="addNewPage" type="success" size="small"
            >Создать</el-button
          >
          <el-button @click="showDialogNewPage(false)" size="small"
            >Отмена</el-button
          >
        </div>
      </span>
    </el-dialog>
    <el-table :data="pageData" border size="mini">
      <el-table-column header-align="center" align="center" width="100">
        <template slot-scope="scope">
          <div style="display: flex">
            <el-button
              size="mini"
              icon="el-icon-edit"
              v-show="!editMode(scope.row)"
              @click="editPage(scope.row, true)"
            ></el-button>
            <el-button
              size="mini"
              icon="el-icon-check"
              v-show="editMode(scope.row)"
              @click="saveEditPage(scope.row)"
            ></el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        label="Название страницы"
      >
        <template slot-scope="scope">
          <el-input
            size="mini"
            v-model="scope.row.PageName"
            :disabled="!editMode(scope.row)"
          ></el-input>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        label="Адрес страницы"
      >
        <template slot-scope="scope">
          <el-input
            size="mini"
            v-model="scope.row.PageUrl"
            :disabled="!editMode(scope.row)"
          ></el-input>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "Pages",
  data: function () {
    return {
      pageData: [],
      newPage: {
        PageName: "",
        PageUrl: "",
      },
      fullScreenLoading: false,
      rules: {},
      visualSettings: {
        dialogNewPage: false,
      },
    };
  },
  methods: {
    saveEditPage(item) {
      this.axios
        .post("/Secure/Page.aspx/savePageData", {
          page: item,
        })
        .then(function (response) {
          if (response.data.Success) {
            this.$set(item, "IsEditable", false);
          } else {
            self.showError("Произошла ошибка при сохранении");
          }
        })
        .catch(function (error) {
          self.showError("Произошла ошибка при сохранении");
        });
    },
    editPage(item, isEdit) {
      this.$set(item, "IsEditable", isEdit);
    },
    editMode(item) {
      if (item.IsEditable) return true;
      return false;
    },
    addNewPage() {
      var self = this;
      this.axios
        .post("/pages/savePageData", 
        {page:this.newPage  }      
        )
        .then(function (response) {
          if (response.data.Success) {
            self.showDialogNewPage(false);
            self.loadData();
          } else {
            console.log("Произошла ошибка при сохранении");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    showDialogNewPage(show) {
      this.visualSettings.dialogNewPage = show;
    },
    startLoading() {
      this.fullScreenLoading = true;
    },
    stopLoading() {
      this.fullScreenLoading = false;
    },
    showSuccess(message) {
      this.$message({
        message: message,
        type: "success",
      });
    },
    showError(message) {
      this.$message.error(message);
    },
    loadData() {
      var self = this;
      this.startLoading();
      this.axios
        .post("/pages/getPagesData", {})
        .then(function (response) {
          self.stopLoading();
          if (response.data.Success) {
            self.pageData = response.data.Data;
          } else {
            console.log("Произошла ошибка при загрузке");
          }
        })
        .catch(function (error) {
          self.stopLoading();
          console.log(error);
        });
    },
  },
  mounted: function () {
    this.loadData();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>