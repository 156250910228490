<template>
  <div>
    <el-form
      size="mini"
      style="width: 600px"
      ref="searchSettings"
      :rules="rules"
      :model="searchSettings"
      label-width="200px"
    >
      <el-form-item label="Партнер:" prop="shop">
        <ShopsListControl v-model="searchSettings.shop" :isEnable="true"></ShopsListControl>
      </el-form-item>
      <el-form-item label="Доставка" prop="DeliveryDate">
        <div style="display: inline">с</div>
        <el-date-picker
          style="width: 125px"
          v-model="searchSettings.DeliveryDateFrom"
          type="date"
          :picker-options="delDatePickOpt"
          format="dd.MM.yyyy"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
        <div style="display: inline">по</div>
        <el-date-picker
          style="width: 125px"
          v-model="searchSettings.DeliveryDateTo"
          type="date"
          :picker-options="delDatePickOpt"
          format="dd.MM.yyyy"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
    </el-form>
    <div>
      <el-button size="mini" @click="getReportFile">Сформировать</el-button>
      <div></div>
    </div>
  </div>
</template>

<script>
import ShopsListControl from "./controls/ShopsListControl.vue";
export default {
  name: "ReportAgent",
  components: {
    ShopsListControl,
  },
  data: function () {
    var firstDay = new Date();
    firstDay = new Date(firstDay.getFullYear(), firstDay.getMonth(), 1);
    let lastDay = new Date(firstDay.getFullYear(), firstDay.getMonth() + 1, 0);
    firstDay = this.fromDateTimeToDate(firstDay);
    lastDay = this.fromDateTimeToDate(lastDay);
    let checkDelivDate = (rule, value, callback) => {
      if (
        !this.searchSettings.DeliveryDateFrom ||
        !this.searchSettings.DeliveryDateTo
      ) {
        callback("Введите даты доставки");
      } else {
        callback();
      }
    };

    return {
      reportData: [],
      searchSettings: {
        shop: null,
        DeliveryDateFrom: firstDay,
        DeliveryDateTo: lastDay,
        shopOptions: [],
      },
      delDatePickOpt: {
        firstDayOfWeek: 1,
      },
      rules: {
        shop: [
          {
            required: true,
            message: "Выберите партнера из списка",
            trigger: "blur",
          },
        ],
        DeliveryDate: [{ validator: checkDelivDate, trigger: "blur" }],
      },
    };
  },
  methods: {
    fromDateTimeToDate(dat){
    let mm = dat.getMonth() + 1;
    let dd = dat.getDate();
    let dateOnly = [
      dat.getFullYear(),
      (mm > 9 ? "" : "0") + mm,
      (dd > 9 ? "" : "0") + dd,
    ].join("-");
    return dateOnly;
    },
    getFilter() {
      let filter = {};
      filter.ShopId = this.searchSettings.shop;
      
      filter.DeliveryDateFrom = this.searchSettings.DeliveryDateFrom;
      filter.DeliveryDateTo = this.searchSettings.DeliveryDateTo;
      return filter;
    },
    getHierarhy(orgLst) {
      let mainOrgs = orgLst
        .filter((org) => !org.ParentId)
        .reduce(function (map, item) {
          map[item.InternetShopId] = {
            value: item.InternetShopId,
            label: item.Name,
          };
          return map;
        }, {});
      orgLst
        .filter((org) => !!org.ParentId)
        .forEach(function (item) {
          let mainOrg = mainOrgs[item.ParentId];
          if (mainOrg) {
            if (!mainOrgs[item.ParentId].children) {
              mainOrgs[item.ParentId].children = [];
            }
            mainOrgs[item.ParentId].children.push({
              value: item.InternetShopId,
              label: item.Name,
            });
          }
        });
      return Object.values(mainOrgs);
    },
    loadSimpleDictionaries(resp) {
      this.searchSettings.shopOptions = this.getHierarhy(
        resp.data.OrganizationList.Dir
      );
    },
    getReportFile() {
      this.$refs["searchSettings"].validate((valid) => {
        if (valid) {
          let self = this;
          self.startLoading();
          this.axios
            .post("/ReportAgent/getReport", {
              searchParams: self.getFilter(),
            })
            .then((resp) => {
              self.stopLoading();
              if (resp.data.Success) {
                self.$helpMethods.downloadBase64Doc(
                  resp.data.Data.FileName,
                  resp.data.Data.FileData
                );
              }
            })
            .catch((e) => {
              self.stopLoading();
              self.showError("Произошла ошибка");
              console.log(e);
            });
        }
      });
    },
    startLoading() {
      this.$loading();
    },
    stopLoading() {
      this.$loading().close();
    },
    showSuccess(message) {
      this.$message({
        message: message,
        type: "success",
      });
    },
    showError(message) {
      this.$message.error(message);
    },
  },
  mounted: function () {
    let self = this;
    self.startLoading();
    this.axios
      .post("/ReportAgent/getDictionaries", {})
      .then((resp) => {
        self.loadSimpleDictionaries(resp);
        self.stopLoading();
      })
      .catch((e) => {
        self.stopLoading();
        self.showError("Произошла ошибка");
        console.log(e);
      });
  },
};
</script>

<style scoped>
</style>