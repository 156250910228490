export const dates = Object.freeze({
    name: 'Работа с датами',
    methods: {
        tomorrow(){
            let dat = new Date();
            return new Date(dat.getFullYear(),dat.getMonth(),dat.getDate()+1);
        },
        today() {
            let dat = new Date();
            return new Date(dat.getFullYear(),dat.getMonth(),dat.getDate());
        },
        todayStr() {
            let dat = new Date();
            let dd = dat.getDate();
            let mm = dat.getMonth() + 1;
            let today = [
                dat.getFullYear(),
                (mm > 9 ? "" : "0") + mm,
                (dd > 9 ? "" : "0") + dd,
            ].join("-");
            return today;
        },
        tomorrowStr() {
            let dat = new Date();
            let tomorrow = new Date(dat.getFullYear(),dat.getMonth(),dat.getDate() + 1);
            let dd = tomorrow.getDate();
            let mm = tomorrow.getMonth() + 1;
            tomorrow = [
                tomorrow.getFullYear(),
                (mm > 9 ? "" : "0") + mm,
                (dd > 9 ? "" : "0") + dd,
            ].join("-");
            return tomorrow;
        },
        toStringDate(dat) {
            if (dat) {
                try {
                    let dd = new Date(dat);
                    return [
                        dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate(),
                        dd.getMonth() < 9 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1,
                        dd.getFullYear(),
                    ].join(".");
                } catch (e) {
                    console.log(e);
                    return "";
                }
            }
            return "";
        },
        toStringDateTime(dat) {
            if (dat) {
                try {
                    let dd = new Date(dat);
                    let date = [
                        dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate(),
                        dd.getMonth() < 9 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1,
                        dd.getFullYear(),
                    ].join(".");

                    let time = [dd.getHours() < 10 ? "0" + dd.getHours() : dd.getHours(),
                    dd.getMinutes() < 10 ? "0" + dd.getMinutes() : dd.getMinutes(),
                    dd.getSeconds() < 10 ? "0" + dd.getSeconds() : dd.getSeconds()].join(
                        ":"
                    );
                    return date + " " + time;
                } catch (e) {
                    console.log(e);
                    return "";
                }
            }
            return "";
        },
        arrayToDictStringDate(dates){
            let self = this;           
            let result = {};
            dates.forEach(item => {
                let date = self.tryParseDate(item);
                if (date){
                    result[self.toStringDate(date)] = true;
                }
            });

            return result;
        },
        tryParseDate(date){
            try {
                return new Date(date);
              } catch (e) {
                return null;
              }
        }
    }
});