<template>
  <div>
    <div style="margin: 10px">
      <el-select
        size="mini"
        v-model="schemeId"
        placeholder="Выберите схему"
        @change="handleSchemeChange"
      >
        <el-option
          v-for="item in schemes"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>
      <el-button @click="addTimetable" :disabled="!this.schemeId" size="mini"
        >Добавить расписание</el-button
      >
    </div>
    <div>
      <el-table
        :data="deliveryTimetable"
        size="mini"
        :header-cell-class-name="'headerCell'"
      >
        <el-table-column prop="Id" label="Id" align="center"> </el-table-column>
        <el-table-column
          prop="SourcePlaceId"
          label="Терминал отгрузки"
          align="center"
          ><template slot-scope="scope"
            ><div>
              <source-terminal-control
                v-model="scope.row.SourcePlaceId"
                :useAllOpt="false"
              ></source-terminal-control>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="DeliveryPlaceId"
          label="Терминал доставки"
          align="center"
        >
          <template slot-scope="scope">
            <div>
              <delivery-terminal-control
                v-model="scope.row.DeliveryPlaceId"
                :useAllOpt="false"
              ></delivery-terminal-control>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="ZoneId" label="Зона" align="center">
          <template slot-scope="scope">
            <el-select
              size="mini"
              v-model="scope.row.ZoneId"
              placeholder="Выберите зону"
            >
              <el-option
                v-for="item in zones"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="Выбрать календарь" align="center" width="250">
          <template slot-scope="scope">
            <el-select
              size="mini"
              clearable
              v-model="scope.row.DeliveryCalendarId"
              placeholder="Выбрать существующий календарь"
              @change="handleDeliveryCalendarChange(scope.row)"
            >
              <el-option
                v-for="item in deliveryCalendars"
                :key="item.Id"
                :label="item.TimetableName"
                :value="item.Id"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          prop="CountOfDays"
          label="Сдвиг даты (в днях)"
          align="center"
        >
          <template slot-scope="scope">
            <number-control
              :disabled="!isNewDeliveryCalendar(scope.row)"
              style="width: 70px; padding: 0 5px"
              v-model="scope.row.CountOfDays"
              :min="0"
              :precision="0"
            ></number-control>
          </template>
        </el-table-column>        
        <el-table-column
          prop="TimetableName"
          label="Название календаря"
          align="center"
        >
          <template slot-scope="scope">
            <el-input
              :disabled="!isNewDeliveryCalendar(scope.row)"
              size="mini"
              v-model="scope.row.TimetableName"
              maxlength="100"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="Days"
          label="Дни доставки"
          align="center"
          width="300"
        >
          <template slot-scope="scope">
            <el-select
              :disabled="!isNewDeliveryCalendar(scope.row)"
              size="mini"
              v-model="scope.row.Days"
              multiple
              style="width: 100%"
            >
              <el-option
                v-for="item in ScheduleList"
                :key="item.Key"
                :label="item.Name"
                :value="item.Key"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="" label="" align="center">
          <template slot-scope="scope">
            <div>
              <el-button
                size="mini"
                @click="saveTimetable(scope.row, scope.$index)"
                >Сохранить</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import DeliveryTerminalControl from "../controls/DeliveryTerminalControl.vue";
import SourceTerminalControl from "../controls/SourceTerminalControl.vue";
import NumberControl from "../controls/NumberControl.vue";
export default {
  name: "ZonesTimetable",
  components: {
    DeliveryTerminalControl,
    SourceTerminalControl,
    NumberControl,
  },
  data: function () {
    return {
      ScheduleList: [
        { Key: 1, Name: "Пн" },
        { Key: 2, Name: "Вт" },
        { Key: 3, Name: "Ср" },
        { Key: 4, Name: "Чт" },
        { Key: 5, Name: "Пт" },
        { Key: 6, Name: "Сб" },
        { Key: 7, Name: "Вс" },
      ],
      schemeId: null,
      deliveryTimetable: [],
      schemes: [],
      deliveryCalendars: [],
    };
  },
  computed: {
    zones() {
      let schemeId = this.schemeId;
      if (!schemeId || !this.schemes) return [];

      let scheme = this.schemes.find((item) => item.Id == schemeId);
      if (!scheme) return [];
      return scheme.Zones;
    },
  },
  methods: {
    isNewDeliveryCalendar(row) {
      return !row.DeliveryCalendarId > 0;
    },
    handleDeliveryCalendarChange(row) {
      if (row.DeliveryCalendarId > 0) {
        let deliveryCalendar = this.deliveryCalendars.find(
          (item) => item.Id == row.DeliveryCalendarId
        );
        row.TimetableName = deliveryCalendar.TimetableName;
        row.CountOfDays = deliveryCalendar.CountOfDays;
        row.Days = deliveryCalendar.Days;
      } else {
        row.TimetableName = "";
        row.CountOfDays = 0;
        row.Days = [];
      }
    },
    loadDeliveryCalendars() {
      let self = this;
      self.startLoading();
      this.axios
        .post("Admin/getDeliveryCalendars", {})
        .then(function (response) {
          self.deliveryCalendars = response.data.Data;
          self.stopLoading();
        })
        .catch(function (error) {
          console.log(error);
          self.stopLoading();
        });
    },
    saveTimetable(row, index) {
      if (
        !row.DeliveryPlaceId ||
        !row.SourcePlaceId ||
        isNaN(row.ZoneId) ||
        !row.CountOfDays ||
        row.Days.length < 1
      ) {
        this.showError("Не заполнены все поля!");
        return;
      }

      let schemeId = this.schemeId;
      let scheme = this.schemes.find((item) => item.Id == schemeId);
      if (!scheme) {
        this.showError("Не выбрана схема!");
        return;
      }

      let zoneIds = scheme.Zones.reduce(function (prev, current) {
        prev.push(current.Id);
        return prev;
      }, []);
      let self = this;

      this.axios
        .post("Admin/saveTimetable", {
          timetable: row,
          zoneIds: zoneIds,
        })
        .then(function (response) {
          self.$set(self.deliveryTimetable, index, response.data.Data);
          self.stopLoading();
          self.loadDeliveryCalendars();
        })
        .catch(function (error) {
          console.log(error);
          self.stopLoading();
        });
    },
    getNames(days) {
      let self = this;
      let names = days.reduce(function (prev, current) {
        let day = self.ScheduleList.find((item) => item.Key == current);
        if (day) {
          prev += day.Name + ",";
        }
        return prev;
      }, "");
      return names;
    },
    addTimetable() {
      this.deliveryTimetable.push({});
    },
    handleSchemeChange() {
      let schemeId = this.schemeId;
      let scheme = this.schemes.find((item) => item.Id == schemeId);
      if (scheme) {
        let zoneIds = scheme.Zones.reduce(function (prev, current) {
          prev.push(current.Id);
          return prev;
        }, []);
        this.getDeliveryTimetables(zoneIds);
      }
    },
    getDeliveryTimetables(zoneIds) {
      let delivTimetablesRequest = this.axios.post(
        "/Admin/getDeliveryTimetables",
        {
          zoneIds: zoneIds,
        }
      );

      this.startLoading();
      let self = this;
      this.axios
        .all([delivTimetablesRequest])
        .then(
          self.axios.spread((...responses) => {
            let responseDelivTimetablesData = responses[0];
            self.deliveryTimetable = responseDelivTimetablesData.data.Data;
            self.stopLoading();
            self.loadDeliveryCalendars();
          })
        )
        .catch((error) => {
          console.log(error);
          self.stopLoading();
        });
    },
    loadData() {
      var schemesRequest = this.axios.post("/Admin/getSchemes", {});
      var mapTypesRequest = this.axios.post("/Admin/getMapTypes", {});
      this.startLoading();
      var self = this;
      this.axios
        .all([schemesRequest, mapTypesRequest])
        .then(
          self.axios.spread((...responses) => {
            var responseSchemesData = responses[0];
            //var responseMapTypesData = responses[2];
            //self.deliveryCalendar = responseDelivTimetablesData.data.Data;
            self.schemes = responseSchemesData.data.Data;
            //self.maptypes = responseMapTypesData.data.Data;
            self.stopLoading();
          })
        )
        .catch((error) => {
          console.log(error);
          self.stopLoading();
        });
    },
    showSuccess(message) {
      this.$message({
        message: message,
        type: "success",
      });
    },
    showError(message) {
      this.$message.error(message);
    },
    startLoading() {
      this.$loading();
    },
    stopLoading() {
      this.$loading().close();
    },
  },
  mounted: function () {
    this.loadData();
  },
};
</script>

<style scoped>
</style>
