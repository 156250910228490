<template>
  <div class="vueTemplate">
    <h3>Обновить файл документации.</h3>
    <div style="padding: 5px">
      <el-upload
        ref="upload"
        action=""
        :on-change="handleUploadChange"
        :limit="1"
        :file-list="fileList"
        :auto-upload="false"
      >
        <el-button slot="trigger" size="small" type="primary"
          >выберите файл</el-button
        >
        <el-button
          :disabled="fileList.length == 0"
          style="margin-left: 10px"
          size="small"
          type="success"
          @click="submitUpload"
          >отправить</el-button
        >
      </el-upload>
    </div>
    <el-table size="mini" :data="docsData" border :row-class-name="getRowClass">
      <el-table-column prop="Id" label="ID" align="center"> </el-table-column>
      <el-table-column
        prop="FileName"
        label="Имя файла"
        width="200"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="FileType" label="Тип файла" align="center">
        <template slot-scope="scope">
          <div>
            {{ scope.row.FileType == 0 ? "Документация" : scope.row.FileType }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="Who" label="Изменял" align="center">
      </el-table-column>
      <el-table-column prop="IsActual" label="Актуальная версия" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.IsActual" class="el-icon-check"></div>
        </template>
      </el-table-column>
      <el-table-column
        prop="ModifyDateStr"
        label="Дата изменения"
        align="center"
      >
        <template slot-scope="scope">
          <div>{{ getStringDate(scope.row.ModifyDate) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="Загрузить" align="center">
        <template slot-scope="scope">
          <el-button size="mini" @click="downloadDoc(scope.row)"
            >Загрузить</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="Назначить актуальной" width="200" align="center">
        <template slot-scope="scope">
          <el-button
            v-if="!scope.row.IsActual"
            size="mini"
            @click="setActual(scope.row)"
            >Назначить актуальной</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="Удалить" align="center">
        <template slot-scope="scope">
          <el-button size="mini" @click="deleteDoc(scope.row)"
            >Удалить</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "Docs",
  data: function () {
    return {
      fileList: [],
      docsData: [],
    };
  },
  methods: {
    deleteDoc(row) {
      let self = this;
      this.$confirm("Удалить выбранный файл документации?", "Предупреждение", {
        confirmButtonText: "Да",
        cancelButtonText: "Отмена",
        type: "warning",
      }).then(() => {
        self.axios
          .post("/Docs/deleteDoc", {
            docId: row.Id,
          })
          .then((resp) => {
            self.docsData = resp.data.Data;
          })
          .catch((e) => {
            console.log(e);
            self.showError("Произошла ошибка");
          });
      });
    },
    setActual(row) {
      let self = this;
      this.$confirm(
        "Сделать выбранный документ актуальной версией документации?",
        "Предупреждение",
        {
          confirmButtonText: "Да",
          cancelButtonText: "Отмена",
          type: "warning",
        }
      ).then(() => {
        self.axios
          .post("/Docs/setActual", {
            docId: row.Id,
          })
          .then((resp) => {
            self.docsData = resp.data.Data;
          })
          .catch((e) => {
            console.log(e);
            self.showError("Произошла ошибка");
          });
      });
    },
    downloadDoc(row) {
      let self = this;
      this.axios
        .post("/Docs/getDocFile", {
          docId: row.Id,
        })
        .then((resp) => {
          self.$helpMethods.downloadBase64Doc(
            resp.data.Data.FileName,
            resp.data.Data.FileData
          );
        })
        .catch((e) => {
          console.log(e);
          self.showError("Произошла ошибка");
        });
    },
    getRowClass(scope) {
      return scope.row.IsActual ? "bkColor" : "";
    },
    getStringDate(dat) {
      if (dat) {
        try {
          let dd = new Date(dat);
          return [
            dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate(),
            dd.getMonth() < 9 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1,
            dd.getFullYear(),
          ].join(".");
        } catch (e) {
          console.log(e);
          return "";
        }
      }
      return "";
    },
    handleUploadChange(file, fileList) {
      this.fileList = fileList.slice(-1);
    },
    sendB64(bytes, fileName, isActual) {
      var self = this;
      this.axios
        .post("/Docs/saveDocs", {
          base64File: bytes,
          fileName,
          isActual,
        })
        .then((resp) => {
          if (resp.data.Error) {
            self.showError(resp.data.Error);
          }
          if (self.fileList.length > 0) {
            this.fileList.pop();
          }
          self.docsData = resp.data.Data;
        })
        .catch((e) => {});
    },
    readFile(isActual) {
      let self = this;
      var reader = new FileReader();
      var fileName = this.fileList[0].name;
      reader.readAsDataURL(this.fileList[0].raw);
      reader.onload = function () {
        if (
          reader.result &&
          reader.result.length > 0 &&
          reader.result.indexOf(",")
        ) {
          let result = reader.result.substring(reader.result.indexOf(",") + 1);
          self.sendB64(result, fileName, isActual);
        }
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },
    submitUpload() {
      var self = this;

      this.$confirm(
        "Сделать выбранный документ актуальной версией документации?",
        "Предупреждение",
        {
          confirmButtonText: "Да",
          cancelButtonText: "Нет",
          type: "warning",
        }
      )
        .then(() => {
          self.readFile(true);
        })
        .catch(() => {
          self.readFile(false);
        });
    },
    startLoading() {
      this.$loading();
    },
    stopLoading() {
      this.$loading().close();
    },
    showSuccess(message) {
      this.$message({
        message: message,
        type: "success",
      });
    },
    showError(message) {
      this.$message.error(message);
    },
  },
  mounted: function () {
    let self = this;
    self.startLoading();
    this.axios
      .post("/Docs/getDocs", {})
      .then((resp) => {
        self.stopLoading();
        self.docsData = resp.data.Data;
      })
      .catch((e) => {
        self.stopLoading();
        self.showError("Произошла ошибка");
        console.log(e);
      });
  },
};
</script>

<style scoped>
.vueTemplate >>> .bkColor{
        background: coral !important;
    }
.vueTemplate >>> .el-form-item--mini.el-form-item {
        margin-bottom: 15px;
    }
</style>