<template>
  <div class="vueTemplate">
    <el-tabs type="border-card" :value="'first'">
      <el-tab-pane label="Общие настройки" name="first">
        <el-form
          size="mini"
          ref="shopData"
          :model="shopData.common"
          label-width="250px"
        >
          <el-button
            size="mini"
            @click="saveCommonSettings"
            v-loading.fullscreen.lock="fullScreenLoading"
            type="success"
            style="margin: 15px"
            >Сохранить</el-button
          >
          <el-form-item
            label="Название магазина"
            prop="ShopName"
            class="panel-cell"
          >
            <el-input size="mini" v-model="shopData.common.ShopName"></el-input>
          </el-form-item>
          <el-form-item
            label="Название для бланка"
            prop="ShopTitle"
            class="panel-cell"
          >
            <el-input
              size="mini"
              v-model="shopData.common.ShopTitle"
            ></el-input>
          </el-form-item>
          <el-form-item label="Юр.лицо" prop="ShopJurFace" class="panel-cell">
            <el-input
              size="mini"
              v-model="shopData.common.ShopJurFace"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="Юр.лицо(для бланка)"
            prop="ShopJurFaceTitle"
            class="panel-cell"
          >
            <el-input
              size="mini"
              v-model="shopData.common.ShopJurFaceTitle"
            ></el-input>
          </el-form-item>
          <el-form-item label="Телефон" prop="ShopPhone" class="panel-cell">
            <el-input
              size="mini"
              v-model="shopData.common.ShopPhone"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="Индекс сортировки"
            prop="Sorting"
            class="panel-cell"
          >
            <el-input-number
              size="mini"
              v-model="shopData.common.Sorting"
              :controls="false"
              style="width: 100%"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="НДС" class="panel-cell">
            <el-switch
              v-model="shopData.common.EnableNDS"
              active-text="Учитывать"
              inactive-text="Не учитывать"
            ></el-switch>
          </el-form-item>
          <el-form-item label="Запрет на создание дублей" class="panel-cell">
            <el-checkbox
              v-model="shopData.common.ForbidDuplicate"
            ></el-checkbox>
          </el-form-item>
          <el-form-item label="Сдвиг даты доставки" class="panel-cell">
            <el-switch
              v-model="shopData.common.EnableDelivDateShift"
              active-text="Включен"
              inactive-text="Выключен"
            ></el-switch>
          </el-form-item>
          <el-form-item
            label="Складская обработка отсутствует"
            class="panel-cell"
          >
            <el-checkbox
              v-model="shopData.common.DisabledStockProc"
            ></el-checkbox>
          </el-form-item>
          <el-form-item
            label="Ключ доступа"
            class="panel-cell"
            style="width: 900px !important"
          >
            <div>
              <el-input
                size="mini"
                v-model="shopData.common.AccessKey"
                disabled
                style="width: 270px"
              ></el-input>
              <el-button
                size="mini"
                @click="generateAccessKey"
                v-if="!shopData.common.AccessKey"
                >Сгенерировать</el-button
              >
            </div>
          </el-form-item>
          <el-form-item
            label="Терминал приемки по умолчанию"
            class="panel-cell"
          >
            <el-select v-model="shopData.common.DefaultSourcePlaceId">
              <el-option
                v-for="item in sourcePlaceList"
                :key="item.Key"
                :label="item.Name"
                :value="item.Key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Необходима замена интервала" class="panel-cell">
            <el-checkbox
              v-model="shopData.common.IntervalSubstRequired"
            ></el-checkbox>
          </el-form-item>
          <el-form-item label="Комментарий по умолчанию" class="panel-cell">
            <el-input
              size="mini"
              type="textarea"
              v-model="shopData.common.DefaultComment"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="Тарифы" name="second">
        <el-form
          size="mini"
          ref="shopTariffData"
          :model="shopTariffData"
          label-width="300px"
        >
          <el-form-item label="Тариф">
            <el-select
              v-model="partnerEditData.selectedTariff"
              placeholder="Нет тарифов"
              @change="loadTariffData"
            >
              <el-option
                v-for="item in shopTariffList"
                :key="item.Id"
                :label="getTariffName(item)"
                :value="item.Id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Название тарифа" class="panel-cell">
            <el-input
              size="mini"
              v-model="shopTariffData.Name"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="Терминал приема" class="panel-cell">
            <el-select v-model="shopTariffData.SourcePlaceId" disabled>
              <el-option
                v-for="item in sourcePlaceList"
                :key="item.Key"
                :label="item.Name"
                :value="item.Key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Терминал доставки" class="panel-cell">
            <el-select v-model="shopTariffData.DeliveryPlaceId" disabled>
              <el-option
                v-for="item in deliveryPlaceList"
                :key="item.Key"
                :label="item.Name"
                :value="item.Key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Тип тарифа" class="panel-cell">
            <el-input
              size="mini"
              v-model="shopTariffData.TariffType"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item
            label="Тип возвратного тарифа (отказ без заезда)"
            class="panel-cell"
          >
            <el-select v-model="shopTariffData.TariffReturnType" disabled>
              <el-option
                v-for="item in returnTypeList"
                :key="item.Key"
                :label="item.Name"
                :value="item.Key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Тип возвратного тарифа (отказ с заездом, перенос с заездом)"
            class="panel-cell"
          >
            <el-select
              v-model="shopTariffData.TariffReturnTypeWithArrival"
              disabled
            >
              <el-option
                v-for="item in returnTypeList"
                :key="item.Key"
                :label="item.Name"
                :value="item.Key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Тип возвратного тарифа (частичный выкуп)"
            class="panel-cell"
          >
            <el-select
              v-model="shopTariffData.TariffReturnTypePartBuyout"
              disabled
            >
              <el-option
                v-for="item in returnTypeList"
                :key="item.Key"
                :label="item.Name"
                :value="item.Key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Дата применения" class="panel-cell">
            <el-date-picker
              v-model="shopTariffData.ApplyDate"
              type="date"
              format="dd.MM.yyyy"
              value-format="yyyy-MM-dd"
              disabled
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="Категория по умолчанию" class="panel-cell">
            <el-input
              size="mini"
              v-model="shopTariffData.DefaultCategoryName"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="За прием наличных средств, %" class="panel-cell">
            <div style="display: inline-flex">
              <el-input
                size="mini"
                style="display: flex"
                v-model="CashCommissionPer"
                disabled
              ></el-input>
              <div
                class="el-form-item__label"
                style="display: flex; white-space: nowrap; padding: 0 10px"
              >
                Не менее чем,руб
              </div>
              <el-input
                size="mini"
                style="display: flex"
                v-model="shopTariffData.CashCommissionMin"
                disabled
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item
            label="За прием оплат по терминалу, %"
            class="panel-cell"
          >
            <div style="display: inline-flex">
              <el-input
                size="mini"
                style="display: flex"
                v-model="BankCommissionPer"
                disabled
              ></el-input>
              <div
                class="el-form-item__label"
                style="display: flex; white-space: nowrap; padding: 0 10px"
              >
                Не менее чем,руб
              </div>
              <el-input
                size="mini"
                style="display: flex"
                v-model="shopTariffData.BankCommissionMin"
                disabled
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="За предоплаченные заказы, %" class="panel-cell">
            <div style="display: inline-flex">
              <el-input
                size="mini"
                style="display: flex"
                v-model="AgentCommissionPer"
                disabled
              ></el-input>
              <div
                class="el-form-item__label"
                style="display: flex; white-space: nowrap; padding: 0 10px"
              >
                Не менее чем,руб
              </div>
              <el-input
                size="mini"
                style="display: flex"
                v-model="shopTariffData.AgentCommission"
                disabled
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="Безналичный расчёт, %" class="panel-cell">
            <div style="display: inline-flex">
              <el-input
                size="mini"
                style="display: flex"
                v-model="CashlessPaymentsPer"
                disabled
              ></el-input>
              <div
                class="el-form-item__label"
                style="display: flex; white-space: nowrap; padding: 0 10px"
              >
                Не менее чем,руб
              </div>
              <el-input
                size="mini"
                style="display: flex"
                v-model="shopTariffData.CashlessPayments"
                disabled
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="Страховой сбор, %" class="panel-cell">
            <el-input
              size="mini"
              v-model="InsuranceCommissionPer"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="Расчет по объемному весу" class="panel-cell">
            <div style="display: inline-flex">
              <el-switch
                disabled
                style="display: flex"
                v-model="shopTariffData.CalcByVolumetricWeight"
              ></el-switch>
              <div
                class="el-form-item__label"
                style="display: flex; white-space: nowrap; padding: 0 10px"
              >
                Коэффициент расчета объемного веса
              </div>
              <el-input
                size="mini"
                style="display: flex; width: 100px"
                v-model="shopTariffData.CoeffVolumetricWeight"
                disabled
              ></el-input>
            </div>
          </el-form-item>
        </el-form>
        <div>Доставка</div>
        <el-table
          :data="shopTariffData.WeightBreaks"
          style="width: 100%"
          size="mini"
        >
          <el-table-column
            prop="ShortName"
            label="Категория"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="MinWeight"
            label="Вес от"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="MaxWeight"
            label="Вес до"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
          key="1"
            :label="getZoneName(1)"
            v-if="zoneShow(1)"
            header-align="center"
            align="center"
          >
            <el-table-column
            key="2"
              prop="price1"
              v-if="colPriceShow('price1')"
              label="Доставка"
              header-align="center"
              align="center"
            >
            </el-table-column>
            <el-table-column
            key="3"
              prop="price1km"
              v-if="colPriceShow('price1km')"
              label="Доставка (за 1 км от границ МКАД/КАД)"
              header-align="center"
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column
          key="4"
            :label="getZoneName(2)"
            v-if="zoneShow(2)"
            header-align="center"
            align="center"
          >
            <el-table-column
            key="5"
              prop="price2"
              v-if="colPriceShow('price2')"
              label="Доставка"
              header-align="center"
              align="center"
            >
            </el-table-column>
            <el-table-column
            key="6"
              prop="price2km"
              v-if="colPriceShow('price2km')"
              label="Доставка (за 1 км от границ МКАД/КАД)"
              header-align="center"
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column
          key="7"
            :label="getZoneName(3)"
            v-if="zoneShow(3)"
            header-align="center"
            align="center"
          >
            <el-table-column
            key="8"
              prop="price3"
              v-if="colPriceShow('price3')"
              label="Доставка"
              header-align="center"
              align="center"
            >
            </el-table-column>
            <el-table-column
            key="9"
              prop="price3km"
              v-if="colPriceShow('price3km')"
              label="Доставка (за 1 км от границ МКАД/КАД)"
              header-align="center"
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column
          key="10"
            :label="getZoneName(4)"
            v-if="zoneShow(4)"
            header-align="center"
            align="center"
          >
            <el-table-column
            key="11"
              prop="price4"
              v-if="colPriceShow('price4')"
              label="Доставка"
              header-align="center"
              align="center"
            >
            </el-table-column>
            <el-table-column
            key="12"
              prop="price4km"
              v-if="colPriceShow('price4km')"
              label="Доставка (за 1 км от границ МКАД/КАД)"
              header-align="center"
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column
          key="13"
            :label="getZoneName(5)"
            v-if="zoneShow(5)"
            header-align="center"
            align="center"
          >
            <el-table-column
            key="14"
              prop="price5"
              v-if="colPriceShow('price5')"
              label="Доставка"
              header-align="center"
              align="center"
            >
            </el-table-column>
            <el-table-column
            key="15"
              prop="price5km"
              v-if="colPriceShow('price5km')"
              label="Доставка (за 1 км от границ МКАД/КАД)"
              header-align="center"
              align="center"
            >
            </el-table-column>
          </el-table-column>
          <el-table-column
          key="16"
            prop="ReturnPrice"
            label="Фиксированный тариф (отказ без заезда)"
            v-if="priceReturnShow(1)"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
          key="17"
            prop="PercentReturnPrice"
            label="Процент от тарифа на доставку (отказ без заезда)"
            v-if="priceReturnShow(2)"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
          key="18"
            prop="ReturnPriceWithArrival"
            label="Фиксированный тариф (отказ с заездом, перенос с заездом)"
            v-if="priceReturnWithArrivalShow(1)"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
          key="19"
            prop="PercentReturnPriceWithArrival"
            label="Процент от тарифа на доставку (отказ с заездом, перенос с заездом)"
            v-if="priceReturnWithArrivalShow(2)"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
          key="20"
            prop="ReturnPricePartBuyout"
            label="Фиксированный тариф (частичный выкуп)"
            v-if="priceReturnPartBuyoutShow(1)"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
          key="21"
            prop="PercentReturnPricePartBuyout"
            label="Процент от тарифа на доставку (частичный выкуп)"
            v-if="priceReturnPartBuyoutShow(2)"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
          key="22"
            prop="SelfDeliveryReturnPrice"
            label="Возврат самовывоза"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
          key="23"
            prop="SelfDeliveryPrice"
            label="Самовывоз"
            header-align="center"
            align="center"
          >
          </el-table-column>
        </el-table>
        <div>Забор</div>
        <el-table
          :data="shopTariffData.IntakeWeightBreaks"
          style="width: 100%"
          size="mini"
        >
          <el-table-column
            prop="Name"
            label="Категория"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="MinVolume"
            label="Объем от"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="MaxVolume"
            label="Объем до"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="price1"
            v-if="colIntakePriceShow('price1')"
            :label="getIntakeZoneName(1)"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="price2"
            v-if="colIntakePriceShow('price2')"
            :label="getIntakeZoneName(2)"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="price3"
            v-if="colIntakePriceShow('price3')"
            :label="getIntakeZoneName(3)"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="price4"
            v-if="colIntakePriceShow('price4')"
            :label="getIntakeZoneName(4)"
            header-align="center"
            align="center"
          >
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="Добавление тарифа" name="third">
        <el-button size="mini" @click="saveTariff" type="Success"
          >Сохранить тариф</el-button
        >
        <el-form
          size="mini"
          :model="shopTariffEditData"
          ref="shopTariffEditData"
          label-width="450px"
          :rules="rules.TariffEditRules"
        >
          <el-form-item label="Число брейков" class="panel-cell">
            <el-input-number
              size="mini"
              v-model="partnerEditData.NumEditBreaks"
              :controls="false"
              :min="1"
              :max="99"
              style="width: 50px"
            ></el-input-number>
            <el-button size="mini" @click="createEditTarifBreaks"
              >Создать</el-button
            >
          </el-form-item>
          <el-form-item label="Загрузить тариф" class="panel-cell">
            <el-select
              v-model="partnerEditData.selectedEditTariff"
              @change="loadTariffData"
              style="width: 100px"
            >
              <el-option
                v-for="item in shopTariffList"
                :key="item.Id"
                :label="getTariffName(item)"
                :value="item.Id"
              >
              </el-option>
            </el-select>
            <el-button size="mini" @click="loadEditableTarif"
              >Загрузить</el-button
            >
          </el-form-item>
          <el-form-item prop="Name" label="Название тарифа" class="panel-cell">
            <el-input size="mini" v-model="shopTariffEditData.Name"></el-input>
          </el-form-item>
          <el-form-item
            prop="SourcePlaceId"
            label="Терминал приема"
            class="panel-cell"
          >
            <el-select
              v-model="shopTariffEditData.SourcePlaceId"
              style="width: 300px"
            >
              <el-option
                v-for="item in sourcePlaceList"
                :key="item.Key"
                :label="item.Name"
                :value="item.Key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            prop="DeliveryPlaceId"
            label="Терминал доставки"
            class="panel-cell"
          >
            <el-select
              v-model="shopTariffEditData.DeliveryPlaceId"
              @change="loadTarifEditScheme(true)"
              style="width: 300px"
            >
              <el-option
                v-for="item in deliveryPlaceList"
                :key="item.Key"
                :label="item.Name"
                :value="item.Key"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="TariffType" label="Тип тарифа" class="panel-cell">
            <el-input-number
              :controls="false"
              size="mini"
              v-model="shopTariffEditData.TariffType"
            ></el-input-number>
          </el-form-item>
          <el-form-item
            prop="TariffReturnType"
            label="Тип возвратного тарифа (отказ без заезда)"
            class="panel-cell"
          >
            <div style="display: inline-flex">
              <el-select
                v-model="shopTariffEditData.TariffReturnType"
                @change="loadDeliveryZones(true)"
                style="width: 300px; display: flex"
              >
                <el-option
                  v-for="item in returnTypeList"
                  :key="item.Key"
                  :label="item.Name"
                  :value="item.Key"
                >
                </el-option>
              </el-select>
              <div
                v-if="shopTariffEditData.TariffReturnType === 2"
                style="display: flex; padding: 0 10px"
              >
                <div style="white-space: nowrap">По умолчанию</div>
                <number-control
                  style="width: 70px; padding: 0 5px"
                  v-model="partnerEditData.DefReturnEditTariffPer"
                  :min="0"
                  :precision="2"
                ></number-control>
                <el-button size="mini" @click="fillReturnEditTarPer"
                  >Заполнить</el-button
                >
              </div>
            </div>
          </el-form-item>
          <el-form-item
            prop="TariffReturnType"
            label="Тип возвратного тарифа (отказ с заездом, перенос с заездом)"
            class="panel-cell"
          >
            <div style="display: inline-flex">
              <el-select
                v-model="shopTariffEditData.TariffReturnTypeWithArrival"
                @change="loadDeliveryZones(true)"
                style="width: 300px"
              >
                <el-option
                  v-for="item in returnTypeList"
                  :key="item.Key"
                  :label="item.Name"
                  :value="item.Key"
                >
                </el-option>
              </el-select>
              <div
                v-if="shopTariffEditData.TariffReturnTypeWithArrival === 2"
                style="display: flex; padding: 0 10px"
              >
                <div style="white-space: nowrap">По умолчанию</div>
                <number-control
                  style="width: 70px; padding: 0 5px"
                  v-model="partnerEditData.DefReturnEditTariffWithArrivalPer"
                  :min="0"
                  :precision="2"
                ></number-control>
                <el-button size="mini" @click="fillReturnEditTarWithArrivalPer"
                  >Заполнить</el-button
                >
              </div>
            </div>
          </el-form-item>
          <el-form-item
            prop="TariffReturnType"
            label="Тип возвратного тарифа (частичный выкуп)"
            class="panel-cell"
          >
            <div style="display: inline-flex">
              <el-select
                v-model="shopTariffEditData.TariffReturnTypePartBuyout"
                @change="loadDeliveryZones(true)"
                style="width: 300px"
              >
                <el-option
                  v-for="item in returnTypeList"
                  :key="item.Key"
                  :label="item.Name"
                  :value="item.Key"
                >
                </el-option>
              </el-select>
              <div
                v-if="shopTariffEditData.TariffReturnTypePartBuyout === 2"
                style="display: flex; padding: 0 10px"
              >
                <div style="white-space: nowrap">По умолчанию</div>
                <number-control
                  style="width: 70px; padding: 0 5px"
                  v-model="partnerEditData.DefReturnEditTariffPartBuyoutPer"
                  :min="0"
                  :precision="2"
                ></number-control>
                <el-button size="mini" @click="fillReturnEditTarPartBuyoutPer"
                  >Заполнить</el-button
                >
              </div>
            </div>
          </el-form-item>
          <el-form-item
            prop="ApplyDate"
            label="Дата применения"
            class="panel-cell"
          >
            <el-date-picker
              v-model="shopTariffEditData.ApplyDate"
              type="date"
              format="dd.MM.yyyy"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            prop="CashCommission"
            label="За прием наличных средств, %"
            class="panel-cell"
          >
            <div style="display: inline-flex">
              <number-control
                style="display: flex; width: 100px"
                v-model="shopTariffEditData.CashCommission"
                :min="0"
              ></number-control>
              <div
                class="el-form-item__label"
                style="display: flex; white-space: nowrap; padding: 0 10px"
              >
                Не менее чем,руб
              </div>
              <number-control
                style="display: inline-flex; width: 100px"
                v-model="shopTariffEditData.CashCommissionMin"
                :min="0"
              ></number-control>
            </div>
          </el-form-item>
          <el-form-item
            prop="BankCommission"
            label="За прием оплат по терминалу, %"
            class="panel-cell"
          >
            <div style="display: inline-flex">
              <number-control
                style="display: flex; width: 100px"
                v-model="shopTariffEditData.BankCommission"
                :min="0"
              ></number-control>
              <div
                class="el-form-item__label"
                style="display: flex; white-space: nowrap; padding: 0 10px"
              >
                Не менее чем,руб
              </div>
              <number-control
                style="display: flex; width: 100px"
                v-model="shopTariffEditData.BankCommissionMin"
                :min="0"
              ></number-control>
            </div>
          </el-form-item>
          <el-form-item
            prop="InsuranceCommission"
            label="За предоплаченные заказы, %"
            class="panel-cell"
          >
            <div style="display: inline-flex">
              <number-control
                style="display: flex; width: 100px"
                v-model="shopTariffEditData.AgentCommissionPer"
                :min="0"
              ></number-control>
              <div
                class="el-form-item__label"
                style="display: flex; white-space: nowrap; padding: 0 10px"
              >
                Не менее чем,руб
              </div>
              <number-control
                style="display: flex; width: 100px"
                v-model="shopTariffEditData.AgentCommission"
                :min="0"
              ></number-control>
            </div>
          </el-form-item>
          <el-form-item
            prop="InsuranceCommission"
            label="Безналичный расчет, %"
            class="panel-cell"
          >
            <div style="display: inline-flex">
              <number-control
                style="display: flex; width: 100px"
                v-model="shopTariffEditData.CashlessPaymentsPer"
                :min="0"
              ></number-control>
              <div
                class="el-form-item__label"
                style="display: flex; white-space: nowrap; padding: 0 10px"
              >
                Не менее чем,руб
              </div>
              <number-control
                style="display: flex; width: 100px"
                v-model="shopTariffEditData.CashlessPayments"
                :min="0"
              ></number-control>
            </div>
          </el-form-item>
          <el-form-item
            prop="InsuranceCommission"
            label="Страховой сбор, %"
            class="panel-cell"
          >
            <number-control
              style="display: flex; width: 100px"
              v-model="shopTariffEditData.InsuranceCommission"
              :min="0"
            ></number-control>
          </el-form-item>
          <el-form-item
            prop="VolumetricWeight"
            label="Расчет по объемному весу"
            class="panel-cell"
          >
            <div style="display: inline-flex">
              <el-switch
                v-model="shopTariffEditData.CalcByVolumetricWeight"
              ></el-switch>
              <div
                class="el-form-item__label"
                style="display: flex; white-space: nowrap; padding: 0 10px"
              >
                Коэффициент расчета объемного веса
              </div>
              <number-control
                style="display: flex; width: 100px"
                v-model="shopTariffEditData.CoeffVolumetricWeight"
                :min="0"
              ></number-control>
            </div>
          </el-form-item>
          <el-form-item
            label="Копировать тариф в подчиненные"
            class="panel-cell"
          >
            <el-switch v-model="partnerEditData.CopyEditTarInChild"></el-switch>
          </el-form-item>
          <div>Тарифы на доставку</div>
          <el-form-item prop="WeightBreak" label-width="0px">
            <el-select
              size="mini"
              v-model="shopTariffEditData.SchemeId"
              placeholder="Схема зонирования"
              @change="loadDeliveryZones"
            >
              <el-option
                v-for="item in TarifEditSchemeList"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
              >
              </el-option>
            </el-select>
            <el-select
              size="mini"
              v-model="partnerEditData.EditDeliveryZones"
              placeholder="Зоны"
              style="width: 300px"
              multiple
              collapse-tags
            >
              <el-option
                v-for="item in TarifEditDelZonesList"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
              >
              </el-option>
            </el-select>
            <el-button size="mini" @click="setDefaultBreak()"
              >Брейк по умолчанию</el-button
            >
            <el-button size="mini" @click="addTarifBreak()"
              >Добавить брейк</el-button
            >
          </el-form-item>
        </el-form>
        <el-table
          :data="shopTariffEditData.WeightBreaks"
          ref="shopTariffEditDataWB"
          style="width: 100%"
          size="mini"
          highlight-current-row
          @current-change="handleTarifBreakSelect"
          :cell-class-name="getWeightBreakRowClass"
        >
          <el-table-column
            prop="ShortName"
            label="Категория"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-input size="mini" v-model="scope.row.ShortName"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="MinWeight"
            label="Вес от"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <number-control
                style="width: 100%"
                v-model="scope.row.MinWeight"
                :min="0"
              ></number-control>
            </template>
          </el-table-column>
          <el-table-column
            prop="MaxWeight"
            label="Вес до"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <number-control
                style="width: 100%"
                v-model="scope.row.MaxWeight"
                :min="0"
              ></number-control>
            </template>
          </el-table-column>
          <el-table-column
            :label="getEditZoneName(1)"
            v-if="zoneEditShow(1)"
            header-align="center"
            align="center"
          >
            <el-table-column
              prop="price1"
              label="Доставка"
              v-if="zoneEditShow(1)"
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                <number-control
                  style="width: 100%"
                  v-model="scope.row.price1"
                  :min="0"
                  :precision="2"
                ></number-control>
              </template>
            </el-table-column>
            <el-table-column
              prop="price1km"
              v-if="zoneEditShow(1) && colEditPriceShow('price1km')"
              label="Доставка (за 1 км от границ МКАД/КАД)"
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                <number-control
                  style="width: 100%"
                  v-model="scope.row.price1km"
                  :min="0"
                  :precision="2"
                ></number-control>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            :label="getEditZoneName(2)"
            v-if="zoneEditShow(2)"
            header-align="center"
            align="center"
          >
            <el-table-column
              prop="price2"
              label="Доставка"
              v-if="zoneEditShow(2)"
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                <number-control
                  style="width: 100%"
                  v-model="scope.row.price2"
                  :min="0"
                  :precision="2"
                ></number-control>
              </template>
            </el-table-column>
            <el-table-column
              prop="price2km"
              v-if="zoneEditShow(2) && colEditPriceShow('price2km')"
              label="Доставка (за 1 км от границ МКАД/КАД)"
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                <number-control
                  style="width: 100%"
                  v-model="scope.row.price2km"
                  :min="0"
                  :precision="2"
                ></number-control>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            :label="getEditZoneName(3)"
            v-if="zoneEditShow(3)"
            header-align="center"
            align="center"
          >
            <el-table-column
              prop="price3"
              label="Доставка"
              v-if="zoneEditShow(3)"
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                <number-control
                  style="width: 100%"
                  v-model="scope.row.price3"
                  :min="0"
                  :precision="2"
                ></number-control>
              </template>
            </el-table-column>
            <el-table-column
              prop="price3km"
              v-if="zoneEditShow(3) && colEditPriceShow('price3km')"
              label="Доставка (за 1 км от границ МКАД/КАД)"
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                <number-control
                  style="width: 100%"
                  v-model="scope.row.price3km"
                  :min="0"
                  :precision="2"
                ></number-control>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            :label="getEditZoneName(4)"
            v-if="zoneEditShow(4)"
            header-align="center"
            align="center"
          >
            <el-table-column
              prop="price4"
              label="Доставка"
              v-if="zoneEditShow(4)"
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                <number-control
                  style="width: 100%"
                  v-model="scope.row.price4"
                  :min="0"
                  :precision="2"
                ></number-control>
              </template>
            </el-table-column>
            <el-table-column
              prop="price4km"
              v-if="zoneEditShow(4) && colEditPriceShow('price4km')"
              label="Доставка (за 1 км от границ МКАД/КАД)"
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                <number-control
                  style="width: 100%"
                  v-model="scope.row.price4km"
                  :min="0"
                  :precision="2"
                ></number-control>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            :label="getEditZoneName(5)"
            v-if="zoneEditShow(5)"
            header-align="center"
            align="center"
          >
            <el-table-column
              prop="price5"
              label="Доставка"
              v-if="zoneEditShow(5)"
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                <number-control
                  style="width: 100%"
                  v-model="scope.row.price5"
                  :min="0"
                  :precision="2"
                ></number-control>
              </template>
            </el-table-column>
            <el-table-column
              prop="price4km"
              v-if="zoneEditShow(5) && colEditPriceShow('price5km')"
              label="Доставка (за 1 км от границ МКАД/КАД)"
              header-align="center"
              align="center"
            >
              <template slot-scope="scope">
                <number-control
                  style="width: 100%"
                  v-model="scope.row.price5km"
                  :min="0"
                  :precision="2"
                ></number-control>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            prop="ReturnPrice"
            label="Фиксированный тариф (отказ без заезда)"
            v-if="zoneEditFixShow(-3) && priceEditReturnShow(1)"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <number-control
                style="width: 100%"
                v-model="scope.row.ReturnPrice"
                :min="0"
                :precision="2"
              ></number-control>
            </template>
          </el-table-column>
          <el-table-column
            prop="PercentReturnPrice"
            label="Процент от тарифа на доставку (отказ без заезда)"
            v-if="zoneEditFixShow(-4) && priceEditReturnShow(2)"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <number-control
                style="width: 100%"
                v-model="scope.row.PercentReturnPrice"
                :min="0"
                :precision="2"
              ></number-control>
            </template>
          </el-table-column>
          <el-table-column
            prop="ReturnPriceWithArrival"
            label="Фиксированный тариф (отказ с заездом, перенос с заездом)"
            v-if="zoneEditFixShow(-5) && priceEditReturnWithArrivalShow(1)"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <number-control
                style="width: 100%"
                v-model="scope.row.ReturnPriceWithArrival"
                :min="0"
                :precision="2"
              ></number-control>
            </template>
          </el-table-column>
          <el-table-column
            prop="PercentReturnPriceWithArrival"
            label="Процент от тарифа на доставку (отказ с заездом, перенос с заездом)"
            v-if="zoneEditFixShow(-6) && priceEditReturnWithArrivalShow(2)"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <number-control
                style="width: 100%"
                v-model="scope.row.PercentReturnPriceWithArrival"
                :min="0"
                :precision="2"
              ></number-control>
            </template>
          </el-table-column>
          <el-table-column
            prop="ReturnPricePartBuyout"
            label="Фиксированный тариф (частичный выкуп)"
            v-if="zoneEditFixShow(-7) && priceEditReturnPartBuyoutShow(1)"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <number-control
                style="width: 100%"
                v-model="scope.row.ReturnPricePartBuyout"
                :min="0"
                :precision="2"
              ></number-control>
            </template>
          </el-table-column>
          <el-table-column
            prop="PercentReturnPricePartBuyout"
            label="Процент от тарифа на доставку (частичный выкуп)"
            v-if="zoneEditFixShow(-8) && priceEditReturnPartBuyoutShow(2)"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <number-control
                style="width: 100%"
                v-model="scope.row.PercentReturnPricePartBuyout"
                :min="0"
                :precision="2"
              ></number-control>
            </template>
          </el-table-column>
          <el-table-column
            prop="SelfDeliveryReturnPrice"
            label="Возврат самовывоза"
            v-if="zoneEditFixShow(-2)"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <number-control
                style="width: 100%"
                v-model="scope.row.SelfDeliveryReturnPrice"
                :min="0"
                :precision="2"
              ></number-control>
            </template>
          </el-table-column>
          <el-table-column
            prop="SelfDeliveryPrice"
            label="Самовывоз"
            v-if="zoneEditFixShow(-1)"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <number-control
                style="width: 100%"
                v-model="scope.row.SelfDeliveryPrice"
                :min="0"
                :precision="2"
              ></number-control>
            </template>
          </el-table-column>
          <el-table-column header-align="center" align="center" width="80">
            <template slot-scope="scope">
              <el-button
                size="mini"
                icon="el-icon-plus"
                @click="addTarifBreak(scope.$index)"
              ></el-button>
            </template>
          </el-table-column>
          <el-table-column header-align="center" align="center" width="80">
            <template slot-scope="scope">
              <el-button
                size="mini"
                icon="el-icon-delete"
                @click="delTarifBreak(scope.$index)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
        <div>Тарифы на забор</div>
        <div>
          <el-select
            size="mini"
            v-model="shopTariffEditData.IntakeSchemeId"
            placeholder="Схема зонирования"
            @change="loadIntakeDeliveryZones(true)"
          >
            <el-option
              v-for="item in TarifEditSchemeList"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id"
            >
            </el-option>
          </el-select>
          <el-select
            size="mini"
            v-model="partnerEditData.EditIntakeDeliveryZones"
            placeholder="Зоны"
            style="width: 300px"
            multiple
            collapse-tags
          >
            <el-option
              v-for="item in TarifEditIntakeDelZonesList"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id"
            >
            </el-option>
          </el-select>
          <el-button size="mini" @click="addIntakeTarifBreak()"
            >Добавить брейк</el-button
          >
        </div>
        <el-table
          :data="shopTariffEditData.IntakeWeightBreaks"
          style="width: 100%"
          size="mini"
        >
          <el-table-column
            label="Категория"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-input size="mini" v-model="scope.row.Name"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="Объем от"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <number-control
                v-model="scope.row.MinVolume"
                :min="0"
                :precision="3"
              ></number-control>
            </template>
          </el-table-column>
          <el-table-column
            label="Объем до"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <number-control
                v-model="scope.row.MaxVolume"
                :min="0"
                :precision="3"
              ></number-control>
            </template>
          </el-table-column>
          <el-table-column
            v-if="zoneIntakeEditShow(1) && colEditIntakePriceShow('price1')"
            :label="getEditIntakeZoneName(1)"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <number-control
                v-model="scope.row.price1"
                :min="0"
                :precision="2"
              ></number-control>
            </template>
          </el-table-column>
          <el-table-column
            v-if="zoneIntakeEditShow(2) && colEditIntakePriceShow('price2')"
            :label="getEditIntakeZoneName(2)"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <number-control
                v-model="scope.row.price2"
                :min="0"
                :precision="2"
              ></number-control>
            </template>
          </el-table-column>
          <el-table-column
            v-if="zoneIntakeEditShow(3) && colEditIntakePriceShow('price3')"
            :label="getEditIntakeZoneName(3)"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <number-control
                v-model="scope.row.price3"
                :min="0"
                :precision="2"
              ></number-control>
            </template>
          </el-table-column>
          <el-table-column
            v-if="zoneIntakeEditShow(4) && colEditIntakePriceShow('price4')"
            :label="getEditIntakeZoneName(4)"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <number-control
                v-model="scope.row.price4"
                :min="0"
                :precision="2"
              ></number-control>
            </template>
          </el-table-column>
          <el-table-column header-align="center" align="center" width="80">
            <template slot-scope="scope">
              <el-button
                size="mini"
                icon="el-icon-plus"
                @click="addIntakeTarifBreak(scope.$index)"
              ></el-button>
            </template>
          </el-table-column>
          <el-table-column header-align="center" align="center" width="80">
            <template slot-scope="scope">
              <el-button
                size="mini"
                icon="el-icon-delete"
                @click="delIntakeTarifBreak(scope.$index)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="Склады" name="fourth">
        <div>
          <el-checkbox v-model="shopActStocksIsShow"
            >Показать активные</el-checkbox
          >
          <el-button size="mini" v-if="shopActStocksIsShow" @click="addStock"
            >Добавить склад</el-button
          >
          <el-button size="mini" @click="setDefaultStock"
            >Назначить по умолчанию</el-button
          >
          <el-button size="mini" @click="delDefaultStock"
            >Убрать склад по умолчанию</el-button
          >
          <el-button size="mini" @click="saveStockChanges"
            >Сохранить изменения</el-button
          >
          <el-button size="mini" @click="cancelStockChanges">Отмена</el-button>
        </div>
        <el-table
          :data="ShopStocksData"
          ref="shopStocks"
          style="width: 100%"
          size="mini"
          highlight-current-row
          @current-change="handleStockSelect"
          :cell-class-name="getStockRowClass"
        >
          <el-table-column
            prop="StockId"
            label="Id"
            header-align="center"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="StockIdImport"
            label="Внешний идентификатор"
            header-align="center"
            align="center"
            width="250"
          >
          </el-table-column>
          <el-table-column
            prop="StockName"
            label="Название склада"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.StockName"
                :disabled="!stockIsEditable(scope.row)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="TerminalId"
            label="Терминал"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <source-terminal-control
                :disabled="!stockIsEditable(scope.row)"
                v-model="scope.row.TerminalId"
                :useAllOpt="false"
              ></source-terminal-control>
            </template>
          </el-table-column>
          <el-table-column
            prop="Address"
            label="Адрес"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.Address"
                :disabled="!stockIsEditable(scope.row)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="PhoneNumber"
            label="Телефон"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.PhoneNumber"
                :disabled="!stockIsEditable(scope.row)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="Сделать активным/неактивным"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                v-show="scope.row.StockId && !scope.row.IsActive"
                @click="setStockState(scope.row.StockId, true, scope.row)"
                >Сделать активным</el-button
              >
              <el-button
                size="mini"
                v-show="scope.row.StockId && scope.row.IsActive"
                @click="setStockState(scope.row.StockId, false, scope.row)"
                >Сделать неактивным</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          :page-sizes="[100, 500, 1000]"
          layout="sizes,prev, pager, next"
          :total="ShopStocksCnt"
          :current-page.sync="shopStocksCurPage"
          :page-size.sync="shopStocksPageSize"
        >
        </el-pagination>
      </el-tab-pane>
      <el-tab-pane label="Подъемы" name="fifth">
        <el-button size="mini" @click="addTariffRise"
          >Добавить подъем</el-button
        >
        <el-table
          :data="shopTariffRise"
          ref="shopTariffRise"
          style="width: 100%"
          size="mini"
          highlight-current-row
          @current-change="handleTariffRiseSelect"
        >
          <el-table-column header-align="center" align="center" width="400">
            <template slot-scope="scope">
              <el-button
                :disabled="!scope.row.Id || scope.row.Id < 0"
                size="mini"
                @click="copyTariffRise(scope.row)"
                >Копировать в подчиненные</el-button
              >
              <el-button
                size="mini"
                @click="saveTariffRise(scope.row, scope.$index)"
                >Сохранить</el-button
              >
              <el-button
                size="mini"
                @click="deleteTariffRise(scope.row, scope.$index)"
                >Удалить</el-button
              >
            </template>
          </el-table-column>
          <el-table-column
            label="Название"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-input size="mini" v-model="scope.row.Name"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="Кто оплачивает подъем"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-select
                size="mini"
                v-model="scope.row.WhoPaysRise"
                placeholder="Выберите из списка"
              >
                <el-option
                  v-for="item in WhoPaysRiseList"
                  :key="item.Key"
                  :label="item.Name"
                  :value="item.Key"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            label="Дата начала тарифа"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-date-picker
                style="width: 100%"
                v-model="scope.row.StartDateFormated"
                type="date"
                format="dd.MM.yyyy"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </template>
          </el-table-column>
          <el-table-column label="Блок" header-align="center" align="center">
            <template slot-scope="scope">
              <el-checkbox
                size="mini"
                v-model="scope.row.Readonly"
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            label="Расширенный режим"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-checkbox size="mini" v-model="scope.row.Mode"></el-checkbox>
            </template>
          </el-table-column>
        </el-table>
        <div style="width: 50%; float: left">
          <div style="text-align: center">Экипажи</div>
          <el-button size="mini" @click="addRiseWeightBreak"
            >Добавить</el-button
          >
          <el-table
            :data="shopWeightBreak"
            ref="shopWeightBreak"
            style="width: 100%"
            size="mini"
            highlight-current-row
          >
            <el-table-column header-align="center" align="center">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  icon="el-icon-delete"
                  @click="removeRiseWeightBreak(scope.$index)"
                ></el-button>
              </template>
            </el-table-column>
            <el-table-column label="Вес">
              <el-table-column
                label="От (Кг)"
                header-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <el-input
                    size="mini"
                    v-model="scope.row.WeightFrom"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column
                label="До (Кг)"
                header-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.WeightTo"></el-input>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="Лифт">
              <el-table-column label="Да" header-align="center" align="center">
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.LiftOn"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="Нет" header-align="center" align="center">
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.LiftOff"></el-input>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
        <div
          style="width: 50%; float: left"
          v-show="this.showCourierWeightBreakTab()"
        >
          <div style="text-align: center">Контрагенты</div>
          <el-button size="mini" @click="addRiseCourierWeightBreak"
            >Добавить</el-button
          >
          <el-table
            :data="shopCourierWeightBreak"
            ref="shopCourierWeightBreak"
            style="width: 100%"
            size="mini"
            highlight-current-row
          >
            <el-table-column header-align="center" align="center">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  icon="el-icon-delete"
                  @click="removeRiseCourierWeightBreak(scope.$index)"
                ></el-button>
              </template>
            </el-table-column>
            <el-table-column label="Вес">
              <el-table-column
                label="От (Кг)"
                header-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <el-input
                    size="mini"
                    v-model="scope.row.WeightFrom"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column
                label="До (Кг)"
                header-align="center"
                align="center"
              >
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.WeightTo"></el-input>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="Лифт">
              <el-table-column label="Да" header-align="center" align="center">
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.LiftOn"></el-input>
                </template>
              </el-table-column>
              <el-table-column label="Нет" header-align="center" align="center">
                <template slot-scope="scope">
                  <el-input size="mini" v-model="scope.row.LiftOff"></el-input>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Автоматические заявки" name="sixth">
        <el-table :data="shopData.autoOrders" style="width: 100%" size="mini">
          <el-table-column
            label="Расписание"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.Name"
                disabled
                v-if="scope.row.Name"
              ></el-input>
              <el-select
                size="mini"
                v-model="scope.row.Schedules"
                v-if="!scope.row.Name"
                multiple
                style="width: 100%"
              >
                <el-option
                  v-for="item in ScheduleList"
                  :key="item.Key"
                  :label="item.Name"
                  :value="item.Key"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="Шаблон" header-align="center" align="center">
            <template slot-scope="scope">
              <el-input
                size="mini"
                v-model="scope.row.OrderId"
                :disabled="scope.row.Name && true"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="Название"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-input size="mini" v-model="scope.row.TemplateName"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="Действие"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="disableAutoOrd(scope.row.TemplateId)"
                v-if="scope.row.Name"
                >Отключить</el-button
              >
              <el-button size="mini" @click="addAutoOrd(scope.row)">{{
                scope.row.TemplateId ? "Сохранить" : "Добавить"
              }}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="СНО" name="seventh">
        <el-button size="mini" @click="addTaxSystem" style="margin: 15px"
          >Добавить</el-button
        >
        <el-table :data="shopData.taxSystem" style="width: 100%" size="mini">
          <el-table-column label="Дата" header-align="center" align="center">
            <template slot-scope="scope">
              <el-date-picker
                v-model="scope.row.StartDate"
                type="date"
                format="dd.MM.yyyy"
                value-format="yyyy-MM-dd"
                :disabled="scope.row.Id && true"
              ></el-date-picker>
            </template>
          </el-table-column>
          <el-table-column label="СНО" header-align="center" align="center">
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.TypeId"
                :disabled="scope.row.Id && true"
              >
                <el-option
                  v-for="item in TaxSystemList"
                  :key="item.Key"
                  :label="item.Name"
                  :value="item.Key"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            label="Действие"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="saveTaxSystem(scope.row)"
                v-if="!scope.row.Id"
                >Сохранить</el-button
              >
              <el-button
                size="mini"
                @click="cancelTaxSystem()"
                v-if="!scope.row.Id"
                >Отмена</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="Определение терминала" name="eighth">
        <el-button size="mini" @click="addTermKeyWord" style="margin: 15px"
          >Добавить</el-button
        >
        <el-button size="mini" @click="saveTermKeyWords">Сохранить</el-button>
        <el-table :data="shopData.termKeyWords" style="width: 100%" size="mini">
          <el-table-column
            label="Слово для поиска"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-input size="mini" v-model="scope.row.KeyWord"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            label="Терминал"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-select v-model="scope.row.TerminalId">
                <el-option
                  v-for="item in sourcePlaceList"
                  :key="item.Key"
                  :label="item.Name"
                  :value="item.Key"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            label="Действие"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="removeKeyWord(scope.row, scope.$index)"
                >Удалить</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="Настройки ЛК" name="nineth">
        <el-form
          size="mini"
          ref="shopData"
          :model="shopData.commonLK"
          label-width="250px"
        >
          <el-button
            size="mini"
            @click="saveCommonLKSettings"
            v-loading.fullscreen.lock="fullScreenLoading"
            type="success"
            style="margin: 15px"
            >Сохранить</el-button
          >
          <div class="panel">
            <div class="panel-heading">Настройки по умолчанию</div>
            <div class="panel-content">
              <el-form-item label="ИМ по умолчанию" class="panel-cell">
                <el-select v-model="shopData.commonLK.DefaultShopId" clearable>
                  <el-option
                    v-for="item in subShopList"
                    :key="item.SettingId"
                    :label="item.Name"
                    :value="item.SettingId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="Регион доставки по умолчанию"
                class="panel-cell"
              >
                <el-select
                  v-model="shopData.commonLK.DefaultDeliveryPlace"
                  clearable
                >
                  <el-option
                    v-for="item in deliveryPlaceList"
                    :key="item.Key"
                    :label="item.Name"
                    :value="item.Key"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label="Регион отгрузки по умолчанию"
                class="panel-cell"
              >
                <el-select
                  v-model="shopData.commonLK.DefaultSourcePlace"
                  clearable
                >
                  <el-option
                    v-for="item in sourcePlaceList"
                    :key="item.Key"
                    :label="item.Name"
                    :value="item.Key"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Тип оплаты по умолчанию" class="panel-cell">
                <el-select v-model="shopData.commonLK.DefaultPayType" clearable>
                  <el-option
                    v-for="item in PayTypeList"
                    :key="item.Key"
                    :label="item.Name"
                    :value="item.Key"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Частичный выкуп запрещен" class="panel-cell">
                <el-switch
                  v-model="shopData.commonLK.DefaultPartBuyout"
                  active-color="#ff4949"
                  inactive-color="#DCDFE6"
                ></el-switch>
              </el-form-item>
              <el-form-item label="Подъем по умолчанию" class="panel-cell">
                <el-select
                  v-model="shopData.commonLK.DefaultWhoPayRise"
                  clearable
                >
                  <el-option
                    v-for="item in WhoPaysRiseList"
                    :key="item.Key"
                    :label="item.Name"
                    :value="item.Key"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="panel">
            <div class="panel-heading">Услуги</div>
            <div class="panel-content">
              <el-form-item
                label="Подписание и возврат сопр. документов"
                class="panel-cell"
              >
                <el-switch
                  v-model="shopData.commonLK.IsNeedForwarding"
                ></el-switch>
              </el-form-item>
            </div>
          </div>
          <div class="panel">
            <div class="panel-heading">Расчет стоимости доставки</div>
            <div class="panel-content">
              <el-form-item
                label="Стоимость выкупа, после которой обнуляется стоимость доставки"
                class="panel-cell"
              >
                <number-control
                  style="width: 130px"
                  v-model="shopData.commonLK.TotalValue"
                  :min="0"
                  :precision="2"
                ></number-control>
              </el-form-item>
              <el-form-item
                label="Необходимость оплаты стоимости доставки при отказе от выкупа"
                class="panel-cell"
              >
                <el-switch
                  v-model="shopData.commonLK.CostDeliveryRequired"
                ></el-switch>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </el-tab-pane>
      <el-tab-pane lazy label="Параметры системы" name="tenth">
        <ShopSystemSettings />
      </el-tab-pane>
      <el-tab-pane lazy label="Параметры интеграции" name="eleventh">
        <ShopIntegrationSettings />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import NumberControl from "./controls/NumberControl.vue";
import SourceTerminalControl from "./controls/SourceTerminalControl.vue";
import ShopIntegrationSettings from "./ShopEditComponents/ShopIntegrationSettings.vue";
import ShopSystemSettings from "./ShopEditComponents/ShopSystemSettings.vue";

export default {
  name: "ShopEdit",
  components: {
    NumberControl,
    SourceTerminalControl,
    ShopIntegrationSettings,
    ShopSystemSettings,
  },
  data: function () {
    var checkWeightBreakValue = (rule, value, callback) => {
      let self = this;
      var error = false;
      let message = "";
      var DefaultCategory = this.shopTariffEditData.WeightBreaks.filter(
        (weightBreak) => weightBreak.IsDefaultCategory
      );
      if (DefaultCategory.length !== 1) {
        message = "Не выбран брейк по умолчанию";
        error = true;
      }
      if (!error) {
        this.shopTariffEditData.WeightBreaks.forEach(function (item) {
          error = error || self.checkWeightBreak(item);
        });
        if (error) message = "Не заполнены брейки на доставку";
      }

      if (!error) {
        this.shopTariffEditData.IntakeWeightBreaks.forEach(function (item) {
          error = error || self.checkIntakeWeightBreak(item);
        });
        if (error) message = "Не заполнены брейки на забор";
      }

      if (error) {
        callback(message);
      } else {
        callback();
      }
    };
    return {
      shopData: {
        common: {
          ShopName: "",
          ShopTitle: "",
          ShopJurFace: "",
          ShopPhone: "",
          Sorting: "",
          ParentId: null,
          EnableNDS: false,
          ForbidDuplicate: false,
          EnableDelivDateShift: false,
          DisabledStockProc: false,
          AccessKey: null,
        },
        commonLK: {
          DefaultShopId: null,
          DefaultSourcePlace: null,
          DefaultDeliveryPlace: null,
          DefaultPartBuyout: null,
          DefaultWhoPayRise: null,
          IsNeedForwarding: false,
        },
        autoOrders: [
          {
            Schedules: [],
          },
        ],
        taxSystem: [],
        termKeyWords: [],
      },
      shopTariffList: [],
      shopTariffData: {
        Name: "",
        SourcePlaceId: null,
        DeliveryPlaceId: null,
        TariffType: "",
        TariffReturnType: "",
        ApplyDate: null,
        tariffDefaultCategory: "",
        CashCommission: 0,
        CashCommissionMin: 0,
        BankCommission: 0,
        BankCommissionMin: 0,
        InsuranceCommission: 0,
        AgentCommission: 0,
        AgentCommissionPer: 0,
        CashlessPayments: 0,
        CashlessPaymentsPer: 0,
        WeightBreaks: [],
        IntakeWeightBreaks: [],
      },
      shopTariffEditData: {
        Name: "",
        SourcePlaceId: null,
        DeliveryPlaceId: null,
        TariffType: "",
        TariffReturnType: "",
        TariffReturnTypeWithArrival: "",
        TariffReturnTypePartBuyout: "",
        ApplyDate: null,
        tariffDefaultCategory: "",
        CashCommission: 0,
        CashCommissionMin: 0,
        BankCommission: 0,
        BankCommissionMin: 0,
        InsuranceCommission: 0,
        AgentCommission: 0,
        AgentCommissionPer: 0,
        CashlessPaymentsPer: 0,
        CashlessPayments: 0,
        CalcByVolumetricWeight: false,
        CoeffVolumetricWeight: 0,
        WeightBreaks: [],
        IntakeWeightBreaks: [],
        SchemeId: null,
      },
      shopStocks: [],
      shopActStocksIsShow: true,
      shopStocksCurPage: 1,
      shopStocksPageSize: 100,
      shopTariffRise: [],
      shopWeightBreak: [],
      shopCourierWeightBreak: [],
      partnerEditData: {
        selectedTariff: null,
        tarColNames: [],
        tarShowColPrice: [],
        tarIntakeColNames: [],
        tarShowIntakeColPrice: [],
        selectedEditTariff: null,
        tarEditColNames: [],
        tarShowEditColPrice: [],
        tarIntakeEditColNames: [],
        tarIntakeShowEditColPrice: [],
        DefReturnEditTariffPer: 0,
        DefReturnEditTariffWithArrivalPer: 0,
        DefReturnEditTariffPartBuyoutPer: 0,
        CopyEditTarInChild: false,
        NumEditBreaks: 1,
        EditDeliveryZones: [],
        EditIntakeDeliveryZones: [],
        selectedStock: null,
        selectedTariffRise: null,
      },
      WhoPaysRiseList: [],
      sourcePlaceList: [],
      deliveryPlaceList: [],
      subShopList: [],
      PayTypeList: [],
      returnTypeList: [
        {
          Key: 1,
          Name: "Фиксированный тариф",
        },
        {
          Key: 2,
          Name: "Процент от тарифа на доставку",
        },
      ],
      ScheduleList: [
        { Key: 1, Name: "Пн" },
        { Key: 2, Name: "Вт" },
        { Key: 3, Name: "Ср" },
        { Key: 4, Name: "Чт" },
        { Key: 5, Name: "Пт" },
        { Key: 6, Name: "Сб" },
        { Key: 7, Name: "Вс" },
      ],
      TaxSystemList: [],
      TarifEditSchemeList: [],
      TarifEditDelZonesList: [],
      TarifEditIntakeDelZonesList: [],
      visualSettings: {
        dialogNewShop: false,
      },
      fullScreenLoading: false,
      rules: {
        TariffEditRules: {
          Name: [
            {
              required: true,
              message: "Введите название тарифа",
              trigger: "blur",
            },
          ],
          SourcePlaceId: [
            {
              required: true,
              message: "Введите терминал приема",
              trigger: "blur",
            },
          ],
          DeliveryPlaceId: [
            {
              required: true,
              message: "Введите терминал доставки",
              trigger: "blur",
            },
          ],
          TariffType: [
            { required: true, message: "Введите тип тарифа", trigger: "blur" },
          ],
          TariffReturnType: [
            {
              required: true,
              message: "Введите тип возвратного тарифа",
              trigger: "blur",
            },
          ],
          ApplyDate: [
            {
              required: true,
              message: "Введите дату применения",
              trigger: "blur",
            },
          ],
          CashCommission: [
            {
              required: true,
              message: "Введите комиссию за прием наличных средств",
              trigger: "blur",
            },
          ],
          BankCommission: [
            {
              required: true,
              message: "Введите комиссию за прием оплат по терминалу",
              trigger: "blur",
            },
          ],
          InsuranceCommission: [
            {
              required: true,
              message: "Введите страховой сбор",
              trigger: "blur",
            },
          ],
          AgentCommission: [
            {
              required: true,
              message: "Введите агентское вознаграждение",
              trigger: "blur",
            },
          ],
          AgentCommissionPer: [
            {
              required: true,
              message: "Введите агентское вознаграждение",
              trigger: "blur",
            },
          ],
          CashlessPayments: [
            {
              required: true,
              message: "Введите безналичный расчёт",
              trigger: "blur",
            },
          ],
          CashlessPaymentsPer: [
            {
              required: true,
              message: "Введите безналичный расчёт",
              trigger: "blur",
            },
          ],
          WeightBreak: [{ validator: checkWeightBreakValue, trigger: "blur" }],
        },
      },
    };
  },
  computed: {
    ShopStocksFilter() {
      let self = this;
      return this.shopStocks.filter(
        (item) => item.IsActive === self.shopActStocksIsShow
      );
    },
    ShopStocksCnt() {
      return this.ShopStocksFilter.length;
    },
    ShopStocksData() {
      let shopStocksCnt = this.ShopStocksCnt;
      let maxVal = this.shopStocksCurPage * this.shopStocksPageSize;
      if (maxVal > shopStocksCnt) maxVal = shopStocksCnt;
      return this.ShopStocksFilter.slice(
        (this.shopStocksCurPage - 1) * this.shopStocksPageSize,
        maxVal
      );
    },
    CashCommissionPer() {
      return this.shopTariffData.CashCommission * 100 + "%";
    },
    BankCommissionPer() {
      return this.shopTariffData.BankCommission * 100 + "%";
    },
    InsuranceCommissionPer() {
      return this.shopTariffData.InsuranceCommission * 100 + "%";
    },
    AgentCommissionPer() {
      return this.shopTariffData.AgentCommissionPer * 100 + "%";
    },
    CashlessPaymentsPer() {
      return this.shopTariffData.CashlessPaymentsPer * 100 + "%";
    },
  },
  methods: {
    checkWeightBreak(weightBreak) {
      var error = false;
      if (
        !weightBreak.ShortName ||
        isNaN(weightBreak.MinWeight) ||
        isNaN(weightBreak.MaxWeight)
      ) {
        error = true;
      } else if (
        this.zoneEditFixShow(-3) &&
        this.priceEditReturnShow(1) &&
        isNaN(weightBreak.ReturnPrice)
      ) {
        error = true;
      } else if (
        this.zoneEditFixShow(-4) &&
        this.priceEditReturnShow(2) &&
        isNaN(weightBreak.PercentReturnPrice)
      ) {
        error = true;
      } else if (
        this.zoneEditFixShow(-2) &&
        isNaN(weightBreak.SelfDeliveryReturnPrice)
      ) {
        error = true;
      } else if (
        this.zoneEditFixShow(-1) &&
        isNaN(weightBreak.SelfDeliveryPrice)
      ) {
        error = true;
      } else {
        for (var i = 1; i < 5; i++) {
          if (this.zoneEditShow(i)) {
            if (isNaN(weightBreak["price" + i])) {
              error = true;
            } else if (
              this.colEditPriceShow("price" + i + "km") &&
              isNaN(weightBreak["price" + i + "km"])
            ) {
              error = true;
            }
          }
        }
      }
      return error;
    },
    checkIntakeWeightBreak(weightBreak) {
      var error = false;
      if (
        !weightBreak.Name ||
        isNaN(weightBreak.MinVolume) ||
        isNaN(weightBreak.MaxVolume)
      ) {
        error = true;
      } else {
        for (var i = 1; i < 5; i++) {
          if (
            this.zoneIntakeEditShow(i) &&
            this.colEditIntakePriceShow("price" + i)
          ) {
            if (isNaN(weightBreak["price" + i])) {
              error = true;
            }
          }
        }
      }
      return error;
    },
    getTariffName(item) {
      let dat;
      try {
        dat = new Date(item.StartDate);
      } catch (e) {
        console.log(e);
        return item.Name;
      }
      let mm = dat.getMonth() + 1; // getMonth() is zero-based
      let dd = dat.getDate();
      let dateStr = [
        (dd > 9 ? "" : "0") + dd,
        (mm > 9 ? "" : "0") + mm,
        dat.getFullYear(),
      ].join(".");
      return [item.Name, "(", dateStr, ")"].join("");
    },
    getWeightBreakRowClass(item) {
      if (item.row.IsDefaultCategory) {
        return "defWBRow";
      }
      return "";
    },
    handleTarifBreakSelect(tariffBreak) {
      this.partnerEditData.selectedTariffBreak = tariffBreak;
    },
    addIntakeTarifBreak(index) {
      var tarifBreak = {};
      if (!index) {
        index = 0;
      }
      this.shopTariffEditData.IntakeWeightBreaks.splice(++index, 0, tarifBreak);
    },
    delIntakeTarifBreak(index) {
      this.shopTariffEditData.IntakeWeightBreaks.splice(index, 1);
    },
    setDefaultBreak() {
      this.shopTariffEditData.WeightBreaks.forEach(function (item) {
        item.IsDefaultCategory = false;
      });
      if (this.partnerEditData.selectedTariffBreak)
        this.partnerEditData.selectedTariffBreak.IsDefaultCategory = true;
      this.$refs.shopTariffEditDataWB.setCurrentRow();
    },
    addTarifBreak(index) {
      var tarifBreak = {};
      if (!index) {
        index = 0;
      }
      this.shopTariffEditData.WeightBreaks.splice(++index, 0, tarifBreak);
    },
    delTarifBreak(index) {
      this.shopTariffEditData.WeightBreaks.splice(index, 1);
    },
    fillReturnEditTarPer() {
      var tarPerPrice = this.partnerEditData.DefReturnEditTariffPer;
      this.shopTariffEditData.WeightBreaks.forEach(function (item) {
        item.PercentReturnPrice = tarPerPrice;
      });
    },
    fillReturnEditTarWithArrivalPer() {
      var tarPerPrice = this.partnerEditData.DefReturnEditTariffWithArrivalPer;
      this.shopTariffEditData.WeightBreaks.forEach(function (item) {
        item.PercentReturnPriceWithArrival = tarPerPrice;
      });
    },
    fillReturnEditTarPartBuyoutPer() {
      var tarPerPrice = this.partnerEditData.DefReturnEditTariffPartBuyoutPer;
      this.shopTariffEditData.WeightBreaks.forEach(function (item) {
        item.PercentReturnPricePartBuyout = tarPerPrice;
      });
    },
    fillLoadEditableTariffData(response) {
      var tariff = response.data.Tariff;
      var weightBreaksMap = this.getWeightBreaks(response);
      tariff.WeightBreaks = weightBreaksMap.WeightBreaks;
      this.partnerEditData.tarEditColNames = weightBreaksMap.columnNames;
      this.partnerEditData.tarShowEditColPrice = weightBreaksMap.colPriceShow;
      var intakeWeightBreaksMap = this.getIntakeWeightBreaks(response);
      tariff.IntakeWeightBreaks = intakeWeightBreaksMap.IntakeWeightBreaks;
      this.partnerEditData.tarIntakeEditColNames =
        intakeWeightBreaksMap.columnNames;
      this.partnerEditData.tarIntakeShowEditColPrice =
        intakeWeightBreaksMap.colPriceShow;
      tariff.CashCommission *= 100;
      tariff.BankCommission *= 100;
      tariff.InsuranceCommission *= 100;
      tariff.AgentCommissionPer *= 100;
      tariff.CashlessPaymentsPer *= 100;
      tariff.ApplyDate = null;
      this.shopTariffEditData = tariff;
      this.loadTarifEditScheme();
      this.loadDeliveryZones();
      this.loadIntakeDeliveryZones();
    },
    loadEditableTarif() {
      var self = this;
      this.axios
        .post("/ShopEdit/getTariff", {
          tariffId: this.partnerEditData.selectedEditTariff,
        })
        .then(function (response) {
          self.fillLoadEditableTariffData(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    createEditTarifBreaks() {
      for (var i = 0; i < this.partnerEditData.NumEditBreaks; i++) {
        this.addTarifBreak();
      }
    },
    saveTariff() {
      this.$refs["shopTariffEditData"].validate((valid) => {
        if (valid) {
          var settingID = this.$route.query.partnerID;
          var tariff = Object.assign({}, this.shopTariffEditData);
          var colName = "price";
          var self = this;
          tariff.WeightBreaks.forEach(function (item) {
            var prices = [];
            for (var i = 1; i < 6; i++) {
              if (self.partnerEditData.tarEditColNames[i]) {
                var price = {};
                price.Value = item[colName + i];
                price.ValueKm = item[colName + i + "km"]
                  ? item[colName + i + "km"]
                  : 0;
                price.RegionId = self.partnerEditData.tarEditColNames[i].Id;
                prices.push(price);
              }
            }
            item.Prices = prices;
          });
          tariff.IntakeWeightBreaks.forEach(function (item) {
            var prices = [];
            for (var i = 1; i < 5; i++) {
              if (self.partnerEditData.tarIntakeEditColNames[i]) {
                var price = {};
                price.Value = item[colName + i];
                price.ValueKm = item[colName + i + "km"]
                  ? item[colName + i + "km"]
                  : 0;
                price.RegionId =
                  self.partnerEditData.tarIntakeEditColNames[i].Id;
                prices.push(price);
              }
            }
            item.Prices = prices;
          });
          tariff.CashCommission /= 100;
          tariff.BankCommission /= 100;
          tariff.InsuranceCommission /= 100;
          tariff.AgentCommissionPer /= 100;
          tariff.CashlessPaymentsPer /= 100;
          this.axios
            .post("/ShopEdit/saveTariff", {
              tariff: tariff,
              settingId: settingID,
              copyToChild: this.partnerEditData.CopyEditTarInChild,
            })
            .then(function (response) {
              if (response.data.Success) {
                self.showSuccess("Тариф сохранен!");
                self.loadTariffList();
                return;
              }
              self.showError("Не удалось сохранить!");
            })
            .catch(function (error) {
              console.log(error);
              self.showError("Не удалось сохранить!");
            });
        }
      });
    },
    priceEditReturnShow(returnType) {
      if (this.shopTariffEditData.TariffReturnType == returnType) {
        return true;
      }
      return false;
    },
    priceEditReturnWithArrivalShow(returnType) {
      if (this.shopTariffEditData.TariffReturnTypeWithArrival == returnType) {
        return true;
      }
      return false;
    },
    priceEditReturnPartBuyoutShow(returnType) {
      if (this.shopTariffEditData.TariffReturnTypePartBuyout == returnType) {
        return true;
      }
      return false;
    },
    zoneEditFixShow(id) {
      if (this.partnerEditData.EditDeliveryZones.indexOf(id) >= 0) {
        return true;
      }
      return false;
    },
    zoneIntakeEditShow(ind) {
      var item = this.partnerEditData.tarIntakeEditColNames[ind];
      if (
        item &&
        !isNaN(item.Id) &&
        this.partnerEditData.EditIntakeDeliveryZones.indexOf(item.Id) >= 0
      ) {
        return true;
      }
      return false;
    },
    zoneEditShow(ind) {
      var item = this.partnerEditData.tarEditColNames[ind];
      if (
        item &&
        !isNaN(item.Id) &&
        this.partnerEditData.EditDeliveryZones.indexOf(item.Id) >= 0
      ) {
        return true;
      }
      return false;
    },
    colEditIntakePriceShow(ind) {
      if (this.partnerEditData.tarIntakeShowEditColPrice[ind]) {
        return this.partnerEditData.tarIntakeShowEditColPrice[ind];
      }
      return false;
    },
    colEditPriceShow(ind) {
      if (this.partnerEditData.tarShowEditColPrice[ind]) {
        return this.partnerEditData.tarShowEditColPrice[ind];
      }
      return false;
    },
    getEditIntakeZoneName(col) {
      if (this.partnerEditData.tarIntakeEditColNames[col]) {
        return this.partnerEditData.tarIntakeEditColNames[col].Name;
      }
      return "";
    },
    getEditZoneName(col) {
      if (this.partnerEditData.tarEditColNames[col]) {
        return this.partnerEditData.tarEditColNames[col].Name;
      }
      return "";
    },
    mapIntakeDeliveryZones(self, zones) {
      self.partnerEditData.tarIntakeEditColNames = [];
      self.partnerEditData.tarIntakeShowEditColPrice = [];
      zones = zones.sort(function (a, b) {
        return a.Ord - b.Ord;
      });
      var i = 1;
      zones.forEach(function (item) {
        if (item.Id >= 0) {
          self.partnerEditData.tarIntakeEditColNames[i] = item;
          self.partnerEditData.tarIntakeShowEditColPrice["price" + i] = true;
          i++;
        }
      });
    },
    mapDeliveryZones(self, zones) {
      self.partnerEditData.tarEditColNames = [];
      self.partnerEditData.tarShowEditColPrice = [];
      zones = zones.sort(function (a, b) {
        return a.Ord - b.Ord;
      });
      var i = 1;
      zones.forEach(function (item) {
        if (item.Id >= 0) {
          self.partnerEditData.tarEditColNames[i] = item;
          self.partnerEditData.tarShowEditColPrice["price" + i] = true;
          if (item.ShowPriceKm) {
            self.partnerEditData.tarShowEditColPrice["price" + i + "km"] = true;
          }
          i++;
        }
      });
    },
    loadIntakeDeliveryZones(resetZones) {
      if (resetZones) this.partnerEditData.EditIntakeDeliveryZones = [];
      if (this.shopTariffEditData.IntakeSchemeId) {
        var self = this;
        this.axios
          .post("/ShopEdit/getIntakeZones", {
            schemeId: this.shopTariffEditData.IntakeSchemeId,
          })
          .then(function (response) {
            self.mapIntakeDeliveryZones(self, response.data);
            self.TarifEditIntakeDelZonesList = response.data;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      this.TarifEditIntakeDelZonesList = [];
    },
    loadDeliveryZones(resetZones) {
      if (resetZones) this.partnerEditData.EditDeliveryZones = [];
      if (
        this.shopTariffEditData.SchemeId &&
        this.shopTariffEditData.TariffReturnType
      ) {
        var self = this;
        this.axios
          .post("/ShopEdit/getDeliveryZones", {
            schemeId: this.shopTariffEditData.SchemeId,
            returnType: this.shopTariffEditData.TariffReturnType,
            returnTypeWithArrival:
              this.shopTariffEditData.TariffReturnTypeWithArrival,
            returnTypePartBuyout:
              this.shopTariffEditData.TariffReturnTypePartBuyout,
          })
          .then(function (response) {
            self.mapDeliveryZones(self, response.data);
            self.TarifEditDelZonesList = response.data;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
      this.TarifEditDelZonesList = [];
    },
    loadTarifEditScheme(resetScheme) {
      if (resetScheme) this.shopTariffEditData.SchemeId = null;
      var self = this;
      this.axios
        .post("/ShopEdit/getScheme", {
          deliveryPlaceId: self.shopTariffEditData.DeliveryPlaceId,
        })
        .then(function (response) {
          self.TarifEditSchemeList = response.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    removeKeyWord(keyWord, index) {
      if (this.shopData.termKeyWords[index]) {
        this.shopData.termKeyWords.splice(index, 1);
      }
      if (keyWord.Id) {
        var self = this;
        var settingID = this.$route.query.partnerID;
        this.axios
          .post("/ShopEdit/removeTermKeyWord", {
            termKeyWord: keyWord,
            settingId: settingID,
          })
          .then(function (response) {
            if (!response.data.Success) {
              self.showError("Ошибка удаления");
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    loadTermKeyWords() {
      var self = this;
      var settingID = this.$route.query.partnerID;
      this.axios
        .post("/ShopEdit/getTermKeyWords", {
          settingId: settingID,
        })
        .then(function (response) {
          self.shopData.termKeyWords = response.data.Data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    saveTermKeyWords() {
      var self = this;
      var settingID = this.$route.query.partnerID;
      this.axios
        .post("/ShopEdit/saveTermKeyWords", {
          termKeyWords: self.shopData.termKeyWords,
          settingId: settingID,
        })
        .then(function (response) {
          if (response.data.Success) {
            self.showSuccess("Изменения сохранены");
            self.loadTermKeyWords();
          } else {
            self.showError("Ошибка сохранения");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    addTermKeyWord() {
      var termKeyWord = {};
      this.shopData.termKeyWords = [...this.shopData.termKeyWords, termKeyWord];
    },
    generateAccessKey() {
      var settingID = this.$route.query.partnerID;
      var self = this;
      this.axios
        .post("/ShopEdit/createAccessKey", {
          settingId: settingID,
        })
        .then(function (response) {
          self.shopData.common.AccessKey = response.data.AccessKey;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    loadTaxSystem() {
      var self = this;
      var settingID = this.$route.query.partnerID;
      this.axios
        .post("/ShopEdit/getShopTaxSystem", {
          settingId: settingID,
        })
        .then(function (response) {
          self.shopData.taxSystem = response.data.Data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    cancelTaxSystem() {
      this.loadTaxSystem();
    },
    saveTaxSystem(taxSystem) {
      if (taxSystem) {
        var settingID = this.$route.query.partnerID;
        var self = this;
        this.axios
          .post("/ShopEdit/addShopTaxSystem", {
            taxSystem: taxSystem,
            settingId: settingID,
          })
          .then(function (response) {
            self.showSuccess("Успешно");
            self.loadTaxSystem();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    addTaxSystem() {
      var taxSystem = {};
      this.shopData.taxSystem = [...this.shopData.taxSystem, taxSystem];
    },
    disableAutoOrd(templateId) {
      if (templateId) {
        var self = this;
        this.axios
          .post("/ShopEdit/disableAutoOrder", {
            templateId: templateId,
          })
          .then(function (response) {
            self.showSuccess("Успешно");
            self.loadAutoOrders();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    addAutoOrd(autoOrd) {
      var settingID = this.$route.query.partnerID;
      if (autoOrd) {
        var self = this;
        this.axios
          .post("/ShopEdit/addAutoOrder", {
            autoOrd: autoOrd,
            settingId: settingID,
          })
          .then(function (response) {
            self.showSuccess("Успешно");
            self.loadAutoOrders();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    loadAutoOrders() {
      var settingID = this.$route.query.partnerID;
      var self = this;
      this.axios
        .post("/ShopEdit/getAutoOrders", {
          settingId: settingID,
        })
        .then(function (response) {
          self.shopData.autoOrders = response.data.Data;
          self.shopData.autoOrders.push({});
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    saveCommonLKSettings() {
      var self = this;
      this.axios
        .post("/ShopEdit/saveCommonLKShopSettings", {
          shop: this.shopData.commonLK,
        })
        .then(function (response) {
          self.showSuccess("Изменения сохранены");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    saveCommonSettings() {
      var self = this;
      this.axios
        .post("/ShopEdit/saveCommonShopSettings", {
          shop: this.shopData.common,
        })
        .then(function (response) {
          self.showSuccess("Изменения сохранены");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    removeRiseWeightBreak(index) {
      if (this.shopWeightBreak[index]) {
        this.shopWeightBreak.splice(index, 1);
      }
    },
    removeRiseCourierWeightBreak(index) {
      if (this.shopCourierWeightBreak[index]) {
        this.shopCourierWeightBreak.splice(index, 1);
      }
    },
    addRiseWeightBreak() {
      var shopWeightBreak = { TypeId: 0 };
      this.shopWeightBreak = [...this.shopWeightBreak, shopWeightBreak];
    },
    addRiseCourierWeightBreak() {
      var shopCourierWeightBreak = { TypeId: 1 };
      this.shopCourierWeightBreak = [
        ...this.shopCourierWeightBreak,
        shopCourierWeightBreak,
      ];
    },
    addTariffRise() {
      var settingID = this.$route.query.partnerID;
      var tariffRise = { ShopId: settingID };
      this.shopTariffRise = [...this.shopTariffRise, tariffRise];
    },
    copyTariffRise(tarRise) {
      if (tarRise) {
        var self = this;
        this.$confirm("Копировать тариф в подчиненные?", "Предупреждение", {
          confirmButtonText: "Да",
          cancelButtonText: "Отмена",
          type: "warning",
        })
          .then(() => {
            self.axios
              .post("ShopEdit/сopyTariffRise", {
                rateId: tarRise.Id,
              })
              .then(function (response) {
                self.showSuccess("Тариф скопирован");
              })
              .catch(function (error) {
                self.showError("Произошла ошибка");
              });
          })
          .catch(() => {});
      }
    },
    saveTariffRise(row, index) {
      if (this.partnerEditData.selectedTariffRise) {
        var self = this;
        this.axios
          .post("ShopEdit/updateTariffRise", {
            header: row,
            weights: this.shopWeightBreak,
            weightCouriers: this.shopCourierWeightBreak,
          })
          .then(function (response) {
            self.showSuccess("Изменения по тарифу на подъем сохранены");
            self.$set(self.shopTariffRise, index, response.data.Data);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    removeTariffRise(index) {
      if (this.shopTariffRise[index]) {
        this.shopTariffRise.splice(index, 1);
      }
    },
    deleteTariffRise(tariffRise, index) {
      let self = this;
      this.$confirm("Удалить тариф на подъем?", "Предупреждение", {
        confirmButtonText: "Да",
        cancelButtonText: "Отмена",
        type: "warning",
      })
        .then(() => {
          if (tariffRise) {
            self.axios
              .post("/ShopEdit/deleteTariffRise", {
                rateId: tariffRise.Id,
              })
              .then(function (response) {
                self.removeTariffRise(index);
              })
              .catch(function (error) {
                console.log(error);
              });
          }
          self.removeTariffRise(index);
        })
        .catch(() => {});
    },
    showCourierWeightBreakTab() {
      if (
        this.partnerEditData.selectedTariffRise &&
        this.partnerEditData.selectedTariffRise.Mode
      ) {
        return true;
      }
      return false;
    },
    handleTariffRiseSelect(tariffRise) {
      this.partnerEditData.selectedTariffRise = tariffRise;
      this.loadWeightBreaks();
    },
    stockIsEditable(item) {
      var settingID = this.$route.query.partnerID;
      if (settingID != item.SettingId) return false;
      return true;
    },
    setStockState(stockId, isActive, row) {
      let self = this;
      this.axios
        .post("/ShopEdit/setStockState", { stockId, isActive })
        .then(function (response) {
          if (response.data.Success) {
            row.IsActive = isActive;
          } else {
            self.showError("Произошла ошибка при сохранении");
          }
        })
        .catch(function (error) {
          self.showError("Произошла ошибка при сохранении");
        });
    },
    saveStockChanges() {
      var settingID = this.$route.query.partnerID;
      if (settingID > 0) {
        var self = this;
        var defaultStockIdClear =
          this.shopStocks.filter((item) => item.IsDefaultStock === true)
            .length === 0
            ? 1
            : 0;
        this.axios
          .post("/ShopEdit/saveStockData", {
            StockChanges: this.shopStocks,
            settingId: settingID,
            defaultStockIdClear: defaultStockIdClear,
          })
          .then(function (response) {
            if (response.data.Success) {
              self.loadStockData();
              self.showSuccess("Изменения сохранены");
            } else {
              self.showError("Произошла ошибка при сохранении");
            }
          })
          .catch(function (error) {
            self.showError("Произошла ошибка при сохранении");
          });
      }
    },
    cancelStockChanges() {
      this.loadStockData();
    },
    delDefaultStock() {
      this.shopStocks.forEach(function (item) {
        item.IsDefaultStock = false;
      });
    },
    getStockRowClass(item) {
      if (item.row.IsDefaultStock) {
        return "defStockRow";
      }
      return "";
    },
    setDefaultStock() {
      if (this.partnerEditData.selectedStock) {
        this.shopStocks.forEach(function (item) {
          item.IsDefaultStock = false;
        });
        this.partnerEditData.selectedStock.IsDefaultStock = true;
      }
    },
    handleStockSelect(stock) {
      this.partnerEditData.selectedStock = stock;
    },
    addStock() {
      var settingId = this.$route.query.partnerID;
      var newStock = { SettingId: settingId, IsActive: true };
      this.shopStocks = [...this.shopStocks, newStock];
    },
    priceReturnShow(returnType) {
      if (this.shopTariffData.TariffReturnType === returnType) {
        return true;
      }
      return false;
    },
    priceReturnWithArrivalShow(returnType) {
      if (this.shopTariffData.TariffReturnTypeWithArrival === returnType) {
        return true;
      }
      return false;
    },
    priceReturnPartBuyoutShow(returnType) {
      if (this.shopTariffData.TariffReturnTypePartBuyout === returnType) {
        return true;
      }
      return false;
    },
    zoneShow(ind) {
      if (this.partnerEditData.tarColNames[ind]) {
        return true;
      }
      return false;
    },
    colIntakePriceShow(ind) {
      if (this.partnerEditData.tarShowIntakeColPrice[ind]) {
        return this.partnerEditData.tarShowIntakeColPrice[ind];
      }
      return false;
    },
    colPriceShow(ind) {
      if (this.partnerEditData.tarShowColPrice[ind]) {
        return this.partnerEditData.tarShowColPrice[ind];
      }
      return false;
    },
    getIntakeZoneName(ind) {
      if (this.partnerEditData.tarIntakeColNames[ind]) {
        return this.partnerEditData.tarIntakeColNames[ind];
      }
      return "";
    },
    getZoneName(ind) {
      if (this.partnerEditData.tarColNames[ind]) {
        return this.partnerEditData.tarColNames[ind];
      }
      return "";
    },
    startLoading() {
      this.fullScreenLoading = true;
    },
    stopLoading() {
      this.fullScreenLoading = false;
    },
    loadTariffData: function () {
      if (this.partnerEditData.selectedTariff) {
        var self = this;
        this.axios
          .post("/ShopEdit/getTariff", {
            tariffId: this.partnerEditData.selectedTariff,
          })
          .then(function (response) {
            self.fillLoadTariffData(response);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    fillLoadTariffData(response) {
      var tariff = response.data.Tariff;
      var weightBreaksMap = this.getWeightBreaks(response);
      tariff.WeightBreaks = weightBreaksMap.WeightBreaks;
      this.partnerEditData.tarColNames = weightBreaksMap.columnNames;
      this.partnerEditData.tarShowColPrice = weightBreaksMap.colPriceShow;
      var intakeWeightBreaksMap = this.getIntakeWeightBreaks(response);
      tariff.IntakeWeightBreaks = intakeWeightBreaksMap.IntakeWeightBreaks;
      this.partnerEditData.tarIntakeColNames =
        intakeWeightBreaksMap.columnNames;
      this.partnerEditData.tarShowIntakeColPrice =
        intakeWeightBreaksMap.colPriceShow;
      this.shopTariffData = tariff;
    },
    getWeightBreaks(response) {
      var zones = response.data.DeliveryZones.sort(function (a, b) {
        return a.Ord - b.Ord;
      });

      var i = 1;
      var columnNames = [];
      var colPriceShow = [];
      var columnMap = [];
      zones.forEach(function (item) {
        if (item.Id >= 0) {
          columnMap[item.Id] = i;
          columnNames[i] = item.Name;
          colPriceShow["price" + i] = true;
          if (item.ShowPriceKm) {
            colPriceShow["price" + i + "km"] = true;
          }
          i++;
        }
      });

      var tariff = response.data.Tariff;
      tariff.WeightBreaks = tariff.WeightBreaks.map(function (weightBreak) {
        weightBreak.Prices.forEach(function (price) {
          var columnId = columnMap[price.RegionId];
          weightBreak["price" + columnId] = price.Value;
          weightBreak["price" + columnId + "km"] = price.ValueKm;
        });
        return weightBreak;
      });
      return { WeightBreaks: tariff.WeightBreaks, columnNames, colPriceShow };
    },
    getIntakeWeightBreaks(response) {
      var zones = response.data.IntakeDeliveryZones.sort(function (a, b) {
        return a.Ord - b.Ord;
      });

      var i = 1;
      var columnNames = [];
      var colPriceShow = [];
      var columnMap = [];
      zones.forEach(function (item) {
        if (item.Id >= 0) {
          columnMap[item.Id] = i;
          columnNames[i] = item.Name;
          colPriceShow["price" + i] = true;
          i++;
        }
      });

      var tariff = response.data.Tariff;
      tariff.IntakeWeightBreaks = tariff.IntakeWeightBreaks.map(function (
        weightBreak
      ) {
        weightBreak.Prices.forEach(function (price) {
          var columnId = columnMap[price.RegionId];
          weightBreak["price" + columnId] = price.Value;
        });
        return weightBreak;
      });
      return {
        IntakeWeightBreaks: tariff.IntakeWeightBreaks,
        columnNames,
        colPriceShow,
      };
    },
    loadShopDictionaries(response) {
      this.sourcePlaceList = response.data.SourceTerminalsList.Dir;
      this.deliveryPlaceList = response.data.DeliveryTerminalsList.Dir;
      this.subShopList = response.data.SubShopList.Dir;
      this.WhoPaysRiseList = response.data.WhoPaysRiseList.Dir;
      this.TaxSystemList = response.data.TaxSystemList.Dir;
      this.PayTypeList = response.data.PayTypeList.Dir;
    },
    loadStockData() {
      var settingID = this.$route.query.partnerID;
      if (settingID > 0) {
        var self = this;
        this.axios
          .post("/ShopEdit/getDataAndCount", {
            startRowIndex: 0,
            maximumRows: 1000,
            settingId: settingID,
          })
          .then(function (response) {
            self.shopStocks = response.data.Data.Data;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    loadWeightBreaks() {
      if (
        this.partnerEditData.selectedTariffRise &&
        this.partnerEditData.selectedTariffRise.Id
      ) {
        var self = this;
        this.axios
          .post("/ShopEdit/getWeightBreak", {
            rateId: this.partnerEditData.selectedTariffRise.Id,
          })
          .then(function (response) {
            self.shopWeightBreak = response.data.Data.filter(
              (item) => item.TypeId === 0
            );
            self.shopCourierWeightBreak = response.data.Data.filter(
              (item) => item.TypeId !== 0
            );
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    loadTariffRise() {
      var settingID = this.$route.query.partnerID;
      if (settingID > 0) {
        var self = this;
        this.axios
          .post("/ShopEdit/getTariffRises", {
            partnerId: settingID,
          })
          .then(function (response) {
            self.shopTariffRise = response.data.Data;
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    loadTariffList() {
      var settingID = this.$route.query.partnerID;
      if (settingID > 0) {
        var self = this;
        this.axios
          .post("/ShopEdit/getTariffList", {
            settingId: settingID,
          })
          .then((resp) => {
            self.shopTariffList = resp.data;
            if (self.shopTariffList.length > 0) {
              self.partnerEditData.selectedTariff = self.shopTariffList[0].Id;
              self.loadTariffData();
            }
          });
      }
    },
    loadData() {
      var settingID = this.$route.query.partnerID;
      if (settingID > 0) {
        var shopRequest = this.axios.post("/ShopEdit/getShopData", {
          settingId: settingID,
        });
        var shopLKRequest = this.axios.post("/ShopEdit/getShopLKData", {
          settingId: settingID,
        });
        var shopTariffRequest = this.axios.post("/ShopEdit/getTariffList", {
          settingId: settingID,
        });
        var shopDictionariesRequest = this.axios.post(
          "/ShopEdit/getDictionaries",
          { shopId: settingID }
        );
        var stocksRequest = this.axios.post("/ShopEdit/getDataAndCount", {
          settingId: settingID,
        });
        var tariffRiseRequest = this.axios.post("/ShopEdit/getTariffRises", {
          partnerId: settingID,
        });
        var autoOrdsRequest = this.axios.post("/ShopEdit/getAutoOrders", {
          settingId: settingID,
        });
        var taxSystemRequest = this.axios.post("/ShopEdit/getShopTaxSystem", {
          settingId: settingID,
        });
        var termKeyWordsRequest = this.axios.post("/ShopEdit/getTermKeyWords", {
          settingId: settingID,
        });

        this.startLoading();
        var self = this;

        this.axios
          .all([
            shopRequest,
            shopTariffRequest,
            shopDictionariesRequest,
            stocksRequest,
            tariffRiseRequest,
            autoOrdsRequest,
            taxSystemRequest,
            termKeyWordsRequest,
            shopLKRequest,
          ])
          .then(
            self.axios.spread((...responses) => {
              var responseShopData = responses[0];
              var responseTariffListData = responses[1];
              var responseDictionaries = responses[2];
              var responseStocksData = responses[3];
              var responseTariffRiseData = responses[4];
              var responseAutoOrdsData = responses[5];
              var responseTaxSystemData = responses[6];
              var responseTermKeyData = responses[7];
              var responseShopLKData = responses[8];

              self.shopData.common = responseShopData.data.Shop;
              self.shopData.commonLK = responseShopLKData.data.Data;
              self.shopTariffList = responseTariffListData.data;
              self.loadShopDictionaries(responseDictionaries);
              self.shopStocks = responseStocksData.data.Data.Data;
              self.shopTariffRise = responseTariffRiseData.data.Data;
              if (self.shopTariffRise && self.shopTariffRise.length > 0) {
                self.$refs.shopTariffRise.setCurrentRow(self.shopTariffRise[0]);
              }
              self.shopData.autoOrders = responseAutoOrdsData.data.Data;
              self.shopData.autoOrders.push({});
              self.shopData.taxSystem = responseTaxSystemData.data.Data;
              self.shopData.termKeyWords = responseTermKeyData.data.Data;

              if (self.shopTariffList.length > 0) {
                self.partnerEditData.selectedTariff = self.shopTariffList[0].Id;
                self.loadTariffData();
              }

              self.stopLoading();
            })
          )
          .catch((error) => {
            console.log(error);
            self.stopLoading();
          });
      }
    },
    showSuccess(message) {
      this.$message({
        message: message,
        type: "success",
      });
    },
    showError(message) {
      this.$message.error(message);
    },
  },
  mounted: function () {
    this.loadData();
  },
};
</script>

<style scoped>
.vueTemplate >>> .panel-cell {
  width: 800px !important;
}
.vueTemplate >>> .defStockRow {
  background: coral !important;
}
.vueTemplate >>> .defWBRow {
  background: coral !important;
}
.vueTemplate >>> .panel {
  border: 1px solid transparent;
  border-color: #ddd;
  border-radius: 7px;
}
.vueTemplate >>> .panel-heading {
  background-color: #dcd9d9;
  padding: 0px 15px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  font-family: Arial, sans-serif;
}

.vueTemplate >>> .panel-content {
  padding: 0px 5px;
}
</style>