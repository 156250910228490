<template>
  <div style="display: flex; align-items: center">
    <div style="width: 10%">
      <img
        style="height: 40px"
        src="/images/logo-stizh-logistic.png"
        alt="Логистическая компания Стриж"
        title="Логистическая компания Стриж"
      />
    </div>
    <div style="margin: 0 10px 0 10px; display: inline-flex; width: 60%">
      <div style="margin: 0 10px 0 10px">
        <el-input
          size="mini"
          style="width: auto"
          placeholder="Заказ Стриж"
          v-model="orderId"
          @input="onOrderIdChange"
          @keyup.enter.native="searchByOrdId"
        ></el-input>
        <el-button
          size="mini"
          icon="el-icon-search"
          @click="searchByOrdId"
        ></el-button>
      </div>
      <div style="margin: 0 10px 0 10px">
        <el-input
          size="mini"
          style="width: auto"
          placeholder="Заказ Партнер"
          v-model="orderIdImport"
          @keyup.enter.native="searchByOrdIdImp"
        ></el-input>
        <el-checkbox
          style="margin: 0 5px"
          title="Полное совпадение"
          v-model="fullMatch"
        ></el-checkbox>
        <el-button
          size="mini"
          icon="el-icon-search"
          @click="searchByOrdIdImp"
        ></el-button>
      </div>
      <div style="margin: 0 10px 0 10px">
        <el-button size="mini" icon="el-icon-reading" @click="getApiDoc"
          >Описание API</el-button
        >
      </div>
      <div style="margin: 0 10px 0 10px">
        <el-button
          v-if="$store.getters.systemSettings.Template"
          size="mini"
          icon="el-icon-reading"
          @click="getTemplateDoc"
          >{{ $store.getters.systemSettings.Template.FileNameUser }}</el-button
        >
      </div>
    </div>
    <div style="margin: 0 10px 0 10px; display: inline-flex; width: 30%">
      <div style="margin: 0 10px 0 10px">
        <div style="display: inline; margin: 0 10px">Ваш регион</div>
        <UserLocation style="display: inline"></UserLocation>
      </div>
      <div style="margin: 0 10px 0 10px">
        <div style="display: inline">
          {{ "Пользователь: " + $store.getters.user }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserLocation from "./UserLocation.vue";

export default {
  name: "Header",
  components: {
    UserLocation,
  },
  data: function () {
    return {
      orderId: null,
      orderIdImport: null,
      fullMatch: true,
    };
  },
  methods: {
    onOrderIdChange(val) {
      this.orderId = val.replace(/\D/g, "");
    },
    startLoading() {
      this.$loading();
    },
    stopLoading() {
      this.$loading().close();
    },
    searchByOrdId() {
      if (this.orderId) {
        let self = this;
        self.startLoading();
        this.axios
          .post("/header/searchByOrdId", { orderId: this.orderId })
          .then((resp) => {
            self.stopLoading();
            if (resp.data.Data) {
              let href = self.$helpMethods.getOrderHref(
                resp.data.Data.OrderId,
                resp.data.Data.SelfDelivery
              );
              self.$router.push(href);
            }
            self.orderId = "";
          })
          .catch((e) => {
            self.stopLoading();
            console.log(e);
          });
      }
    },
    searchByOrdIdImp() {
      if (this.orderIdImport) {
        let self = this;
        self.startLoading();
        this.axios
          .post("/header/searchByOrdIdImport", {
            orderIdImport: this.orderIdImport,
            fullMatch: this.fullMatch,
          })
          .then((resp) => {
            self.stopLoading();
            if (resp.data.Data) {
              if (resp.data.Data.length == 1) {
                let href = self.$helpMethods.getOrderHref(
                  resp.data.Data[0].OrderId,
                  resp.data.Data[0].SelfDelivery
                );
                self.$router.push(href);
              } else if (resp.data.Data.length > 1) {
                self.$router.push({
                  path: "/DeliveryCompany/OrderSearch",
                  query: {
                    orderIdImport: self.orderIdImport,
                    fullMatch: self.fullMatch,
                  },
                });
              } else {
                self.$router.push({
                  path: "/DeliveryCompany/OrderSearch",
                  query: {
                    orderIdImport: self.orderIdImport,
                  },
                });
              }
              //
            }
            self.orderIdImport = "";
          })
          .catch((e) => {
            self.stopLoading();
            console.log(e);
          });
      }
    },
    getApiDoc() {
      this.axios
        .post("/header/getapidoc", {})
        .then((resp) => {
          this.$helpMethods.downloadBase64Doc(
            resp.data.FileName,
            resp.data.FileData
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getTemplateDoc() {
      this.axios
        .post("/header/getTemplateDoc", {
          templateId: this.$store.getters.systemSettings.Template.Id,
        })
        .then((resp) => {
          this.$helpMethods.downloadBase64Doc(
            resp.data.FileName,
            resp.data.FileData
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
