<template>
  <div class="vueTemplate">
    <div style="margin-bottom: 28px">
      <div style="padding: 2px" class="gridContainer">
        <div class="gridFirstL">
          <el-button size="mini" @click="setDeliveryYesterday"
            >Доставка вчера</el-button
          >
          <el-button size="mini" @click="setDeliveryToday"
            >Доставка сегодня</el-button
          >
          <el-button size="mini" @click="setDeliveryTomorrow"
            >Доставка завтра</el-button
          >
          <el-button size="mini" @click="setCreateToday"
            >Созданы сегодня</el-button
          >
        </div>
        <div class="gridFirstC">
          <el-button size="mini" @click="showDialogOrderList(true)"
            >Поиск по списку номеров</el-button
          >
          <el-button size="mini" @click="showDialogOrderImportList(true)"
            >Поиск по списку внешних номеров</el-button
          >
        </div>
        <div class="gridFirstR">
          <div class="block" style="display: inline-block">
            <span>Партнер </span>
            <ShopsListControl
              v-model="searchSettings.shopid"
              :allOption="true"
              :isEnable="true"
              :initVal="searchSettings.initShopId"
              :clearVal="searchSettings.shopidClear"
              :defExpandLevel="1"
            ></ShopsListControl>
          </div>
          <div class="block" style="display: inline-block">
            <span>Ключевое слово </span
            ><el-input
              size="mini"
              style="width: 150px"
              v-model="searchSettings.keyString"
              placeholder="Ключевое слово"
            ></el-input>
          </div>
        </div>
        <div class="gridSecondL">
          <el-button size="mini" @click="clearFilters">Очистить</el-button>
          <el-button size="mini" @click="getData">Найти</el-button>
          <div class="block" style="display: inline-block">
            <span>Статус заказа </span
            ><StatusStateListControl
              v-model="searchSettings.statusState"
            ></StatusStateListControl>
          </div>
        </div>
        <div class="gridSecondR">
          <div class="block" style="display: inline">
            <span>Показать колонки </span
            ><el-select
              class="columnSelect"
              size="mini"
              v-model="visualSettings.columns"
              multiple
              collapse-tags
              @change="handleColChange"
            >
              <el-option
                v-for="item in ColumnAllList"
                :key="item.Key"
                :label="item.Name"
                :value="item.Key"
              ></el-option>
            </el-select>
          </div>
          <div class="block" style="display: inline">
            <el-select size="mini" v-model="searchSettings.report">
              <el-option
                v-for="item in ReportList"
                :key="item.Key"
                :label="item.Name"
                :value="item.Key"
              ></el-option>
            </el-select>
            <el-button size="mini" @click="createReport">Выгрузить</el-button>
          </div>
        </div>
      </div>
      <div>
        <div v-bind:style="{ height: visualSettings.maxTabHeight + 'px' }">
          <el-table
            ref="ordersTable"
            size="mini"
            :data="searchPageData"
            @expand-change="onExpand"
            stripe
            border
            height="100%"
            :max-height="visualSettings.maxTabHeight"
            style="width: 100%"
            header-cell-class-name="headCell"
          >
            <el-table-column
              fixed
              type="expand"
              width="15"
              className="borderCell"
            >
              <template slot-scope="scope">
                <div style="display: flex">
                  <div>
                    <p>
                      Имя покупателя:
                      {{ getInf(scope.row.OrderId, "UserName") }}
                    </p>
                    <p>Телефон: {{ getInf(scope.row.OrderId, "UserTel") }}</p>
                    <p>
                      Адрес доставки:
                      {{ getInf(scope.row.OrderId, "FullAddress") }}
                    </p>
                    <p>
                      Комментарий: {{ getInf(scope.row.OrderId, "Comment") }}
                    </p>
                    <p>
                      Комментарий Стриж:
                      {{ getInf(scope.row.OrderId, "ReasonRrefusal") }}
                    </p>
                  </div>
                  <div><p>Товар:</p></div>
                  <div>
                    <div
                      style="display: flex"
                      v-for="item in getInf(scope.row.OrderId, 'ItemsInf')"
                      :key="item.Name"
                    >
                      <div style="display: inline; padding: 5px">
                        {{ item.Name }}
                      </div>
                      <div style="display: inline; padding: 5px">
                        {{ "кол-во:" + item.Count }}
                      </div>
                      <div style="display: inline; padding: 5px">
                        {{ item.Price }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column fixed align="center" className="borderTCell">
              <el-table-column
                prop="OrderId"
                width="100"
                label="Номер стриж"
                align="center"
                className="borderBCell"
              >
                <template v-slot:header style="padding: 0">
                  <div :class="sortIcon('OrderId')" @click="sort('OrderId')">
                    Номер стриж
                  </div>
                  <div class="filterCell">
                    <el-input v-model="searchSettings.OrderId" size="mini" />
                  </div>
                </template>
                <template slot-scope="scope">
                  <a
                    :href="getHref(scope.row.OrderId, scope.row.TypeDeliveryId)"
                    target="_blank"
                    >{{ scope.row.OrderId }}</a
                  >
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column className="borderTCell">
              <el-table-column
                width="100"
                prop="OrderIdImport"
                label="Номер партнер"
                align="center"
                className="borderCell"
              >
                <template v-slot:header style="padding: 0">
                  <div
                    :class="sortIcon('OrderIdImport')"
                    @click="sort('OrderIdImport')"
                  >
                    Номер партнер
                  </div>
                  <div class="filterCell">
                    <el-input
                      v-model="searchSettings.OrderIdImport"
                      size="mini"
                    />
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column className="borderTCell">
              <el-table-column
                prop="TypeDelivery"
                label="Заявка"
                align="center"
                width="120"
                className="borderBCell"
              >
                <template v-slot:header style="padding: 0">
                  <div>Заявка</div>
                  <div class="filterCell">
                    <SelfDeliveryListControl
                      v-model="searchSettings.TypeDelivery"
                      :clearVal="searchSettings.TypeDeliveryClear"
                    ></SelfDeliveryListControl>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="Дата" align="center" className="borderCell">
              <el-table-column
                prop="DeliveryDate"
                label="доставки"
                width="180"
                align="center"
                className="borderCell"
              >
                <template v-slot:header style="padding: 0">
                  <div>доставки</div>
                  <div class="filterCell">
                    <el-date-picker
                      size="mini"
                      style="width: 100%; padding: 0"
                      v-model="searchSettings.DeliveryDate"
                      type="daterange"
                      range-separator="-"
                      start-placeholder="Дата от"
                      end-placeholder="Дата до"
                      format="dd.MM.yyyy"
                      value-format="yyyy-MM-dd"
                      :picker-options="pickOpts"
                    ></el-date-picker>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div>{{ getStringDate(scope.row.DeliveryDate) }}</div>
                </template>
              </el-table-column>
              <el-table-column
                v-if="colIsShow('createDate')"
                prop="CreateDate"
                label="создания"
                width="180"
                align="center"
                className="borderCell"
              >
                <template v-slot:header style="padding: 0">
                  <div>создания</div>
                  <div class="filterCell">
                    <el-date-picker
                      size="mini"
                      style="width: 100%; padding: 0"
                      v-model="searchSettings.CreateDate"
                      type="daterange"
                      range-separator="-"
                      start-placeholder="Дата от"
                      end-placeholder="Дата до"
                      format="dd.MM.yyyy"
                      value-format="yyyy-MM-dd"
                      :picker-options="pickOpts"
                    ></el-date-picker>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div>{{ getStringDate(scope.row.CreateDate) }}</div>
                </template>
              </el-table-column>
              <el-table-column
                v-if="colIsShow('stockDate')"
                prop="StockDate"
                label="поступления"
                width="180"
                align="center"
                className="borderCell"
              >
                <template v-slot:header style="padding: 0">
                  <div>поступления</div>
                  <div class="filterCell">
                    <el-date-picker
                      size="mini"
                      style="width: 100%; padding: 0"
                      v-model="searchSettings.StockDate"
                      type="daterange"
                      range-separator="-"
                      start-placeholder="Дата от"
                      end-placeholder="Дата до"
                      format="dd.MM.yyyy"
                      value-format="yyyy-MM-dd"
                      :picker-options="pickOpts"
                    ></el-date-picker>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div>{{ getStringDate(scope.row.StockDate) }}</div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column
              v-if="colIsShow('timeFrom') || colIsShow('timeTo')"
              label="Время"
              align="center"
              className="borderCell"
            >
              <el-table-column
                v-if="colIsShow('timeFrom')"
                prop="TimeDeliveryFrom"
                label="с"
                align="center"
                width="50"
                className="borderCell"
              ></el-table-column>
              <el-table-column
                v-if="colIsShow('timeTo')"
                prop="TimeDeliveryTo"
                label="по"
                align="center"
                width="50"
                className="borderCell"
              ></el-table-column>
            </el-table-column>
            <el-table-column
              v-if="colIsShow('statusStock') || colIsShow('statusLogistic')"
              label="Статус"
              align="center"
              className="borderCell"
            >
              <el-table-column
                v-if="colIsShow('statusStock')"
                prop="StatusStock"
                label="складской"
                width="100"
                align="center"
                className="borderCell"
              >
                <template slot="header" style="padding: 0">
                  <div>складской</div>
                  <div class="filterCell">
                    <StatusStockListControl
                      v-model="searchSettings.StatusStock"
                      :clearVal="searchSettings.StatusStockClear"
                    ></StatusStockListControl>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                v-if="colIsShow('statusLogistic')"
                prop="StatusLogistic"
                label="логистический"
                width="100"
                align="center"
                className="borderCell"
              >
                <template v-slot:header style="padding: 0">
                  <div>логистический</div>
                  <div class="filterCell">
                    <StatusLogisticListControl
                      v-model="searchSettings.StatusLogistic"
                      :clearVal="searchSettings.StatusLogisticClear"
                    ></StatusLogisticListControl>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column
              v-if="colIsShow('transCancelReason')"
              label="Причина"
              align="center"
              className="borderTCell"
            >
              <el-table-column
                prop="LastTransCancelReason"
                label="отказа/переноса"
                width="100"
                align="center"
                className="borderBCell"
              >
                <template v-slot:header style="padding: 0">
                  <div>отказа/переноса</div>
                  <div class="filterCell">
                    <TransCancelListControl
                      v-model="searchSettings.Reason"
                      :clearVal="searchSettings.ReasonClear"
                    ></TransCancelListControl>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column
              v-if="colIsShow('guiltyTK')"
              prop="GuiltyTK"
              label="В.С."
              align="center"
              width="40"
              className="borderCell"
            >
              <template slot-scope="scope">
                <el-checkbox
                  v-model="scope.row.GuiltyTK"
                  disabled
                ></el-checkbox> </template
            ></el-table-column>
            <el-table-column
              v-if="colIsShow('doc')"
              prop="Doc"
              label="Документ"
              align="center"
              width="70"
              className="borderCell"
            >
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  v-show="scope.row.DocId"
                  icon="el-icon-view"
                  @click="showDoc(scope.row.DocId)"
                ></el-button>
              </template>
            </el-table-column>
            <el-table-column
              v-if="colIsShow('deliveryPlace') || colIsShow('sourcePlace')"
              label="Регион"
              align="center"
              className="borderCell"
            >
              <el-table-column
                v-if="colIsShow('deliveryPlace')"
                prop="DeliveryPlace"
                label="доставки"
                align="center"
                className="borderCell"
              >
                <template v-slot:header style="padding: 0">
                  <div>доставки</div>
                  <div class="filterCell">
                    <DeliveryTerminalsListControl
                      v-model="searchSettings.DeliveryPlace"
                      :clearVal="searchSettings.DeliveryPlaceClear"
                    ></DeliveryTerminalsListControl>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                v-if="colIsShow('sourcePlace')"
                prop="SourcePlace"
                label="отгрузки"
                align="center"
                className="borderCell"
              >
                <template v-slot:header style="padding: 0">
                  <div>отгрузки</div>
                  <div class="filterCell">
                    <SourceTerminalsListControl
                      v-model="searchSettings.SourcePlace"
                      :clearVal="searchSettings.SourcePlaceClear"
                      ref="sourcePlaceList"
                    ></SourceTerminalsListControl>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column
              v-if="colIsShow('address')"
              prop="FullAddress"
              label="Адрес"
              align="center"
              className="borderCell"
            >
              <template slot-scope="scope">
                <div class="fl2" :title="scope.row.FullAddress">
                  {{ scope.row.FullAddress }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="colIsShow('zoneName')"
              prop="ZoneName"
              label="Зона"
              align="center"
              className="borderCell"
            >
              <template slot-scope="scope">
                <div class="fl2" :title="scope.row.ZoneName">
                  {{ scope.row.ZoneName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="colIsShow('fio')"
              prop="UserName"
              label="ФИО"
              align="center"
              className="borderCell"
            >
              <template slot-scope="scope">
                <div class="fl2" :title="scope.row.UserName">
                  {{ scope.row.UserName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="colIsShow('phone')"
              prop="OrderPhone"
              align="center"
              className="borderTCell"
            >
              <el-table-column
                prop="OrderPhone"
                label="Телефон клиента"
                align="center"
                className="borderBCell"
                width="130"
                ><template v-slot:header style="padding: 0">
                  <div>Телефон клиента</div>
                  <div class="filterCell">
                    <el-input
                      v-model="searchSettings.OrderPhone"
                      placeholder="+7(___)___-__-__"
                      v-mask="'+7(###)###-##-##'"
                      size="mini"
                    />
                  </div>
                </template>
                <template slot-scope="scope">
                  <div class="fl2" :title="scope.row.OrderPhone">
                    {{ scope.row.OrderPhone }}
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column
              v-if="colIsShow('comment')"
              prop="Comment"
              label="Комментарий клиента"
              align="center"
              className="borderCell"
            >
              <template slot-scope="scope">
                <div class="fl2" :title="scope.row.Comment">
                  {{ scope.row.Comment }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="colIsShow('sumCost')"
              prop="SumCost"
              label="Сумма"
              align="center"
              className="borderCell"
            ></el-table-column>
            <el-table-column
              v-if="colIsShow('assessedCost')"
              prop="AssessedCost"
              label="Оценочная"
              align="center"
              className="borderCell"
            ></el-table-column>
            <el-table-column
              v-if="colIsShow('itemsCost')"
              prop="ItemsCost"
              label="Товаров"
              align="center"
              className="borderCell"
            ></el-table-column>
            <el-table-column
              v-if="colIsShow('deliveryCost')"
              prop="DeliveryCost"
              label="Доставки"
              align="center"
              className="borderCell"
            ></el-table-column>
            <el-table-column
              v-if="colIsShow('commentStrizh')"
              prop="CommentStrizh"
              label="Комментарий админки"
              align="center"
              className="borderCell"
            ></el-table-column>
            <el-table-column
              v-if="colIsShow('payType')"
              className="borderTCell"
            >
              <el-table-column
                prop="PayType"
                label="Оплата"
                align="center"
                className="borderBCell"
              >
                <template v-slot:header style="padding: 0">
                  <div>Оплата</div>
                  <div class="filterCell">
                    <PayTypeListControl
                      v-model="searchSettings.PayType"
                      :clearVal="searchSettings.PayTypeClear"
                    ></PayTypeListControl>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column
              v-if="colIsShow('piecesOfFreight')"
              prop="PiecesOfFreight"
              label="Мест"
              align="center"
              width="40"
              className="borderCell"
            ></el-table-column>
            <el-table-column
              v-if="colIsShow('serviceDC')"
              prop="ServiceDC"
              label="Услуги"
              align="center"
              className="borderCell"
            ></el-table-column>
            <el-table-column
              v-if="colIsShow('weight')"
              prop="Weight"
              label="Вес"
              align="center"
              width="50"
              className="borderCell"
            ></el-table-column>
            <el-table-column v-if="colIsShow('car')" className="borderTCell">
              <el-table-column
                prop="CarNumber"
                label="Машина"
                align="center"
                className="borderBCell"
              >
                <template v-slot:header style="padding: 0">
                  <div>Машина</div>
                  <div class="filterCell">
                    <el-select
                      size="mini"
                      v-model="searchSettings.Car"
                      placeholder="Выберите из списка"
                      filterable
                    >
                      <el-option
                        v-for="item in CarList"
                        :key="item.Key"
                        :label="item.Name"
                        :value="item.Key"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column
              v-if="colIsShow('carPhone')"
              prop="CarPhone"
              label="Телефон экипажа"
              align="center"
              className="borderCell"
            ></el-table-column>
            <el-table-column
              v-if="colIsShow('carFIO')"
              prop="CarFIO"
              label="Экипаж"
              align="center"
              className="borderCell"
            >
              <template slot-scope="scope">
                <div class="fl2" :title="scope.row.CarFIO">
                  {{ scope.row.CarFIO }}
                </div>
              </template></el-table-column
            >
            <el-table-column
              prop="ShopName"
              label="Партнер"
              align="center"
              className="borderCell"
            >
              <template slot-scope="scope">
                <div class="fl2" :title="scope.row.ShopName">
                  {{ scope.row.ShopName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="HeadShopName"
              label="Головная организация"
              align="center"
              className="borderCell"
            >
              <template slot-scope="scope">
                <div class="fl2" :title="scope.row.HeadShopName">
                  {{ scope.row.HeadShopName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="colIsShow('stockName')"
              prop="StockName"
              label="Склад"
              align="center"
              width="100"
              className="borderCell"
            >
              <template slot-scope="scope">
                <div class="fl2" :title="scope.row.StockName">
                  {{ scope.row.StockName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="colIsShow('planDeliveryDate')"
              className="borderTCell"
            >
              <el-table-column
                prop="PlanDeliveryDate"
                label="Плановая дата доставки"
                align="center"
                className="borderBCell"
                width="200"
              >
                <template v-slot:header style="padding: 0">
                  <div>Плановая дата доставки</div>
                  <div class="filterCell">
                    <el-date-picker
                      size="mini"
                      style="width: 100%; padding: 0"
                      v-model="searchSettings.PlanDeliveryDate"
                      type="daterange"
                      range-separator="-"
                      start-placeholder="Дата от"
                      end-placeholder="Дата до"
                      format="dd.MM.yyyy"
                      value-format="yyyy-MM-dd"
                      :picker-options="pickOpts"
                    ></el-date-picker>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div>{{ getStringDate(scope.row.PlanDeliveryDate) }}</div>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div
      style="
        position: fixed;
        max-height: 28px;
        bottom: 0;
        background-color: white;
        width: 100%;
        z-index: 101;
        display: flex;
        align-items: center;
      "
    >
      <el-pagination
        background
        layout="total,prev, pager, next"
        :current-page.sync="searchSettings.currentPage"
        :page-size="searchSettings.pageSize"
        :total="searchSettings.totalCount"
      >
      </el-pagination>
      <div style="padding: 0 10px 0 10px">Число записей</div>
      <el-select
        size="mini"
        v-model="searchSettings.pageSize"
        placeholder="Select"
      >
        <el-option
          v-for="item in PageSizesList"
          :key="item.Key"
          :label="item.Name"
          :value="item.Key"
        ></el-option>
      </el-select>
    </div>
    <el-dialog
      width="30%"
      :center="true"
      title="Поиск по списку номеров"
      :visible.sync="visualSettings.dialogOrderId"
    >
      <span style="text-align: center">
        <div style="margin: 10px">
          <el-input
            type="textarea"
            :rows="10"
            placeholder="Введите номера заказов"
            v-model="searchSettings.OrderIdList"
          >
          </el-input>
          <div>{{ "Заказов " + this.OrdersParseCnt }}</div>
          <el-button @click="searchOrderList" size="mini">Найти</el-button>
          <el-button @click="showDialogOrderList(false)" size="mini"
            >Закрыть</el-button
          >
        </div>
      </span>
    </el-dialog>
    <el-dialog
      width="30%"
      :center="true"
      title="Поиск по списку внешних номеров"
      :visible.sync="visualSettings.dialogOrderIdImport"
    >
      <span style="text-align: center">
        <div style="margin: 10px">
          <el-input
            type="textarea"
            :rows="10"
            placeholder="Введите внешние номера заказов"
            v-model="searchSettings.OrderIdImportList"
          >
          </el-input>
          <div>{{ "Заказов " + this.OrdersImportParseCnt }}</div>
          <el-button @click="searchOrderImportList" size="mini"
            >Найти</el-button
          >
          <el-button @click="showDialogOrderImportList(false)" size="mini"
            >Закрыть</el-button
          >
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ShopsListControl from "./controls/ShopsListControl.vue";
import StatusStateListControl from "./controls/StatusStateListControl.vue";
import SelfDeliveryListControl from "./controls/SelfDeliveryListControl.vue";
import StatusStockListControl from "./controls/StatusStockListControl.vue";
import StatusLogisticListControl from "./controls/StatusLogisticListControl.vue";
import TransCancelListControl from "./controls/TransCancelListControl.vue";
import SourceTerminalsListControl from "./controls/SourceTerminalsListControl.vue";
import DeliveryTerminalsListControl from "./controls/DeliveryTerminalsListControl.vue";
import PayTypeListControl from "./controls/PayTypeListControl.vue";
export default {
  name: "OrderSearch",
  components: {
    ShopsListControl,
    StatusStateListControl,
    SelfDeliveryListControl,
    StatusStockListControl,
    StatusLogisticListControl,
    TransCancelListControl,
    SourceTerminalsListControl,
    DeliveryTerminalsListControl,
    PayTypeListControl,
  },
  data: function () {
    let today = new Date();
    today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    return {
      searchSettings: {
        pageSize: 20,
        currentPage: 1,
        totalCount: 0,
        shopid: 0,
        initShopId: 0,
        shopidClear: null,
        keyString: null,
        statusState: null,
        OrderId: null,
        OrderIdList: null,
        OrderIdImportList: null,
        OrderIdImport: null,
        TypeDelivery: null,
        DeliveryDate: [today, today],
        CreateDate: null,
        StockDate: null,
        report: 6,
        StatusStock: null,
        StatusStockClear: null,
        StatusLogistic: null,
        StatusLogisticClear: null,
        Reason: null,
        ReasonClear: null,
        DeliveryPlace: null,
        DeliveryPlaceClear: null,
        SourcePlace: null,
        SourcePlaceClear: null,
        PayType: null,
        PayTypeClear: null,
        Car: null,
        OrderPhone: null,
        PlanDeliveryDate: null
      },
      pickOpts: {
        firstDayOfWeek: 1,
      },
      visualSettings: {
        dialogOrderId: false,
        dialogOrderIdImport: false,
        columns: [],
        oldColumns: [],
        maxTabHeight: 7000,
      },
      sorting: {
        dirAsc: false,
        colName: null,
      },
      CarList: [],
      ColumnList: [
        { Key: "createDate", Name: "Дата создания" },
        { Key: "stockDate", Name: "Дата поступления" },
        { Key: "timeFrom", Name: "Время с" },
        { Key: "timeTo", Name: "Время по" },
        { Key: "statusStock", Name: "Складской статус" },
        { Key: "statusLogistic", Name: "Логистический статус" },
        { Key: "transCancelReason", Name: "Причина отказа/переноса" },
        { Key: "guiltyTK", Name: "Вина стриж" },
        { Key: "deliveryPlace", Name: "Регион доставки" },
        { Key: "sourcePlace", Name: "Регион отгрузки" },
        { Key: "address", Name: "Адрес" },
        { Key: "zoneName", Name: "Зона" },
        { Key: "fio", Name: "ФИО" },
        { Key: "phone", Name: "Телефон" },
        { Key: "comment", Name: "Комментарий клиента" },
        { Key: "sumCost", Name: "Сумма" },
        { Key: "assessedCost", Name: "Оценочная стоимость" },
        { Key: "itemsCost", Name: "Стоимость товаров" },
        { Key: "deliveryCost", Name: "Доставка" },
        { Key: "commentStrizh", Name: "Комментарий админки" },
        { Key: "payType", Name: "Тип оплаты" },
        { Key: "piecesOfFreight", Name: "Число мест" },
        { Key: "serviceDC", Name: "Услуги" },
        { Key: "weight", Name: "Вес" },
        { Key: "car", Name: "Машина" },
        { Key: "carPhone", Name: "Телефон экипажа" },
        { Key: "carFIO", Name: "Экипаж" },
        { Key: "stockName", Name: "Склад" },
        { Key: "doc", Name: "Документ" },
        { Key: "planDeliveryDate", Name: "Плановая дата доставки" },
      ],
      PageSizesList: [
        { Key: 10, Name: "10" },
        { Key: 20, Name: "20" },
        { Key: 50, Name: "50" },
        { Key: 100, Name: "100" },
      ],
      searchData: [],
      orderInf: {},
    };
  },
  methods: {
    showDoc(docId) {
      let self = this;
      this.axios
        .post("/OrderEdit/getDoc", {
          fileId: docId,
          asBase64: false,
        })
        .then(function (response) {
          self.$helpMethods.showBase64Doc(
            response.data.Data.FileExt,
            response.data.Data.FileData
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getStringDate(dat) {
      if (dat) {
        try {
          let dd = new Date(dat);
          return [
            dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate(),
            dd.getMonth() < 9 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1,
            dd.getFullYear(),
          ].join(".");
        } catch (e) {
          console.log(e);
          return "";
        }
      }
      return "";
    },
    getInf(orderId, prop) {
      if (this.orderInf[orderId]) {
        return this.orderInf[orderId][prop];
      }
      return "";
    },
    onExpand(row) {
      if (!this.orderInf[row.OrderId]) {
        let self = this;
        this.axios
          .post("/OrderSearch/getInf", {
            orderid: row.OrderId,
          })
          .then((resp) => {
            self.$set(self.orderInf, row.OrderId, resp.data.Data);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    getHref(orderId, type) {
      let href = this.$helpMethods.getOrderHref(orderId, type);
      return href;
    },
    createReport() {
      if (this.searchSettings.report) {
        let self = this;
        self.startLoading();
        this.axios
          .post("/OrderSearch/getReport", {
            sortExpressions: "",
            filterExpression: this.getFilterExpressions(),
            reportType: this.searchSettings.report,
          })
          .then((resp) => {
            self.stopLoading();
            if (resp.data.Success) {
              self.$helpMethods.downloadBase64Doc(
                resp.data.Data.FileName,
                resp.data.Data.FileBody
              );
            } else {
              self.showError("Произошла ошибка!");
            }
          })
          .catch((error) => {
            console.log(error);
            self.stopLoading();
            self.showError("Произошла ошибка!");
          });
      }
    },
    handleColChange(newVal) {
      let allOptInNewVal = newVal.indexOf("All") > -1;
      let allOptInOldVal = this.visualSettings.oldColumns.indexOf("All") > -1;
      let newValLength = newVal.filter((value) => value != "All").length;
      if (allOptInNewVal && !allOptInOldVal) {
        let allVals = [];
        this.ColumnAllList.forEach((element) => {
          allVals.push(element.Key);
        });
        this.visualSettings.columns = allVals;
      } else if (!allOptInNewVal && allOptInOldVal) {
        this.visualSettings.columns = [];
      } else if (
        !allOptInNewVal &&
        !allOptInOldVal &&
        newValLength == this.ColumnList.length
      ) {
        this.visualSettings.columns.push("All");
      } else if (
        allOptInNewVal &&
        allOptInOldVal &&
        newValLength <= this.ColumnList.length
      ) {
        let pos = newVal.indexOf("All");
        if (pos > -1) {
          this.visualSettings.columns.splice(pos, 1);
        }
      }
      this.visualSettings.oldColumns = this._.cloneDeep(
        this.visualSettings.columns
      );
      this.saveColumns();
    },
    saveColumns() {
      let self = this;
      let cols = this.ColumnList.reduce(function (acc, item) {
        let key = item.Key;
        acc[key] = self.visualSettings.columns.indexOf(key) > -1 ? 1 : 0;
        return acc;
      }, {});
      this.axios
        .post("/OrderSearch/setHideColumns", {
          values: cols,
        })
        .then((resp) => {})
        .catch((e) => {
          console.log(e);
        });
    },
    searchOrderList() {
      this.showDialogOrderList(false);
      this.getData();
    },
    searchOrderImportList() {
      this.showDialogOrderImportList(false);
      this.getData();
    },
    getDateVal(dat) {
      if (dat) {
        let val = new Date(dat);
        return [
          val.getDate() < 10 ? "0" + val.getDate() : val.getDate(),
          val.getMonth() < 9 ? "0" + (val.getMonth() + 1) : val.getMonth() + 1,
          val.getFullYear().toString().substring(2, 4),
        ].join(".");
      }
      return "";
    },
    getFilterExpressions() {
      var fil = {};
      if (
        this.searchSettings.DeliveryDate &&
        this.searchSettings.DeliveryDate[0] &&
        this.searchSettings.DeliveryDate[1]
      )
        fil.DeliveryDate =
          this.getDateVal(this.searchSettings.DeliveryDate[0]) +
          " - " +
          this.getDateVal(this.searchSettings.DeliveryDate[1]);
      if (
        this.searchSettings.CreateDate &&
        this.searchSettings.CreateDate[0] &&
        this.searchSettings.CreateDate[1]
      )
        fil.CreateDate =
          this.getDateVal(this.searchSettings.CreateDate[0]) +
          " - " +
          this.getDateVal(this.searchSettings.CreateDate[1]);
      if (
        this.searchSettings.StockDate &&
        this.searchSettings.StockDate[0] &&
        this.searchSettings.StockDate[1]
      )
        fil.StockDate =
          this.getDateVal(this.searchSettings.StockDate[0]) +
          " - " +
          this.getDateVal(this.searchSettings.StockDate[1]);

      if (
        this.searchSettings.PlanDeliveryDate &&
        this.searchSettings.PlanDeliveryDate[0] &&
        this.searchSettings.PlanDeliveryDate[1]
      )
        fil.PlanDeliveryDate =
          this.getDateVal(this.searchSettings.PlanDeliveryDate[0]) +
          " - " +
          this.getDateVal(this.searchSettings.PlanDeliveryDate[1]);

      if (this.searchSettings.OrderIdList)
        fil.OrdersList = this.searchSettings.OrderIdList;
      if (this.searchSettings.OrderIdImportList)
        fil.OrdersImportList = this.searchSettings.OrderIdImportList;

      if (this.searchSettings.keyString != null)
        fil.KeyWords = this.searchSettings.keyString;

      if (this.searchSettings.shopid != null) {
        fil.InternetShop = this.searchSettings.shopid;
      }

      if (
        this.searchSettings.statusState != null &&
        this.searchSettings.statusState != 0
      )
        fil.State = this.searchSettings.statusState;
      if (this.searchSettings.OrderId != null)
        fil.OrderId = this.searchSettings.OrderId;
      if (this.searchSettings.OrderIdImport != null)
        fil.OrderIdImport = this.searchSettings.OrderIdImport;
      if (
        this.searchSettings.TypeDelivery &&
        this.searchSettings.TypeDelivery.length > 0
      )
        fil.TypeDelivery = this.searchSettings.TypeDelivery.join(",");
      if (
        this.searchSettings.StatusStock &&
        this.searchSettings.StatusStock.length > 0
      )
        fil.StatusStock = this.searchSettings.StatusStock.join(",");
      if (
        this.searchSettings.StatusLogistic &&
        this.searchSettings.StatusLogistic.length > 0
      )
        fil.StatusLogistic = this.searchSettings.StatusLogistic.join(",");
      if (this.searchSettings.Reason && this.searchSettings.Reason.length > 0)
        fil.TransCancelReason = this.searchSettings.Reason.join(",");
      if (this.searchSettings.SourcePlace != null)
        fil.SourcePlace = this.searchSettings.SourcePlace.join(",");
      if (this.searchSettings.DeliveryPlace != null)
        fil.DeliveryPlace = this.searchSettings.DeliveryPlace.join(",");
      if (this.searchSettings.PayType && this.searchSettings.PayType.length > 0)
        fil.PayType = this.searchSettings.PayType.join(",");
      if (this.searchSettings.Car != null)
        fil.CarNumber = this.searchSettings.Car;

      if (this.searchSettings.OrderPhone) {
        fil.OrderPhone = this.searchSettings.OrderPhone.replace(/\D/g, "");
      }

      if (
        this.$route.query.fullMatch == "true" ||
        this.$route.query.fullMatch == "false"
      ) {
        fil.FullMatchOrderImport = this.$route.query.fullMatch;
      }

      if (
        this.searchSettings.PlanDeliveryDate &&
        this.searchSettings.PlanDeliveryDate[0] &&
        this.searchSettings.PlanDeliveryDate[1]
      )
        fil.PlanDeliveryDate =
          this.getDateVal(this.searchSettings.PlanDeliveryDate[0]) +
          " - " +
          this.getDateVal(this.searchSettings.PlanDeliveryDate[1]);

      return fil;
    },
    getData() {
      var self = this;
      self.startLoading();
      this.axios
        .post("/OrderSearch/GetOrdersByFilter", {
          startRowIndex: 0,
          maximumRows: 1000,
          sortExpressions: "",
          filterExpression: this.getFilterExpressions(),
        })
        .then((resp) => {
          self.$refs.ordersTable.layout.updateElsHeight();
          self.searchData = resp.data.Data.Orders;
          self.searchSettings.totalCount = resp.data.Data.Count;
          self.stopLoading();
        })
        .catch((error) => {
          console.log(error);
          self.stopLoading();
          self.showError("Произошла ошибка!");
        });
    },
    clearFilters() {
      let today = new Date();
      today = new Date(today.getFullYear(), today.getMonth(), today.getDate());

      this.searchSettings.shopidClear = !this.searchSettings.shopidClear;
      this.searchSettings.keyString = null;
      this.searchSettings.statusState = 0;
      this.searchSettings.OrderId = null;
      this.searchSettings.OrderIdList = null;
      this.searchSettings.OrderIdImportList = null;
      this.searchSettings.OrderIdImport = null;

      this.searchSettings.TypeDeliveryClear =
        !this.searchSettings.TypeDeliveryClear;

      this.searchSettings.DeliveryDate = [today, today];
      this.searchSettings.PlanDeliveryDate = null;
      this.searchSettings.CreateDate = null;
      this.searchSettings.StockDate = null;
      this.searchSettings.StatusStockClear =
        !this.searchSettings.StatusStockClear;
      this.searchSettings.StatusLogisticClear =
        !this.searchSettings.StatusLogisticClear;
      this.searchSettings.ReasonClear = !this.searchSettings.ReasonClear;

      this.searchSettings.DeliveryPlaceClear =
        !this.searchSettings.DeliveryPlaceClear;
      this.searchSettings.SourcePlaceClear =
        !this.searchSettings.SourcePlaceClear;
      this.searchSettings.PayTypeClear = !this.searchSettings.PayTypeClear;
      this.searchSettings.Car = null;
      this.searchSettings.OrderPhone = null;
      this.clearData();
    },
    clearData() {
      this.searchData = [];
      this.searchSettings.totalCount = 0;
    },
    showDialogOrderImportList(isShow) {
      this.visualSettings.dialogOrderIdImport = isShow;
    },
    showDialogOrderList(isShow) {
      this.visualSettings.dialogOrderId = isShow;
    },
    sortIcon(colName) {
      if (this.sorting.colName === colName) {
        return this.sorting.dirAsc
          ? "el-icon-caret-top"
          : "el-icon-caret-bottom";
      }
      return "";
    },
    sort(colName) {
      if (this.sorting.colName === colName) {
        this.sorting.dirAsc = !this.sorting.dirAsc;
        return;
      }
      this.sorting.colName = colName;
      this.sorting.dirAsc = true;
    },
    startLoading() {
      this.$loading();
    },
    stopLoading() {
      this.$loading().close();
    },
    showError(message) {
      this.$message.error(message);
    },
    colIsShow(colName) {
      return this.visualSettings.columns.indexOf(colName) > -1;
    },
    loadHideColumns(resp) {
      var self = this;
      for (const [key, value] of Object.entries(resp.data.Data.Columns)) {
        if (value == 1 && self.ColumnList.some((col) => col.Key == key)) {
          self.visualSettings.columns.push(key);
        }
        self.visualSettings.oldColumns = this._.cloneDeep(
          self.visualSettings.columns
        );
        if (self.visualSettings.columns.length == self.ColumnList.length) {
          self.visualSettings.columns.push("All");
        }
      }
    },
    loadSimpleDictionaries(resp) {
      this.CarList = resp.data.CarList.Dir;
    },
    setDeliveryToday() {
      this.searchSettings.DeliveryDate = [this.today, this.today];
      this.searchSettings.CreateDate = null;
      this.searchSettings.StockDate = null;
      this.searchSettings.PlanDeliveryDate = null;
    },
    setDeliveryYesterday() {
      this.searchSettings.DeliveryDate = [this.yesterday, this.yesterday];
      this.searchSettings.CreateDate = null;
      this.searchSettings.StockDate = null;
      this.searchSettings.PlanDeliveryDate = null;
    },
    setDeliveryTomorrow() {
      this.searchSettings.DeliveryDate = [this.tomorrow, this.tomorrow];
      this.searchSettings.CreateDate = null;
      this.searchSettings.StockDate = null;
      this.searchSettings.PlanDeliveryDate = null;
    },
    setCreateToday() {
      this.searchSettings.CreateDate = [this.today, this.today];
      this.searchSettings.DeliveryDate = null;
      this.searchSettings.StockDate = null;
      this.searchSettings.PlanDeliveryDate = null;
    },
    headerHeight() {
      let headHeight = document.getElementsByClassName("gridContainer");
      if (headHeight.length > 0) {
        return headHeight[0].clientHeight;
      }
      return 0;
    },
    calcHeight() {
      //return window.innerHeight - 210;
      return window.innerHeight - 140 - this.headerHeight();
    },
  },
  computed: {
    OrdersParseCnt() {
      if (this.searchSettings.OrderIdList == null) return 0;
      let cnt = 0;
      this.searchSettings.OrderIdList.split("\n").forEach(function (item) {
        if (!isNaN(item)) {
          cnt++;
        }
      });
      return cnt;
    },
    OrdersImportParseCnt() {
      if (this.searchSettings.OrderIdImportList == null) return 0;
      let cnt = this.searchSettings.OrderIdImportList.split("\n").length;
      return cnt;
    },
    ReportList() {
      let repList = [];     
      if (!this.$store.getters.ruleSettings.IsInternetShop) {
        repList.push({ Key: 1, Name: "Выгрузить в Excel" });
        repList.push({ Key: 3, Name: "Штрих-коды заказов(по местам)" });
        repList.push({ Key: 4, Name: "Отчет для кладовщика и водителей" });
        repList.push({ Key: 5, Name: "Отчет по адресам доставки" });
      }
      repList.push({ Key: 6, Name: "Выгрузить с товарами" });
      return repList;
    },
    ColumnAllList() {
      return [{ Key: "All", Name: "Все" }, ...this.ColumnList];
    },
    searchPageData() {
      if (this.searchSettings.pageSize == "1000") {
        return this.searchData;
      }
      if (this.searchData.length > 0) {
        let maxVal =
          this.searchSettings.currentPage * this.searchSettings.pageSize;
        if (maxVal > this.searchSettings.totalCount)
          maxVal = this.searchSettings.totalCount;
        return this.searchData.slice(
          (this.searchSettings.currentPage - 1) * this.searchSettings.pageSize,
          maxVal
        );
      }
      return [];
    },
    today() {
      let today = new Date();
      return [
        today.getFullYear(),
        today.getMonth() < 9
          ? "0" + (today.getMonth() + 1)
          : today.getMonth() + 1,
        today.getDate() < 10 ? "0" + today.getDate() : today.getDate(),
      ].join("-");
    },
    tomorrow() {
      let tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return [
        tomorrow.getFullYear(),
        tomorrow.getMonth() < 9
          ? "0" + (tomorrow.getMonth() + 1)
          : tomorrow.getMonth() + 1,
        tomorrow.getDate() < 10 ? "0" + tomorrow.getDate() : tomorrow.getDate(),
      ].join("-");
    },
    yesterday() {
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      return [
        yesterday.getFullYear(),
        yesterday.getMonth() < 9
          ? "0" + (yesterday.getMonth() + 1)
          : yesterday.getMonth() + 1,
        yesterday.getDate() < 10
          ? "0" + yesterday.getDate()
          : yesterday.getDate(),
      ].join("-");
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to.query.orderIdImport) {
      let self = this;
      self.searchSettings.OrderIdImport = to.query.orderIdImport;
      self.searchSettings.DeliveryDate = null;
      self.getData();
      next();
    }
  },
  mounted: function () {
    this.visualSettings.maxTabHeight = this.calcHeight();
    this.searchSettings.report = this.ReportList[0]["Key"];
    this.startLoading();
    let self = this;
    let dictRequest = this.axios.post("/OrderSearch/getDictionaries", {});
    let hideColumnsRequest = this.axios.post("/OrderSearch/getHideColumns", {
    });
    this.axios
      .all([dictRequest, hideColumnsRequest])
      .then(
        self.axios.spread((...responses) => {
          self.loadSimpleDictionaries(responses[0]);
          self.loadHideColumns(responses[1]);
          self.stopLoading();
          if (self.$route.query.orderIdImport) {
            self.searchSettings.OrderIdImport = self.$route.query.orderIdImport;
            self.searchSettings.DeliveryDate = null;
            self.getData();
          }
        })
      )
      .catch((error) => {
        console.log(error);
        self.stopLoading();
        self.showError("Произошла ошибка!");
      });
  },
};
</script>

<style scoped>
.columnSelect >>> .el-select__tags {
  display: none;
}
.vueTemplate >>> .block {
  padding: 0 7px;
}
.vueTemplate >>> .gridFirstL {
  grid-area: fleft;
  text-align: left;
}
.vueTemplate >>> .gridFirstC {
  grid-area: fcenter;
  text-align: center;
}
.vueTemplate >>> .gridFirstR {
  grid-area: fright;
  text-align: right;
}
.vueTemplate >>> .gridSecondL {
  grid-area: sleft;
  text-align: left;
}
.vueTemplate >>> .gridSecondR {
  grid-area: sright;
  text-align: right;
}
.vueTemplate >>> .gridContainer {
  display: grid;
  grid-gap: 10px;
  grid-template-areas:
    "fleft fleft fleft fleft fcenter fcenter fcenter fcenter fright fright"
    "sleft sleft sleft sleft sleft sright sright sright sright sright";
}
.vueTemplate >>> .borderTCell {
  border-top-color: #828282 !important;
  border-left-color: #828282 !important;
  border-right-color: #828282 !important;
}
.vueTemplate >>> .borderBCell {
  border-bottom-color: #828282 !important;
  border-left-color: #828282 !important ;
  border-right-color: #828282 !important;
}
.vueTemplate >>> .borderCell {
  border-color: #828282;
}
.vueTemplate >>> .headCell {
  padding: 0 !important;
}
.vueTemplate >>> .cell {
  padding: 0 !important;
}
.vueTemplate >>> .filterCell {
}
.vueTemplate >>> .el-date-editor .el-range-separator {
  padding: 0 !important;
}
.vueTemplate >>> .el-table .cell {
  word-break: normal;
}
.vueTemplate >>> .el-table--mini td {
  padding: 0;
}
.vueTemplate >>> .fl2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 1367px) {
  .vueTemplate >>> .gridContainer {
    display: grid;
    grid-gap: 5px 10px;
    grid-template-areas:
      "fleft fleft fleft fleft fleft fcenter fcenter fcenter fcenter fcenter"
      "fright fright fright fright fright fright fright fright fright fright"
      "sleft sleft sleft sleft sleft sright sright sright sright sright";
  }
  .vueTemplate >>> .gridFirstR {
    grid-area: fright;
    text-align: left;
  }
}
</style>
