<template>
  <div>
    <div style="font-weight: bold; background-color: #a1c6f2">
      Отчет по доставкам
    </div>
    <div style="padding: 5px">
      <el-form
        size="mini"
        style="width: 600px"
        ref="searchSettings"
        :model="searchSettings"
        label-width="200px"
      >
        <el-form-item label="Партнер:" prop="shop">
          <ShopsListControl
            v-model="searchSettings.shop"
            :isEnable="true"
            :allOption="true"
            :initVal="0"
            :defExpandLevel="1"
          ></ShopsListControl>
        </el-form-item>
        <el-form-item label="Доставка" prop="DeliveryDate">
          <div style="display: inline">с</div>
          <el-date-picker
            style="width: 125px"
            v-model="searchSettings.DeliveryDateFrom"
            type="date"
            :picker-options="delDatePickOpt"
            format="dd.MM.yyyy"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
          <div style="display: inline">по</div>
          <el-date-picker
            style="width: 125px"
            v-model="searchSettings.DeliveryDateTo"
            type="date"
            :picker-options="delDatePickOpt"
            format="dd.MM.yyyy"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="Терминал приемки" prop="SourcePlace">
          <source-terminal-control
            v-model="searchSettings.SourcePlace"
            :useAllOpt="true"
          ></source-terminal-control>
        </el-form-item>
        <el-form-item label="Терминал доставки" prop="DeliveryPlace">
          <delivery-terminal-control
            v-model="searchSettings.DeliveryPlace"
            :useAllOpt="true"
          ></delivery-terminal-control>
        </el-form-item>
        <el-form-item label="Тип доставки" prop="TypeDelivery">
          <self-delivery-control
            v-model="searchSettings.TypeDelivery"
            :useAllOpt="true"
          ></self-delivery-control>
        </el-form-item>
        <el-form-item label="Тип оплаты" prop="PayType">
          <el-select
            size="mini"
            v-model="searchSettings.PayType"
            placeholder="Выберите из списка"
          >
            <el-option
              v-for="item in PayTypeListAll"
              :key="item.Key"
              :label="item.Name"
              :value="item.Key"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-button size="mini" @click="getReport">Сформировать</el-button>
      <el-button size="mini" @click="getReportFile"
        >Выгрузить в Excel</el-button
      >
    </div>
    <el-table
      size="mini"
      :data="reportData"
      show-summary
      :summary-method="getSummary"
      border
    >
      <el-table-column prop="Name" label="Партнер" align="center">
      </el-table-column>
      <el-table-column prop="Count" label="Кол-во заказов" align="center">
      </el-table-column>
      <el-table-column prop="Oborot" label="Оборот" align="center">
      </el-table-column>
      <el-table-column prop="Nal" label="Наличные" align="center">
      </el-table-column>
      <el-table-column prop="Term" label="Оплата по терминалу" align="center">
      </el-table-column>
      <el-table-column prop="Bnal" label="Безналичные" align="center">
      </el-table-column>
      <el-table-column
        prop="AssessedCost"
        label="Оценочная стоимость"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="AgentComission"
        label="АВ за прием наличных ДС"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="BankKom" label="АВ за прием карт" align="center">
      </el-table-column>
      <el-table-column prop="Insurance" label="Страховой сбор" align="center">
      </el-table-column>
      <el-table-column
        prop="ServiceDC"
        label="Стоимость услуг по доставке"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="Serv" label="Итого услуги" align="center">
      </el-table-column>
      <el-table-column prop="Vat" label="НДС 20%" align="center">
      </el-table-column>
      <el-table-column
        prop="ServWithVat"
        label="Итого с учетом НДС 20%"
        align="center"
      >
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import ShopsListControl from "./controls/ShopsListControl.vue";
import DeliveryTerminalControl from "./controls/DeliveryTerminalControl.vue";
import SourceTerminalControl from "./controls/SourceTerminalControl.vue";
import SelfDeliveryControl from "./controls/SelfDeliveryControl.vue";
export default {
  name: "ReportDelivery",
  components: {
    ShopsListControl,
    DeliveryTerminalControl,
    SourceTerminalControl,
    SelfDeliveryControl,
  },
  data: function () {
    let dat = new Date();
    dat = new Date(dat.getFullYear(), dat.getMonth(), dat.getDate());
    let mm = dat.getMonth() + 1;
    let dd = dat.getDate();
    let today = [
      dat.getFullYear(),
      (mm > 9 ? "" : "0") + mm,
      (dd > 9 ? "" : "0") + dd,
    ].join("-");
    return {
      reportData: [],
      searchSettings: {
        shop: 0,
        DeliveryDateFrom: today,
        DeliveryDateTo: today,
        DeliveryPlace: "All",
        SourcePlace: "All",
        PayType: 0,
        TypeDelivery: "All",
      },
      delDatePickOpt: {
        firstDayOfWeek: 1,
      },
      PayTypeList: [],
    };
  },
  computed: {
    PayTypeListAll() {
      return [{ Key: 0, Name: "Все" }, ...this.PayTypeList];
    },
  },
  methods: {
    getSummary(param) {
      const sums = [];

      param.columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "Итого:";
          return;
        }
        const values = param.data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values
            .reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0)
            .toFixed(2);
        } else {
          sums[index] = "";
        }
      });

      return sums;
    },
    getFilter() {
      let filter = {};
      filter.ShopId = null;
      if (this.searchSettings.shop) {
        filter.ShopId = this.searchSettings.shop;
      }

      filter.DeliveryDateFrom = this.searchSettings.DeliveryDateFrom;
      filter.DeliveryDateTo = this.searchSettings.DeliveryDateTo;
      if (this.searchSettings.SourcePlace > 0)
        filter.SourcePlace = this.searchSettings.SourcePlace;
      if (this.searchSettings.DeliveryPlace > 0)
        filter.DeliveryPlace = this.searchSettings.DeliveryPlace;
      filter.PayType =
        this.searchSettings.PayType == 0 ? -1 : this.searchSettings.PayType;
      if (this.searchSettings.TypeDelivery >= 0)
        filter.TypeDelivery = this.searchSettings.TypeDelivery;
      return filter;
    },
    getData() {
      let self = this;
      self.startLoading();
      this.axios
        .post("/ReportDeliv/getReport", {
          searchParams: self.getFilter(),
        })
        .then((resp) => {
          self.stopLoading();
          if (resp.data.Data) {
            self.reportData = resp.data.Data.sort(function (a, b) {
              return ("" + a.Name).localeCompare(b.Name, "en");
            });
          } else {
            self.reportData = [];
          }
        })
        .catch((e) => {
          self.stopLoading();
          self.showError("Произошла ошибка");
          console.log(e);
        });
    },
    loadSimpleDictionaries(resp) {
      this.PayTypeList = resp.data.PayTypeList.Dir;
    },
    getReportFile() {
      let self = this;
      self.startLoading();
      this.axios
        .post("/ReportDeliv/getReportFile", {
          searchParams: self.getFilter(),
        })
        .then((resp) => {
          self.stopLoading();
          if (resp.data.Success) {
            self.$helpMethods.downloadBase64Doc(
              resp.data.Data.FileName,
              resp.data.Data.FileData
            );
          } else {
            self.showError("Произошла ошибка!");
          }
        })
        .catch((e) => {
          self.stopLoading();
          self.showError("Произошла ошибка");
          console.log(e);
        });
    },
    getReport() {
      this.getData();
    },
    startLoading() {
      this.$loading();
    },
    stopLoading() {
      this.$loading().close();
    },
    showSuccess(message) {
      this.$message({
        message: message,
        type: "success",
      });
    },
    showError(message) {
      this.$message.error(message);
    },
  },
  mounted: function () {
    let self = this;
    self.startLoading();
    this.axios
      .post("/ReportDeliv/getDictionaries", {})
      .then((resp) => {
        self.loadSimpleDictionaries(resp);
        self.stopLoading();
      })
      .catch((e) => {
        self.stopLoading();
        self.showError("Произошла ошибка");
        console.log(e);
      });
  },
};
</script>

<style scoped>
</style>
