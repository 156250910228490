<template>
  <div>
    <el-table
      :data="tableData"
      size="mini"
      :header-cell-class-name="'headerCell'"
      v-loading="loading"
    >
      <el-table-column prop="whoLastChange" label="Кто" align="center">
      </el-table-column>
      <el-table-column prop="modifyDate" label="Изменено" align="center">
        <template slot-scope="scope">
          <div>{{getDateTime(scope.row.modifyDate)}}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="fullAddress"
        label="Адрес"
        width="200"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="coords" label="Геокоординаты" align="center">
        <template slot-scope="scope">
          <div>{{ getCoords(scope.row.lat, scope.row.lng) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="coords" label="Изменен КС" align="center">
        <template slot-scope="scope">
          <div>
            <el-checkbox disabled v-model="scope.row.ChangedBy1C"></el-checkbox>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { dates } from "../../scripts/dates";
export default {
  name: "OrderComponentAdrHist",
  props: ["orderid"],
  data: function () {
    return {
      tableData: [],
      loading: false,
    };
  },
  methods: {
        getDateTime(dat) {
      return dates.methods.toStringDateTime(dat);
    },
    getCoords(lat, lng) {
      return (lat ? lat.toFixed(6) : "") + " " + (lng ? lng.toFixed(6) : "");
    },
    refresh: function () {
      this.loadData(this.orderid);
    },
    loadData: function (orderId) {
      var self = this;
      self.loading = true;
      this.axios
        .post("/OrderEdit/getOrderHistory", {
          orderId: orderId,
        })
        .then(function (response) {
          self.loading = false;
          self.tableData = response.data.Data;
        })
        .catch(function (error) {
          self.loading = false;
          console.log(error);
        });
    },
  },
  watch: {
    orderid: function (newVal, oldVal) {
      if (newVal > 0) {
        //this.loadData(newVal);
      }
    },
  },
  mounted: function () {
    this.loadData(this.orderid);
  },
};
</script>

<style scoped>
</style>
