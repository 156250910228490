export const files = Object.freeze({
    name: 'Работа с файлами',
    methods: {
        downloadBase64Doc(fileName, b64data) {
            const byteCharacters = atob(b64data);

            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            let bytes = new Uint8Array(byteNumbers);
            let blob = new Blob([bytes], { type: 'application/octet-binary' });

            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
        },
        downloadBinaryDoc(fileName, data) {
            let bytes = new Uint8Array(data);
            let blob = new Blob([bytes], { type: 'application/octet-binary' });

            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
        },
        readFileAsBase64(file) {
            return new Promise(function (resolve, reject) {
                let reader = new FileReader();

                reader.onload = function () {
                    if (
                        reader.result &&
                        reader.result.length > 0 &&
                        reader.result.indexOf(",")
                    ) {
                        let result = reader.result.substring(reader.result.indexOf(",") + 1);
                        let fileResult = { fileName: file.name, data: result };
                        resolve(fileResult);
                    }
                };
                reader.onerror = function () {
                    reject(reader);
                };
                reader.readAsDataURL(file.raw);
            });
        },
        getFileName(fullName) {
            if (fullName) {
                let index = fullName.lastIndexOf(".")
                if (index > -1) {
                    return fullName.substring(0, index);
                }
                else return fullName;

            }
            return "";
        },
        getFileExt(fullName) {
            if (fullName) {
                let index = fullName.lastIndexOf(".")
                if (index > -1) {
                    return fullName.substring(index + 1, fullName.length).toUpperCase();
                }
                else return "";

            }
            return "";
        }
    }
})