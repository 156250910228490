
//Vue, Vuex,Router
import Vue from 'vue'
import { store } from './store'
import { router } from './router'

//ElementUI Library
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/ru-RU'

//CurrencyInput
import VueCurrencyInput from 'vue-currency-input'
Vue.use(VueCurrencyInput)

//Yandex Maps
import YmapPlugin from 'vue-yandex-maps'
const settings = {
  apiKey: '',
  lang: 'ru_RU',
  coordorder: 'latlong',
  version: '2.1'
}
Vue.use(YmapPlugin, settings)

//Маска
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

//MainPage
import App from './App.vue'

//LodashScripts
import VueLodash from 'vue-lodash'
import lodash from 'lodash'

require('dotenv').config()


Vue.use(ElementUI, { locale });

Vue.use(VueLodash, { name: 'custom', lodash: lodash })

Vue.config.productionTip = false


Vue.prototype.$helpMethods = {
  downloadBase64Doc(fileName, b64data) {
    const byteCharacters = atob(b64data);

    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    let bytes = new Uint8Array(byteNumbers);
    let blob = new Blob([bytes], { type: 'application/octet-binary' });

    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  },
  downloadBinaryDoc(fileName, data) {
    let bytes = new Uint8Array(data);
    let blob = new Blob([bytes], { type: 'application/octet-binary' });

    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  },
  getOrderHref(orderId, typeDelivery) {
    let resHref = "";
    switch (typeDelivery) {
      case 0:
      case 4:
      case 5:
        resHref = "/Order/OrderEditDelivery?orderID=" + orderId;
        break;
      case 1:
        resHref = "/Order/OrderEditPickup?orderID=" + orderId;
        break;
      case 2:
        resHref = "/Order/OrderEditCargoPickup?orderID=" + orderId;
        break;
      case 3:
        resHref = "/Order/OrderEditFreight?orderID=" + orderId;
        break;
    }
    return resHref;
  },
  showDoc: function (ext, data) {
    let type = this.getMimeType(ext.toLowerCase());
    const openPDF = this._openFile(data, type);
    openPDF.next();
    openPDF.next();
  },
  showBase64Doc: function (ext, data) {
    let type = this.getMimeType(ext.toLowerCase());
    const byteCharacters = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const openPDF = this._openFile(byteArray, type);
    openPDF.next();
    openPDF.next();
  },
  showBase64DocTest: function (ext, data) {
    let type = this.getMimeType(ext.toLowerCase());
    let docWindow = window.open("");
    docWindow.document.write(
      "<iframe width='100%' height='100%' src='data:" + type + ";base64, " +
      encodeURI(data) + "'></iframe>"
    );
  },
  getMimeType: function (ext) {
    let mimType = "";
    switch (ext) {
      case "jpg":
        mimType = "image/jpg";
        break;
      case "jpeg":
        mimType = "image/jpg";
        break;
      case "png":
        mimType = "image/png";
        break;
      case "pdf":
        mimType = "application/pdf";
        break;
    }
    return mimType;
  },
  _openFile: function* (data, type) {
    let newWindow = window.open('/Order/docs/loadingFile.html');

    yield;
    if (newWindow.document.readyState === 'complete') {
      this._openFileHelper(newWindow, data, type);
    } else {
      newWindow.onload = () => {
        this._openFileHelper(newWindow, data, type);
      };
    }
  },
  _openFileHelper: function (newWindow, data, type) {
    let bytes = new Uint8Array(data);
    let blob = new Blob([bytes], { type: type });
    newWindow.location = URL.createObjectURL(blob);
  }
}

window.localVue = new Vue({
  render: h => h(App),
  store,
  router,
  beforeCreate() { this.$store.commit('initialiseStore'); }
}).$mount('#app')
