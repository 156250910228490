<template>
  <div>
    <h1>Отчет "Количество доставок"</h1>
    <div style="padding: 5px">
      <el-form
        size="mini"
        style="width: 600px"
        ref="searchSettings"
        :rules="rules"
        :model="searchSettings"
        label-width="200px"
      >
        <el-form-item label="Дата доставки" prop="DeliveryDate">
          <el-date-picker
            style="width: 125px"
            v-model="searchSettings.DeliveryDate"
            type="date"
            :picker-options="delDatePickOpt"
            format="dd.MM.yyyy"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="Терминал доставки" prop="DeliveryPlace">
          <delivery-terminal-control
            v-model="searchSettings.DeliveryPlace"
            :useAllOpt="true"
          ></delivery-terminal-control>
        </el-form-item>
      </el-form>
      <el-button size="mini" @click="getReport">Сформировать</el-button>
    </div>
    <div>{{ "Количество заказов: " + this.getSum() }}</div>
    <el-table size="mini" :data="reportData" border>
      <el-table-column prop="ShopTitle" label="Партнер" align="center">
      </el-table-column>
      <el-table-column prop="Count" label="Количество"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
import DeliveryTerminalControl from "./controls/DeliveryTerminalControl.vue";

export default {
  name: "ReportDelivCnt",
  components: { 
    DeliveryTerminalControl,
   },
  data: function () {
    let dat = new Date();
    dat.setDate(dat.getDate() + 1);
    let mm = dat.getMonth() + 1;
    let dd = dat.getDate();
    let tomorrow = [
      dat.getFullYear(),
      (mm > 9 ? "" : "0") + mm,
      (dd > 9 ? "" : "0") + dd,
    ].join("-");
    return {
      reportData: [],
      disabled:false,
      searchSettings: {
        DeliveryDate: tomorrow,
        DeliveryPlace: "All",
      },
      delDatePickOpt: {
        firstDayOfWeek: 1,
      },
      rules: {
        DeliveryDate: [
          {
            required: true,
            message: "Выберите дату доставки",
            trigger: "change",
          },
        ],
        DeliveryPlace: [
          {
            required: true,
            message: "Выберите терминал доставки",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {

  },
  methods: {
    getParams() {
      let par = {};
      par.DeliveryDate = this.searchSettings.DeliveryDate;
      if (this.searchSettings.DeliveryPlace > 0) {
        par.DeliveryPlace = this.searchSettings.DeliveryPlace;
      }
      return par;
    },
    getSum() {
      let cnt = 0;
      if (this.reportData.length > 0) {
        this.reportData.forEach(function (item) {
          cnt += item.Count;
        });
      }
      return cnt;
    },
    getData() {
      this.$refs["searchSettings"].validate((valid) => {
        if (valid) {
          let self = this;
          self.startLoading();
          this.axios
            .post("/ReportDelivCnt/getReport", {
              searchParams: self.getParams(),
            })
            .then((resp) => {
              self.stopLoading();
              self.reportData = resp.data.Data;
            })
            .catch((e) => {
              self.stopLoading();
              self.showError("Произошла ошибка");
              console.log(e);
            });
        }
      });
    },
    getReport() {
      this.getData();
    },
    startLoading() {
      this.$loading();
    },
    stopLoading() {
      this.$loading().close();
    },
    showSuccess(message) {
      this.$message({
        message: message,
        type: "success",
      });
    },
    showError(message) {
      this.$message.error(message);
    },
  },
  mounted: function () {
    this.getData();
  },
};
</script>