<template>
  <el-select
    size="mini"
    v-model="subShopId"
    :disabled="disabled"
    filterable
    placeholder="Выберите из списка"
    @change="handleChange"
  >
    <el-option
      v-for="item in SubShopList"
      :key="item.InternetShopId"
      :label="item.Name"
      :value="item.InternetShopId"
    ></el-option>
  </el-select>
</template>
<script>
export default {
  name: "SubShopControl",
  props: ["value", "disabled", "selectAny", "shopIdMain","isEnable"],
  data: function () {
    return {
      subShopId: null,
    };
  },
  watch: {
    shopIdMain(newVal, oldVal) {
      let cnt = this.SubShopList.length;
      this.$emit("shopMainChange", cnt);
    },
    value(newVal, oldVal) {
      if (newVal != oldVal) {
        if (
          this.SubShopList.some((shopId) => shopId.InternetShopId === newVal)
        ) {
          this.subShopId = newVal;
        } else {
          this.subShopId = null;
        }
      }
    },
    subShopId(newVal) {
      this.$emit("input", newVal);
      let item = this.SubShopList.find(function (element) {
        if (element.InternetShopId !== newVal) {
          return false;
        }
        return true;
      });
      //this.$emit("change", item);
    },
  },
  computed: {
    SubShopList() {
      if (this.shopIdMain) {
        let self = this;
        return this.$store.getters.organizationList.filter(
          (shop) => shop.ParentId == self.shopIdMain && (!self.isEnable || shop.IsEnable)
        );
      }
      return [];
    },
  },
  methods: {
    handleChange(val){
      let shop = this.getShop(val);
      this.$emit("change", shop);
    },
    getShop(internetShopId) {
      let shop = null;
      this.$store.getters.organizationList.filter(function (item) {
        if (item.ParentId && item.InternetShopId == internetShopId) {
          shop = item;
        }
      });
      return shop;
    },
  },
  mounted: function () {
    if (this.value) {
      this.subShopId = this.value;
      this.$emit("change",this.getShop(this.subShopId));
    } else if (this.selectAny && this.SubShopList.length === 1) {
      this.subShopId = this.SubShopList[0].InternetShopId;
      this.$emit("change",this.getShop(this.subShopId));
    }
  },
};
</script>