<template>
    <div>
    
</div>
</template>

<script>
export default {
  name: 'Default',
  data:function() {
  return {
  }
  },
  methods:{
      
    }
}
</script>

<style scoped>
</style>
