<template>
  <el-select
    size="mini"
    v-model="reason"
    multiple
    collapse-tags
    @change="handleChange"
  >
    <el-option
      v-for="item in TransCancelReasonList"
      :key="item.Key"
      :label="item.Name"
      :value="item.Key"
    ></el-option>
  </el-select>
</template>
<script>
export default {
  name: "TransCancelListControl",
  props: ["clearVal"],
  data: function () {
    return {
      reason: [],
      oldVal: [],
    };
  },
    watch: {
    clearVal(newV, oldV) {
      this.reason = null;
      this.$emit("input", null);
    },
  },
  computed: {
    TransCancelReasonList() {
      return [
        { Key: "All", Name: "Все" },
        ...this.$store.getters.transCancelReasonList,
      ];
    },
    DictValLength() {
      return this.$store.getters.transCancelReasonList.length;
    },
  },
  methods: {
    handleChange(newVal) {
      let allOptInNewVal = newVal.indexOf("All") > -1;
      let allOptInOldVal = this.oldVal.indexOf("All") > -1;
      let newValLength = newVal.filter((value) => value != "All").length;
      if (allOptInNewVal && !allOptInOldVal) {
        let allVals = [];
        this.$store.getters.transCancelReasonList.forEach((element) => {
          allVals.push(element.Key);
        });
        allVals.push("All");
        this.reason = allVals;
      } else if (!allOptInNewVal && allOptInOldVal) {
        this.reason = [];
      } else if (
        !allOptInNewVal &&
        !allOptInOldVal &&
        newValLength == this.DictValLength
      ) {
        this.reason.push("All");
      } else if (
        allOptInNewVal &&
        allOptInOldVal &&
        newValLength <= this.DictValLength
      ) {
        let pos = newVal.indexOf("All");
        if (pos > -1) {
          this.reason.splice(pos, 1);
        }
      }
      this.oldVal = this._.cloneDeep(this.reason);
      this.$emit(
        "input",
        this.reason.filter((value) => value != "All")
      );
    },
  },
};
</script>