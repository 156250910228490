<template>
  <div>
    <div v-show="!visualSettings.dialogRules">
      <el-button style="margin-bottom: 5px" size="mini" @click="createRole"
        >Добавить пользователя</el-button
      >
      <el-tree :data="rolesData" :props="{ children: 'Roles' }" node-key="id">
        <span slot-scope="{ node, data }">
          <span
            :class="{ undr: node.level != 1 }"
            @click="() => openRules(node, data)"
            >{{ node.level == 1 ? data.GroupName : data.RoleName }}</span
          >
        </span>
      </el-tree>
    </div>
    <div v-show="visualSettings.dialogRules">
      <el-button size="mini" icon="el-icon-back" @click="showRules(false)"
        >Вернуться</el-button
      >
      <el-button style="margin-bottom: 5px" size="mini" @click="saveRoleData"
        >Сохранить изменения</el-button
      >
      <el-form
        ref="RulesData"
        :model="roleData"
        :rules="rules"
        style="width: 50%"
        label-width="200px"
      >
        <el-form-item class="field" label="ФИО:" prop="RoleName">
          <el-input size="mini" v-model="roleData.RoleName"></el-input>
        </el-form-item>
        <el-form-item class="field" label="Логин" prop="Login">
          <el-input size="mini" v-model="roleData.Login"></el-input>
        </el-form-item>
        <el-form-item class="field" label="Пароль" prop="Password">
          <el-input
            size="mini"
            v-model="roleData.Password"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item class="field" label="Email" prop="Email">
          <el-input size="mini" v-model="roleData.Email"></el-input>
        </el-form-item>
        <el-form-item
          class="field"
          label="Группа пользователей"
          prop="RoleGroupId"
        >
          <el-select
            size="mini"
            v-model="roleData.RoleGroupId"
            placeholder="Выберите из списка"
          >
            <el-option
              v-for="item in GroupsDict"
              :key="item.RoleGroupId"
              :label="item.GroupName"
              :value="item.RoleGroupId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="field" label="Терминалы" prop="Terminals">
          <el-select
            size="mini"
            v-model="roleData.Terminals"
            multiple
            placeholder="Выберите из списка"
          >
            <el-option
              v-for="item in TerminalsDict"
              :key="item.Key"
              :label="item.Name"
              :value="item.Key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          class="field"
          label="Активный пользователь"
          prop="IsUserActive"
        >
          <el-checkbox
            size="mini"
            v-model="roleData.IsUserActive"
          ></el-checkbox>
        </el-form-item>
        <el-form-item class="field" label="Работник ФСД" prop="IsFSD">
          <el-checkbox size="mini" v-model="roleData.IsFSD"></el-checkbox>
        </el-form-item>
      </el-form>
      <el-tabs type="border-card" :value="'first'">
        <el-tab-pane label="Права пользователя" name="first">
          <div style="min-height: 390px; padding-top: 10px">
            <el-table size="mini" :data="roleData.RoleRules" border>
              <el-table-column prop="RoleAttrName" label="Разрешения">
                <template slot-scope="scope">
                  <el-checkbox
                    v-model="scope.row.IsAccess"
                    :disabled="scope.row.IsEdit === false"
                    >{{ scope.row.RoleAttrName }}</el-checkbox
                  >
                </template>
              </el-table-column>
            </el-table>
          </div></el-tab-pane
        >
        <el-tab-pane label="Доступ к магазинам" name="second">
          <div style="min-height: 390px; padding-top: 10px">
            <el-table size="mini" :data="roleData.ShopsAccess" border>
              <el-table-column prop="ShopID" label="№"> </el-table-column>
              <el-table-column prop="ShopName" label="Название">
              </el-table-column>
              <el-table-column prop="IsAccess" label="Доступ">
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.IsAccess"></el-checkbox>
                </template>
              </el-table-column>
            </el-table></div
        ></el-tab-pane>
        <el-tab-pane label="Доступ к страницам" name="third">
          <div style="min-height: 390px; padding-top: 10px">
              <el-table size="mini" :data="roleData.PagesAccess" border>
                  <el-table-column prop="PageId" label="PageId">
                  </el-table-column>
                   <el-table-column prop="PageName" label="Название страницы">
                  </el-table-column>
              <el-table-column prop="IsAccess" label="Доступ">
                <template slot-scope="scope">
                  <el-checkbox
                    v-model="scope.row.IsAccess"
                    :disabled="scope.row.IsEdit === false"
                    ></el-checkbox
                  >
                </template>
              </el-table-column>
            </el-table>
              </div
        ></el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: "Role",
  data: function () {
    return {
      rolesData: [],
      roleData: {
        RoleName: null,
        Login: null,
        Password: null,
        Email: null,
        IsUserActive: false,
        IsFSD: false,
        RoleGroupId: null,
        Terminals: [],
        RoleRules: [],
        ShopsAccess: [],
        PagesAccess: [],
      },
      visualSettings: {
        dialogRules: false,
      },
      RulesDict: [],
      ShopsDict: [],
      TerminalsDict: [],
      GroupsDict: [],
      rules: {
        RoleName: [
          {
            required: true,
            message: "Введите фио пользователя",
            trigger: "change",
          },
        ],
        Login: [
          { required: true, message: "Введите логин", trigger: "change" },
        ],
        RoleGroupId: [
          { required: true, message: "Выберите группу", trigger: "change" },
        ],
      },
    };
  },
  computed: {},
  methods: {
    createRole() {
      let self = this;
      this.roleData = {
        RoleName: null,
        Login: null,
        Password: null,
        Email: null,
        IsUserActive: false,
        IsFSD: false,
        RoleGroupId: null,
        Terminals: [],
        RoleRules: self._.cloneDeep(this.RulesDict),
        ShopsAccess: self._.cloneDeep(this.ShopsDict),
      };

      this.roleData.ShopsAccess.map(function (item) {
        self.$set(item, "IsAccess", false);

        return item;
      });
      this.roleData.RoleRules.map(function (item) {
        item.IsEdit = false;
        return item;
      });
      this.showRules(true);
    },
    openRules(node, ruleData) {
      let self = this;
      self.startLoading();
      this.axios
        .post("/Role/getRole", { roleId: ruleData.RoleId })
        .then((response) => {
          self.stopLoading();
          if (response.data.Success) {
            self.loadRule(response.data.Data);
            self.showRules(true);
          } else {
            self.showError("Произошла ошибка");
          }
        })
        .catch((e) => {
          self.showError("Произошла ошибка");
          console.log(e);
        });
    },
    loadRule(role) {
      let self = this;
      let ruleDataDict = role.RoleRules.reduce(function (result, item) {
        if (item.IsAccess) {
          result[item.RoleAttrID] = { IsAccess: true, IsEdit: item.IsEdit };
        }
        return result;
      }, {});

      let shopsDataDict = role.ShopsAccess.reduce(function (result, item) {
        if (item.IsAccess) {
          result[item.ShopID] = true;
        }
        return result;
      }, {});
      this.roleData = {
        RoleId: role.RoleId,
        RoleName: role.RoleName,
        Login: role.Login,
        Email: role.Email,
        IsUserActive: role.IsUserActive,
        IsFSD: role.IsFSD,
        RoleGroupId: role.RoleGroupId,
        Terminals: role.Terminals,
      };
      this.roleData.RoleRules = this._.cloneDeep(this.RulesDict);
      this.roleData.RoleRules.map(function (item) {
        if (ruleDataDict[item.RoleAttrID]) {
          self.$set(item, "IsAccess", true);
          self.$set(item, "IsEdit", ruleDataDict[item.RoleAttrID].IsEdit);
        } else {
          self.$set(item, "IsAccess", false);
          self.$set(item, "IsEdit", true);
        }
        return item;
      });

      this.roleData.ShopsAccess = this._.cloneDeep(this.ShopsDict);
      this.roleData.ShopsAccess.map(function (item) {
        if (shopsDataDict[item.ShopID]) {
          self.$set(item, "IsAccess", true);
        } else {
          self.$set(item, "IsAccess", false);
        }
        return item;
      });
      this.roleData.PagesAccess = this._.cloneDeep(role.PagesAccess);
    },
    showRules(isShow) {
      this.visualSettings.dialogRules = isShow;
    },
    saveRoleData() {
      this.$refs["RulesData"].validate((valid) => {
        if (valid) {
          this.startLoading();
          let self = this;
          this.axios
            .post("/Role/saveRole", { role: this.roleData })
            .then((response) => {
              self.stopLoading();
              if (response.data.Success) {
                let id = 1;
                self.rolesData = response.data.Data.map(function (gr) {
                  gr.id = id;
                  id++;
                  gr.Roles.map(function (item) {
                    item.id = id;
                    id++;
                    return id;
                  });
                  return gr;
                });
                self.showRules(false);
              } else {
                self.showError("Произошла ошибка");
              }
            })
            .catch((e) => {
              self.showError("Произошла ошибка");
              console.log(e);
            });
        }
      });
    },
    loadSimpleDictionaries(resp) {
      this.RulesDict = resp.data.RulesList;
      this.ShopsDict = resp.data.ShopsList;
      this.TerminalsDict = resp.data.TerminalsList.Dir;
      this.GroupsDict = resp.data.GroupsList;
    },
    startLoading() {
      this.$loading();
    },
    stopLoading() {
      this.$loading().close();
    },
    showSuccess(message) {
      this.$message({
        message: message,
        type: "success",
      });
    },
    showError(message) {
      this.$message.error(message);
    },
  },
  mounted: function () {
    let self = this;
    self.startLoading();
    let dictRequest = this.axios.post("/Role/getDictionaries", {});
    let rolesDataRequest = this.axios.post("/Role/getRoles", {});
    this.axios
      .all([dictRequest, rolesDataRequest])
      .then(
        self.axios.spread((...responses) => {
          self.loadSimpleDictionaries(responses[0]);
          let id = 1;
          self.rolesData = responses[1].data.Data.map(function (gr) {
            gr.id = id;
            id++;
            gr.Roles.map(function (item) {
              item.id = id;
              id++;
              return id;
            });
            return gr;
          });
          self.stopLoading();
        })
      )
      .catch((e) => {
        self.stopLoading();
        self.showError("Произошла ошибка");
        console.log(e);
      });
  },
};
</script>