<template>
  <el-select
    size="mini"
    v-model="payType"
    :disabled="disabled"
    placeholder="Выберите из списка"
  >
    <el-option
      v-for="item in PayTypeAllList"
      :key="item.Key"
      :label="item.Name"
      :value="item.Key"
      :disabled="ForbidDisable && item.Disabled"
    ></el-option>
  </el-select>
</template>
<script>
export default {
  name: "PayTypeControl",
  props: {
    useAllOpt: {
      default: false,
      type: Boolean,
    },
    value: {},
    disabled: {
      default: false,
      type: Boolean,
    },
    selfDelivery: {
      default: null,
      type: Number,
    },
    forbidDisable: {
      default: false,
      type: Boolean,
    },
  },
  data: function () {
    return {
      payType: null,
      payTypeCached: null
    };
  },
  watch: {
    value(newVal, oldVal) {
      this.payTypeCached = newVal;
      if (newVal != oldVal) {
        if (this.PayTypeAllList.some((typeId) => typeId.Key === newVal)) {
          this.payType = newVal;
        } else {
          this.payType = null;
        }
      }
    },
    selfDelivery(newVal, oldVal){
      let self = this;
      if (newVal != oldVal) {
        if (this.PayTypeAllList.some((typeId) => typeId.Key === self.payTypeCached)) {
          this.payType = self.payTypeCached;
        } else {
          this.payType = null;
        }
      }
    },
    payType(newVal) {
      this.$emit("input", newVal);
    },
  },
  computed: {
    ForbidDisable() {
      return this.forbidDisable;
    },
    PayTypeAllList() {
      let filterVals = null;
      if (this.selfDelivery != null) {
        let self = this;
        filterVals = this.$store.getters.payTypeList.filter(
          (item) => item.SelfDelEnable.indexOf(this.selfDelivery) > -1
        );
      } else {
        filterVals = this.$store.getters.payTypeList;
      }

      if (this.useAllOpt) {
        return [{ Key: "All", Name: "Все" }, ...filterVals];
      }
      return filterVals;
    },
  },
  methods: {},
  mounted: function () {
    this.payType = this.value;
    this.payTypeCached = this.value;
  },
};
</script>