<template>
  <div></div>
</template>

<script>
export default {
  name: "ScrollTo",
  props: ["anchor", "block"],
  data: function () {
    return {};
  },
  methods: {
    scrollTo() {
      this.$el.scrollIntoView({
        block: this.block,
        behavior: "smooth",
        inline: "nearest",
      });
    },
    handleScroll: function (evt, el, position) {
      if (position.top >= this.bottompos && position.top <= this.toppos) {
        this.$emit("anchor-shown", this.anchor, position);
      }
      return;
    },
  },
};
</script>

<style scoped>
</style>
