<template>
  <div class="vueTemplate">
    <el-checkbox v-model="shopsActIsShow">Показать активные</el-checkbox>
    <el-button
      v-if="shopsActIsShow"
      style="margin: 10px"
      type="primary"
      size="mini"
      @click="showDialogNewShop(true)"
      >Регистрация нового магазина</el-button
    >
    <el-dialog
      width="900px"
      :center="true"
      title="пользователи"
      :visible.sync="visualSettings.dialogUsers"
    >
      <el-button size="mini" @click="addUser">Добавить пользователя</el-button>
      <el-table :data="usersData" border size="mini">
        <el-table-column header-align="center" align="center" width="210px">
          <template slot-scope="scope">
            <div style="display: flex">
              <el-button
                size="mini"
                icon="el-icon-edit"
                v-show="!scope.row.IsEdit"
                @click="editUser(scope.$index, true)"
              ></el-button>
              <el-button
                size="mini"
                v-show="scope.row.IsEdit && !scope.row.RoleId"
                @click="saveNewUser(scope.row,scope.$index)"
                >Добавить</el-button
              >
              <el-button
                size="mini"
                v-show="scope.row.IsEdit && scope.row.RoleId"
                @click="saveEditUser(scope.row,scope.$index)"
                >Сохранить</el-button
              >
              <el-button
                size="mini"
                v-show="scope.row.IsEdit"
                @click="cancelUser(scope.row,scope.$index)"
                >Отмена</el-button
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          label="ID"
          prop="RoleId"
          align="center"
        ></el-table-column>
        <el-table-column
          header-align="center"
          label="Логин"
          prop="Login"
          align="center"
        >
          <template slot-scope="scope">
            <el-input
              size="mini"
              :disabled="!scope.row.IsEdit"
              v-model="scope.row.Login"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          label="Имя"
          prop="RoleName"
          align="center"
        >
          <template slot-scope="scope">
            <el-input
              size="mini"
              :disabled="!scope.row.IsEdit"
              v-model="scope.row.RoleName"
            ></el-input> </template
        ></el-table-column>
        <el-table-column
          header-align="center"
          label="Пароль"
          align="center"
          width="180px"
        >
          <template slot-scope="scope">
            <el-input size="mini" v-show="scope.row.IsEdit && !scope.row.RoleId" v-model="scope.row.Password"></el-input>
            <el-button
              size="mini"
              v-show="scope.row.IsEdit && scope.row.RoleId"
              @click="resetPassword(scope.row.RoleId)"
              >Сбросить пароль</el-button
            >
          </template></el-table-column
        >
        <el-table-column
          header-align="center"
          label="Включить/отключить"
          align="center"
        >
          <template slot-scope="scope">
            <el-button v-show="scope.row.RoleId" size="mini" @click="setUserState(scope.row.RoleId,scope.$index)">{{
              scope.row.IsUserActive ? "Отключить" : "Включить"
            }}</el-button>
          </template></el-table-column
        >
      </el-table>
    </el-dialog>
    <el-dialog
      width="400"
      :center="true"
      title="Регистрация нового магазина"
      :visible.sync="visualSettings.dialogNewShop"
    >
      <el-form
        size="mini"
        ref="newShop"
        :model="newShop"
        label-width="150px"
        :rules="rules"
      >
        <el-form-item label="Название магазина" prop="ShopName">
          <el-input size="mini" v-model="newShop.ShopName"></el-input>
        </el-form-item>
        <el-form-item label="Название для бланка" prop="ShopTitle">
          <el-input size="mini" v-model="newShop.ShopTitle"></el-input>
        </el-form-item>
        <el-form-item label="Юр.лицо" prop="ShopJurFace">
          <el-input size="mini" v-model="newShop.ShopJurFace"></el-input>
        </el-form-item>
        <el-form-item label="Телефон" prop="ShopPhone">
          <el-input size="mini" v-model="newShop.ShopPhone"></el-input>
        </el-form-item>
        <el-form-item label="Индекс сортировки" prop="Sorting">
          <el-input size="mini" v-model="newShop.Sorting"></el-input>
        </el-form-item>
        <el-form-item label="Холдинг">
          <el-select
            size="mini"
            style="margin: 5px; width: 300px"
            v-model="newShop.ParentId"
            filterable
            clearable
            placeholder="Выберите из списка"
            @change="onParentShopChange"
          >
            <el-option
              v-for="item in shopDict"
              :key="item.InternetShopId"
              :label="item.Name"
              :value="item.InternetShopId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-show="newShop.ParentId"
          label="Копировать тарифы на доставку"
          prop="copyDeliveryTariffs"
        >
          <el-checkbox
            v-model="newShopSettings.CopyTariffsDelivery"
          ></el-checkbox>
        </el-form-item>
        <div style="text-align: center">Пользователь</div>
        <el-form-item label="Логин">
          <el-input size="mini" v-model="newShopUser.Login"></el-input>
        </el-form-item>
        <el-form-item label="Пароль">
          <el-input size="mini" v-model="newShopUser.Password"></el-input>
        </el-form-item>
      </el-form>
      <span style="text-align: center">
        <div style="margin: 10px">
          <el-button @click="addNewShop" type="success" size="small"
            >Создать</el-button
          >
          <el-button @click="showDialogNewShop(false)" size="small"
            >Отмена</el-button
          >
        </div>
      </span>
    </el-dialog>
    <el-table
      :data="ShopsData"
      border
      row-key="SettingId"
      :load="load"
      lazy
      :tree-props="{ children: 'children', hasChildren: 'HasChildren' }"
      :row-class-name="getRowColor"
      size="mini"
    >
      <el-table-column
        header-align="center"
        align="center"
        width="50"
      ></el-table-column>
      <el-table-column header-align="center" align="center" width="80">
        <template slot-scope="scope">
          <div style="display: flex">
            <el-button
              size="mini"
              icon="el-icon-edit"
              v-show="!editMode(scope.row)"
              @click="editShop(scope.row, true)"
            ></el-button>
            <el-button
              size="mini"
              icon="el-icon-check"
              v-show="editMode(scope.row)"
              @click="saveEditShop(scope.row)"
            ></el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" width="100">
        <template slot-scope="scope">
          <el-link
            :href="getHrefPartner(scope.row)"
            size="mini"
            type="success"
            target="_blank"
            >Показать</el-link
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="SettingId"
        label="settingId"
        header-align="center"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="InternetShopId"
        label="internetShopId"
        header-align="center"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="IsActive"
        label="Включен"
        header-align="center"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          <el-checkbox
            v-model="scope.row.IsActive"
            size="mini"
            :disabled="!editMode(scope.row)"
          ></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column
        prop="ShopName"
        label="Название магазина"
        header-align="center"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="INN"
        label="ИНН"
        header-align="center"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="Sorting"
        label="Сортировка"
        header-align="center"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          <el-input-number
            size="mini"
            v-model="scope.row.Sorting"
            :disabled="!editMode(scope.row)"
            :controls="false"
            style="width: 100%"
          ></el-input-number>
        </template>
      </el-table-column>
      <el-table-column
        prop="ShopTitle"
        label="Заголовок в бланке"
        header-align="center"
        align="center"
      ></el-table-column>
      <el-table-column header-align="center" align="center" width="140">
        <template slot-scope="scope">
          <el-button size="mini" @click="showDialogUsers(true, scope.row)"
            >Пользователи</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        label="Сделать активным/неактивным"
        header-align="center"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            v-show="!scope.row.IsEnable"
            @click="setShopState(scope.row.SettingId, true, scope.row)"
            >Сделать активным</el-button
          >
          <el-button
            size="mini"
            v-show="scope.row.IsEnable"
            @click="setShopState(scope.row.SettingId, false, scope.row)"
            >Сделать неактивным</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :page-sizes="[100, 500, 1000]"
      layout="sizes,prev, pager, next"
      :total="ShopsCnt"
      :current-page.sync="shopsCurPage"
      :page-size.sync="shopsPageSize"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "Shops",
  data: function () {
    return {
      newShop: {
        ShopName: "",
        ShopTitle: "",
        ShopJurFace: "",
        ShopPhone: "",
        Sorting: "",
        ParentId: null,
      },
      newShopUser: {
        Login: "",
        Password: "",
      },
      newShopSettings: {
        CopyTariffsDelivery: false,
      },
      shopsActIsShow: true,
      shopsCurPage: 1,
      shopsPageSize: 100,
      shopsData: [
        {
          SettingId: 1,
          InternetShopId: 2,
          IsActive: 1,
          IsEnable: true,
          ShopName: "",
          ShopTitle: "",
          INN: "",
          Sorting: "1",
          HasChildren: true,
          IsEditable: false,
          IsHeadShop: true,
        },
      ],
      usersData: [],
      usersInternetShopId:null,
      shopDict: [],
      visualSettings: {
        dialogNewShop: false,
        dialogUsers: false,
      },
      rules: {
        ShopName: [
          {
            required: true,
            message: "Введите название магазина",
            trigger: "blur",
          },
        ],
        ShopTitle: [
          {
            required: true,
            message: "Введите название для бланка",
            trigger: "blur",
          },
        ],
        ShopJurFace: [
          { required: true, message: "Введите юр.лицо", trigger: "blur" },
        ],
        ShopPhone: [
          { required: true, message: "Введите телефон", trigger: "blur" },
        ],
        Sorting: [
          {
            required: true,
            message: "Введите индекс сортировки",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ShopsFilter() {
      let self = this;
      return this.shopsData.filter(
        (item) => item.IsEnable === self.shopsActIsShow
      );
    },
    ShopsCnt() {
      return this.ShopsFilter.length;
    },
    ShopsData() {
      let shopsCnt = this.ShopsCnt;
      let maxVal = this.shopsCurPage * this.shopsPageSize;
      if (maxVal > shopsCnt) maxVal = shopsCnt;
      return this.ShopsFilter.slice(
        (this.shopsCurPage - 1) * this.shopsPageSize,
        maxVal
      );
    },
  },
  methods: {
    onParentShopChange(val) {
      if (!val) {
        this.newShopSettings.CopyTariffsDelivery = false;
      }
    },
    getHrefPartner(item) {
      return "/Settings/ShopEdit?partnerID=" + item.SettingId;
    },
    addNewShop() {
      this.$refs["newShop"].validate((valid) => {
        if (valid) {
          var self = this;
          self.startLoading();
          this.axios
            .post("/Shops/addNewShop", {
              shop: this.newShop,
              user: this.newShopUser,
              newShopSettings: this.newShopSettings,
            })
            .then(function (response) {
              self.stopLoading();
              if (response.data.Success) {
                self.showDialogNewShop(false);
                self.loadData();
              } else {
                self.showError("Произошла ошибка при сохранении");
              }
            })
            .catch(function (error) {
              self.stopLoading();
              self.showError("Произошла ошибка при сохранении");
            });
        }
      });
    },
    editUser(index, isEdit) {
      this.$set(this.usersData[index], "IsEdit", isEdit);
    },
    cancelUser(row,index) {
      if(!row.RoleId){
        this.usersData.splice(index,1);
        return;
      }
      let self = this;
      self.startLoading();
      this.axios
        .post("/Shops/loadUser", {
          roleId: row.RoleId,
        })
        .then(function (response) {
          self.stopLoading();
          self.$set(self.usersData,index,response.data.Data);
          //self.$set(row, "IsEdit", false);
          self.editUser(index, false);
        })
        .catch(function (error) {
          self.stopLoading();
          self.showError("Произошла ошибка при сохранении");
        });
    },
    addUser(){
      this.usersData.push({
        IsEdit : true
      });
    },
    setUserState(roleId,index) {
      let self = this;
      self.startLoading();
      this.axios
        .post("/Shops/setUserState", {
          roleId: roleId
        })
        .then(function (response) {
          self.stopLoading();
          //self.showSuccess("");
          self.$set(self.usersData,index,response.data.Data);
        })
        .catch(function (error) {
          self.stopLoading();
          self.showError("Произошла ошибка при сохранении");
        });
    },
    resetPassword(roleId) {
      let self = this;
      self.startLoading();
      this.axios
        .post("/Shops/resetPassword", {
          roleId: roleId
        })
        .then(function (response) {
          self.stopLoading();
          self.showSuccess("Пароль сброшен!");
          //self.$set(self.usersData,index,response.data.Data);
        })
        .catch(function (error) {
          self.stopLoading();
          self.showError("Произошла ошибка при сохранении");
        });
    },
    saveNewUser(row,index) {
      if(!row.Password || row.Password.length < 5){
        this.showError("Задайте пароль не менее 5 символов!");
        return;
      }
      let self = this;
      self.startLoading();
      this.axios
        .post("/Shops/addUser", {
          role: row,
          internetShopId:this.usersInternetShopId 
        })
        .then(function (response) {
          self.stopLoading();
          self.$set(self.usersData,index,response.data.Data);
          self.editUser(index, false);
        })
        .catch(function (error) {
          self.stopLoading();
          self.showError("Произошла ошибка при сохранении");
        });
    },
    saveEditUser(row,index) {
      let self = this;
      self.startLoading();
      this.axios
        .post("/Shops/saveUser", {
          role: row,
        })
        .then(function (response) {
          self.stopLoading();
          self.$set(self.usersData,index,response.data.Data);
          self.editUser(index, false);
        })
        .catch(function (error) {
          self.stopLoading();
          self.showError("Произошла ошибка при сохранении");
        });
    },
    loadUsers(internetShopId, cb) {
      let self = this;
      self.startLoading();
      this.axios
        .post("/Shops/loadUsers", {
          internetShopId: internetShopId,
        })
        .then(function (response) {
          self.stopLoading();
          if (response.data.Success) {
            self.usersData = response.data.Data;
            cb();
          } else {
            self.showError("Произошла ошибка при сохранении");
          }
        })
        .catch(function (error) {
          self.stopLoading();
          self.showError("Произошла ошибка при сохранении");
        });
    },
    showDialogUsers(show, row) {
      let self = this;
      if (show) {
        this.loadUsers(row.InternetShopId, function () {
          self.visualSettings.dialogUsers = show;
          self.usersInternetShopId = row.InternetShopId;
        });
      } else {
        this.visualSettings.dialogUsers = show;
      }
    },
    showDialogNewShop(show) {
      this.visualSettings.dialogNewShop = show;
    },
    getItemData(item) {
      var itemData = this.shopsData.filter(function (itemData) {
        return itemData.SettingId === item.SettingId;
      });
      if (itemData.length === 1) {
        return itemData[0];
      }
      return null;
    },
    setShopState(settingId, isEnable, row) {
      let self = this;
      this.$confirm(
        "Сделать магазин " + (isEnable ? "активным?" : "неактивным?"),
        "Предупреждение",
        {
          confirmButtonText: "Да",
          cancelButtonText: "Отмена",
          type: "warning",
        }
      )
        .then(() => {
          self.axios
            .post("/Shops/setShopState", { settingId, isEnable })
            .then(function (response) {
              if (response.data.Success) {
                row.IsEnable = isEnable;
              } else {
                self.showError("Произошла ошибка при сохранении");
              }
            })
            .catch(function (error) {
              self.showError("Произошла ошибка при сохранении");
            });
        })
        .catch(() => {});
    },
    saveEditShop(item) {
      this.axios
        .post("/Shops/saveShopData", {
          shop: item,
        })
        .then(function (response) {
          if (response.data.Success) {
            item.IsEditable = false;
          } else {
            self.showError("Произошла ошибка при сохранении");
          }
        })
        .catch(function (error) {
          self.showError("Произошла ошибка при сохранении");
        });
    },
    editMode(item) {
      if (item.IsEditable) return true;
      return false;
    },
    editShop(item, isEdit) {
      item.IsEditable = isEdit;
    },
    getRowColor(row, rowIndex) {
      if (!row.row.IsActive) {
        return "nonActive";
      }
      if (row.row.HasChildren) {
        return "hasChild";
      }
      if (!row.row.IsHeadShop) {
        return "childs";
      }
      return null;
    },
    startLoading() {
      this.$loading();
    },
    stopLoading() {
      this.$loading().close();
    },
    load(tree, treeNode, resolve) {
      this.axios
        .post("/Shops/getChildShopsData", {
          parentId: tree.SettingId,
        })
        .then(function (response) {
          if (response.data.Success) {
            resolve(response.data.Data);
          } else {
            self.showError("Произошла ошибка при сохранении");
          }
        })
        .catch(function (error) {
          self.showError("Произошла ошибка при сохранении");
        });
    },
    loadData() {
      var shopsRequest = this.axios.post("/Shops/getShopsData", {});
      var shopsDictRequest = this.axios.post("/Shops/getMainShopList", {});
      this.startLoading();
      var self = this;
      this.axios
        .all([shopsRequest, shopsDictRequest])
        .then(
          self.axios.spread((...responses) => {
            var responseShopsData = responses[0];
            var responseShopDictData = responses[1];
            self.shopsData = responseShopsData.data.Data;
            self.shopsData.forEach(function (item) {
              self.$set(item, "IsEditable", false);
              self.$set(item, "IsHeadShop", true);
              return item;
            });
            self.shopDict = responseShopDictData.data.Dir;
            self.stopLoading();
          })
        )
        .catch((error) => {
          console.log(error);
          self.stopLoading();
        });
    },
    showSuccess(message) {
      this.$message({
        message: message,
        type: "success",
      });
    },
    showError(message) {
      this.$message.error(message);
    },
  },
  mounted: function () {
    this.loadData();
  },
};
</script>

  <style scoped>
.vueTemplate >>> .hasChild {
  background-color: #f8e6f9 !important;
}
.vueTemplate >>> .childs {
  background-color: #bbcfef !important;
}
.vueTemplate >>> .nonActive {
  background-color: gainsboro !important;
}
</style>