<template>
<div>
    <div>Параметры поиска логов</div>    
    <el-form ref="searchParams" :model="searchParams" label-width="150px" size="mini" :rules="rules" style="width: 700px">
        <el-form-item label="Источник:" prop="Source">
            <el-select style="width: 300px;" size="mini" v-model="searchParams.Source" placeholder="Выберите из списка" @change="selectSource">
                <el-option v-for="item in sourceDict" :key="item.Key" :label="item.Name" :value="item.Key">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="Название метода:" prop="MethodName">
            <el-select style="width: 300px;" size="mini" v-model="searchParams.MethodName" placeholder="Выберите из списка" >
                <el-option v-for="item in logMethods" :key="item.Key" :label="item.Name" :value="item.Name">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="Дата лога" prop="LogDate">
            <div style="display: inline">С</div>
            <el-date-picker size="mini" v-model="searchParams.LogDateFrom" type="date" placeholder="Дата с" format="dd.MM.yyyy" value-format="yyyy-MM-dd"></el-date-picker>
            <div style="display: inline">По</div>
            <el-date-picker size="mini" v-model="searchParams.LogDateTo" type="date" placeholder="Дата по" format="dd.MM.yyyy" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="Тип лога" prop="LogType">
            <el-select style="width: 300px;" size="mini" v-model="searchParams.LogType" placeholder="Выберите из списка">
                <el-option v-for="item in typeLogDict" :key="item.Key" :label="item.Name" :value="item.Key">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="Содержит" prop="Contain">
            <el-input size="mini" v-model="searchParams.Contain"></el-input>
        </el-form-item>
    </el-form>
    <el-button style="margin: 10px" type="primary" v-loading.fullscreen.lock="fullScreenLoading" size="mini" @click="searchLogs">Поиск</el-button>
    <el-table :data="searchData" border size="mini">
        <el-table-column label="Номер" prop="Num" header-align="center" align="center" width="70"></el-table-column>        
        <el-table-column prop="LogDate" label="Дата лога" header-align="center" align="center" width="200"></el-table-column>
        <el-table-column prop="LogType" label="Тип лога" header-align="center" align="center" width="100">
            <template slot-scope="scope">
                <div>{{getLogTypeName(scope.row.LogType)}}</div>
            </template>
        </el-table-column>
        <el-table-column prop="LogText" label="Текст" header-align="center" align="center">
            <template slot-scope="scope">
                <div class="grid" style="text-align: center" :title=scope.row.LogText>{{ scope.row.LogText.substring(0,250) }}</div>
            </template>
        </el-table-column>
        <el-table-column header-align="center" align="center" width="130">
            <template slot-scope="scope">
                <el-button style="margin: 10px" icon="el-icon-download" type="primary" size="mini" @click="downloadLog(scope.row.LogText, scope.row.FileName, scope.row.ContentType)">Скачать</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-pagination background layout="total,prev, pager, next" :current-page.sync="currentPage" :page-size="numElements" :total="countElements"  @current-change="searchLogs">
    </el-pagination>
</div>
</template>

<script>
export default {
  name: "Logs",
  data:function() {
            return {
                searchParams: {
                    Source: null,
                    MethodName: null,
                    LogDateFrom: null,
                    LogDateTo: null,
                    LogType: 0,
                    Contain: ''
                },
                currentPage:1,
                numElements: 20,
                countElements: 0,
                searchData: [],
                sourceDict: [
                    {
                        Key: 1, Name: 'SincAPI', Methods: [
                            { Key: 1, Name: 'RegisterOrderExtended' },
                            { Key: 2, Name: 'UpdateOrderExtended' },
                            { Key: 3, Name: 'RefuseOrder' },
                            { Key: 4, Name: 'SetOrderParams' },
                            { Key: 5, Name: 'SetOrderBarcodes' },
                            { Key: 6, Name: 'GetOrderInf' },
                            { Key: 7, Name: 'SetOrderIDAdditional' },
                            { Key: 8, Name: 'AddCarriage' },
                            { Key: 9, Name: 'GetRefusedOrderItems' },
                            { Key: 10, Name: 'CreatePickingTask' }
                        ]
                    },
                    {
                        Key: 2, Name: 'YandexAPI', Methods: [
                            { Key: 1, Name: 'createOrder' },
                            { Key: 2, Name: 'updateOrder' },
                            { Key: 3, Name: 'cancelOrder' },
                            { Key: 4, Name: 'getOrder' },
                            { Key: 5, Name: 'getReferencePickupPoints' },
                            { Key: 6, Name: 'getReferenceServices' },
                            { Key: 7, Name: 'putReferenceServices' },
                            { Key: 8, Name: 'getReferenceWarehouses' },
                            { Key: 9, Name: 'putReferenceWarehouses' },
                            { Key: 10, Name: 'createRegister' },
                            { Key: 11, Name: 'createIntake' },
                            { Key: 12, Name: 'createSelfExport' },
                            { Key: 13, Name: 'getOrderHistory' },
                            { Key: 14, Name: 'getOrdersStatus' },
                            { Key: 15, Name: 'getAttachedDocs' },
                            { Key: 16, Name: 'getLabels' },
                            { Key: 17, Name: 'getTransactionsOrders' },
                            { Key: 18, Name: 'getTransactionsIntakes' },
                            { Key: 19, Name: 'getOrdersDeliveryDate' },
                            { Key: 20, Name: 'getReferenceTimetableCouriers' }
                            ]
                    },
                    {
                        Key: 3, Name: 'KorablikAPI', Methods: [
                            { Key: 1, Name: 'invoice' },
                            { Key: 2, Name: 'tracking' },
                            { Key: 3, Name: 'return' },
                            { Key: 4, Name: 'cancel' }
                            ]
                    },
                    {
                        Key: 4, Name: 'OzonAPI', Methods: [
                            { Key: 1, Name: 'PostingSend' }
                            ]
                    },
                    {
                        Key: 5, Name: 'LMAPI', Methods: [
                            { Key: 1, Name: 'createOrder' },
                            { Key: 2, Name: 'updateOrder' },
                        ]
                    }
                ],
                typeLogDict:[
                    { Key: 0, Name: "Все" },
                    { Key: 1, Name: "Запрос" },
                    { Key: 2, Name: "Ответ" }
                ],
                fullScreenLoading: false,
                rules: {
                    ShopName: [{required: true,message: 'Введите название магазина',trigger: 'blur'}],
                    ShopTitle: [{ required: true, message: 'Введите название для бланка', trigger: 'blur' }],
                    ShopJurFace: [{ required: true, message: 'Введите юр.лицо', trigger: 'blur' }],
                    ShopPhone: [{ required: true, message: 'Введите телефон', trigger: 'blur' }],
                    Sorting: [{ required: true, message: 'Введите индекс сортировки', trigger: 'blur' }]
                }
            }
        },
        computed: {
            logMethods() {
                if (this.searchParams.Source) {
                    var methods = this.sourceDict.filter(source => source.Key === this.searchParams.Source);
                    return methods[0].Methods;
                }
                return null;
            }
        },
        methods: {
            downloadLog(item, fileName, contentType) {

                fileName = fileName ?? 'LogServ.txt';
                contentType = contentType ?? 'text/plain';

                var element = document.createElement('a');
                element.setAttribute('href', 'data:'+ contentType + ';charset=utf-8,' + encodeURIComponent(item));
                element.setAttribute('download', fileName);
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);

                //var bytes = new Uint8Array(item);
                //var blob = new Blob([bytes], { type: 'application/octet-binary' });

                //var link = document.createElement('a');
                //link.href = window.URL.createObjectURL(blob);
                //var fileName = "LogServ.txt";
                //link.download = fileName;
                //link.click();
            },
            getLogTypeName(type) {
                return this.typeLogDict.filter(item => item.Key === type)[0].Name;
             },
            selectSource(key) {
                this.searchParams.MethodName = null;
            },
            startLoading() {
                this.fullScreenLoading = true;
            },
            stopLoading() {
                this.fullScreenLoading = false;
            },
            searchLogs() {
                var self = this;
                self.startLoading();
                this.axios.post('/Logs/getLogsData', {
                    searchParams: self.searchParams,
                    firstElement: (self.currentPage - 1) * self.numElements,
                    numElements:self.numElements
                    })
                    .then(function (response) {
                        if (response.data.Success) {
                            self.searchData = response.data.Data.Data;
                            self.countElements = response.data.Data.Count;
                        } else {
                            console.log("Произошла ошибка при загрузке");
                        }
                        self.stopLoading();
                    })
                    .catch(function (error) {
                        console.log(error);
                        self.stopLoading();
                    });
            }
        },
        mounted: function () {

        }
  }
  </script>