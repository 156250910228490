<template>
  <div>
    <el-table :data="tableData" size="mini" align="center" v-loading="loading">
      <el-table-column prop="modifyDate" label="Дата" align="center">
      </el-table-column>
      <el-table-column prop="Message" label="Статус" align="center">
      </el-table-column>
      <el-table-column prop="StatusCode" label="Код статуса" align="center">
      </el-table-column>
      <el-table-column prop="setDate" label="Время события" align="center">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "OrderComponentMapHistory",
  props: ["orderid"],
  data: function () {
    return {
      tableData: [],
      loading: false,
    };
  },
  methods: {
    refresh: function () {
      this.loadData(this.orderid);
    },
    loadData: function (orderId) {
      var self = this;
      self.loading = true;
      this.axios
        .post("/OrderEdit/getYandexStatusHistory", {
          orderId: orderId,
        })
        .then(function (response) {
          self.tableData = response.data.Data;
          self.loading = false;
        })
        .catch(function (error) {
          self.loading = false;
          console.log(error);
        });
    },
  },
  watch: {
    orderid: function (newVal, oldVal) {
      if (newVal > 0) {
        //this.loadData(newVal);
      }
    },
  },
  mounted: function () {
    this.loadData(this.orderid);
  },
};
</script>

<style scoped>
</style>
