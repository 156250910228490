<template>
  <div>
    <el-form
      v-loading="fullScreenLoading"
      size="mini"
      ref="shopSystemSettings"
      :model="shopSystemSettings"
      label-width="300px"
    >
      <el-button
        size="mini"
        @click="saveShopSystStgs"
        type="success"
        style="margin: 15px"
        >Сохранить</el-button
      >
      <el-form-item label="Использование механизма загрузки" class="panel-cell">
        <el-checkbox
          v-model="shopSystemSettings.DownloadFileUse"
          @change="onShopSystFileUse"
          size="mini"
        ></el-checkbox>
        <el-select
        v-loading="loadingList"
          v-if="shopSystemSettings.DownloadFileUse"
          v-model="shopSystemSettings.FileUploadTemplateId"
          clearable
        >
          <el-option
            v-for="item in shopFileTemplatesList"
            :key="item.Id"
            :label="item.FileName"
            :value="item.Id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Cоздавать ШК грузовых мест" class="panel-cell">
        <el-checkbox
          v-model="shopSystemSettings.GenerateBarcodes"
          size="mini"
        ></el-checkbox>
      </el-form-item>
      <el-form-item label="Доступ для получателя по номеру заказа" class="panel-cell">
        <el-checkbox
          v-model="shopSystemSettings.AccessByOrderId"
          size="mini"
        ></el-checkbox>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "ShopSystemSettings",
  data: function () {
    return {
      fullScreenLoading: false,
      loadingList: false,
      shopSystemSettings: {
        DownloadFileUse: null,
        FileUploadTemplateId: null,
        GenerateBarcodes: false,
        AccessByOrderId: false
      },
      shopFileTemplatesList: [],
    };
  },
  methods: {
    onShopSystFileUse(val) {
      if (!val) {
        this.shopSystemSettings.FileUploadTemplateId = null;
      }
    },
    startLoadingList(){
      this.loadingList = true;

    },
    stopLoadingList(){
      this.loadingList = false;
    },
    startLoading() {
      this.fullScreenLoading = true;
    },
    stopLoading() {
      this.fullScreenLoading = false;
    },
    saveShopSystStgs() {
      if (!this.shopSystemSettings.DownloadFileUse) {
        this.shopSystemSettings.FileUploadTemplateId = null;
      }
      var self = this;
      var settingID = this.$route.query.partnerID;
      self.axios
        .post("/ShopEdit/saveShopSystStgs", {
          settingId: settingID,
          DownloadFileUse: this.shopSystemSettings.DownloadFileUse,
          FileUploadTemplateId: this.shopSystemSettings.FileUploadTemplateId,
          GenerateBarcodes: this.shopSystemSettings.GenerateBarcodes,
          AccessByOrderId: this.shopSystemSettings.AccessByOrderId
        })
        .then(function (response) {
          self.shopSystemSettings = response.data.Data;
          self.showSuccess("Изменения сохранены");
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    loadShopFileTemplatesList(){
      var self = this;
      var settingID = this.$route.query.partnerID;   
      this.startLoadingList();
      self.axios
        .post("/ShopEdit/getDictionaries", {
          shopId: settingID,
        })
        .then(function (response) {
          self.stopLoadingList();
          self.shopFileTemplatesList = response.data.ShopFileTemplatesList;
        })
        .catch(function (error) {
          self.stopLoadingList();
          console.log(error);
        });

    },
    loadData() {
      var self = this;
      var settingID = this.$route.query.partnerID;          
      this.startLoading();
      self.axios
        .post("/ShopEdit/getShopSystStgs", {
          SettingId: settingID,
        })
        .then(function (response) {
          self.stopLoading();
          self.shopSystemSettings = response.data.Data;
        })
        .catch(function (error) {
          self.stopLoading();
          console.log(error);
        });
    },
    showSuccess(message) {
      this.$message({
        message: message,
        type: "success",
      });
    },
    showError(message) {
      this.$message.error(message);
    },
  },
  mounted: function () {
    this.loadShopFileTemplatesList();
    this.loadData();
  },
};
</script>

<style scoped>
</style>
