<template>
  <div id="app">
    <SignIn v-if="!isLoggedIn" />
    <Main v-if="isLoggedIn" />
  </div>
</template>

<script>
import Main from "./components/Main.vue";
import SignIn from "./components/SignIn.vue";

export default {
  name: "app",
  components: {
    Main,
    SignIn,
  },
  methods: {
    startLoading() {
      this.$loading();
    },
    stopLoading() {
      this.$loading().close();
    },
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
  },
  mounted: function () {
    this.startLoading();
    let self = this;
    this.$store.dispatch("checkAuthorize", { })
      .then(() => {
        self.stopLoading();
      })
      .catch((err) => {
        self.stopLoading();
      });
  },
};
</script>

<style>
#app {
}
</style>
