<template>
  <div class="shipment">
    <div style="display: grid">
      <div>
        <h3 style="margin:5px;">Параметры поиска заказов для отгрузки</h3>
      </div>
      <div>
        <el-form size="mini" label-width="200px">
          <div class="cols">
            <div class="col4">
              <el-form-item prop="shopMainId" label="Дата доставки с:">
                <el-date-picker
                  size="mini"
                  style="width: 125px"
                  v-model="searchSettings.DeliveryDateFrom"
                  type="date"
                  :picker-options="delivDatePickOpts"
                  format="dd.MM.yyyy"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
                <div style="display: inline">по</div>
                <el-date-picker
                  size="mini"
                  style="width: 125px"
                  v-model="searchSettings.DeliveryDateTo"
                  type="date"
                  :picker-options="delivDatePickOpts"
                  format="dd.MM.yyyy"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
              <el-form-item prop="shopMainId" label="Интернет-магазин:">
                <ShopsListControl
                  v-model="searchSettings.shop"
                  :allOption="false"
                  :defExpandLevel="0"
                ></ShopsListControl>
              </el-form-item>
              <el-form-item prop="shopMainId" label="Регион приемки:">
                <SourceTerminalControl
                  v-model="searchSettings.SourcePlaceId"
                  :useAllOpt="true"
                ></SourceTerminalControl>
              </el-form-item>
              <el-form-item prop="shopMainId" label="Регион доставки:">
                <DeliveryTerminalControl
                  v-model="searchSettings.DeliveryPlaceId"
                  :useAllOpt="true"
                ></DeliveryTerminalControl>
              </el-form-item>
            </div>
            <div class="col4">
              <el-form-item label="Формат этикетки:">
                <el-select
                  size="mini"
                  v-model="markingPrint"
                  placeholder="Выберите из списка"
                >
                  <el-option
                    v-for="item in MarkingPrintDict"
                    :key="item.Id"
                    :label="item.Name"
                    :value="item.Id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
        </el-form>
      </div>
      <div style="padding: 5px">
        <el-button
          size="mini"
          @click="getData"
          :disabled="!searchSettings.shop || !searchSettings.DeliveryDateTo"
          >Поиск</el-button
        >
        <el-button v-show="!SearchShipMode" size="mini" @click="hideUncheck"
          >Оставить отмеченные</el-button
        >
        <el-button
          v-show="!SearchShipMode"
          :disabled="
            searchSettings.SearchByOrderLst ||
            !searchSettings.shop ||
            !AnyOrderIsSelect
          "
          size="mini"
          @click="createCarriage"
          >Произвести отгрузку</el-button
        >
        <el-button
          v-show="!SearchShipMode"
          :disabled="!carriageId"
          size="mini"
          @click="getCarriageReport"
          >Скачать АПП</el-button
        >
        <el-button
          v-show="!SearchShipMode"
          :disabled="!AnyOrderIsSelect"
          size="mini"
          @click="getGoodsReport"
          >Сформировать наборную ведомость</el-button
        >
        <el-button
          v-show="!SearchShipMode"
          :disabled="!AnyOrderIsSelect"
          size="mini"
          @click="getOrdersMarking"
          >Сформировать этикетки</el-button
        >
        <el-button
          v-show="!SearchShipMode"
          size="mini"
          @click="setSearchShipMode(true)"
          >Искать отгруженные</el-button
        >
        <el-button
          v-show="SearchShipMode"
          size="mini"
          @click="setSearchShipMode(false)"
          >Искать неотгруженные</el-button
        >
        <el-button
          :disabled="!searchSettings.shop"
          size="mini"
          @click="showAllApp(true)"
          >Все АПП</el-button
        >
      </div>
    </div>
    <el-table
      size="mini"
      :data="searchData"
      border
      @selection-change="handleSelectionChange"
      ref="dataTable"
    >
      <el-table-column
        type="selection"
        width="40"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="OrderId"
        label="№ Стриж"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          <a
            :href="getHref(scope.row.OrderId, scope.row.TypeDelivery)"
            target="_blank"
            >{{ scope.row.OrderId }}</a
          >
        </template>
      </el-table-column>
      <el-table-column prop="OrderIdImport" label="№ Магазин" align="center">
      </el-table-column>
      <el-table-column
        prop="DeliveryDate"
        label="Дата"
        align="center"
        width="80"
      >
        <template slot-scope="scope">
          <div>{{ getStringDate(scope.row.DeliveryDate) }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="DeliveryPlaceId"
        label="Регион доставки"
        align="center"
        width="80"
      >
        <template slot-scope="scope">
          <div>{{ getDelivPlaceName(scope.row.DeliveryPlaceId) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="UserName" label="ФИО" align="center" width="180">
      </el-table-column>
      <el-table-column
        prop="UserAddress"
        label="Адрес"
        align="center"
        width="180"
      >
      </el-table-column>
      <el-table-column
        prop="TypeDelivery"
        label="Тип доставки"
        align="center"
        width="100"
      >
        <template slot-scope="scope">
          <div>{{ getTypeDelivName(scope.row.TypeDelivery) }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="PiecesOfFreight"
        label="Количество мест"
        align="center"
        width="240"
      >
        <template v-slot:header style="padding: 0">
          <div style="display: inline; padding: 0 5px">Количество мест</div>
          <el-button size="mini" @click="resetPieces">Сбросить</el-button>
        </template>
        <template slot-scope="scope">
          <el-button
            :disabled="isPiecesCanEdit(scope.row)"
            v-if="!SearchShipMode || searchSettings.SearchByOrderLst"
            v-show="!scope.row.IsEditPieces"
            size="mini"
            @click="editPieces(scope.row, true)"
            >Изменить</el-button
          >
          <el-button
            v-show="scope.row.IsEditPieces"
            size="mini"
            @click="savePieces(scope.row)"
            >Сохранить</el-button
          >
          <el-button
            v-show="scope.row.IsEditPieces"
            size="mini"
            @click="cancelPieces(scope.row)"
            >Отмена</el-button
          >
          <el-input-number
            style="width: 30px"
            size="mini"
            :disabled="!scope.row.IsEditPieces"
            v-model="scope.row.PiecesOfFreight"
            controls-position="right"
            v-bind:controls="false"
          ></el-input-number>
        </template>
      </el-table-column>
      <el-table-column prop="PayType" label="Тип оплаты" align="center">
        <template slot-scope="scope">
          <div>{{ getPayTypeName(scope.row.PayType) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="Sum" label="Итого" align="center" width="60px">
      </el-table-column>
      <el-table-column prop="IsShipment" label="Статус" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.IsShipment ? "Отгружен" : "Не отгружен" }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="Who" label="Печать" align="center">
        <template slot-scope="scope">
          <el-button size="mini" @click="getOrderMarking(scope.row)"
            >Печать</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        v-if="!SearchShipMode"
        prop="IsCollect"
        label="Собран"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            size="mini"
            v-show="scope.row.IsCollect"
            type="success"
            icon="el-icon-check"
            circle
            @click="setIsCollect(scope.row)"
          ></el-button>
          <el-button
            size="mini"
            v-show="!scope.row.IsCollect"
            type="danger"
            icon="el-icon-close"
            circle
            @click="setIsCollect(scope.row)"
          ></el-button>
        </template>
      </el-table-column>
      <el-table-column
        v-if="SearchShipMode"
        prop="ShipmentNumber"
        label="Номер АПП"
        align="center"
      >
        <template slot-scope="scope">
          <div>{{ scope.row.ShipmentNumber }}</div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      width="100%"
      :center="true"
      title="Акты приема передачи"
      :visible.sync="visualSettings.isShowAllApp"
    >
      <span style="text-align: center">
        <el-table
          size="mini"
          :data="appHistory"
          border
          @expand-change="onExpandAppHist"
        >
          <el-table-column type="expand">
            <template slot-scope="scope">
              <table style="width: 100%">
                <tr>
                  <th>Номер заказа внутренний</th>
                  <th>Номер заказа внешний</th>
                </tr>
                <tr v-for="item in getAppInf(scope.row.id)" :key="item.id">
                  <td>
                    <a
                      :href="getHref(item.orderId, item.selfDelivery)"
                      target="_blank"
                      >{{ item.orderId }}</a
                    >
                  </td>
                  <td>{{ item.orderIdImport }}</td>
                </tr>
              </table>
            </template>
          </el-table-column>
          <el-table-column
            prop="shipmentNumber"
            label="Номер АПП"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="dateShipment"
            label="Дата"
            align="center"
          ></el-table-column>
          <el-table-column label="" align="center">
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="getCarriageReportById(scope.row.id)"
                >Печать</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ShopsListControl from "./controls/ShopsListControl.vue";
import DeliveryTerminalControl from "./controls/DeliveryTerminalControl.vue";
import SourceTerminalControl from "./controls/SourceTerminalControl.vue";
import { dates } from "../scripts/dates";
export default {
  name: "Shipment",
  components: {
    ShopsListControl,
    DeliveryTerminalControl,
    SourceTerminalControl,
  },
  data: function () {
    let tomorrow = new Date();
    let today = new Date(
      tomorrow.getFullYear(),
      tomorrow.getMonth(),
      tomorrow.getDate()
    );
    tomorrow.setDate(today.getDate() + 1);
    tomorrow = new Date(
      tomorrow.getFullYear(),
      tomorrow.getMonth(),
      tomorrow.getDate()
    );

    let checkDeliveryDate = (date) => {
      return date < today;
    };
    return {
      searchSettings: {
        DeliveryDateFrom: tomorrow,
        DeliveryDateTo: null,
        SourcePlaceId: "All",
        DeliveryPlaceId: "All",
        shop: null,
        SearchShipOrders: false,
        SearchByOrderLst: false,
      },
      visualSettings: {
        isShowAllApp: false,
      },
      carriageId: null,
      multipleSelection: [],
      searchData: [],
      appHistory: [],
      appHistoryInf: {},
      delivDatePickOpts: {
        disabledDate: checkDeliveryDate,
        firstDayOfWeek: 1,
      },
      markingPrint: null,
      DeliveryPlaceDict: {},
      PayTypeDict: {},
      DelivTypeDict: {},
      MarkingPrintDict: {},
    };
  },
  computed: {
    SearchShipMode() {
      return this.searchSettings.SearchShipOrders;
    },
    AnyOrderIsSelect() {
      return this.multipleSelection && this.multipleSelection.length > 0;
    },
  },
  methods: {
    isPiecesCanEdit(item) {
      return item.StockDate;
    },
    resetPieces() {
      let self = this;
      self.startLoading();
      this.axios
        .post("/Shipment/setOrdersPlaces", {
          orders: this.multipleSelection
            .filter((item) => !item.StockDate)
            .map(function (item) {
              return item.OrderId;
            }),
        })
        .then((resp) => {
          let dict = {};
          resp.data.Data.forEach((item) => {
            dict[item] = true;
          });
          this.multipleSelection.forEach(function (item) {
            if (dict[item.OrderId]) {
              item.PiecesOfFreight = 1;
            }
          });
          self.stopLoading();
        })
        .catch((e) => {
          self.stopLoading();
          console.log(e);
          self.showError("Произошла ошибка");
        });
    },
    getHref(orderId, type) {
      let href = this.$helpMethods.getOrderHref(orderId, type);
      return href;
    },
    getAppInf(shipId) {
      if (this.appHistoryInf[shipId]) {
        return this.appHistoryInf[shipId];
      }
      return [];
    },
    onExpandAppHist(row) {
      let self = this;
      self.startLoading();
      this.axios
        .post("/Shipment/loadOrders", {
          shipmentID: row.id,
        })
        .then((resp) => {
          self.stopLoading();
          this.$set(self.appHistoryInf, row.id, resp.data.Data);
        })
        .catch((e) => {
          self.stopLoading();
          console.log(e);
          self.showError("Произошла ошибка");
        });
    },
    showAllApp(isShow) {
      if (isShow && this.searchSettings.shop) {
        let self = this;
        self.startLoading();
        this.axios
          .post("/Shipment/getAppHistory", {
            internetShopID: this.searchSettings.shop,
          })
          .then((resp) => {
            self.stopLoading();
            self.appHistory = resp.data.Data;
            self.visualSettings.isShowAllApp = isShow;
          })
          .catch((e) => {
            self.stopLoading();
            console.log(e);
            self.showError("Произошла ошибка");
          });
      } else {
        this.visualSettings.isShowAllApp = isShow;
      }
    },
    setSearchShipMode(val) {
      this.searchSettings.SearchShipOrders = val;
      this.searchData = [];
    },
    getCarriageReport() {
      this.getCarriageReportById(this.carriageId);
    },
    getCarriageReportById(carriageId) {
      if (carriageId) {
        let self = this;
        this.axios
          .post("/Shipment/getCarriageReport", {
            carriageId: carriageId,
          })
          .then((resp) => {
            self.$helpMethods.downloadBase64Doc(
              resp.data.Data.FileName,
              resp.data.Data.FileData
            );
          })
          .catch((e) => {
            console.log(e);
            self.showError("Произошла ошибка");
          });
      }
    },
    getGoodsReport() {
      let self = this;
      this.axios
        .post("/Shipment/getGoodsReport", {
          orders: this.multipleSelection.map(function (item) {
            return item.OrderId;
          }),
        })
        .then((resp) => {
          self.$helpMethods.downloadBase64Doc(
            resp.data.Data.FileName,
            resp.data.Data.FileData
          );
        })
        .catch((e) => {
          console.log(e);
          self.showError("Произошла ошибка");
        });
    },
    createCarriage() {
      let self = this;
      this.$confirm("Сформировать документ отгрузки?", "Предупреждение", {
        confirmButtonText: "Да",
        cancelButtonText: "Отмена",
        type: "warning",
      }).then(() => {
        self.startLoading();
        this.axios
          .post("/Shipment/createCarriage", {
            orders: this.multipleSelection.map(function (item) {
              return item.OrderId;
            }),
            shopId: this.searchSettings.shop,
          })
          .then((resp) => {
            self.stopLoading();
            if (resp.data.Error) {
              self.showError(resp.data.Error);
              return;
            }
            self.carriageId = resp.data.Data;
            self.searchSettings.SearchByOrderLst = true;
            self.getData();
          })
          .catch((e) => {
            self.stopLoading();
            console.log(e);
            self.showError("Произошла ошибка");
          });
      });
    },
    getOrdersMarking() {
      if (!this.markingPrint) {
        this.showError("Не выбран формат печати");
      } else {
        let self = this;
        this.axios
          .post("/Shipment/getOrdersMarking", {
            orders: this.multipleSelection.map(function (item) {
              return item.OrderId;
            }),
            templateId: self.markingPrint,
          })
          .then((resp) => {
            self.$helpMethods.downloadBase64Doc(
              resp.data.Data.FileName,
              resp.data.Data.FileBody
            );
          })
          .catch((e) => {
            console.log(e);
            self.showError("Произошла ошибка");
          });
      }
    },
    getOrderMarking(row) {
      if (!this.markingPrint) {
        this.showError("Не выбран формат печати");
      } else {
        let self = this;
        this.axios
          .post("/Shipment/getOrderMarking", {
            orderId: row.OrderId,
            templateId: self.markingPrint,
          })
          .then((resp) => {
            self.$helpMethods.downloadBase64Doc(
              resp.data.Data.FileName,
              resp.data.Data.FileBody
            );
          })
          .catch((e) => {
            console.log(e);
            self.showError("Произошла ошибка");
          });
      }
    },
    editPieces(row, isEdit) {
      this.$set(row, "IsEditPieces", isEdit);
    },
    savePieces(row) {
      this.editPieces(row, false);
      this.setOrderPlaces(row);
    },
    cancelPieces(row) {
      this.editPieces(row, false);
      this.getOrderData(row);
    },
    hideUncheck() {
      this.searchData = this.multipleSelection;
      this.$nextTick(() => {
        this.$refs.dataTable.toggleAllSelection();
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getFilter() {
      let filter = {};
      if (this.searchSettings.shop != null) {
        filter.ShopId = this.searchSettings.shop;
      }

      filter.DeliveryDateFrom = this.searchSettings.DeliveryDateFrom;
      filter.DeliveryDateTo = this.searchSettings.DeliveryDateTo;
      if (this.searchSettings.SourcePlaceId > 0)
        filter.SourcePlaceId = this.searchSettings.SourcePlaceId;
      if (this.searchSettings.DeliveryPlaceId > 0)
        filter.DeliveryPlaceId = this.searchSettings.DeliveryPlaceId;
      filter.SearchShipOrders = this.searchSettings.SearchShipOrders;

      if (this.searchSettings.SearchByOrderLst) {
        filter.SearchByOrderLst = this.searchSettings.SearchByOrderLst;
        filter.Orders = this.multipleSelection.map(function (item) {
          return item.OrderId;
        });
      }

      return filter;
    },
    getData() {
      let self = this;
      self.startLoading();
      this.axios
        .post("/Shipment/getData", {
          searchParams: self.getFilter(),
        })
        .then((resp) => {
          self.stopLoading();
          self.searchSettings.SearchByOrderLst = false;
          self.searchData = resp.data.Data;
        })
        .catch((e) => {
          self.stopLoading();
          console.log(e);
          self.showError("Произошла ошибка");
        });
    },
    setIsCollect(row) {
      let self = this;
      row.IsCollect = !row.IsCollect;
      this.axios
        .post("/Shipment/setOrderFinish", {
          orderID: row.OrderId,
          finish: row.IsCollect,
        })
        .then((resp) => {})
        .catch((e) => {
          console.log(e);
          self.showError("Произошла ошибка");
        });
    },
    setOrderPlaces(row) {
      let self = this;
      self.startLoading();
      this.axios
        .post("/Shipment/setOrderPlaces", {
          orderID: row.OrderId,
          piecesOfFreight: row.PiecesOfFreight,
        })
        .then((resp) => {
          row.PiecesOfFreight = resp.data.Data.PiecesOfFreight;
          self.stopLoading();
        })
        .catch((e) => {
          self.stopLoading();
          console.log(e);
          self.showError("Произошла ошибка");
        });
    },
    getOrderData(row, index) {
      let self = this;
      self.startLoading();
      this.axios
        .post("/Shipment/getOrderData", {
          orderID: row.OrderId,
        })
        .then((resp) => {
          row.PiecesOfFreight = resp.data.Data.PiecesOfFreight;
          self.stopLoading();
        })
        .catch((e) => {
          self.stopLoading();
          console.log(e);
          self.showError("Произошла ошибка");
        });
    },
    getPayTypeName(payTypeId) {
      return this.PayTypeDict[payTypeId];
    },
    getTypeDelivName(typeDelivId) {
      return this.DelivTypeDict[typeDelivId];
    },
    getDelivPlaceName(delivPlaceId) {
      return this.DeliveryPlaceDict[delivPlaceId];
    },
    getStringDate(dat) {
      return dates.methods.toStringDate(dat);
    },
    loadSimpleDictionaries(resp) {
      this.DeliveryPlaceDict = resp.data.DeliveryTerminalsList.Dir.reduce(
        function (result, item) {
          result[item.Key] = item.Name;
          return result;
        },
        {}
      );
      this.PayTypeDict = resp.data.PayTypeList.Dir.reduce(function (
        result,
        item
      ) {
        result[item.Key] = item.Name;
        return result;
      },
      {});
      this.DelivTypeDict = resp.data.SelfDeliveryList.Dir.reduce(function (
        result,
        item
      ) {
        result[item.Key] = item.Name;
        return result;
      },
      {});
      this.MarkingPrintDict = resp.data.MarkingPrintList;
    },
    startLoading() {
      this.$loading();
    },
    stopLoading() {
      this.$loading().close();
    },
    showSuccess(message) {
      this.$message({
        message: message,
        type: "success",
      });
    },
    showError(message) {
      this.$message.error(message);
    },
  },
  mounted: function () {
    let self = this;
    self.startLoading();
    let dictRequest = this.axios.post("/Shipment/getDictionaries", {});
    this.axios
      .all([dictRequest])
      .then(
        self.axios.spread((...responses) => {
          self.stopLoading();
          self.loadSimpleDictionaries(responses[0]);
        })
      )
      .catch((e) => {
        self.stopLoading();
        self.showError("Произошла ошибка");
        console.log(e);
      });
  },
};
</script>

<style scoped>
.shipment >>> .cols {
}
.shipment >>> .col3 {
  width: 33%;
  float: left;
}
.shipment >>> .col4 {
  float: left;
  width: 25%;
}
</style>
