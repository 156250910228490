import Vue from 'vue';
import Vuex from 'vuex';
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(Vuex);
Vue.use(VueAxios,axios);


Vue.axios.defaults.baseURL = process.env.VUE_APP_BASEURL
if(localStorage.getItem('token')){
  Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
}

Vue.axios.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config;
    if (error.response.status !== 401) {
      return Promise.reject(error.response);
  }
  if (originalRequest.url === 'account/getAccessToken') {
           localStorage.removeItem('token');
           localStorage.removeItem('refreshToken');
           return Promise.reject(error);
    }   
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      return Vue.axios.post('account/getAccessToken',{
        "placeId":localStorage.getItem('currentLocation')
      },{ headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('refreshToken')
      } }).then(response =>{
        let token = response.data.access_token;
        localStorage.setItem('token',token);
        Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token; 
        originalRequest.headers['Authorization']='Bearer ' + token;
        return Vue.axios(originalRequest);
      }).catch(error => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        store.commit('logout');
        return Promise.reject(error);
         //.catch(err => console.log(err));
      });
    }   
    return Promise.reject(error);
  //Vue.axios.interceptors.response.eject(interceptor);
  }
 );

//createAxiosResponseInterceptor();

export const store = new Vuex.Store({
    state: {
      token: localStorage.getItem('refreshToken') || '',
      user : '',
      ruleSettings:{},
      pageSettings:{},
      currentLocation:1,
      organizationList:[],
      statusStateList:[],
      selfDeliveryList:[],
      statusStockList:[],
      statusLogisticList:[],
      transCancelReasonList:[],
      sourceTerminalsList:[],
      deliveryTerminalsList:[],
      payTypeList:[],
      systemSettings:{}
    },
    getters : {
      isLoggedIn: state => !!state.token,
      user:state => state.user,
      systemSettings:state => state.systemSettings,
      ruleSettings: state => state.ruleSettings,
      pageSettings: state => state.pageSettings,
      currentLocation: state => state.currentLocation,
      organizationList:state => state.organizationList,
      statusStateList:state => state.statusStateList,
      selfDeliveryList:state => state.selfDeliveryList,
      statusStockList:state => state.statusStockList,
      statusLogisticList: state => state.statusLogisticList,
      transCancelReasonList:state => state.transCancelReasonList,
      sourceTerminalsList:state=> state.sourceTerminalsList,
      deliveryTerminalsList:state=>state.deliveryTerminalsList,
      payTypeList:state=> state.payTypeList
    },
    mutations: {
      currentLocation(state,currentLocation){
        localStorage.setItem('currentLocation',currentLocation);
      },
      initialiseStore(state) {
        if (localStorage.getItem('userName')) {
          state.user = localStorage.getItem('userName');
        }
        if (localStorage.getItem('systemSettings')) {
          state.systemSettings = JSON.parse(localStorage.getItem('systemSettings'));
        }
        if (localStorage.getItem('ruleSettings')) {
          state.ruleSettings = JSON.parse(localStorage.getItem('ruleSettings'));
        }
        if (localStorage.getItem('pageSettings')) {
          state.pageSettings = JSON.parse(localStorage.getItem('pageSettings'));
        }
        if (localStorage.getItem('currentLocation')) {
          state.currentLocation = parseInt(localStorage.getItem('currentLocation'));
        }
        if(localStorage.getItem('organizationList')){
          state.organizationList = JSON.parse(localStorage.getItem('organizationList'));
        }
        if(localStorage.getItem('statusStateList')){
          state.statusStateList = JSON.parse(localStorage.getItem('statusStateList'));
        }
        if(localStorage.getItem('selfDeliveryList')){
          state.selfDeliveryList = JSON.parse(localStorage.getItem('selfDeliveryList'));
        }
        if(localStorage.getItem('statusStockList')){
          state.statusStockList = JSON.parse(localStorage.getItem('statusStockList'));
        }
        if(localStorage.getItem('statusLogisticList')){
          state.statusLogisticList = JSON.parse(localStorage.getItem('statusLogisticList'));
        }
        if(localStorage.getItem('transCancelReasonList')){
          state.transCancelReasonList = JSON.parse(localStorage.getItem('transCancelReasonList'));
        }
        if(localStorage.getItem('sourceTerminalsList')){
          state.sourceTerminalsList = JSON.parse(localStorage.getItem('sourceTerminalsList'));
        }
        if(localStorage.getItem('deliveryTerminalsList')){
          state.deliveryTerminalsList = JSON.parse(localStorage.getItem('deliveryTerminalsList'));
        }
        if(localStorage.getItem('payTypeList')){
          state.payTypeList = JSON.parse(localStorage.getItem('payTypeList'));
        }
      },
      auth_success(state, {token,refreshToken, userName}){
        state.token = token;
        state.user = userName;
        localStorage.setItem('token', token);
        localStorage.setItem('userName', userName);
        localStorage.setItem('refreshToken', refreshToken);
      },
      auth_error(state){

      },
      logout(state){
        state.token = '';
      },
      system_settings(state,stgs){
        state.systemSettings = stgs;
        localStorage.setItem('systemSettings',JSON.stringify(state.systemSettings));
      },
      rule_settings(state,rules){
        state.ruleSettings = rules;
        localStorage.setItem('ruleSettings',JSON.stringify(state.ruleSettings));
      },
      page_settings(state,pages){
        state.pageSettings = pages;
        localStorage.setItem('pageSettings',JSON.stringify(state.pageSettings));
      },
      setDictionaries(state,dicts){
        state.organizationList = dicts.OrganizationList.Dir;
        state.statusStateList = dicts.StatusStateList.Dir;
        state.selfDeliveryList = dicts.SelfDeliveryList.Dir;
        state.statusStockList = dicts.StatusStockList.Dir;
        state.statusLogisticList = dicts.StatusLogisticList.Dir;
        state.transCancelReasonList = dicts.TransCancelReasonList.Dir;
        state.sourceTerminalsList = dicts.SourceTerminalsList.Dir;
        state.deliveryTerminalsList = dicts.DeliveryTerminalsList.Dir;
        state.payTypeList = dicts.PayTypeList.Dir;
        localStorage.setItem('organizationList',JSON.stringify(state.organizationList));
        localStorage.setItem('statusStateList',JSON.stringify(state.statusStateList));
        localStorage.setItem('selfDeliveryList',JSON.stringify(state.selfDeliveryList));
        localStorage.setItem('statusStockList',JSON.stringify(state.statusStockList));
        localStorage.setItem('statusLogisticList',JSON.stringify(state.statusLogisticList));
        localStorage.setItem('transCancelReasonList',JSON.stringify(state.transCancelReasonList));
        localStorage.setItem('sourceTerminalsList',JSON.stringify(state.sourceTerminalsList));
        localStorage.setItem('deliveryTerminalsList',JSON.stringify(state.deliveryTerminalsList));
        localStorage.setItem('payTypeList',JSON.stringify(state.payTypeList));
      }
    },
    actions: {
      async getToken({commit},user){
        return new Promise((resolve, reject) => {
          Vue.axios({url: 'account/token', data: user, method: 'POST' })
          .then(resp => {
            if(resp.data.message){
              reject(resp);
            }
            else{
              let token;
        let refreshToken;
        let userName;
              token = resp.data.access_token;
              refreshToken = resp.data.refresh_token;
              userName = resp.data.username;
              Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
              commit('auth_success', {token,refreshToken,userName})
              resolve(resp);
            }
          })
          .catch(err =>{
            reject(err)
          })
        });
      },
      async getDictionaries({commit}){
        return new Promise((resolve, reject) => {
          Vue.axios({url: 'dictionary/getDictionaries', data: {}, method: 'POST' })
          .then(resp => {
            if(resp.data){
              commit("setDictionaries",resp.data);
              resolve(resp);
            }
          })
          .catch(err =>{
            reject(err)
          })
        });
      },
      async checkAuthorize(state) {
        let resp = await Vue.axios({url: 'account/checkAuthorize', data: {}, method: 'POST' });
      },
      async setUserLocation({commit},location){
        let resp;
        let token;
        try{
          resp = await Vue.axios({url: 'account/setUserLocation', data: {placeId:location}, method: 'POST' });
          commit('currentLocation',location);
          token = resp.data.access_token
            localStorage.setItem('token', token)
            Vue.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token            
        }
        catch(err){
          localStorage.removeItem('token')
          throw new Error(err);
        }        
      },     
      async login({commit,dispatch,getters},user){
        let data = user;
        data.placeId = getters.currentLocation;
        await dispatch('getToken',user);
        await dispatch('getRuleSettings');
        await dispatch('getPageAccessSettings');
        await dispatch('getDictionaries');
        await dispatch('getSystemSettings');
      },     
      logout({commit}){
          //todo удаление токена на сервере
        return new Promise((resolve, reject) => {
          commit('logout')
          localStorage.removeItem('token')
          localStorage.removeItem('refreshToken')
          delete Vue.axios.defaults.headers.common['Authorization']
          resolve()
        })
       },
       getSystemSettings({commit}){
        return new Promise((resolve, reject) => {
          Vue.axios({url: 'account/getSystemSettings', data: {}, method: 'POST' })
          .then(resp => {
            if(resp.data){
              commit("system_settings",resp.data);
              resolve(resp);
            }
          })
          .catch(err =>{
            reject(err)
          })
        });
       },
       getRuleSettings({commit}){
        return new Promise((resolve, reject) => {
          Vue.axios({url: 'account/getRuleSettings', data: {}, method: 'POST' })
          .then(resp => {
            if(resp.data){
              commit("rule_settings",resp.data);
              resolve(resp);
            }
          })
          .catch(err =>{
            reject(err)
          })
        });
       },
       getPageAccessSettings({commit}){
        return new Promise((resolve, reject) => {
          Vue.axios({url: 'account/getPageAccessSettings', data: {}, method: 'POST' })
          .then(resp => {
            if(resp.data){
              commit("page_settings",resp.data);
              resolve(resp);
            }
          })
          .catch(err =>{
            reject(err)
          })
        });
       }
    },
  })