<template>
  <div class="vueTemplate" v-if="!visualSettings.accessDenied">
    <div
      style="
        position: fixed;
        width: 100%;
        z-index: 101;
        background-color: white;
        max-height: 116px;
        top: 105px;
      "
    >
      <el-form
        :model="orderData"
        ref="orderDataIndicators"
        :rules="rules"
        size="mini"
      >
        <el-form-item
          style="min-width: 310px"
          label-width="150"
          prop="OrderType"
          label="Тип заявки"
        >
          <el-input
            v-model="orderTypeName"
            maxlength="100"
            style="max-width: 150px"
            readonly
          ></el-input>
          <el-button
            :disabled="!cancelOrdEnable"
            @click="showDialogCancelOrder(true)"
            size="mini"
            v-if="createdOrder"
            type="danger"
            >Отменить фрахт</el-button
          >
        </el-form-item>
        <div class="panel">
          <div class="panel-heading">Идентификаторы</div>
          <div
            class="panel-content"
            :class="orderData.RoutingOperator ? 'routingOperator' : ''"
          >
            <el-form-item
              class="panel-cell4"
              style="min-width: 310px"
              label-width="150"
              prop="OrderIdShow"
              label="Номер заказа Стриж"
            >
              <el-input
                class="input-field"
                v-model="OrderIdShow"
                maxlength="100"
                style="max-width: 100px"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item
              class="panel-cell4"
              style="min-width: 310px"
              label-width="150"
              prop="OrderIdImport"
              label="Номер заказа партнера"
            >
              <el-input
                class="input-field"
                v-model="orderData.OrderIdImport"
                maxlength="100"
                style="max-width: 150px"
                :disabled="!isEdit('OrderIdImport')"
              ></el-input>
            </el-form-item>
            <el-form-item
              class="panel-cell4"
              style="min-width: 280px"
              prop="shopMainId"
              label="Партнер"
              label-width="150"
            >
              <main-shop-control
                :isEnable="true"
                v-model="orderEditData.shopMainId"
                @change="handleMainShopSelect"
                :disabled="!isEdit('OrderIdImport')"
                :selectAny="!this.createdOrder"
                ref="mainShop"
              ></main-shop-control>
            </el-form-item>
            <el-form-item
              class="panel-cell4"
              style="min-width: 350px"
              label-width="150"
              prop="subShopId"
              label="Дочерняя организация"
              v-show="orderEditData.SubShopCnt > 0"
            >
              <sub-shop-control
                :isEnable="true"
                v-model="orderEditData.subShopId"
                @change="handleSubShopSelect"
                :disabled="!isEdit('OrderIdImport')"
                :selectAny="!this.createdOrder"
                :shopIdMain="orderEditData.shopMainId"
                @shopMainChange="handleShopMainChange"
                ref="subShop"
              ></sub-shop-control>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <div style="width: 40px">
      <el-menu
        :default-active="activeIndex"
        style="width: 40px; position: fixed; height: 100%; top: 221px"
        :collapse="true"
        background-color="#cfcfcf"
      >
        <el-menu-item index="2" @click="menuClick" style="text-align: center">
          <div class="el-icon-phone"></div>
          <span slot="title">Контактная информация</span>
        </el-menu-item>
        <el-menu-item index="4" @click="menuClick" style="text-align: center">
          <div class="el-icon-s-order"></div>
          <span slot="title">Информация о фрахте</span>
        </el-menu-item>
        <el-menu-item
          index="5"
          @click="menuClick"
          style="text-align: center"
          v-show="createdOrder"
        >
          <div class="el-icon-monitor"></div>
          <span slot="title">Информация стриж</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div
      style="
        text-align: center;
        position: fixed;
        bottom: 0;
        z-index: 101;
        width: 100%;
        background-color: white;
        max-height: 28px;
      "
    >
      <el-button v-if="!createdOrder" @click="saveOrder(1, false)" size="mini"
        >Сохранить как черновик</el-button
      >
      <el-button
        v-if="!createdOrder || draftOrder || editCommonMode"
        @click="saveOrder(2, true)"
        type="success"
        size="mini"
        >{{
          !createdOrder || draftOrder
            ? "Отправить заявку на фрахт"
            : "Сохранить"
        }}</el-button
      >
    </div>
    <div style="margin-left: 40px; margin-top: 221px; margin-bottom: 28px">
      <ScrollTo
        ref="orderUserInfo"
        v-on:anchor-shown="activeMenu"
        :anchor="2"
        :block="'center'"
      >
      </ScrollTo>
      <div id="scrollingHelper"></div>

      <div class="panel">
        <div class="panel-heading">
          <div style="display: inline">Контактная информация</div>
          <el-button
            v-show="
              createdOrder &&
              !editCommonMode &&
              !this.visualSettings.sectionContact &&
              !finishedOrder
            "
            icon="el-icon-edit"
            @click="editSection(1, true)"
            size="mini"
          ></el-button>
          <el-button
            v-show="
              createdOrder &&
              !editCommonMode &&
              this.visualSettings.sectionContact
            "
            @click="saveSectionContact()"
            size="mini"
            >Сохранить изменения</el-button
          >
          <el-button
            v-show="
              createdOrder &&
              !editCommonMode &&
              this.visualSettings.sectionContact
            "
            @click="cancelSectionContact()"
            size="mini"
            >Отменить изменения</el-button
          >
        </div>
        <div class="panel-content">
          <div style="display: inline-block; width: 50%; height: 100%">
            <el-form
              :model="orderData"
              ref="orderDataContact"
              :rules="rules"
              size="mini"
            >
              <div class="panel-cellV">
                <el-form-item
                  prop="FirstName"
                  label="Контактное лицо заказчика"
                  label-width="200px"
                >
                  <el-input
                    :disabled="!isEdit('Contact')"
                    class="input-field"
                    v-model="orderData.User.FirstName"
                    maxlength="1000"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="panel-cellV">
                <el-form-item
                  prop="UserTel"
                  label="Контактный тел. заказчика"
                  label-width="200px"
                >
                  <el-input
                    :disabled="!isEdit('Contact')"
                    class="input-field"
                    v-model="orderData.User.UserTel"
                    maxlength="100"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="panel-cellV">
                <el-form-item
                  prop="Email"
                  label="Почта заказчика"
                  label-width="200px"
                >
                  <el-input
                    :disabled="!isEdit('Contact')"
                    class="input-field"
                    v-model="orderData.User.Email"
                  ></el-input>
                </el-form-item>
              </div>
            </el-form>
            <el-form
              :model="orderData"
              ref="orderDataTerms"
              :rules="rules"
              size="mini"
            >
              <div class="panel-cell3">
                <el-form-item
                  prop="DeliveryPlaceId"
                  label="Регион работы"
                  label-width="200px"
                >
                  <delivery-terminal-control
                    v-model="orderData.DeliveryPlaceId"
                    :disabled="!isEdit('Contact')"
                    @change="onRegionSelect"
                  ></delivery-terminal-control>
                </el-form-item>
              </div>
            </el-form>
            <el-form
              :model="orderData"
              ref="orderDataAdr"
              :rules="rules"
              size="mini"
            >
              <div class="panel-cellV">
                <el-form-item
                  prop="FullAddress"
                  label="Адрес подачи авто"
                  label-width="200px"
                >
                  <el-autocomplete
                    :disabled="!isEdit('Contact')"
                    class="inline-input"
                    v-model="orderData.Address.FullAddress"
                    @input="onAddressChange"
                    placeholder="Введите адрес до корпуса, строения"
                    :fetch-suggestions="querySearch"
                    :trigger-on-focus="false"
                    value-key = "FullAddress"
                    @select="handleSelect"
                  ></el-autocomplete>
                </el-form-item>
              </div>
              <div class="panel-cellV">
                <el-form-item
                  prop="Address.Comment"
                  label="Комментарий к адресу"
                  label-width="200px"
                >
                  <el-input
                    :disabled="!isEdit('Contact')"
                    class="input-field"
                    v-model="orderData.Address.Comment"
                    maxlength="1000"
                  ></el-input>
                  <div></div>
                </el-form-item>
              </div>
            </el-form>
          </div>
          <div
            style="
              display: inline-block;
              margin: 10px;
              height: 230px;
              width: 45%;
            "
          >
            <yandex-map
              :coords="coords"
              zoom="15"
              :scroll-zoom="false"
              :options="yaMapOptions"
            >
              <ymap-marker
                :coords="coords"
                marker-id="123"
                marker-type="placemark"
              />
            </yandex-map>
            <el-form :model="orderData" size="mini">
              <div class="panel-cellV">
                <el-form-item
                  prop="geocordinates"
                  label="Геокоординаты"
                  label-width="200px"
                >
                  <el-button
                    v-if="
                      createdOrder &&
                      (!orderData.Address.Latitude ||
                        !orderData.Address.Longitude)
                    "
                    @click="setGeoCoords"
                    >Отобразить</el-button
                  >
                  <el-input
                    style="width: 200px"
                    :value="geocordinates"
                  ></el-input>
                  <el-tooltip
                    effect="dark"
                    content="Координаты переданы партнером"
                    placement="right"
                    ><i
                      v-show="
                        orderData.Address && orderData.Address.CoordByPartner
                      "
                      style="margin: 5px"
                      class="el-icon-warning-outline"
                    ></i
                  ></el-tooltip>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
      </div>
      <el-form
        :model="orderData"
        ref="orderDataInfo"
        :rules="rules"
        size="mini"
      >
        <ScrollTo
          ref="orderInfo"
          v-on:anchor-shown="activeMenu"
          :anchor="4"
          :block="'start'"
        >
        </ScrollTo>
        <div class="panel">
          <div class="panel-heading">
            <div style="display: inline">Информация о фрахте</div>
            <el-button
              v-show="
                createdOrder &&
                !editCommonMode &&
                !this.visualSettings.sectionOrder &&
                !finishedOrder &&
                this.orderData.LocationFlagId !== 3
              "
              icon="el-icon-edit"
              @click="editSection(5, true)"
              size="mini"
            ></el-button>
            <el-button
              v-show="
                createdOrder &&
                !editCommonMode &&
                this.visualSettings.sectionOrder
              "
              @click="saveSectionOrder()"
              size="mini"
              >Сохранить изменения</el-button
            >
            <el-button
              v-show="
                createdOrder &&
                !editCommonMode &&
                this.visualSettings.sectionOrder
              "
              @click="cancelSectionOrder()"
              size="mini"
              >Отменить изменения</el-button
            >
          </div>
          <div class="panel-content">
            <div class="cols">
              <div class="col2">
                <el-form-item
                  prop="DeliveryDate"
                  label="Дата фрахта"
                  label-width="230px"
                >
                  <el-date-picker
                    style="width: 125px"
                    v-model="orderData.DeliveryDate"
                    type="date"
                    :disabled="createdOrder"
                    :picker-options="deliveryDatePickerOptions"
                    format="dd.MM.yyyy"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                  <el-button
                    @click="showDialogDeliveryDate(true)"
                    size="mini"
                    v-if="
                      createdOrder &&
                      (delivDateEditable ||
                        this.$store.getters.ruleSettings
                          .IsAccessToEditOrderOnDeliv)
                    "
                    >Изменить</el-button
                  >
                  <el-dialog
                    width="30%"
                    :center="true"
                    title="Перенести фрахт?"
                    :visible.sync="visualSettings.dialogEditDeliveryDate"
                  >
                    <span style="text-align: center">
                      <div style="margin: 10px">
                        <el-button
                          @click="confirmChangeDeliveryDate"
                          size="mini"
                          >Перенести</el-button
                        >
                        <el-button
                          @click="showDialogDeliveryDate(false)"
                          size="mini"
                          >Нет</el-button
                        >
                      </div>
                      <div style="margin: 10px">
                        <el-date-picker
                          v-model="orderEditData.DeliveryDate"
                          :picker-options="deliveryDatePickerOptions"
                          type="date"
                          format="dd.MM.yyyy"
                          value-format="yyyy-MM-dd"
                        ></el-date-picker>
                      </div>
                      <div
                        style="margin: 10px"
                        v-if="!this.$store.getters.ruleSettings.IsInternetShop"
                      >
                        <div
                          v-if="orderEditData.TransErrorMessage"
                          class="el-icon-error"
                        >
                          {{ orderEditData.TransErrorMessage }}
                        </div>
                        <div>
                          <el-select
                            v-model="orderEditData.TransReason"
                            placeholder="Причина переноса"
                          >
                            <el-option
                              v-for="item in TransReasonList"
                              :key="item.Id"
                              :label="item.Name"
                              :value="item.Id"
                            >
                            </el-option>
                          </el-select>
                        </div>
                      </div>
                    </span>
                  </el-dialog>
                </el-form-item>
                <el-form-item
                  prop="DeliveryInterval"
                  label="Время подачи авто на первую точку"
                  label-width="230px"
                >
                  <el-autocomplete
                    :disabled="!isEdit('Order')"
                    v-model="orderData.StartDeliveryIntervalTime"
                    v-mask="'##:##'"
                    style="width: 100px"
                    :fetch-suggestions="getTimeFrom"
                    placeholder="с"
                  >
                  </el-autocomplete>
                  <el-autocomplete
                    :disabled="!isEdit('Order')"
                    v-model="orderData.EndDeliveryIntervalTime"
                    v-mask="'##:##'"
                    style="width: 100px"
                    :fetch-suggestions="getTimeTo"
                    placeholder="до"
                  >
                  </el-autocomplete>
                </el-form-item>
                <el-form-item prop="Car" label="Выбор авто" label-width="230px">
                  <el-select
                    :disabled="!isEdit('Order')"
                    filterable
                    v-model="orderData.CarId"
                    placeholder="Выберите из списка"
                  >
                    <el-option
                      v-for="item in CarList"
                      :key="item.Key"
                      :label="item.Name"
                      :value="item.Key"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <div class="col2">
                <el-form-item
                  prop="IsNeedForwarding"
                  label="Необходимо экспедирование"
                  label-width="230px"
                >
                  <el-switch
                    :disabled="!isEdit('Order')"
                    v-model="orderData.CargoPickup.IsNeedForwarding"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-text="Да"
                    inactive-text="Нет"
                  ></el-switch>
                </el-form-item>
                <el-form-item
                  prop="IsNeedLoadUnloadWork"
                  label="Необходимы ПРР"
                  label-width="230px"
                >
                  <el-switch
                    :disabled="!isEdit('Order')"
                    v-model="orderData.CargoPickup.IsNeedLoadUnloadWork"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    active-text="Да"
                    inactive-text="Нет"
                  ></el-switch>
                </el-form-item>
              </div>
            </div>
            <div>
              <el-form-item
                prop="Comment"
                label="Комментарий"
                label-width="200px"
              >
                <el-input
                  :disabled="!isEdit('Order')"
                  placeholder="Описание маршрута, характера работы, адреса и контактная информация"
                  type="textarea"
                  :rows="2"
                  class="input-field"
                  v-model="orderData.Comment"
                ></el-input>
              </el-form-item>
            </div>
          </div>
        </div>
        <el-dialog
          width="30%"
          :center="true"
          title="Отменить фрахт?"
          :visible.sync="visualSettings.dialogCancelOrder"
        >
          <span style="text-align: center">
            <div style="margin: 10px">
              <el-button @click="confirmCancelOrder" size="mini"
                >Отменить</el-button
              >
              <el-button @click="showDialogCancelOrder(false)" size="mini"
                >Нет</el-button
              >
            </div>
            <div
              style="margin: 10px"
              v-if="!this.$store.getters.ruleSettings.IsInternetShop"
            >
              <div
                v-if="orderEditData.CancelErrorMessage"
                class="el-icon-error"
              >
                {{ orderEditData.CancelErrorMessage }}
              </div>
              <div>
                <el-select
                  v-model="orderEditData.CancelReason"
                  placeholder="Причина отмены"
                >
                  <el-option
                    v-for="item in CancelReasonList"
                    :key="item.Id"
                    :label="item.Name"
                    :value="item.Id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </span>
        </el-dialog>
        <div class="panel" v-show="createdOrder">
          <ScrollTo
            ref="orderStrizhInfo"
            v-on:anchor-shown="activeMenu"
            :anchor="5"
            :block="'start'"
          >
          </ScrollTo>
          <div class="panel-heading">Информация Стриж</div>
          <div class="panel-content">
            <el-tabs type="border-card" v-model="visualSettings.currentTab">
              <el-tab-pane label="Общая информация" name="first">
                <div style="min-height: 390px; padding-top: 10px">
                  <div class="panel-cell">
                    <el-form-item label="Возврат с забором" label-width="200px">
                      <el-select
                        :disabled="true"
                        v-model="orderData.CargoPickup.CargoPickupType"
                      >
                        <el-option
                          v-for="item in CargoTypeList"
                          :key="item.Key"
                          :label="item.Name"
                          :value="item.Key"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div class="panel-cell">
                    <el-form-item label="Тип забора" label-width="200px">
                      <el-select
                        :disabled="true"
                        v-model="orderData.CargoPickup.CargoPickupType"
                      >
                        <el-option
                          v-for="item in CargoTypeList"
                          :key="item.Key"
                          :label="item.Name"
                          :value="item.Key"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div class="panel-cell">
                    <el-form-item label="Состояние" label-width="200px">
                      <el-select
                        :disabled="true"
                        v-model="orderData.StatusState"
                      >
                        <el-option
                          v-for="item in StatusStateList"
                          :key="item.Key"
                          :label="item.Name"
                          :value="item.Key"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                  <div class="panel-cell">
                    <el-form-item
                      prop="CompleteDate"
                      label="Дата исполнения заказа"
                      label-width="200px"
                    >
                      <el-date-picker
                        style="width: 150px"
                        v-model="orderData.CompleteDate"
                        type="date"
                        readonly
                        format="dd.MM.yyyy"
                        value-format="yyyy-MM-dd"
                      ></el-date-picker>
                      <div style="display: inline">Услуги СД руб.</div>
                      <el-input
                        style="width: 100px"
                        readonly
                        v-model="orderData.ServiceDC"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="panel-cell">
                    <el-form-item prop="Car" label="Машина" label-width="200px">
                      <el-input
                        class="input-field"
                        readonly
                        v-model="orderData.Car"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="panel-cell">
                    <el-form-item
                      prop="StockDateFull"
                      label="Дата поступления"
                      label-width="200px"
                    >
                      <el-date-picker
                        v-model="orderData.StockDateFull"
                        type="datetime"
                        readonly
                        format="dd.MM.yyyy HH:mm:ss"
                        value-format="yyyy-MM-ddTHH:mm:ss"
                      ></el-date-picker>
                    </el-form-item>
                  </div>
                  <div class="panel-cell">
                    <el-form-item
                      prop="Driver"
                      label="Водитель"
                      label-width="200px"
                    >
                      <el-input
                        class="input-field"
                        readonly
                        v-model="orderData.Driver"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="panel-cell">
                    <el-form-item
                      prop="StatusStock"
                      label="Складской статус"
                      label-width="200px"
                    >
                      <el-input
                        class="input-field"
                        readonly
                        v-model="orderData.StatusStock"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="panel-cell">
                    <el-form-item
                      prop="Expeditor"
                      label="Экспедитор"
                      label-width="200px"
                    >
                      <el-input
                        class="input-field"
                        readonly
                        v-model="orderData.Expeditor"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="panel-cell">
                    <el-form-item
                      prop="StatusLogistic"
                      label="Логистический статус"
                      label-width="200px"
                    >
                      <el-input
                        class="input-field"
                        readonly
                        v-model="orderData.StatusLogistic"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="panel-cell">
                    <el-form-item
                      prop="CarPhone"
                      label="Телефон"
                      label-width="200px"
                    >
                      <el-input
                        class="input-field"
                        readonly
                        v-model="orderData.CarPhone"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="panel-cell">
                    <el-form-item
                      prop="FinalStatus"
                      label="Финальный статус"
                      label-width="200px"
                    >
                      <el-input
                        class="input-field"
                        readonly
                        v-model="orderData.FinalStatus"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="panel-cell">
                    <el-form-item
                      prop="Poly"
                      label="Полигон"
                      label-width="200px"
                    >
                      <el-input
                        class="input-field"
                        disabled
                        v-model="orderData.Poly"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="panel-cell">
                    <el-form-item
                      prop="LocationFlagName"
                      label="Местонахождение"
                      label-width="200px"
                    >
                      <el-input
                        class="input-field"
                        readonly
                        v-model="orderData.LocationFlagName"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="panel-cell">
                    <el-form-item prop="Zone" label="Зона" label-width="200px">
                      <el-input
                        class="input-field"
                        disabled
                        v-model="orderData.Zone"
                      ></el-input>
                    </el-form-item>
                  </div>
                  <div class="panel-cell"></div>
                  <div class="panel-cell">
                    <el-form-item label-width="200px">
                      <el-button @click="getMarking" size="mini"
                        >Маркировка</el-button
                      >
                      <el-button @click="getBlank" size="mini"
                        >Бланк заказа</el-button
                      >
                    </el-form-item>
                  </div>
                  <div class="panel-cell"></div>
                  <div style="margin: 10px; text-align: center">
                    <el-button
                      v-show="
                        createdOrder &&
                        !editCommonMode &&
                        this.visualSettings.sectionInfo
                      "
                      @click="saveSectionInfo()"
                      size="mini"
                      >Сохранить изменения</el-button
                    >
                    <el-button
                      v-show="
                        createdOrder &&
                        !editCommonMode &&
                        this.visualSettings.sectionInfo
                      "
                      @click="cancelSectionInfo()"
                      size="mini"
                      >Отменить изменения</el-button
                    >
                  </div>
                  <el-form-item
                    prop="CommentSD"
                    label="Комментарий стриж"
                    label-width="200px"
                  >
                    <div style="display: flex">
                      <el-button
                        style="display: inline; margin-right: 10px"
                        v-show="
                          createdOrder &&
                          !editCommonMode &&
                          !this.visualSettings.sectionInfo &&
                          !finishedOrder &&
                          (this.orderData.LocationFlagId !== 3 ||
                            this.$store.getters.ruleSettings
                              .IsAccessToEditOrderOnDeliv)
                        "
                        icon="el-icon-edit"
                        @click="editSection(6, true)"
                        size="mini"
                      ></el-button>
                      <el-input
                        :disabled="!isEdit('Info')"
                        style="display: inline"
                        v-model="orderData.CommentSD"
                      ></el-input>
                    </div>
                  </el-form-item>
                </div>
              </el-tab-pane>
              <el-tab-pane label="История заказа" name="second" :lazy="true">
                <div style="min-height: 400px">
                  <OrderComponentHistory
                    :orderid="orderData.OrderId"
                    ref="orderHistory"
                  ></OrderComponentHistory>
                </div>
              </el-tab-pane>
              <el-tab-pane label="Документы" name="third">
                <div style="min-height: 400px">
                  <OrderComponentDocs
                    :orderid="orderData.OrderId"
                  ></OrderComponentDocs>
                </div>
              </el-tab-pane>
              <el-tab-pane label="История (тариф)" name="fourth">
                <div style="min-height: 400px">
                  <OrderComponentServSDHist
                    :orderid="orderData.OrderId"
                    ref="orderServHistory"
                  ></OrderComponentServSDHist>
                </div>
              </el-tab-pane>
              <el-tab-pane
                :disabled="
                  !this.$store.getters.ruleSettings.IsAccessToViewBarcodes
                "
                label="Штрихкоды"
                name="fifth"
              >
                <div style="min-height: 390px; padding-top: 10px">
                  <div style="display: inline-block">
                    <el-form-item
                      prop="PiecesOfFreight"
                      label="Кол-во мест"
                      label-width="100px"
                    >
                      <el-input-number
                        v-model="orderData.PiecesOfFreight"
                        :min="0"
                        :max="50"
                        controls-position="right"
                        v-bind:controls="false"
                      ></el-input-number>
                    </el-form-item>
                  </div>
                  <div
                    style="display: inline-block"
                    v-if="
                      this.orderEditData.NumOfBarcodes !==
                      this.orderData.PiecesOfFreight
                    "
                    class="el-icon-warning"
                  >
                    Число штрихкодов не совпадает с числом мест!
                  </div>
                  <OrderComponentBarcode
                    :orderid="orderData.OrderId"
                    :editable="
                      this.$store.getters.ruleSettings.IsAccessToEditBarcodes
                    "
                    :supereditable="
                      this.$store.getters.ruleSettings
                        .IsAccessToEditOrderOnDeliv
                    "
                    v-on:update-barcodes="updateBarcodes"
                    v-on:save-barcodes="onSaveBarcodes"
                  ></OrderComponentBarcode>
                </div>
              </el-tab-pane>
              <el-tab-pane
                label="История статусов (партнер)"
                name="sixth"
                :lazy="true"
              >
                <div style="min-height: 400px">
                  <OrderComponentMapHistory
                    :orderid="orderData.OrderId"
                    ref="orderMapHistory"
                  ></OrderComponentMapHistory>
                </div>
              </el-tab-pane>
              <el-tab-pane
                label="История адреса заказа"
                name="seventh"
                :lazy="true"
              >
                <div style="min-height: 400px">
                  <OrderComponentAdrHist
                    :orderid="orderData.OrderId"
                    ref="orderAddressHistory"
                  ></OrderComponentAdrHist>
                </div>
              </el-tab-pane>
              <el-tab-pane
                v-if="!$store.getters.ruleSettings.IsInternetShop"
                label="Доп. параметры"
                name="ningth"
              >
                <div style="min-height: 390px; padding-top: 10px">
                  <div class="cols">
                    <div class="col2">
                      <el-form-item
                        prop="CreateWayName"
                        label="Способ создания"
                        label-width="200px"
                      >
                        <el-input
                          readonly
                          v-model="orderData.CreateWayName"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        prop="RoutingOperator"
                        label="Оператор маршрутизации"
                        label-width="200px"
                      >
                        <el-input
                          readonly
                          v-model="orderData.RoutingOperator"
                        ></el-input>
                      </el-form-item>
                    </div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import OrderComponentAdrHist from "./OrderComponents/OrderComponentAdrHist.vue";
import OrderComponentBarcode from "./OrderComponents/OrderComponentBarcode.vue";
import OrderComponentDocs from "./OrderComponents/OrderComponentDocs.vue";
import OrderComponentHistory from "./OrderComponents/OrderComponentHistory.vue";
import OrderComponentMapHistory from "./OrderComponents/OrderComponentMapHistory.vue";
import OrderComponentServSDHist from "./OrderComponents/OrderComponentServSDHist.vue";
import ScrollTo from "./OrderComponents/ScrollTo.vue";
import DeliveryTerminalControl from "./controls/DeliveryTerminalControl.vue";
import MainShopControl from "./controls/MainShopControl.vue";
import SubShopControl from "./controls/SubShopControl.vue";
import { dates } from "../scripts/dates";

export default {
  name: "OrderEditFreight",
  components: {
    OrderComponentAdrHist,
    OrderComponentBarcode,
    OrderComponentDocs,
    OrderComponentHistory,
    OrderComponentMapHistory,
    OrderComponentServSDHist,
    ScrollTo,
    DeliveryTerminalControl,
    MainShopControl,
    SubShopControl,
  },
  data: function () {
    var tomorrow = dates.methods.tomorrow();
    var today = dates.methods.today();

    var checkDeliveryDate = (date) => {
      if (this.orderData.DeliveryThisDay) {
        return date < today;
      } else {
        return date < tomorrow;
      }
    };

    var checkCommonExistValue = (rule, value, callback) => {
      var error = false;
      var message = rule.message;
      switch (rule.field) {
        case "shopMainId":
          if (!this.orderEditData.shopMainId) {
            error = true;
          }
          break;
        case "OrderIdImport":
          if (
            !this.orderData.OrderIdImport ||
            /\s/.test(this.orderData.OrderIdImport)
          ) {
            error = true;
            message = "Введите внешний номер заказа без пробелов";
          }
          break;
        case "FirstName":
          if (!this.orderData.User.FirstName) {
            error = true;
          }
          break;
        case "UserTel":
          if (!this.orderData.User.UserTel) {
            error = true;
          }
          break;
        case "FullAddress":
          if (!this.orderData.Address.FullAddress) {
            error = true;
          }
          break;
        case "DeliveryInterval":
          if (
            !this.orderData.StartDeliveryIntervalTime ||
            !this.orderData.EndDeliveryIntervalTime
          ) {
            message = "Введите интервал фрахта";
            error = true;
          } else {
            try {
              let reg = new RegExp("\\d\\d:\\d\\d");
              let timeFrom = parseInt(
                this.orderData.StartDeliveryIntervalTime.substring(0, 2)
              );
              let timeTo = parseInt(
                this.orderData.EndDeliveryIntervalTime.substring(0, 2)
              );
              if (
                !reg.test(this.orderData.StartDeliveryIntervalTime) ||
                !reg.test(this.orderData.EndDeliveryIntervalTime) ||
                timeFrom >= timeTo
              ) {
                throw new Error();
              }
            } catch (e) {
              error = true;
              message = "Введите интервал в корректном формате";
            }
          }
          break;
      }
      if (error) {
        callback(message);
      } else {
        callback();
      }
    };

    let initData = this.getInitVals();

    return {
      orderData: initData.orderData,
      orderEditData: initData.orderEditData,
      activeIndex: "1",
      yaMapOptions: {
        zoom: 10,
        controls: ["zoomControl", "fullscreenControl"],
      },
      deliveryDatePickerOptions: {
        disabledDate: checkDeliveryDate,
        firstDayOfWeek: 1,
      },
      rules: {
        shopMainId: [
          {
            validator: checkCommonExistValue,
            message: "Выберите головную организацию",
            trigger: "change",
          },
        ],
        OrderIdImport: [{ validator: checkCommonExistValue, trigger: "blur" }],
        FirstName: [
          {
            validator: checkCommonExistValue,
            message: "Введите ФИО",
            trigger: "blur",
          },
        ],
        UserTel: [
          {
            validator: checkCommonExistValue,
            message: "Введите номер телефона",
            trigger: "blur",
          },
        ],
        SourcePlaceId: [
          {
            required: true,
            message: "Выберите регион отгрузки",
            trigger: "change",
          },
        ],
        DeliveryPlaceId: [
          {
            required: true,
            message: "Выберите регион забора",
            trigger: "change",
          },
        ],
        FullAddress: [
          {
            validator: checkCommonExistValue,
            message: "Введите адрес забора",
            trigger: "blur",
          },
        ],
        DeliveryDate: [
          { required: true, message: "Выберите дату забора", trigger: "blur" },
        ],
        DeliveryInterval: [
          { validator: checkCommonExistValue, trigger: "blur" },
        ],
        PayType: [
          { required: true, message: "Выберите тип оплаты", trigger: "blur" },
        ],
        WhoPaysRise: [
          {
            required: true,
            message: "Выберите, кто оплачивает подъем",
            trigger: "blur",
          },
        ],
        OrderWeight: [
          { required: true, message: "Введите вес заказа", trigger: "blur" },
        ],
        StockId: [
          {
            required: true,
            message: "Выберите склад возврата",
            trigger: "blur",
          },
        ],
      },
      visualSettings: initData.visualSettings,
      OrderItemTypeList: [],
      NDSDeliveryList: [],
      WhoPaysRiseList: [],
      AdditionalServicesList: [],
      TransReasonList: [],
      CancelReasonList: [],
      SelfDeliveryList: [],
      BarcodePackList: [],
      StatusStateList: [],
      DefaultShopValues: initData.DefaultShopValues,
      CargoTypeList: [],
      CarList: [],
    };
  },
  computed: {
    payTypeSelList() {
      if (this.orderData.SelfDelivery != null) {
        return this.PayTypeList.filter(
          (item) => item.SelfDelEnable.indexOf(this.orderData.SelfDelivery) > -1
        );
      } else {
        return this.PayTypeList;
      }
    },
    items() {
      if (this.visualSettings.itemsDeleted) {
        return this.orderData.Items;
      }
      return this.orderData.Items.filter((item) => !item.IsDeleted);
    },
    orderTypeName() {
      var typeName = "";
      switch (this.orderData.SelfDelivery) {
        case 0:
          typeName = "Доставка";
          break;
        case 2:
          typeName = "Забор";
          break;
        case 3:
          typeName = "Фрахт";
          break;
        case 4:
          typeName = "Клиентский возврат";
          break;
        case 5:
          typeName = "Обмен";
          break;
      }
      return typeName;
    },
    editCommonMode() {
      return this.visualSettings.showSaveMainButton;
    },
    draftOrder() {
      return this.orderData.StatusState && this.orderData.StatusState === 1;
    },
    createdOrder() {
      return this.orderData.OrderId > 0;
    },
    finishedOrder() {
      return (
        this.orderData.StatusState === 3 || this.orderData.StatusState === 4
      );
    },
    delivDateEditable() {
      return (
        this.orderData.LocationFlagId !== 3 &&
        this.orderData.StatusState !== 3 &&
        this.orderData.StatusState !== 4 &&
        this.orderData.StatusLogisticId !== 221 &&
        this.orderData.StatusLogisticId !== 231 &&
        this.orderData.StatusLogisticId !== 237 &&
        this.orderData.StatusLogisticId !== 238 &&
        this.orderData.StatusLogisticId !== 235
      );
    },
    cancelOrdEnable() {
      return (
        this.orderData.LocationFlagId !== 3 &&
        this.orderData.StatusState !== 3 &&
        this.orderData.StatusState !== 4 &&
        this.orderData.StatusLogisticId !== 221 &&
        this.orderData.StatusLogisticId !== 231 &&
        this.orderData.StatusLogisticId !== 237
      );
    },
    coords() {
      if (
        this.orderData.Address.Latitude > 0 &&
        this.orderData.Address.Longitude > 0
      ) {
        return [
          this.orderData.Address.Latitude,
          this.orderData.Address.Longitude,
        ];
      }
      return [55.75222, 37.61556];
    },
    geocordinates() {
      if (this.orderData.Address.Latitude && this.orderData.Address.Longitude) {
        return (
          this.orderData.Address.Latitude.toFixed(6) +
          " " +
          this.orderData.Address.Longitude.toFixed(6)
        );
      }
      return "Адрес не распознан";
    },
    OrderIdShow() {
      if (this.orderData.OrderId > 0) return this.orderData.OrderId;
      return "Не создан";
    },
  },
  methods: {
    onAddressChange(val) {
      this.orderData.Address.Latitude = null;
      this.orderData.Address.Longitude = null;
    },
    openOrder() {
      var self = this;
      this.sendRequest(
        "/OrderEdit/openOrder",
        {
          orderId: this.orderData.OrderId,
        },
        function (response) {
          self.loadOrderData(response);
          self.refresh();
        }
      );
    },
    setGeoCoords() {
      var self = this;
      self.sendRequest(
        "/OrderEdit/setGeoCoords",
        { orderId: this.orderData.OrderId },
        function (resp) {
          self.orderData = resp.data.Data;
        }
      );
    },
    onRegionSelect(selVal) {
      this.orderData.SourcePlaceId = selVal;
    },
    activeMenu(anchor) {
      this.activeIndex = anchor.toString();
      console.log(this.activeIndex);
    },
    menuClick(key) {
      var index = key.index;
      this.activeIndex = index;
      switch (index) {
        case "1":
          this.$refs.orderShopInfo.scrollTo();
          break;
        case "2":
          this.$refs.orderUserInfo.scrollTo();
          break;
        case "3":
          this.$refs.orderItemsInfo.scrollTo();
          break;
        case "4":
          this.$refs.orderInfo.scrollTo();
          break;
        case "5":
          this.$refs.orderStrizhInfo.scrollTo();
          break;
      }
    },
    confirmCancelOrder() {
      if (
        !this.$store.getters.ruleSettings.IsInternetShop &&
        !this.orderEditData.CancelReason
      ) {
        this.orderEditData.CancelErrorMessage = "Не выбрана причина отклонения";
      } else {
        this.orderEditData.CancelErrorMessage = "";
        this.visualSettings.dialogCancelOrder = false;
        var ReasonId =
          this.orderEditData.CancelReason === null
            ? 132
            : this.orderEditData.CancelReason;
        var self = this;
        this.sendRequest(
          "/OrderEdit/cancelOrder",
          {
            orderId: this.orderData.OrderId,
            reasonId: ReasonId,
          },
          function (response) {
            self.loadOrderData(response);
            self.refresh();
          }
        );
      }
    },
    showDialogCancelOrder(show) {
      this.visualSettings.dialogCancelOrder = show;
    },
    confirmChangeDeliveryDate() {
      if (!this.orderEditData.DeliveryDate) {
        this.orderEditData.TransErrorMessage = "Не выбрана дата забора";
      } else if (
        !this.$store.getters.ruleSettings.IsInternetShop &&
        !this.orderEditData.TransReason
      ) {
        this.orderEditData.TransErrorMessage = "Не выбрана причина переноса";
      } else {
        this.orderEditData.TransErrorMessage = "";
        this.visualSettings.dialogEditDeliveryDate = false;
        this.orderData.DeliveryDate = this.orderEditData.DeliveryDate;
        var ReasonId =
          this.orderEditData.TransReason === null
            ? 28
            : this.orderEditData.TransReason;
        var self = this;
        this.sendRequest(
          "/OrderEdit/changeDeliveryDate",
          {
            orderId: this.orderData.OrderId,
            deliveryDate: this.orderData.DeliveryDate,
            reasonId: ReasonId,
          },
          function (response) {
            self.loadOrderData(response);
            self.refresh();
          }
        );
      }
    },
    showDialogDeliveryDate(show) {
      this.visualSettings.dialogEditDeliveryDate = show;
    },
    getBlank() {
      if (this.orderData.OrderId > 0) {
        let self = this;
        var orderId = this.orderData.OrderId;
        this.axios
          .post("/OrderEdit/getBlank", {
            orderId: this.orderData.OrderId,
          })
          .then(function (resp) {
            self.$helpMethods.downloadBase64Doc(
              resp.data.Data.FileName,
              resp.data.Data.FileData
            );
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    getMarking() {
      if (this.orderData.OrderId > 0) {
        let self = this;
        var orderId = this.orderData.OrderId;
        this.sendRequest(
          "/OrderEdit/getMarking",
          {
            orderId: this.orderData.OrderId,
          },
          function (resp) {
            self.$helpMethods.downloadBase64Doc(
              resp.data.Data.FileName,
              resp.data.Data.FileBody
            );
          }
        );
      }
    },
    onSaveBarcodes() {},
    updateBarcodes(numBar) {
      this.orderEditData.NumOfBarcodes = numBar;
    },
    getOrderItemTypeName(item) {
      var dicName = this.OrderItemTypeList.filter(
        (dic) => dic.Key === item.OrderItemType
      );
      if (dicName.length !== 0) {
        return dicName[0].Name;
      }
      return "";
    },
    loadAdditionalService(serviceId, data) {
      if (
        data.AdditionalServices.filter(
          (item) => item.ServiceId === serviceId && item.Count > 0
        ).length > 0
      ) {
        return true;
      }
      return false;
    },
    saveAdditionalService(serviceId, isAdd) {
      var serv = this.orderData.AdditionalServices.filter(
        (item) => item.ServiceId === serviceId
      );
      var count = isAdd ? 1 : 0;
      if (serv.length > 0) {
        this.orderData.AdditionalServices.map(function (item) {
          if (item.ServiceId === serviceId) {
            item.Count = count;
            return item;
          }
          return item;
        });
      } else {
        this.orderData.AdditionalServices.push({
          ServiceId: serviceId,
          Count: count,
          Price: 0,
        });
      }
    },
    saveNotStandartData() {
      this.saveAdditionalService(9, this.orderEditData.ServiceMarking);
    },
    loadNotStandartData(data) {
      if (data.AdditionalServices) {
        this.orderEditData.ServiceMarking = this.loadAdditionalService(9, data);
      }
    },
    startLoading() {
      this.$loading();
    },
    stopLoading() {
      this.$loading().close();
    },
    checkItemData(item) {
      if (item.row.Code) {
        return "Введите артикул товара";
      }
      return "";
    },
    addSecondNumber() {
      this.visualSettings.showSecondNumber = true;
    },
    handleShopMainChange: function (cnt) {
      this.orderEditData.SubShopCnt = cnt;
    },
    handleSubShopSelect: function (subShop) {
      this.fillSubShopSelect(subShop);
    },
    fillSubShopSelect: function (subShop) {
      if (subShop) {
        this.orderData.SettingId = subShop.SettingId;
        this.orderData.InternetShopId = subShop.InternetShopId;
      }
    },
    fillMainShopSelect: function (shop) {
      this.orderData.SettingId = shop.SettingId;
      this.orderData.InternetShopId = shop.InternetShopId;
    },
    handleMainShopSelect: function (shop) {
      if (shop) {
        this.fillMainShopSelect(shop);

        if (this.orderData.InternetShopId && !this.createdOrder) {
          let self = this;
          this.startLoading();
          this.setDefaultValues()
            .then((resp) => {
              self.onSetDefaultValuesResp(resp);
              self.stopLoading();
            })
            .catch(() => {
              self.stopLoading();
            });
        }
      }
    },
    refresh: function () {
      if (this.$refs.orderHistory) this.$refs.orderHistory.refresh();
      if (this.$refs.orderAddressHistory)
        this.$refs.orderAddressHistory.refresh();
      this.$refs.orderServHistory.refresh();
      if (this.$refs.orderMapHistory) this.$refs.orderMapHistory.refresh();
    },
    clearValidate() {
      this.$refs["orderDataIndicators"].clearValidate();
      this.$refs["orderDataContact"].clearValidate();
      this.$refs["orderDataTerms"].clearValidate();
      this.$refs["orderDataAdr"].clearValidate();
      this.$refs["orderDataInfo"].clearValidate();
    },
    saveOrder: function (defStatusState, needValid) {
      var validOrdIndicators = true;
      var validOrdContact = true;
      var validOrdTerms = true;
      var validOrdAddr = true;
      var validOrdInfo = true;
      this.clearValidate();
      this.$refs["orderDataIndicators"].validate((valid) => {
        validOrdIndicators = valid;
      });
      this.$refs["orderDataTerms"].validate((valid) => {
        validOrdTerms = valid;
      });
      if (needValid) {
        this.$refs["orderDataContact"].validate((valid) => {
          validOrdContact = valid;
        });
        this.$refs["orderDataAdr"].validate((valid) => {
          validOrdAddr = valid;
        });
        this.$refs["orderDataInfo"].validate((valid) => {
          validOrdInfo = valid;
        });
      }
      if (
        validOrdIndicators &&
        validOrdContact &&
        validOrdTerms &&
        validOrdAddr &&
        validOrdInfo
      ) {
        this.startLoading();
        var self = this;
        this.saveNotStandartData();
        if (!this.orderData.StatusState || this.orderData.StatusState === 1) {
          this.orderData.StatusState = defStatusState;
        }
        this.sendRequest(
          "/OrderEdit/saveOrderData",
          {
            order: this.orderData,
            section: null,
          },
          function (response) {
            let href = self.$helpMethods.getOrderHref(
              response.data.Data.OrderId,
              response.data.Data.SelfDelivery
            );
            self.$router.push(href);
          },
          function (response) {
            self.stopLoading();
            if (response && response.data.Error) {
              self.showError(response.data.Error);
            } else {
              self.showError("Произошла ошибка при сохранении");
            }
          }
        );
      } else {
        this.showError(
          "Изменения не сохранены. Не введены все обязательные поля!"
        );
      }
    },
    removeItem(index) {
      if (
        this.createdOrder &&
        this.orderData.Items.filter((item) => !item.IsDeleted).length < 2
      ) {
        this.showError("Нельзя удалить последний товар!");
        return;
      }
      if (this.orderData.Items[index]) {
        this.orderData.Items.splice(index, 1);
      }
    },
    addItem: function (orderItemType) {
      var newRow = { OrderItemType: orderItemType };
      switch (orderItemType) {
        case 2:
          newRow.Count = 1;
          break;
        case 3:
          newRow.Price = 0;
          break;
      }
      this.orderData.Items = [...this.orderData.Items, newRow];
    },
    getTomorrowDate() {
      var tomorrow = new Date();
      tomorrow.setDate(new Date().getDate() + 1);
      return tomorrow;
    },
    getTimeTo(queryString, cb) {
      let minHour = 11;
      if (this.orderData.StartDeliveryIntervalTime) {
        try {
          let h = parseInt(
            this.orderData.StartDeliveryIntervalTime.substring(0, 2)
          );
          if (h >= minHour) minHour = h + 1;
        } catch (e) {
          console.log(e);
        }
      }
      const dt = new Date(1970, 0, 1, minHour);
      const maxHour = 22;
      const step = 60;
      const rc = [];
      while (dt.getDate() === 1 && dt.getHours() <= maxHour) {
        rc.push({
          value: [
            dt.getHours() < 10 ? "0" + dt.getHours() : dt.getHours(),
            ":",
            dt.getMinutes() < 10 ? "0" + dt.getMinutes() : dt.getMinutes(),
          ].join(""),
        });
        dt.setMinutes(dt.getMinutes() + step);
      }
      cb(rc);
    },
    getTimeFrom(queryString, cb) {
      const dt = new Date(1970, 0, 1, 10);
      let maxHour = 21;
      if (this.orderData.EndDeliveryIntervalTime) {
        try {
          let h = parseInt(
            this.orderData.EndDeliveryIntervalTime.substring(0, 2)
          );
          if (h <= maxHour) maxHour = h - 1;
        } catch (e) {
          console.log(e);
        }
      }
      const step = 60;
      const rc = [];
      while (dt.getDate() === 1 && dt.getHours() <= maxHour) {
        rc.push({
          value: [
            dt.getHours() < 10 ? "0" + dt.getHours() : dt.getHours(),
            ":",
            dt.getMinutes() < 10 ? "0" + dt.getMinutes() : dt.getMinutes(),
          ].join(""),
        });
        dt.setMinutes(dt.getMinutes() + step);
      }
      cb(rc);
    },
    getSuggestions(queryString, cb) {
      this.querySearch(queryString, cb, 10);
    },
    querySearch(queryString, cb, count) {
      this.axios
        .post("/OrderEdit/getAddressSuggest", {
          address: queryString,
          count: count ? count : 10,
        })
        .then(function (response) {
          cb(response.data.Data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleSelect(item) {
      if (item.GeoLat && item.GeoLng) {
        this.orderData.Address.Latitude = item.GeoLat;
        this.orderData.Address.Longitude = item.GeoLng;
      }
      if (item.value) {
        let self = this;
        this.querySearch(
          item.value,
          function (response) {
            if (
              response.length > 0 &&
              response[0].GeoLat &&
              response[0].GeoLng
            ) {
              self.orderData.Address.Latitude = response[0].GeoLat;
              self.orderData.Address.Longitude = response[0].GeoLng;
            }
          },
          1
        );
      }
    },
    showSuccess(message) {
      this.$message({
        message: message,
        type: "success",
      });
    },
    showError(message) {
      this.$message.error(message);
    },
    loadSimpleDictionaries(response) {
      this.OrderItemTypeList = response.data.OrderItemTypeList.Dir;
      this.NDSDeliveryList = response.data.NDSDeliveryList.Dir;
      this.WhoPaysRiseList = response.data.WhoPaysRiseList.Dir;
      this.TransReasonList = response.data.TransReasonList;
      this.CancelReasonList = response.data.CancelReasonList;
      this.PickupPointList = response.data.PickupPointList.Dir;
      this.StatusStateList = response.data.StatusStateList.Dir;
      this.CargoTypeList = response.data.CargoTypeList.Dir;
      this.CarList = response.data.CarList.Dir;
    },
    loadDynamicDictionaries() {
      var self = this;
      self.orderEditData.subShopId = null;
      let subShop = this.$refs.subShop.getShop(self.orderData.InternetShopId);
      if (subShop) {
        self.orderEditData.shopMainId = subShop.ParentId;
        this.$nextTick(() => {
          self.orderEditData.subShopId = subShop.InternetShopId;
        });
      } else {
        let mainShop = this.$refs.mainShop.getShop(
          self.orderData.InternetShopId
        );
        if (mainShop) {
          self.orderEditData.shopMainId = mainShop.InternetShopId;
        }
      }
    },
    sendRequest(url, data, cb, onError, onSuccess) {
      var self = this;
      self.startLoading();
      this.axios
        .post(url, data)
        .then(function (response) {
          if (response.data.Success || response.data.Success === undefined) {
            self.stopLoading();
            if (onSuccess) {
              onSuccess(response);
              cb(response);
            } else {
              cb(response);
            }
          } else {
            if (onError) {
              self.stopLoading();
              onError(response);
            } else {
              self.stopLoading();
              self.showError("Произошла ошибка");
            }
          }
        })
        .catch(function (error) {
          if (onError) {
            self.stopLoading();
            onError();
          } else {
            self.stopLoading();
            self.showError("Произошла ошибка");
          }
          console.log(error);
        });
    },
    saveSectionOrder() {
      var self = this;
      this.$refs["orderDataInfo"].validate((valid) => {
        if (valid) {
          this.saveNotStandartData();
          this.sendRequest(
            "/OrderEdit/saveOrderData",
            { order: this.orderData, section: 5 },
            function (resp) {
              self.loadSectionOrder(resp.data.Data);
              self.refresh();
            }
          );
        }
      });
    },
    loadSectionOrder(data) {
      var order = data;
      this.orderData.DeliveryDate = order.DeliveryDate;
      this.orderData.StartDeliveryIntervalTime =
        order.StartDeliveryIntervalTime;
      this.orderData.EndDeliveryIntervalTime = order.EndDeliveryIntervalTime;
      this.orderData.Comment = order.Comment;
      this.orderData.Car = order.Car;
      this.orderData.CarPhone = order.CarPhone;
      this.loadNotStandartData(this.orderData);
      this.editSection(5, false);
    },
    cancelSectionOrder() {
      var self = this;
      this.sendRequest(
        "/OrderEdit/getOrderData",
        { orderID: this.$route.query.orderID },
        function (resp) {
          self.loadSectionOrder(resp.data.Data);
        }
      );
    },
    saveSectionInfo() {
      var self = this;
      this.sendRequest(
        "/OrderEdit/saveOrderData",
        { order: this.orderData, section: 6 },
        function (resp) {
          self.loadSectionInfo(resp.data.Data);
          self.refresh();
        }
      );
    },
    loadSectionInfo(data) {
      this.orderData.CommentSD = data.CommentSD;
      this.editSection(6, false);
    },
    cancelSectionInfo() {
      var self = this;
      this.sendRequest(
        "/OrderEdit/getOrderData",
        { orderID: this.$route.query.orderID },
        function (resp) {
          self.loadSectionInfo(resp.data.Data);
        }
      );
    },
    saveSectionCargo() {
      var self = this;
      this.$refs["orderDataCargo"].validate((valid) => {
        if (valid) {
          this.sendRequest(
            "/OrderEdit/saveOrderData",
            { order: this.orderData, section: 4 },
            function (resp) {
              self.loadSectionCargo(resp.data.Data);
              self.refresh();
            }
          );
        }
      });
    },
    loadSectionCargo(data) {
      var order = data;
      this.orderData.CargoPickup = order.CargoPickup;
      this.editSection(4, false);
    },
    cancelSectionCargo() {
      var self = this;
      this.sendRequest(
        "/OrderEdit/getOrderData",
        { orderID: this.$route.query.orderID },
        function (resp) {
          self.loadSectionCargo(resp.data.Data);
        }
      );
    },
    saveSectionContact() {
      let validContact = true;
      let validTerms = true;
      let validAdr = true;
      this.$refs["orderDataTerms"].validate((valid) => {
        validTerms = valid;
      });
      if (!this.draftOrder) {
        this.$refs["orderDataContact"].validate((valid) => {
          validContact = valid;
        });
        this.$refs["orderDataAdr"].validate((valid) => {
          validAdr = valid;
        });
      }
      if (validContact && validTerms && validAdr) {
        var self = this;
        this.sendRequest(
          "/OrderEdit/saveOrderData",
          { order: this.orderData, section: 1 },
          function (resp) {
            self.loadSectionContact(resp.data.Data);
            self.refresh();
          }
        );
      }
    },
    loadSectionContact(data) {
      var order = data;
      this.orderData.User = order.User;
      this.orderData.Address = order.Address;
      this.orderData.SourcePlaceId = order.SourcePlaceId;
      this.orderData.DeliveryPlaceId = order.DeliveryPlaceId;
      this.editSection(1, false);
    },
    cancelSectionContact() {
      var self = this;
      this.sendRequest(
        "/OrderEdit/getOrderData",
        { orderID: this.$route.query.orderID },
        function (resp) {
          self.loadSectionContact(resp.data.Data);
        }
      );
    },
    editSection(sectionNum, edit) {
      switch (sectionNum) {
        case 1:
          if (edit && this.orderData.LocationFlagId === 3) {
            this.showSuccess(
              "Заказ находится в рейсе. Внесенные вами изменения будут применены при окончании текущего рейса"
            );
          }
          this.visualSettings.sectionContact = edit;
          break;
        case 5:
          this.visualSettings.sectionOrder = edit;
          break;
        case 4:
          this.visualSettings.sectionCargo = edit;
          break;
        case 6:
          this.visualSettings.sectionInfo = edit;
          break;
      }
    },
    isEditItems(item) {
      var isEdit =
        this.isEdit("Items") &&
        !item.IsDeleted &&
        (!item.IsAccepted ||
          this.$store.getters.ruleSettings.IsAccessToEditAcceptedItems);
      return isEdit;
    },
    isEdit(fieldName) {
      var isEdit = true;
      switch (fieldName) {
        case "OrderIdImport":
          isEdit = !this.createdOrder;
          break;
        case "Contact":
          isEdit =
            !this.createdOrder ||
            this.visualSettings.sectionContact ||
            this.editCommonMode;
          break;
        case "Cargo":
          isEdit =
            !this.createdOrder ||
            this.visualSettings.sectionCargo ||
            this.editCommonMode;
          break;
        case "Order":
          isEdit =
            !this.createdOrder ||
            this.visualSettings.sectionOrder ||
            this.editCommonMode;
          break;
        case "Info":
          isEdit =
            !this.createdOrder ||
            this.visualSettings.sectionInfo ||
            this.editCommonMode;
          break;
      }
      return isEdit;
    },
    onSetDefaultValuesResp(resp) {
      if (resp && resp.data.Data) {
        var data = resp.data.Data;
        this.DefaultShopValues = data;
        if (!this.orderData.SourcePlaceId && data.SourcePlaceId) {
          this.orderData.SourcePlaceId = data.SourcePlaceId;
        }
        if (!this.orderData.DeliveryPlaceId && data.DeliveryPlaceId) {
          this.orderData.DeliveryPlaceId = data.DeliveryPlaceId;
        }
      }
    },
    setDefaultValues() {
      if (this.orderData.InternetShopId && !this.createdOrder) {
        return this.axios.post("/OrderEdit/getDefaultVals", {
          internetShopId: this.orderData.InternetShopId,
        });
      }
      return false;
    },
    loadOrderData(response) {
      this.orderData = response.data.Data;
      this.loadNotStandartData(response.data.Data);
    },
    getInitVals() {
      let tomorrowStr = dates.methods.tomorrowStr();
      let todayStr = dates.methods.todayStr();

      return {
        orderData: {
          OrderIdImport: "",
          OrderId: 0,
          SettingId: 0,
          InternetShopId: 0,
          SourcePlaceId: null,
          DeliveryPlaceId: null,
          ShipmentWHDate: todayStr,
          DeliveryThisDay: false,
          DeliveryDate: tomorrowStr,
          StartDeliveryIntervalTime: "",
          EndDeliveryIntervalTime: "",
          DeliveryCost: 0,
          NDSDelivery: null,
          PayType: 7,
          WhoPaysRise: null,
          IsPartialGiveoutDisabled: false,
          Oversized: false,
          CategoryDelivery: 0,
          StockId: null,
          OrderWeight: 0,
          OrderVolume: 0,
          PiecesOfFreight: 0,
          AssessedCost: 0,
          PrepaymentAmount: 0,
          Comment: "",
          CompleteDate: null,
          CommentSD: "",
          FinalStatusId: null,
          StatusLogisticId: null,
          StatusStockId: null,
          FinalStatus: null,
          StatusLogistic: null,
          StatusStock: null,
          StockDate: null,
          StockDateFull: null,
          Driver: "",
          Expeditor: "",
          Car: "",
          CarPhone: "",
          LocationFlagName: "",
          LocationFlagId: null,
          SelfDelivery: null,
          IsInStockFull: null,
          StatusState: null,
          DynamicDeliveryCost: null,
          OrderSumFinal: 0,
          ServiceDC: 0,
          User: {
            FirstName: "",
            UserTel: "",
            Email: "",
          },
          Address: {
            FullAddress: "",
            Latitude: "",
            Longitude: "",
            Comment: "",
          },
          CargoPickup: {
            VolumePlan: 0,
            VolumeFact: 0,
            NumOfPalletPlan: 0,
            NumOfPalletFact: 0,
            NumOfOrdersPlan: 0,
            NumOfOrdersFact: 0,
            CargoPickupType: null,
            IsNeedForwarding: false,
            IsNeedLoadUnloadWork: false,
          },
          Items: [],
          AdditionalServices: [],
        },
        orderEditData: {
          shopMainId: "",
          subShopId: "",
          NumOfBarcodes: 0,
          ServiceMarking: false,
          DeliveryDate: tomorrowStr,
          TransErrorMessage: "",
          CancelErrorMessage: "",
          TransReason: null,
          CancelReason: null,
          SubShopCnt: 0,
        },
        visualSettings: {
          dialogEditDeliveryDate: false,
          dialogCancelOrder: false,
          sectionContact: false,
          sectionCargo: false,
          sectionOrder: false,
          sectionInfo: false,
          itemsDeleted: false,
          showSaveMainButton: false,
          accessDenied: false,
          currentTab: "first",
        },
        DefaultShopValues: {
          StockId: null,
          InternetShopId: null,
          DeliveryPlaceId: null,
          SourcePlaceId: null,
          PayType: null,
          PartBuyout: null,
          WhoPaysRise: null,
          NDS: null,
        },
      };
    },
    createNewOrderType(orderData, newOrderType) {
      switch (newOrderType) {
        case 0:
          this.orderData = orderData;
          this.orderData.Address.FullAddress = null;
          this.orderData.Address.Latitude = null;
          this.orderData.Address.Longitude = null;
          this.orderData.StartDeliveryIntervalTime = null;
          this.orderData.EndDeliveryIntervalTime = null;
          this.orderData.Address.Comment = null;
          this.orderData.SelfDelivery = 0;
          this.visualSettings.showSaveMainButton = true;
          break;
        case 4:
          var self = this;
          self.orderData.Items = [];
          orderData.Items.forEach(function (item) {
            self.orderData.Items.push({
              Code: item.Code,
              BarcodePackage: item.BarcodePackage,
              MarkingCode: item.MarkingCode,
              Name: item.Name,
              Count: item.Count > 0 ? item.Count * -1 : item.Count,
              Price: item.Price,
              NDS: item.NDS,
              OrderItemType: item.OrderItemType,
            });
          });
          this.orderData.Address = orderData.Address;
          this.orderData.User = orderData.User;
          this.orderData.DeliveryPlaceId = orderData.DeliveryPlaceId;
          this.orderData.SourcePlaceId = orderData.SourcePlaceId;
          this.orderData.SettingId = orderData.SettingId;
          this.orderData.InternetShopId = orderData.InternetShopId;
          this.orderData.OrderIdImport = "B" + orderData.OrderIdImport;
          this.orderData.OrderId = 0;
          this.orderData.SelfDelivery = newOrderType;
          break;
      }
    },
    load(orderID) {
      this.orderData.SelfDelivery = 3;
      var orderDictionariesRequest = this.axios.post(
        "/OrderEdit/getDictionaries",
        {}
      );
      this.startLoading();
      var self = this;
      if (orderID) {
        var barcodePackRequest = this.axios.post(
          "/OrderEdit/getBarcodePackList",
          { orderId: orderID }
        );
        var orderDataRequest = this.axios.post("/OrderEdit/getOrderData", {
          orderID: orderID,
        });
        this.axios
          .all([orderDataRequest, orderDictionariesRequest, barcodePackRequest])
          .then(
            self.axios.spread((...responses) => {
              var responseOrderData = responses[0];
              if (!responseOrderData.data.Access) {
                self.visualSettings.accessDenied = true;
                self.stopLoading();
                self.showError("Доступ запрещен");
                return false;
              }
              var responseDictionaries = responses[1];
              var responseBarcodePack = responses[2];
              self.loadSimpleDictionaries(responseDictionaries);
              self.loadOrderData(responseOrderData);
              self.BarcodePackList = responseBarcodePack.data.Data;
              self.loadDynamicDictionaries();
              self.refresh();
              self.stopLoading();
              return false;
            })
          )
          .catch((error) => {
            console.log(error);
            self.stopLoading();
            self.showError("Произошла ошибка!");
          });
      } else {
        this.axios
          .all([orderDictionariesRequest])
          .then(
            self.axios.spread((...responses) => {
              var responseDictionaries = responses[0];
              self.loadSimpleDictionaries(responseDictionaries);
              self.loadDynamicDictionaries();
              self.refresh();
              self.stopLoading();
              return false;
            })
          )
          .catch((error) => {
            console.log(error);
            self.stopLoading();
            self.showError("Произошла ошибка при загрузке страницы");
          });
      }
    },
  },
  created: function () {
    //helpMethods.showPage();
  },
  beforeRouteUpdate(to, from, next) {
    if (!to.query.orderID) {
      let initData = this.getInitVals();
      this.orderData = initData.orderData;
      this.orderEditData = initData.orderEditData;
      this.visualSettings = initData.visualSettings;
      this.DefaultShopValues = initData.DefaultShopValues;
    } else {
      let initData = this.getInitVals();
      this.orderEditData = initData.orderEditData;
      this.visualSettings = initData.visualSettings;
    }
    this.load(to.query.orderID);
    next();
  },
  mounted: function () {
    var orderID = this.$route.query.orderID;
    this.load(orderID);
  },
};
</script>

<style scoped>
.vueTemplate >>> .routingOperator {
  background-color: #00fc7e;
}

.vueTemplate >>> .panel {
  border: 1px solid transparent;
  border-color: #ddd;
  border-radius: 7px;
}

.vueTemplate >>> .panel-heading {
  background-color: #dcd9d9;
  padding: 0px 15px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  font-family: Arial, sans-serif;
}

.vueTemplate >>> .panel-content {
  padding: 0px 15px;
  padding-top: 10px;
}

.vueTemplate >>> .input-label {
  width: 50%;
  display: inline-block;
}

.vueTemplate >>> .el-form-item {
  margin-bottom: 15px !important;
}

.vueTemplate >>> .panel-cell {
  display: inline-block;
  width: 45%;
  min-width: 400px;
}

.vueTemplate >>> .panel-cell3 {
  display: inline-block;
  width: 30%;
  min-width: 400px;
}

.vueTemplate >>> .panel-cell4 {
  display: inline-block;
  width: 20%;
  min-width: 340px;
}

.vueTemplate >>> .panel-cellV {
  min-width: 400px;
}

.vueTemplate >>> .el-autocomplete {
  width: 100%;
}

.vueTemplate >>> .ymap-container {
  height: 100%;
}
.vueTemplate >>> .itemsHeader {
  padding: 0px !important;
}
.vueTemplate >>> .itemsCell {
  padding: 1px !important;
}
.vueTemplate >>> .headerCell {
  font-weight: normal;
  overflow: unset !important;
}
.vueTemplate >>> .cell {
  line-height: 10px !important;
  word-break: keep-all !important;
  padding: 0 !important;
  text-align: center;
}
.vueTemplate >>> .el-tabs__content {
  padding: 0 !important;
}
.vueTemplate >>> .el-tooltip {
  padding: 0 !important;
}
.vueTemplate >>> .cols {
  overflow: auto;
}
.vueTemplate >>> .col2 {
  width: 50%;
  float: left;
}
.vueTemplate >>> .col3 {
  width: 33%;
  float: left;
}
</style>
