<template>
  <el-select
    size="mini"
    v-model="sourcePlace"
    :disabled="disabled"
    placeholder="Выберите из списка"
  >
    <el-option
      v-for="item in SourcePlaceAllList"
      :key="item.Key"
      :label="item.Name"
      :value="item.Key"
    ></el-option>
  </el-select>
</template>
<script>
export default {
  name: "SourceTerminalControl",
  props: ["useAllOpt", "value", "disabled"],
  data: function () {
    return {
      sourcePlace: null,
    };
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal != oldVal) {
        if (this.SourcePlaceAllList.some((placeId) => placeId.Key === newVal)) {
          this.sourcePlace = newVal;
        } else {
          this.sourcePlace = null;
        }
      }
    },
    sourcePlace(newVal) {
      this.$emit("input", newVal);
    },
  },
  computed: {
    SourcePlaceAllList() {
      if (this.useAllOpt) {
        return [
          { Key: "All", Name: "Все" },
          ...this.$store.getters.sourceTerminalsList,
        ];
      }
      return this.$store.getters.sourceTerminalsList;
    },
  },
  methods: {},
  mounted: function () {
    this.sourcePlace = this.value;
  },
};
</script>