<template>
  <div>
    <el-form
      v-loading="fullScreenLoading"
      size="mini"
      ref="shopIntegrationSettings"
      :model="shopIntegrationSettings"
      label-width="250px"
    >
      <el-button
        size="mini"
        @click="saveShopIntStgs"
        type="success"
        style="margin: 15px"
        >Сохранить</el-button
      >
      <el-form-item label="Отправлять статусы партнеру" class="panel-cell">
        <el-checkbox
          v-model="shopIntegrationSettings.SendToMessageQueue"
          size="mini"
        ></el-checkbox>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "ShopIntegrationSettings",
  data: function () {
    return {
      fullScreenLoading: false,
      shopIntegrationSettings: {
        SendToMessageQueue: false,
      },
    };
  },
  methods: {
    startLoading() {
      this.fullScreenLoading = true;
    },
    stopLoading() {
      this.fullScreenLoading = false;
    },
    saveShopIntStgs() {
      this.startLoading();
      var self = this;
      var settingID = this.$route.query.partnerID;
      self.axios
        .post("/ShopEdit/saveShopIntStgs", {
          SettingId: settingID,
          ShopIntSettings: this.shopIntegrationSettings,
        })
        .then(function (response) {
          self.stopLoading();
          self.shopIntegrationSettings = response.data.Data.ShopIntSettings;
          self.showSuccess("Изменения сохранены");
        })
        .catch(function (error) {
          self.stopLoading();
          console.log(error);
        });
    },
    loadData() {
      var self = this;
      var settingID = this.$route.query.partnerID;
      this.startLoading();
      self.axios
        .post("/ShopEdit/getShopIntStgs", {
          SettingId: settingID,
        })
        .then(function (response) {
          self.stopLoading();
          self.shopIntegrationSettings = response.data.Data.ShopIntSettings;
        })
        .catch(function (error) {
          self.stopLoading();
          console.log(error);
        });
    },
    showSuccess(message) {
      this.$message({
        message: message,
        type: "success",
      });
    },
    showError(message) {
      this.$message.error(message);
    },
  },
  mounted: function () {
    this.loadData();
  },
};
</script>

<style scoped>
</style>
