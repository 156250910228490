<template>
  <el-input
   class="moneyControl"
    :disabled="disabled"
    ref="input"
    v-model="stringValue"
    v-currency="currencySettings"
  >
  </el-input>
</template>

<script>
import { setValue, getValue } from "vue-currency-input";
export default {
  name: "MoneyControl",
  props: ["value", "disabled"],
  data: function () {
    return {
      inputDisabled: false,
      dataLoaded: false,
      numberValue: 0,
      stringValue: "0",
      currencySettings: {
        currency: { prefix: null, suffix: " руб" },
        locale: "sv",
        distractionFree: false,
      },
    };
  },
  watch: {
    value(val) {
      if (val != this.numberValue) {
        setValue(this.$refs.input, val);
        this.numberValue = val;
        //this.stringValue = val ? val.toString() : "0";
        
      }
    },
    stringValue(val) {
      this.numberValue = getValue(this.$refs.input);
        if (this.numberValue != this.value) {
          this.$emit("input", this.numberValue);
        }
    },
  },
  mounted: function () {
    setValue(this.$refs.input, 0);
  },
  methods: {},
};
</script>

<style scoped>
.moneyControl >>> .el-input__inner {
  padding: 0px !important;
  text-align: center;
}
</style>
