<template>
  <div>
    <div style="margin: 10px">
      <el-select
        size="mini"
        v-model="schemeId"
        placeholder="Выберите схему"
        @change="handleSchemeChange"
      >
        <el-option
          v-for="item in schemes"
          :key="item.Id"
          :label="item.Name"
          :value="item.Id"
        >
        </el-option>
      </el-select>
      <el-select
        size="mini"
        v-model="mapId"
        placeholder="Выберите карту"
        @change="handleMapChange"
      >
        <el-option
          v-for="item in maps"
          :key="item.Id"
          :label="getMapLabelName(item)"
          :value="item.Id"
        >
        </el-option>
      </el-select>
    </div>
    <div style="margin: 10px">
      <div style="display: inline">Ссылка на карту:</div>
      <a target="_blank" v-bind:href="mapHref">{{ mapHref }}</a>
    </div>
    <div style="margin: 10px">
      <el-button @click="savePolyToZone" size="mini">Сохранить</el-button>
    </div>
    <el-table
      :data="polygonsToZones"
      size="mini"
      :header-cell-class-name="'headerCell'"
    >
      <el-table-column prop="Id" label="Id" align="center"> </el-table-column>
      <el-table-column prop="PolygonId" label="Полигон" align="center">
        <template slot-scope="scope"
          ><div>{{ getPolygonName(scope.row.PolygonId) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="ZoneId" label="Зона" align="center">
        <template slot-scope="scope">
          <el-select
            size="mini"
            v-model="scope.row.ZoneId"
            placeholder="Выберите зону"
          >
            <el-option
              v-for="item in zones"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

        
<script>
import { dates } from "../../scripts/dates";
export default {
  name: "MapToZones",
  props: [],
  data: function () {
    return {
      schemeId: null,
      schemes: [],
      mapId: null,
      maps: [],
      polygonsToZones: [],
      polygons: [],
      loading: false,
      maptypes: [],
    };
  },
  computed: {
    mapHref() {
      const baseLink =
        "https://yandex.ru/maps/?ll=36.438592%2C56.253645&z=8&mode=usermaps&source=constructorLink&um=constructor%";
      if (this.maptypes.length < 1 || !this.mapId) {
        return "";
      }

      let self = this;
      let map = this.maps.find((item) => item.Id == self.mapId);
      if (!map) {
        return "";
      }
      let mapTypeId = map.MapType;
      let mapType = this.maptypes.find((item) => item.Id == mapTypeId);
      if (!mapType) {
        return "";
      }
      return baseLink + mapType.ConstructorId;
    },
    zones() {
      let schemeId = this.schemeId;
      if (!schemeId || !this.schemes) return [];

      let scheme = this.schemes.find((item) => item.Id == schemeId);
      if (!scheme) return [];
      return scheme.Zones;
    },
  },
  methods: {
    getMapLabelName(map) {
      let date = dates.methods.toStringDateTime(map.CreateDate);
      return (map.RegionId == 1 ? "МСК" : "СПБ") + " (" + date + ")";
    },
    getPolygonName(polyId) {
      if (!polyId) return "Полигон не найден";
      let poly = this.polygons.find((item) => item.Id == polyId);
      if (!poly) return "Полигон не найден";
      return poly.Name;
    },
    savePolyToZone() {
      if (!this.mapId) {
        this.showError("Не выбрана карта");
        return;
      }
      if (!this.schemeId) {
        this.showError("Не выбрана схема");
        return;
      }
      if (this.polygonsToZones.find((item) => isNaN(item.ZoneId))) {
        this.showError("Не заполнены все зоны");
        return;
      }

      let self = this;
      self.startLoading();
      this.axios
        .post("/Admin/savePolygonsForZones", {
          mapId: this.mapId,
          schemeId: this.schemeId,
          polygonsToZones: this.polygonsToZones,
        })
        .then(function (response) {
          self.showSuccess("Сохранение завершено");
          self.stopLoading();
        })
        .catch(function (error) {
          self.showError("Произошла ошибка при сохранении");
          self.stopLoading();
          console.log(error);
        });
    },
    handleSchemeChange() {
      let schemeId = this.schemeId;
      let scheme = this.schemes.find((item) => item.Id == schemeId);
      if (scheme) {
        if (scheme.IdMap) {
          this.mapId = scheme.IdMap;
        } else {
          this.mapId = null;
        }
        this.handleMapChange();
      }
    },
    handleMapChange() {
      if (this.schemeId) {
        if (this.mapId) {
          this.getPolygonsForZones(this.schemeId, this.mapId);
        } else {
          this.polygonsToZones = [];
        }
      }
    },
    getPolygonsForZones(schemeId, mapId) {
      var polyToZonesRequest = this.axios.post("/Admin/getPolygonsForZones", {
        mapId: mapId,
        schemeId: schemeId,
      });
      var polygonsRequest = this.axios.post("/Admin/getPolygons", {
        mapId: mapId,
      });
      this.startLoading();
      var self = this;
      this.axios
        .all([polyToZonesRequest, polygonsRequest])
        .then(
          self.axios.spread((...responses) => {
            var responsePolyToZonesData = responses[0];
            var responsePolygonsData = responses[1];
            self.polygonsToZones = responsePolyToZonesData.data.Data;
            self.polygons = responsePolygonsData.data.Data;
            self.stopLoading();
          })
        )
        .catch((error) => {
          console.log(error);
          self.stopLoading();
        });
    },
    loadData() {
      var schemesRequest = this.axios.post("/Admin/getSchemes", {});
      var mapsRequest = this.axios.post("/Admin/getMaps", {});
      var mapTypesRequest = this.axios.post("/Admin/getMapTypes", {});
      this.startLoading();
      var self = this;
      this.axios
        .all([schemesRequest, mapsRequest, mapTypesRequest])
        .then(
          self.axios.spread((...responses) => {
            var responseSchemesData = responses[0];
            var responseMapsData = responses[1];
            var responseMapTypesData = responses[2];
            self.schemes = responseSchemesData.data.Data;
            self.maps = responseMapsData.data.Data;
            self.maptypes = responseMapTypesData.data.Data;
            self.stopLoading();
          })
        )
        .catch((error) => {
          console.log(error);
          self.stopLoading();
        });
    },
    showSuccess(message) {
      this.$message({
        message: message,
        type: "success",
      });
    },
    showError(message) {
      this.$message.error(message);
    },
    startLoading() {
      this.$loading();
    },
    stopLoading() {
      this.$loading().close();
    },
  },
  mounted: function () {
    this.loadData();
  },
};
</script>
