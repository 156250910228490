<template>
<div>
    <div v-show="!visualSettings.dialogRules">
        <el-button style="margin-bottom: 5px;" size="mini" @click="createGroup">Добавить группу</el-button>
        <el-table size="mini" :data="roleGroupsData" border>
            <el-table-column prop="Name" label="Имя группы" align="center">
                <template slot-scope="scope">
                    <div style="display: inline;">{{scope.row.GroupName}}</div>
                </template>
            </el-table-column>
            <el-table-column prop="Count" label="Разрешения" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" @click="openRules(scope.row)">Просмотр</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <div v-show="visualSettings.dialogRules">
        <el-button size="mini" icon="el-icon-back" @click="showRules(false)">Вернуться</el-button>
        <el-button style="margin-bottom: 5px;" size="mini" @click="saveGroupData">Сохранить изменения</el-button>
        <el-form ref="groupRulesData" :model="roleGroupData" :rules="rules" style="width: 50%" label-width="120px">
            <el-form-item label="Имя группы" prop="GroupName">
                <el-input size="mini" v-model="roleGroupData.GroupName"></el-input>
            </el-form-item>
        </el-form>
        <div style="width: 49%; display: inline-flex;">
            <el-table size="mini" :data="roleGroupData.UserRules" border>
                <el-table-column prop="RoleAttrName" label="Разрешения">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.IsAccess">{{scope.row.RoleAttrName}}</el-checkbox>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div style="width: 49%; display: inline-flex;">
            <el-table size="mini" :data="roleGroupData.PagesAccess" border>
                <el-table-column prop="RoleAttrName" label="Доступ к страницам">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.IsAccess">{{scope.row.PageName +'(' + scope.row.PageUrl + ')'}}</el-checkbox>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: "RoleGroup",
  data:function () {
            return {
                roleGroupsData: [],
                roleGroupData: {},
                visualSettings:{
                    dialogRules:false
                },
                RulesDict: [],
                PagesDict: [],
                rules: {
                    GroupName: [{ required: true, message: 'Введите название группы', trigger: 'change' }]
                },
            }
        },
        computed: {
            
        },
        methods: {
            createGroup() {
                let self = this;
                this.roleGroupData = {
                    GroupName: null,
                    UserRules: self._.cloneDeep(this.RulesDict),
                    PagesAccess: self._.cloneDeep(this.PagesDict)
                }
                this.showRules(true);
            },
            openRules(ruleData) {
                let ruleDataDict = ruleData.UserRules.reduce(function(result,item) {
                    if (item.IsAccess) {
                        result[item.RoleAttrID] = true;
                    }
                    return result;
                }, {});
                let pageDataDict = ruleData.PagesAccess.reduce(function (result, item) {
                    if (item.IsAccess) {
                        result[item.PageId] = true;
                    }
                    return result;
                }, {});

                this.roleGroupData = {
                    GroupName: ruleData.GroupName,
                    RoleGroupId:ruleData.RoleGroupId
                }
                this.roleGroupData.UserRules = this._.cloneDeep(this.RulesDict);
                this.roleGroupData.UserRules.map(function(item) {
                    if (ruleDataDict[item.RoleAttrID]) {
                        item.IsAccess = true;
                    }
                    return item;
                });
                this.roleGroupData.PagesAccess = this._.cloneDeep(this.PagesDict);
                this.roleGroupData.PagesAccess.map(function(item) {
                    if (pageDataDict[item.PageId]) {
                        item.IsAccess = true;
                    }
                    return item;
                });
                    
                this.showRules(true);
            },
            showRules(isShow) {
                this.visualSettings.dialogRules = isShow;
            },
            saveGroupData() {
                this.$refs['groupRulesData'].validate((valid) => {
                    if (valid) {
                        this.startLoading();
                        let self = this;
                        this.axios.post('/RoleGroup/saveGroupData',
                                { roleGroup: this.roleGroupData })
                            .then(response => {
                                self.stopLoading();
                                if (response.data.Success) {
                                    self.roleGroupsData = response.data.Data;
                                    self.showRules(false);
                                } else {
                                    self.showError("Произошла ошибка");
                                }
                            })
                            .catch(e => {
                                self.showError("Произошла ошибка");
                                console.log(e);
                            });
                    }
                });
            },
            loadSimpleDictionaries(resp) {
                this.RulesDict = resp.data.RulesList;
                this.PagesDict = resp.data.PagesList;
            },
            startLoading() {
                this.$loading();
            },
            stopLoading() {
                this.$loading().close();
            },
            showSuccess(message) {
                this.$message({
                    message: message,
                    type: 'success'
                });
            },
            showError(message) {
                this.$message.error(message);
            }
        },
        mounted:function() {
            let self = this;
            self.startLoading();
            let dictRequest = this.axios.post('/RoleGroup/getDictionaries', {});
            let groupsDataRequest = this.axios.post('/RoleGroup/getRoleGroups', {});
            this.axios.all([dictRequest, groupsDataRequest])
                .then(self.axios.spread((...responses) => {
                    self.loadSimpleDictionaries(responses[0]);
                    self.roleGroupsData = responses[1].data.Data;
                    self.stopLoading();
                })).catch(e => {
                    self.stopLoading();
                    self.showError("Произошла ошибка");
                    console.log(e);
                });
        }
  }
  </script>