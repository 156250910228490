<template>
  <div>
    <div
      style="
        position: fixed;
        width: 100%;
        top: 0;
        background: white;
        z-index: 100;
        max-height: 105px;
      "
    >
      <Header />
      <Navigation />
    </div>

    <router-view style="margin-top: 105px;" />
  </div>
</template>

<script>
import Header from "./Header.vue";
import Navigation from "./Navigation.vue";

export default {
  name: "mainPage",
  components: {
    Header,
    Navigation,
  },
};
</script>