<template>
  <el-select size="mini" v-model="statusState" @change="handleChange">
    <el-option
      v-for="item in StatusStateAllList"
      :key="item.Key"
      :label="item.Name"
      :value="item.Key"
    ></el-option>
  </el-select>
</template>
<script>
export default {
  name: "StatusStateListControl",
  props: ["value"],
  data: function () {
    return {
      statusState: 0
    };
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal != oldVal) {
        this.statusState = newVal;
      }
    },
  },
  computed: {
    StatusStateAllList() {
      return [{ Key: 0, Name: "Все" }, ...this.$store.getters.statusStateList];
    },
  },
  methods: {
    handleChange(val) {
      this.$emit("input", val);
    },
  },
};
</script>