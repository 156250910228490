<template>
  <div>
    <el-select size="mini" v-model="regValue" @change="setUserLocation" style="width: 130px;">
      <el-option
        v-for="item in locList"
        :key="item.Key"
        :label="item.Name"
        :value="item.Key"
      >
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: "UserLocation",
  data: function () {
    return {
      regValue: 1,
      locList: [
        { Key: 1, Name: "МСК И МО" },
        { Key: 2, Name: "СПБ И ЛО" },
      ],
      mskCoords: {
        minLattitude: 54.272757,
        maxLattitude: 56.934991,
        minLongitude: 35.141417,
        maxLongitude: 38.668122,
      },
      spbCoords: {
        minLattitude: 58.43068,
        maxLattitude: 61.296179,
        minLongitude: 26.601048,
        maxLongitude: 35.721138,
      },
      latitude: 0,
      longitude: 0,
    };
  },
  methods: {
    setUserLocation() {
      let self = this;
      this.$store
        .dispatch("setUserLocation", this.regValue )
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    },
    getRegion(lattitude, longitude) {
      //MSK
      if (this.pointInPoly(lattitude, longitude, this.mskCoords)) {
        return 1;
      }
      //SPB
      if (this.pointInPoly(lattitude, longitude, this.spbCoords)) {
        return 2;
      }
      return 1;
    },
    pointInPoly(lattitude, longitude, coords) {
      if (
        lattitude >= coords.minLattitude &&
        lattitude <= coords.maxLattitude &&
        longitude >= coords.minLongitude &&
        longitude <= coords.maxLongitude
      )
        return true;
      return false;
    },
    onLoad() {
      let self = this;
      let curLocation = this.$store.getters.currentLocation;
      if(curLocation){
this.regValue = curLocation;
      }
      else {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            let regionId = this.getRegion(
              position.coords.latitude,
              position.coords.longitude
            );
            if (self.regValue != regionId) {
              self.setUserLocation();
              self.regValue = regionId;
            }
          },
          (error) => {
            console.log(error.message);
          }
        );
      }
    },
  },
  mounted: function () {
    this.onLoad();
  },
};
</script>

<style scoped>
</style>
