<template>
  <div class="vueTemplate">
    <el-tabs type="border-card" :value="'first'">
      <el-tab-pane label="Привязка зон к полигонам" name="first" :lazy="true">
        <div style="min-height: 400px">
          <MapToZones></MapToZones>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Привязка расписаний к зонам" name="second" :lazy="true">
        <div style="min-height: 400px">
          <ZonesTimetable></ZonesTimetable>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Управление зонами" name="third" :lazy="true">
        <div style="min-height: 400px">
          <Zones></Zones>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import MapToZones from "./AdminComponents/MapToZones.vue";
import ZonesTimetable from "./AdminComponents/ZonesTimetable.vue";
import Zones from "./AdminComponents/Zones.vue";
export default {
  name: "Admin",
  components: {
    MapToZones,
    ZonesTimetable,
    Zones
  },
  data: function () {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
</style>
