<template>
  <div>
  <div></div>
    <el-button
      :disabled="!IsAccessToAddOrderDocs"
      size="mini"
      style="margin: 10px"
      @click="showDialogAddFile(true)"
      >Добавить файл</el-button
    >
    <el-table :data="tableData" size="mini">
      <el-table-column prop="NameType" label="Тип документа" align="center">
      </el-table-column>
      <el-table-column prop="DocumentNumber" label="Номер" align="center">
      </el-table-column>
      <el-table-column
        prop="DocumentDate"
        label="Дата"
        align="center"
        width="250"
      >
        <template slot-scope="scope">
          <div>{{ getDateTime(scope.row.DocumentDate) }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="FileName" label="Название" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.FileName + "." + scope.row.Ext }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="FileId" label="Документ" align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            icon="el-icon-download"
            @click="downloadFile(scope.row)"
          ></el-button>
        </template>
      </el-table-column>
      <el-table-column prop="FileId" label="Просмотр" align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            icon="el-icon-view"
            @click="showDoc(scope.row)"
          ></el-button>
        </template>
      </el-table-column>
      <el-table-column prop="FileId" label="Удалить" align="center">
        <template slot-scope="scope">
          <el-button
            :disabled="!IsAccessToRemoveOrderDocs"
            size="mini"
            icon="el-icon-delete"
            @click="removeDoc(scope.row, scope.$index)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="Добавление файла"
      :visible.sync="dialogVisible"
      width="80%"
    >
      <el-form ref="formFile" :model="formFile" label-width="150px">
        <el-form-item size="mini">
          <el-upload
            ref="upload"
            action=""
            :auto-upload="false"
            :file-list="fileList"
            :on-change="handleUploadChange"
            :on-remove="handleRemove"
          >
            <el-button slot="trigger" size="small" type="primary"
              >Выбрать файл</el-button
            >
          </el-upload>
        </el-form-item>
        <el-form-item size="mini" label="Тип документа" prop="name">
          <el-select
            v-model="formFile.FileTypeId"
            placeholder="Выберите тип документа"
          >
            <el-option
              v-for="item in fileTypes"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item size="mini" label="Название файла" prop="name">
          <el-input disabled v-model="formFile.FileName"></el-input>
        </el-form-item>
        <el-form-item size="mini" label="Расширение файла" prop="name">
          <el-input disabled v-model="formFile.FileExt"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialogAddFile(false)">Отмена</el-button>
        <el-button :disabled="!IsAddFileEnable" type="primary" @click="addFile"
          >Добавить</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { dates } from "../../scripts/dates";
import { files } from "../../scripts/files";
export default {
  name: "OrderComponentDocs",
  props: ["orderid"],
  data: function () {
    return {
      formFile: {
        FileName: null,
        FileTypeId: null,
        FileExt: null,
      },
      dialogVisible: false,
      fileList: [],
      tableData: [],
      fileTypes: [
        { Id: 1, Name: "Приходная накладная" },
        { Id: 2, Name: "Бланк заказа" },
        { Id: 3, Name: "Накладная на возврат" },
        { Id: 4, Name: "Акт о браке" },
        { Id: 5, Name: "Акт о недовложении" },
        { Id: 6, Name: "Фото товара" },
        { Id: 7, Name: "Бесконтактная доставка" },
        { Id: 501, Name: "Акт товарного отклонения с Клиентом" },
      ],
    };
  },
  computed: {
    IsAccessToRemoveOrderDocs() {
      return this.$store.getters.ruleSettings.IsAccessToRemoveOrderDocs;
    },
    IsAccessToAddOrderDocs() {
      return this.$store.getters.ruleSettings.IsAccessToAddOrderDocs;
    },
    IsAddFileEnable() {
      return this.fileList.length > 0 && this.formFile.FileTypeId;
    },
  },
  watch: {
    orderid: function (newVal, oldVal) {
      if (newVal > 0) {
        var self = this;
        this.axios
          .post("/OrderEdit/getDocsList", {
            orderId: newVal,
          })
          .then(function (response) {
            self.tableData = response.data.Data;
          })
          .catch(function (error) {});
      }
    },
  },
  methods: {
    showError(message) {
      this.$message.error(message);
    },
    addFile() {
      if (this.fileList.length > 0) {
        let self = this;
        files.methods
          .readFileAsBase64(this.fileList[0])
          .then((fileB64) => {
            let doc = {
              FileName: self.formFile.FileName,
              Ext: self.formFile.FileExt,
              FileTypeId: self.formFile.FileTypeId,
            };
            self.axios
              .post("/OrderEdit/addDoc", {
                orderId: self.orderid,
                doc: doc,
                fileDataB64: fileB64.data,
              })
              .then(function (response) {
                self.tableData = response.data.Data;
                self.showDialogAddFile(false);
              })
              .catch(function (error) {
                self.showError("Произошла ошибка");
              });
          })
          .catch(function (error) {
            self.showError("Произошла ошибка");
          });
      }
    },
    showDialogAddFile(isShow) {
      this.dialogVisible = isShow;
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
      this.formFile.FileName = null;
      this.formFile.FileExt = null;
    },
    handleUploadChange(file, fileList) {
      this.$refs.upload.clearFiles();
      let fList = [];
      fList.push(file);
      this.fileList = fList;
      this.formFile.FileName = files.methods.getFileName(file.name);
      this.formFile.FileExt = files.methods.getFileExt(file.name);
    },
    getDocsList(orderId) {
      if (orderId > 0) {
        var self = this;
        this.axios
          .post("/OrderEdit/getDocsList", {
            orderId: orderId,
          })
          .then(function (response) {
            self.tableData = response.data.Data;
          })
          .catch(function (error) {});
      }
    },
    getDateTime(dat) {
      return dates.methods.toStringDateTime(dat);
    },
    removeDoc(item, index) {
      let self = this;
      this.$confirm("Удалить документ?")
        .then((_) => {
          this.axios
            .post("/OrderEdit/removeDoc", {
              fileId: item.FileId,
            })
            .then(function (response) {
              self.tableData.splice(index, 1);
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch((_) => {});
    },
    showDoc(item) {
      let self = this;
      this.axios
        .post("/OrderEdit/getDoc", {
          fileId: item.FileId,
          asBase64: false,
        })
        .then(function (response) {
          self.$helpMethods.showBase64Doc(
            response.data.Data.FileExt,
            response.data.Data.FileData
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    downloadFile(doc) {
      let self = this;
      this.axios
        .post("/OrderEdit/getDoc", {
          fileId: doc.FileId,
          asBase64: false,
        })
        .then(function (response) {
          self.$helpMethods.downloadBase64Doc(
            response.data.Data.FileName +
              "." +
              response.data.Data.FileExt.toLowerCase(),
            response.data.Data.FileData
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  mounted: function () {
    this.getDocsList(this.orderid);
  },
};
</script>

<style scoped>
</style>
