<template>
  <div>
    <div>
      <el-input
      size="mini"
      style="width: auto"
      placeholder="Логин"
      v-model="username"
    ></el-input>
    <el-input
      size="mini"
      style="width: auto"
      placeholder="Пароль"
      v-model="password"
      show-password
    ></el-input>
    <el-button size="mini" @click="login">Войти</el-button>
      </div>
    <div v-show="isError">{{errorMessage}}</div>
  </div>
</template>

<script>
export default {
  name: "SignIn",
  data: function () {
    return {
      username: "",
      password: "",
      isError:false,
      errorMessage:"Неверный логин или пароль"
    };
  },
  methods: {
    showError(message) {
      this.$message.error(message);
    },
    login() {
      let username = this.username;
      let password = this.password;
      let self = this;
      this.$store
        .dispatch("login", { username, password })
        .then(() => {
          self.isError = false;
          //if (self.$route.path !== "/")
          //self.$router.push("/")
          })
        .catch((err) => {
          self.isError = true;
          self.errorMessage = err.data.message;
          self.password = "";
        });
    },
  },
  mounted() {},
};
</script>