<template>
  <div class="groupOperations">
    <div style="padding: 10px">
      <el-button size="mini" @click="showDialogOrderList(true)"
        >Поиск по списку номеров</el-button
      >
      <el-button size="mini" @click="showDialogOrderImportList(true)"
        >Поиск по списку внешних номеров</el-button
      >
    </div>
    <div>
      <div class="block" style="display: inline">
        <span>Вариант обработки </span
        ><el-select size="mini" v-model="operationValue">
          <el-option
            v-for="item in OperationsList"
            :key="item.Key"
            :label="item.Name"
            :value="item.Key"
          ></el-option>
        </el-select>
      </div>
      <div class="block" style="display: inline">
        <span>Действие </span
        ><el-select size="mini" v-model="actionValue">
          <el-option
            v-for="item in ActionsList"
            :key="item.Key"
            :label="item.Name"
            :value="item.Key"
          ></el-option>
        </el-select>
      </div>
      <el-button
        :disabled="operationValue == null || actionValue == null"
        size="mini"
        @click="doOperation"
        >Выполнить</el-button
      >
    </div>
    <div style="padding: 10px">
      <el-table
        ref="ordersTable"
        size="mini"
        :data="searchData"
        border
        style="width: 100%"
        header-cell-class-name="headCell"
      >
        <el-table-column
          prop="OrderId"
          label="Номер стриж"
          align="center"
          className="borderBCell"
        >
          <template slot-scope="scope">
            <a
              :href="getHref(scope.row.OrderId, scope.row.TypeDeliveryId)"
              target="_blank"
              >{{ scope.row.OrderId }}</a
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="OrderIdImport"
          label="Номер партнер"
          align="center"
          className="borderCell"
        >
        </el-table-column>
        <el-table-column
          prop="Oversized"
          label="Признак негабаритного заказа"
          align="center"
          className="borderBCell"
        >
          <template slot-scope="scope">
            <el-checkbox disabled v-model="scope.row.Oversized"></el-checkbox>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      width="30%"
      :center="true"
      title="Поиск по списку номеров"
      :visible.sync="dialogOrderIdIsVisible"
    >
      <span style="text-align: center">
        <div style="margin: 10px">
          <el-input
            type="textarea"
            :rows="10"
            placeholder="Введите номера заказов"
            v-model="searchSettings.OrderIdList"
          >
          </el-input>
          <div>{{ "Заказов " + this.OrdersParseCnt }}</div>
          <el-button @click="searchOrderList" size="mini">Найти</el-button>
          <el-button @click="showDialogOrderList(false)" size="mini"
            >Закрыть</el-button
          >
        </div>
      </span>
    </el-dialog>
    <el-dialog
      width="30%"
      :center="true"
      title="Поиск по списку внешних номеров"
      :visible.sync="dialogOrderIdImportIsVisible"
    >
      <span style="text-align: center">
        <div style="margin: 10px">
          <el-input
            type="textarea"
            :rows="10"
            placeholder="Введите внешние номера заказов"
            v-model="searchSettings.OrderIdImportList"
          >
          </el-input>
          <div>{{ "Заказов " + this.OrdersImportParseCnt }}</div>
          <el-button @click="searchOrderImportList" size="mini"
            >Найти</el-button
          >
          <el-button @click="showDialogOrderImportList(false)" size="mini"
            >Закрыть</el-button
          >
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "GroupOperations",
  data: function () {
    return {
      searchSettings: {
        OrderIdList: null,
        OrderIdImportList: null,
      },
      dialogOrderIdIsVisible: false,
      dialogOrderIdImportIsVisible: false,
      searchData: [],
      operationValue: null,
      actionValue: null,
      OperationsList: [{ Key: 0, Name: "Признак КГТ" }],
      ActionsList: [
        { Key: 0, Name: "Установить" },
        { Key: 1, Name: "Снять" },
      ],
    };
  },
  methods: {
    doOperation() {
      if (this.actionValue != null && this.operationValue != null) {
        let self = this;
        let orders = self.searchData.map(function (item) {
          return item.OrderId;
        });
        self.startLoading();
        this.axios
          .post("/GroupOperations/doOperation", {
            ordersId: orders,
            operationId: self.operationValue,
            actionId: self.actionValue,
          })
          .then((resp) => {
            self.searchData = resp.data.Orders;
            self.stopLoading();
          })
          .catch((error) => {
            console.log(error);
            self.stopLoading();
            self.showError("Произошла ошибка!");
          });
      }
    },
    getHref(orderId, type) {
      let href = this.$helpMethods.getOrderHref(orderId, type);
      return href;
    },
    startLoading() {
      this.$loading();
    },
    stopLoading() {
      this.$loading().close();
    },
    showError(message) {
      this.$message.error(message);
    },
    searchOrderList() {
      this.showDialogOrderList(false);
      this.getData();
    },
    searchOrderImportList() {
      this.showDialogOrderImportList(false);
      this.getData();
    },
    showDialogOrderImportList(isShow) {
      this.dialogOrderIdImportIsVisible = isShow;
    },
    showDialogOrderList(isShow) {
      this.dialogOrderIdIsVisible = isShow;
    },
    getFilterExpressions() {
      var fil = {};
      if (this.searchSettings.OrderIdList)
        fil.OrdersList = this.searchSettings.OrderIdList;
      if (this.searchSettings.OrderIdImportList)
        fil.OrdersImportList = this.searchSettings.OrderIdImportList;
      return fil;
    },
    getData() {
      let self = this;
      self.startLoading();
      this.axios
        .post("/OrderSearch/GetOrdersByFilter", {
          startRowIndex: 0,
          maximumRows: 1000,
          sortExpressions: "",
          filterExpression: this.getFilterExpressions(),
        })
        .then((resp) => {
          //self.$refs.ordersTable.layout.updateElsHeight();
          self.searchData = resp.data.Data.Orders;
          self.stopLoading();
        })
        .catch((error) => {
          console.log(error);
          self.stopLoading();
          self.showError("Произошла ошибка!");
        });
    },
  },
  computed: {
    OrdersParseCnt() {
      if (this.searchSettings.OrderIdList == null) return 0;
      let cnt = 0;
      this.searchSettings.OrderIdList.split("\n").forEach(function (item) {
        if (!isNaN(item)) {
          cnt++;
        }
      });
      return cnt;
    },
    OrdersImportParseCnt() {
      if (this.searchSettings.OrderIdImportList == null) return 0;
      let cnt = this.searchSettings.OrderIdImportList.split("\n").length;
      return cnt;
    },
  },
};
</script>

<style scoped>
.groupOperations >>> .block {
  padding: 0 7px;
}
</style>
