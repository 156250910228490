<template>
  <treeselect style="width: 200px;display: inline-block;position:relative;top: 7px;height:28px;" :defaultExpandLevel="defExpandLevel" placeholder ="Выберите..." v-model="shop" :options="ShopsAll" :clearable="false" @select="handleChange"/>
</template>

<script>
 import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  name: "ShopsListControl",
  components: { Treeselect },
  props: ["initVal", "clearVal", "allOption", "isEnable","defExpandLevel"],
  data: function () {
    return {
      shop: null,
      shopOptions: [],
    };
  },
  watch: {
    clearVal() {
      this.shop = this.getInitVal();
      this.handleChange(this.shop);
    },
  },
  methods: {
    getInitVal() {
      if (this.initVal == 0) {
        return 0;
      }
      return this.initVal;
    },
    filterList(node, keyword) {
      return node.text.toLowerCase().includes(keyword.toLowerCase());
    },
    handleChange(shop) {
      let val = shop.id;
      
      this.$emit("input", val);
    },
    sortBy(a,b){
      return ("" + a).localeCompare(b,'en');
    },
    getHierarhy(orgLst) {
      let self = this;
      let mainOrgs = orgLst
        .filter((org) => !org.ParentId)
        .reduce(function (map, item) {
          map[item.InternetShopId] = {
            id: item.InternetShopId,
            label: item.Name,
          };
          return map;
        }, {});
      orgLst.sort(function(a,b){return self.sortBy(a.Name,b.Name)})
        .filter((org) => !!org.ParentId)
        .forEach(function (item) {
          let mainOrg = mainOrgs[item.ParentId];
          if (mainOrg) {
            if (!mainOrgs[item.ParentId].children) {
              mainOrgs[item.ParentId].children = [];
            }
            mainOrgs[item.ParentId].children.push({
              id: item.InternetShopId,
              label: item.Name,
            });
          }
        });
        let resultOrgs = Object.values(mainOrgs).sort(function(a,b){
          return self.sortBy(a.label,b.label);
        });
      return resultOrgs;
    },
  },
  computed: {
    ShopsAll() {
      if (this.allOption) {
        return [{ id: 0, label: "Все",children:this.shopOptions }];
      }
      return this.shopOptions;
    },
  },
  mounted: function () {
    if (this.isEnable) {
      this.shopOptions = this.getHierarhy(
        this.$store.getters.organizationList.filter((item) => item.IsEnable)
      );
    } else {
      this.shopOptions = this.getHierarhy(this.$store.getters.organizationList);
    }

    this.shop = this.getInitVal();
  },
};
</script>

<style scoped>
</style>
