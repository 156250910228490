<template>
  <div>
    <el-tabs type="border-card" :value="'first'">
      <el-tab-pane label="Рейсы" name="first">
        <div style="min-height: 390px; padding-top: 10px">
          <el-date-picker
            :clearable="false"
            size="mini"
            style="width: 125px"
            v-model="tripDate"
            type="date"
            :picker-options="tripDatePickOpts"
            format="dd.MM.yyyy"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
          <el-button size="mini" @click="searchTrips">Поиск</el-button>
          <el-button size="mini" @click="addTrip">Добавить рейс</el-button>
          <el-table :data="tableTrips" style="width: 100%">
            <el-table-column prop="TripId" label="Номер рейса" align="center">
            </el-table-column>
            <el-table-column prop="date" label="Дата рейса" align="center">
              <template slot-scope="scope">
                <el-date-picker
                  :disabled="!scope.row.IsEdit"
                  size="mini"
                  style="width: 125px"
                  v-model="scope.row.TripDate"
                  type="date"
                  :picker-options="tripDatePickOpts"
                  format="dd.MM.yyyy"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="Авто" align="center">
              <template slot-scope="scope">
                <el-select
                  :disabled="!scope.row.IsEdit"
                  size="mini"
                  v-model="scope.row.CarId"
                  placeholder="Выберите из списка"
                  filterable
                >
                  <el-option
                    v-for="item in CarList"
                    :key="item.CarId"
                    :label="item.CarNumber"
                    :value="item.CarId"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="Телефон" align="center">
            </el-table-column>
            <el-table-column label="Водитель" align="center">
              <template slot-scope="scope">
                <el-select
                  :disabled="!scope.row.IsEdit"
                  size="mini"
                  v-model="scope.row.DriverId"
                  placeholder="Выберите из списка"
                  filterable
                >
                  <el-option
                    v-for="item in DriverList"
                    :key="item.ManId"
                    :label="item.FIO"
                    :value="item.ManId"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="Экспедитор" align="center">
              <template slot-scope="scope">
                <el-select
                  :disabled="!scope.row.IsEdit"
                  size="mini"
                  v-model="scope.row.ForwarderId"
                  placeholder="Выберите из списка"
                  filterable
                >
                  <el-option
                    v-for="item in ForwarderList"
                    :key="item.ManId"
                    :label="item.FIO"
                    :value="item.ManId"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="Редактирование" align="center">
              <template slot-scope="scope">
                <el-button
                  v-show="!scope.row.IsEdit"
                  size="mini"
                  @click="editTrip(scope.$index)"
                  >Изменить</el-button
                >
                <el-button
                  v-show="scope.row.IsEdit"
                  size="mini"
                  @click="saveTrip(scope.row, scope.$index)"
                  >Сохранить</el-button
                >
                <el-button
                  v-show="scope.row.IsEdit"
                  size="mini"
                  @click="deleteTrip(scope.row, scope.$index)"
                  >Удалить</el-button
                >
                <el-button
                  v-show="scope.row.IsEdit"
                  size="mini"
                  @click="cancelTrip(scope.$index)"
                  >Отмена</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div></el-tab-pane
      >
      <el-tab-pane label="Привязка к заказам" name="second">
        <div style="min-height: 390px; padding-top: 10px">
          <el-date-picker
            :clearable="false"
            size="mini"
            style="width: 125px"
            v-model="deliveryDate"
            type="date"
            :picker-options="tripDatePickOpts"
            format="dd.MM.yyyy"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
          <el-button size="mini" @click="searchOrders">Поиск</el-button>
          <el-select
            size="mini"
            v-model="orderTripId"
            placeholder="Выберите рейс"
            filterable
          >
            <el-option
              v-for="item in OrderTripsList"
              :key="item.Key"
              :label="item.Name"
              :value="item.Key"
            >
            </el-option>
          </el-select>
          <el-button size="mini" @click="bindOrders"
            >Привязать к рейсу</el-button
          >
          <el-button size="mini" @click="unbindOrders"
            >Отвязать заказы от рейса</el-button
          >
          <el-table
            :data="tableOrders"
            style="width: 100%"
            @selection-change="handleSelectionOrders"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column prop="OrderId" label="Номер заказа" align="center">
            </el-table-column>
            <el-table-column prop="CarNumber" label="Авто" align="center">
            </el-table-column>
            <el-table-column prop="TripId" label="Номер рейса" align="center">
            </el-table-column>
          </el-table></div
      ></el-tab-pane>
      <el-tab-pane label="Авто" name="third">
        <div style="min-height: 390px; padding-top: 10px">
          <el-button size="mini" @click="addCar">Добавить авто</el-button>
          <el-table :data="CarList" style="width: 100%">
            <el-table-column prop="CarId" label="Id" align="center">
            </el-table-column>
            <el-table-column prop="CarNumber" label="Номер авто" align="center">
              <template slot-scope="scope">
                <el-input
                  :disabled="!scope.row.IsEdit"
                  v-model="scope.row.CarNumber"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="CarModel" label="Модель авто" align="center">
              <template slot-scope="scope">
                <el-input
                  :disabled="!scope.row.IsEdit"
                  v-model="scope.row.CarModel"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="CarPhone" label="Телефон" align="center">
              <template slot-scope="scope">
                <el-input
                  :disabled="!scope.row.IsEdit"
                  v-model="scope.row.CarPhone"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="Редактирование" align="center">
              <template slot-scope="scope">
                <el-button
                  v-show="!scope.row.IsEdit"
                  size="mini"
                  @click="editCar(scope.$index)"
                  >Изменить</el-button
                >
                <el-button
                  v-show="scope.row.IsEdit"
                  size="mini"
                  @click="saveCar(scope.row, scope.$index)"
                  >Сохранить</el-button
                >
                <el-button
                  v-show="scope.row.IsEdit"
                  size="mini"
                  @click="deleteCar(scope.row, scope.$index)"
                  >Удалить</el-button
                >
                <el-button
                  v-show="scope.row.IsEdit"
                  size="mini"
                  @click="cancelCar(scope.$index)"
                  >Отмена</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Экипажи" name="fourth">
        <div style="min-height: 390px; padding-top: 10px">
          <el-button size="mini" @click="addMan">Добавить экипаж</el-button>
          <el-table :data="ManList" style="width: 100%">
            <el-table-column prop="ManId" label="Id" align="center">
            </el-table-column>
            <el-table-column prop="FIO" label="ФИО" align="center">
              <template slot-scope="scope">
                <el-input
                  size="mini"
                  :disabled="!scope.row.IsEdit"
                  v-model="scope.row.FIO"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="Тип" align="center">
              <template slot-scope="scope">
                <el-select
                  :disabled="!scope.row.IsEdit"
                  size="mini"
                  v-model="scope.row.ManType"
                  placeholder="Выберите тип"
                >
                  <el-option
                    v-for="item in ManTypeList"
                    :key="item.Key"
                    :label="item.Name"
                    :value="item.Key"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="Редактирование" align="center">
              <template slot-scope="scope">
                <el-button
                  v-show="!scope.row.IsEdit"
                  size="mini"
                  @click="editMan(scope.$index)"
                  >Изменить</el-button
                >
                <el-button
                  v-show="scope.row.IsEdit"
                  size="mini"
                  @click="saveMan(scope.row, scope.$index)"
                  >Сохранить</el-button
                >
                <el-button
                  v-show="scope.row.IsEdit"
                  size="mini"
                  @click="deleteMan(scope.row, scope.$index)"
                  >Удалить</el-button
                >
                <el-button
                  v-show="scope.row.IsEdit"
                  size="mini"
                  @click="cancelMan(scope.$index)"
                  >Отмена</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Выставление статусов" name="fifth">
        <div style="min-height: 390px; padding-top: 10px">
          <el-date-picker
            :clearable="false"
            size="mini"
            style="width: 125px"
            v-model="statusDeliveryDate"
            type="date"
            :picker-options="tripDatePickOpts"
            format="dd.MM.yyyy"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
          <el-button size="mini" @click="searchOrdersStatus">Поиск</el-button>
          <el-button size="mini" @click="finalizeDay">Закрыть день</el-button>
          <el-table :data="tableOrdersStatus" style="width: 100%">
            <el-table-column prop="OrderId" label="Номер заказа" align="center">
            </el-table-column>
            <el-table-column prop="OrderIdImport" label="Номер партнер" align="center">
            </el-table-column>
            <el-table-column prop="TripId" label="Номер рейса" align="center">
            </el-table-column>
            <el-table-column
              prop="StatusId"
              label="Текущий статус"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="FinalStatusId"
              label="Финальный статус"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="StatusStateName"
              label="Состояние"
              align="center"
            >
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  v-show="
                    scope.row.StatusState != 3 && scope.row.StatusState != 4
                  "
                  @click="setOrderStatus(scope.$index, 231)"
                  >Доставлен</el-button
                ></template
              >
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  v-show="
                    scope.row.StatusState != 3 && scope.row.StatusState != 4
                  "
                  @click="setOrderStatus(scope.$index, 235, 4)"
                  >Отказ</el-button
                ></template
              >
            </el-table-column>
            <el-table-column>
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  v-show="
                    scope.row.StatusState != 3 && scope.row.StatusState != 4
                  "
                  @click="showDialogDeliveryDate(true, scope.$index)"
                  >Перенос</el-button
                ></template
              >
            </el-table-column>
          </el-table>
        </div>
        <el-dialog
          width="30%"
          :center="true"
          title="Перенести заказ?"
          :visible.sync="dialogEditDeliveryDate.showDialog"
        >
          <span style="text-align: center">
            <div style="margin: 10px">
              <el-button @click="confirmChangeDeliveryDate" size="mini"
                >Перенести</el-button
              >
              <el-button @click="showDialogDeliveryDate(false)" size="mini"
                >Нет</el-button
              >
            </div>
            <div style="margin: 10px">
              <el-date-picker
                v-model="dialogEditDeliveryDate.DeliveryDate"
                :picker-options="dialogEditDeliveryDate.delivDateChangePickOpts"
                type="date"
                format="dd.MM.yyyy"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div style="margin: 10px">
              <div
                v-if="dialogEditDeliveryDate.TransErrorMessage"
                class="el-icon-error"
              >
                {{ dialogEditDeliveryDate.TransErrorMessage }}
              </div>
              <div>
                <el-select
                  v-model="dialogEditDeliveryDate.TransReason"
                  placeholder="Причина переноса"
                >
                  <el-option
                    v-for="item in TransReasonList"
                    :key="item.Id"
                    :label="item.Name"
                    :value="item.Id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
          </span> </el-dialog
      ></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { dates } from "../scripts/dates";
export default {
  name: "Trip",
  data: function () {
    let todayStr = dates.methods.todayStr();
    let tomorrowStr = dates.methods.tomorrowStr();
    var today = dates.methods.today();

    var checkChangeDelivDate = (date) => {
      return date < today;
    };

    return {
      tomorrowStr: tomorrowStr,
      tripDate: tomorrowStr,
      deliveryDate: tomorrowStr,
      statusDeliveryDate: tomorrowStr,
      tableTrips: [],
      tableOrders: [],
      tableOrdersSel: [],
      tableOrderTrips: [],
      orderTripId: null,
      tableOrdersStatus: [],
      tripDatePickOpts: {
        firstDayOfWeek: 1,
      },
      dialogEditDeliveryDate: {
        showDialog: false,
        OrderId: null,
        TransErrorMessage: null,
        TransReason: null,
        index: null,
        delivDateChangePickOpts: {
          disabledDate: checkChangeDelivDate,
          firstDayOfWeek: 1,
        },
      },
      CarList: [],
      ManList: [],
      ManTypeList: [
        { Key: 1, Name: "Водитель" },
        { Key: 2, Name: "Экспедитор" },
      ],
      TransReasonList: [],
    };
  },
  computed: {
    OrderTripsList() {
      let carDict = this.CarList.reduce(function (result, item) {
        if (item.CarId) {
          result[item.CarId] = item.CarNumber;
        }
        return result;
      }, {});
      return this.tableOrderTrips.map(function (item) {
        return {
          Key: item.TripId,
          Name: carDict[item.CarId] ? carDict[item.CarId] : item.CarId,
        };
      });
    },
    DriverList() {
      return this.ManList.filter((item) => item.ManId && item.ManType == 1);
    },
    ForwarderList() {
      return this.ManList.filter((item) => item.ManId && item.ManType == 2);
    },
  },
  methods: {
    confirmChangeDeliveryDate() {
      if (!this.dialogEditDeliveryDate.DeliveryDate) {
        this.dialogEditDeliveryDate.TransErrorMessage =
          "Не выбрана дата доставки";
      } else if (!this.dialogEditDeliveryDate.TransReason) {
        this.dialogEditDeliveryDate.TransErrorMessage =
          "Не выбрана причина переноса";
      } else {
        this.dialogEditDeliveryDate.TransErrorMessage = "";
        this.dialogEditDeliveryDate.showDialog = false;
        var self = this;
        self.axios
          .post("/Trip/changeDeliveryDate", {
            orderId: self.dialogEditDeliveryDate.OrderId,
            deliveryDate: self.dialogEditDeliveryDate.DeliveryDate,
            reasonId: self.dialogEditDeliveryDate.TransReason,
          })
          .then((resp) => {
            self.$set(
              self.tableOrdersStatus,
              self.dialogEditDeliveryDate.index,
              resp.data
            );
          });
      }
    },
    showDialogDeliveryDate(isShow, index) {
      this.dialogEditDeliveryDate.showDialog = isShow;
      if (isShow) {
        this.dialogEditDeliveryDate.OrderId =
          this.tableOrdersStatus[index].OrderId;
        this.dialogEditDeliveryDate.index = index;
      }
    },
    finalizeDay() {
      let orders = this.tableOrdersStatus.map(function (item) {
        return item.OrderId;
      });

      let self = this;
      this.axios
        .post("/Trip/finalizeDay", {
          orders: orders,
        })
        .then((resp) => {
          self.tableOrdersStatus = resp.data.Data;
        })
        .catch((error) => {
          console.log(error);
          self.stopLoading();
          self.showError("Произошла ошибка!");
        });
    },
    setOrderFinalStatus(row, index, statusState) {
      let self = this;
      this.axios
        .post("/Trip/setOrderFinalStatus", {
          orderId: row.OrderId,
          statusState: statusState,
          tripDate: self.statusDeliveryDate,
        })
        .then((resp) => {
          self.$set(self.tableOrdersStatus, index, resp.data.Data);
        })
        .catch((error) => {
          console.log(error);
          self.stopLoading();
          self.showError("Произошла ошибка!");
        });
    },
    setOrderStatus(index, statusId, statusState) {
      let self = this;
      this.axios
        .post("/Trip/setOrderStatus", {
          orderId: self.tableOrdersStatus[index].OrderId,
          statusId: statusId,
          statusState: statusState ? statusState : null,
        })
        .then((resp) => {
          self.$set(self.tableOrdersStatus, index, resp.data.Data);
        })
        .catch((error) => {
          console.log(error);
          self.stopLoading();
          self.showError("Произошла ошибка!");
        });
    },
    searchOrdersStatus() {
      let self = this;
      this.axios
        .post("/Trip/searchOrdersStatus", {
          tripDate: self.statusDeliveryDate,
        })
        .then((resp) => {
          self.tableOrdersStatus = resp.data.Data;
        })
        .catch((error) => {
          console.log(error);
          self.stopLoading();
          self.showError("Произошла ошибка!");
        });
    },
    saveMan(row, index) {
      let self = this;
      if (row.FIO && row.ManType) {
        self.startLoading();
        this.axios
          .post("/Trip/saveMan", {
            man: row,
          })
          .then((resp) => {
            self.stopLoading();
            if (!resp.data.Success) {
              self.showError(
                "Изменение запрещено! Есть рейсы, привязанные к экипажу"
              );
            } else {
              self.$set(self.ManList, index, resp.data.Data);
            }
          })
          .catch((e) => {
            self.stopLoading();
            self.showError("Произошла ошибка");
            console.log(e);
          });
      } else {
        self.showError("Не задано ФИО или тип!");
      }
    },
    deleteMan(row, index) {
      let self = this;
      if (row.ManId) {
        self.startLoading();
        this.axios
          .post("/Trip/deleteMan", {
            manId: row.ManId,
          })
          .then((resp) => {
            self.stopLoading();
            if (!resp.data.Success) {
              self.showError(
                "Удаление запрещено! Есть рейсы, привязанные к экипажу"
              );
            } else {
              self.ManList.splice(index, 1);
            }
          })
          .catch((e) => {
            self.stopLoading();
            self.showError("Произошла ошибка");
            console.log(e);
          });
      } else {
        self.ManList.splice(index, 1);
      }
    },
    saveCar(row, index) {
      let self = this;
      if (row.CarNumber) {
        self.startLoading();
        this.axios
          .post("/Trip/saveCar", {
            car: row,
          })
          .then((resp) => {
            self.stopLoading();
            self.$set(self.CarList, index, resp.data.Data);
          })
          .catch((e) => {
            self.stopLoading();
            self.showError("Произошла ошибка");
            console.log(e);
          });
      } else {
        self.showError("Не задан номер авто!");
      }
    },
    deleteCar(row, index) {
      let self = this;
      if (row.CarId) {
        self.startLoading();
        this.axios
          .post("/Trip/deleteCar", {
            carId: row.CarId,
          })
          .then((resp) => {
            self.stopLoading();
            if (!resp.data.Success) {
              self.showError(
                "Удаление запрещено! Есть заказы, привязанные к авто"
              );
            } else {
              self.CarList.splice(index, 1);
            }
          })
          .catch((e) => {
            self.stopLoading();
            self.showError("Произошла ошибка");
            console.log(e);
          });
      } else {
        self.CarList.splice(index, 1);
      }
    },
    addCar() {
      this.CarList.push({ IsEdit: true });
    },
    cancelCar(index) {
      this.$set(this.CarList[index], "IsEdit", false);
    },
    editCar(index) {
      this.$set(this.CarList[index], "IsEdit", true);
    },
    addMan() {
      this.ManList.push({ IsEdit: true });
    },
    cancelMan(index) {
      this.$set(this.ManList[index], "IsEdit", false);
    },
    editMan(index) {
      this.$set(this.ManList[index], "IsEdit", true);
    },
    unbindOrders() {
      let self = this;
      self.startLoading();
      this.axios
        .post("/Trip/unbindToTrip", {
          orders: self.tableOrdersSel.map(function (item) {
            return item.OrderId;
          }),
        })
        .then((resp) => {
          self.stopLoading();
          self.tableOrders = resp.data.Data;
        })
        .catch((e) => {
          self.stopLoading();
          self.showError("Произошла ошибка");
          console.log(e);
        });
    },
    bindOrders() {
      let self = this;
      self.startLoading();
      this.axios
        .post("/Trip/bindToTrip", {
          orders: self.tableOrdersSel.map(function (item) {
            return item.OrderId;
          }),
          tripId: self.orderTripId,
        })
        .then((resp) => {
          self.stopLoading();
          self.tableOrders = resp.data.Data;
        })
        .catch((e) => {
          self.stopLoading();
          self.showError("Произошла ошибка");
          console.log(e);
        });
    },
    handleSelectionOrders(val) {
      this.tableOrdersSel = val;
    },
    searchOrders() {
      let self = this;
      let ordsRequest = this.axios.post("/Trip/searchOrders", {
        tripDate: self.deliveryDate,
      });
      let ordTripsRequest = this.axios.post("/Trip/getTrips", {
        tripDate: self.deliveryDate,
      });
      this.axios
        .all([ordsRequest, ordTripsRequest])
        .then(
          self.axios.spread((...responses) => {
            self.tableOrders = responses[0].data.Data;
            self.tableOrderTrips = responses[1].data.Data;
            self.stopLoading();
          })
        )
        .catch((error) => {
          console.log(error);
          self.stopLoading();
          self.showError("Произошла ошибка!");
        });
    },
    saveTrip(row, index) {
      let self = this;
      if (row.CarId && row.TripDate) {
        self.startLoading();
        this.axios
          .post("/Trip/saveTrip", {
            trip: row,
          })
          .then((resp) => {
            self.stopLoading();
            self.$set(self.tableTrips, index, resp.data.Data);
          })
          .catch((e) => {
            self.stopLoading();
            self.showError("Произошла ошибка");
            console.log(e);
          });
      } else {
        self.showError("Не выбрано авто или дата рейса!");
      }
    },
    editTrip(index) {
      this.$set(this.tableTrips[index], "IsEdit", true);
    },
    cancelTrip(index) {
      this.$set(this.tableTrips[index], "IsEdit", false);
    },
    deleteTrip(row, index) {
      let self = this;
      if (row.TripId) {
        self.startLoading();
        this.axios
          .post("/Trip/deleteTrip", {
            tripId: row.TripId,
          })
          .then((resp) => {
            self.stopLoading();
            self.tableTrips.splice(index, 1);
          })
          .catch((e) => {
            self.stopLoading();
            self.showError("Произошла ошибка");
            console.log(e);
          });
      } else {
        self.tableTrips.splice(index, 1);
      }
    },
    searchTrips() {
      this.load();
    },
    loadSimpleDictionaries(resp) {
      this.CarList = resp.data.CarsData;
      this.ManList = resp.data.ManList;
      this.TransReasonList = resp.data.TransReasonList;
    },
    showSuccess(message) {
      this.$message({
        message: message,
        type: "success",
      });
    },
    showError(message) {
      this.$message.error(message);
    },
    startLoading() {
      this.$loading();
    },
    stopLoading() {
      this.$loading().close();
    },
    addTrip() {
      this.tableTrips.push({ TripDate: this.tomorrowStr, IsEdit: true });
    },
    load() {
      this.startLoading();
      let self = this;
      let dictRequest = this.axios.post("/Trip/getDictionaries", {});
      let tripsRequest = this.axios.post("/Trip/getTrips", {
        tripDate: self.tripDate,
      });
      this.axios
        .all([dictRequest, tripsRequest])
        .then(
          self.axios.spread((...responses) => {
            self.loadSimpleDictionaries(responses[0]);
            self.tableTrips = responses[1].data.Data;
            self.stopLoading();
          })
        )
        .catch((error) => {
          console.log(error);
          self.stopLoading();
          self.showError("Произошла ошибка!");
        });
    },
  },
  mounted: function () {
    this.load();
  },
};
</script>

<style scoped>
</style>
