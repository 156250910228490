<template>
  <div>
    <div>{{ WorkerIsActive }}</div>
    <div>
      {{ "Последний запуск: " + getStringDate(serviceWorker.LastDate) }}
    </div>
    <el-table size="mini" :data="workerData" border>
      <el-table-column prop="JobName" label="Имя задачи" align="center">
      </el-table-column>
      <el-table-column
        prop="ModifyDateStr"
        label="Последний запуск"
        align="center"
      >
        <template slot-scope="scope">
          <div>{{ getStringDate(scope.row.LastExecution) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="Расписание" align="center">
        <template slot-scope="scope">
          <el-button size="mini" @click="showDialogTimeTable(true, scope.row)"
            >Расписание</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="Логи" align="center">
        <template slot-scope="scope">
          <el-button size="mini" @click="showDialogLogs(true, scope.row)"
            >Логи</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="Перезапустить задачу" align="center">
        <template slot-scope="scope">
          <div
            v-show="scope.row.IsNeedRestart"
            style="display: inline; margin: 10px"
          >
            Запланирован перезапуск
          </div>
          <el-button size="mini" @click="restartJob(scope.row, scope.$index)"
            >Перезапустить</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      width="80%"
      :center="true"
      title="Расписание"
      :visible.sync="dialogTimeTable"
    >
      <el-form size="mini" :model="timeTable" label-width="200px">
        <el-form-item label="Тип расписания">
          <el-select
            size="mini"
            v-model="timeTable.TableType"
            placeholder="Выберите из списка"
          >
            <el-option
              v-for="item in TableTypeList"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Период, сек" v-show="timeTable.TableType == 0">
          <el-input-number
            size="mini"
            v-model="timeTable.Period"
            :controls="false"
            maxlength="150"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="Моменты времени" v-show="timeTable.TableType == 1">
          <div style="margin: 10px">
            <el-button @click="addExecMoment">Добавить</el-button>
          </div>
          <el-table :data="timeTable.ExecMoments">
            <el-table-column label="Момент времени">
              <template slot-scope="scope">
                <el-time-picker
                  v-model="scope.row.ExecMoment"
                  placeholder="Время вызова"
                >
                </el-time-picker>
              </template>
            </el-table-column>
            <el-table-column label="">
              <template slot-scope="scope">
                <el-button @click="deleteExecMoment(scope.$index)"
                  >Удалить</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <div>
        <el-button @click="saveTimeTable">Сохранить</el-button>
      </div>
    </el-dialog>
    <el-dialog
      width="80%"
      :center="true"
      title="Логи"
      :visible.sync="dialogLogs"
    >
      <span style="text-align: center">
        <el-table :data="logs" border size="mini" height="500">
          <el-table-column
            label="Id"
            prop="Id"
            header-align="center"
            align="center"
            width="70"
          ></el-table-column>
          <el-table-column
            prop="Date"
            label="Дата лога"
            header-align="center"
            align="center"
            width="200"
          >
            <template slot-scope="scope"
              ><div>{{ getStringDate(scope.row.Date) }}</div></template
            >
          </el-table-column>
          <el-table-column
            prop="LogType"
            label="Тип лога"
            header-align="center"
            align="center"
            width="150"
          >
            <template slot-scope="scope">
              <div>{{ getLogTypeName(scope.row.LogType) }}</div>
            </template>
          </el-table-column>
          <el-table-column
            prop="Message"
            label="Текст"
            header-align="center"
            align="center"
          >
          </el-table-column>
        </el-table>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { dates } from "../scripts/dates";
export default {
  name: "ServiceWorker",
  data: function () {
    return {
      serviceWorker: {
        IsActive: true,
        LastDate: null,
      },
      workerData: [],
      timeTable: {
        TableType: null,
        Period: null,
        ExecMoments: [],
      },
      logs: [],
      TableTypeList: [
        { Id: 0, Name: "Периодически" },
        { Id: 1, Name: "Ежедневно" },
      ],
      LogTypeList: [
        { Id: 0, Name: "Запуск задачи" },
        { Id: 1, Name: "Завершение задачи" },
        { Id: 2, Name: "Запуск планировщика" },
        { Id: 3, Name: "Остановка планировщика" },
        { Id: 4, Name: "Сообщение" },
        { Id: 5, Name: "Ошибка" },
      ],
      dialogTimeTable: false,
      dialogLogs: false,
    };
  },
  computed: {
    WorkerIsActive() {
      return this.serviceWorker.IsActive
        ? "Планировщик активен"
        : "Планировщик неактивен";
    },
  },
  methods: {
    saveTimeTable() {
      this.axios
        .post("/ServiceWorker/saveTimeTable", {
          timeTable: this.timeTable,
        })
        .then((resp) => {
          self.stopLoading();
          //self.$set(self.workerData, index, resp.data.Data);
          //self.workerData[index] = resp.data.Data;
        })
        .catch((e) => {
          self.stopLoading();
          console.log(e);
          self.showError("Произошла ошибка");
        });
    },
    deleteExecMoment(index) {
      this.timeTable.ExecMoments.splice(index, 1);
    },
    addExecMoment() {
      this.timeTable.ExecMoments.push({});
    },
    restartJob(row, index) {
      let self = this;
      this.axios
        .post("/ServiceWorker/restartJob", {
          jobName: row.JobName,
        })
        .then((resp) => {
          self.stopLoading();
          self.$set(self.workerData, index, resp.data.Data);
          //self.workerData[index] = resp.data.Data;
        })
        .catch((e) => {
          self.stopLoading();
          console.log(e);
          self.showError("Произошла ошибка");
        });
    },
    getLogTypeName(logType) {
      return this.LogTypeList.find(function (item) {
        return item.Id === logType;
      }).Name;
    },
    showDialogTimeTable(isShow, row) {
      if (isShow) {
        this.timeTable = {
          TableType: row.TableType,
          Period: row.Period,
          ExecMoments: row.ExecMoments,
        };
      }

      this.dialogTimeTable = isShow;
    },
    showDialogLogs(isShow, row) {
      if (isShow) {
        let self = this;
        self.startLoading();
        this.axios
          .post("/ServiceWorker/getLogs", {
            jobName: row.JobName,
          })
          .then((resp) => {
            self.stopLoading();
            self.logs = resp.data.Data;
            self.dialogLogs = isShow;
          })
          .catch((e) => {
            self.stopLoading();
            console.log(e);
            self.showError("Произошла ошибка");
          });
      } else {
        this.dialogLogs = isShow;
      }
    },
    startLoading() {
      this.$loading();
    },
    stopLoading() {
      this.$loading().close();
    },
    showSuccess(message) {
      this.$message({
        message: message,
        type: "success",
      });
    },
    showError(message) {
      this.$message.error(message);
    },
    loadWorkerState() {
      let self = this;
      this.axios
        .post("/ServiceWorker/isWorkerActive", {})
        .then((resp) => {
          self.serviceWorker = resp.data.Data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getData() {
      let self = this;
      this.startLoading();
      this.axios
        .post("/ServiceWorker/getJobs", {})
        .then((resp) => {
          self.workerData = resp.data.Data;
          self.stopLoading();
        })
        .catch((e) => {
          self.stopLoading();
          self.showError("Произошла ошибка");
          console.log(e);
        });
    },
    getStringDate(dat) {
      return dates.methods.toStringDateTime(dat);
    },
  },
  created: function () {
    this.interval = setInterval(this.loadWorkerState, 5000);
  },
  beforeDestroy: function () {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  },
  mounted: function () {
    this.loadWorkerState();
    this.getData();
  },
};
</script>

<style scoped>
</style>
